import React, { useState} from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  IconButton,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckoutCheckbox from "./CheckoutCheckbox.web";
import CheckoutRadioButton from "./CheckoutRadioButton.web";
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },
    MuiCardContent:{
      root:{
        paddingTop:0,
        paddingBottom:0,
        paddingLeft:'16px',
        paddingRight:'16px',
      },
    }

     },
});
const PurpleCheckbox = withStyles({
  root: {
      color: '#9267ac',
    '&$checked': {
      color: '#9267ac',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CheckoutSubjectCheckbox = ({
   checkbox_img,
   handleChangeRadio,
   valueRadio,
   handlChangeCheckBox,
   customSubEnglish,
   RecommSubEnglishGrammar,
  }: any) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  };

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>

        <Card elevation={0} style={webStyle.subjectCardStyle}>
          <CardContent>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                 <FormControlLabel
                  control={
                 <PurpleCheckbox
                  // style={{ marginLeft: '0.5em'}}
                  checked={customSubEnglish}
                  onChange={handlChangeCheckBox}
                  name="customSubEnglish"
                />
                  }
                  label={<span style={webStyle.checkboxLabelStyle}>Maths</span>}
                />
              </Box>

              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
               {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </IconButton>
            </Box>

            <Typography style={webStyle.textRecommended} > Recommended</Typography>

            {
              !expanded ?
                (<>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Box>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox
                            checked={RecommSubEnglishGrammar}
                            onChange={handlChangeCheckBox}
                            name="RecommSubEnglishGrammar"
                />
                  }
                  label={<span style={webStyle.checkboxSubLabelStyle}>Targeting Mathematics 1A (TB + WB + TM)</span>}
                />
                  </Box>
                    <Typography style={webStyle.cardAmtStyle}>  ₱ 1,239.00 </Typography>
                  </Box>
                </>)
                : null
            }
          </CardContent>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
          {/* Recommended Check box Expand */}
            <CardContent style={webStyle.cardContentStyle}>
              <CheckoutCheckbox checkbox_img={checkbox_img} 
              RecommSubEnglishGrammar={RecommSubEnglishGrammar}
              handlChangeCheckBox = {handlChangeCheckBox}
               />
            </CardContent >

            <Typography style={webStyle.textAlternate } > Alternate</Typography>

            <CardContent style={webStyle.cardContentStyleRadio}>
           
            <RadioGroup aria-label="alternateTextbooks" name="alternateTextbooks"
             value={valueRadio} 
             onChange={handleChangeRadio}>
              <CheckoutRadioButton checkbox_img={checkbox_img} value={'lifepac'}/>
              <CheckoutRadioButton checkbox_img={checkbox_img} value={'maths_for_life'}/>
              <CheckoutRadioButton checkbox_img={checkbox_img} value={'primary_maths'}/>
            
              </RadioGroup>
             
            </CardContent>

          </Collapse>
        </Card>


      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {
  cardContentStyle:{
    paddingTop:0,
    // paddingBottom:'1em',
    // paddingLeft:'1em',
    // paddingRight:'1em',
     
  },
  cardContentStyleRadio:{
    padding:'0, 1em'
  },

  subjectCardStyle: {
    marginTop: '20px',
    paddingTop:'1em',
    borderRadius: '15px',
    border: '1px solid #e2e2ea',
   },
  checkboxLabelStyle: {
    //height: '17px',
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
  },

  checkboxSubLabelStyle: {
   // height: '14px',
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '14px',
  },
  cardAmtStyle: {
    marginRight: '0.5em',
    color: ' #5e5e5e',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },
  textRecommended: {
    paddingBottom:0,
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },
  textAlternate: {
   marginLeft:'1.5em',
    marginTop:'0.8em',
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },

};

export default CheckoutSubjectCheckbox;
// Customizable Area End
