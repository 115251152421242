import React from 'react'
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";


const PaymentPage = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }


    }
    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button>pay</button>
        </form>


    )
}

export default PaymentPage