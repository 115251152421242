

import React from "react";

import {
  // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { dragonPay, stripe , ipay, mastercard, visa, paypal} from './assets';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"






const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: '#99cb51',
        '&$checked': {
            color: '#99cb51',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const PurpleRadio = withStyles({
    root: {
        color: '#9267ac',
        '&$checked': {
            color: '#9267ac',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);


// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,

} from "./OrderDetailViewController";
// Customizable Area End
export default class Checkout6 extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={this.handleDrawerOpen}
                                navigation={this.props.navigation}
                            
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', width: '95%', padding: "0" }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto', padding: "0" }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                         {/* stepper tabs start */}
                                         <Grid container style={webStyle.stepperContainer}>

                                            <Grid item xs={12} style={webStyle.stepperGridItem}>
                                                <Box style={webStyle.stepperMenuBox}>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText1}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText2}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText3}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText4}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText5}</Typography>
                                                </Box>
                                                <Box style={webStyle.stepperLineBox}></Box>
                                            </Grid>
                                         </Grid>
                                        {/* stepper tabs ends */}
                                        <Container maxWidth={false} style={webStyle.mainWrapper}>
                                            <Container maxWidth={false} style={webStyle.GridWrapper}>
                                                <Typography style={webStyle.mainheader}>Checkout</Typography>

                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={{ fontSize: "18px",...webStyle.mainheadingSubProgramPriceTotalSubject}}>Sub Total </Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 2,33,388.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <hr style={{ border: "1px solid #ececf1", height: "1px" }}></hr>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={{ fontSize: "22px",...webStyle.mainheadingSubProgramPriceTotalSubject}}>
                                                        Choose Payment Option
                                                    </Typography>
                                                 </Box>
                                                <RadioGroup 
                                                defaultValue="stripe"  
                                                aria-label="paymentOption" 
                                                name="paymentOption"
                                                value={this.state.checkoutDataObj.paymentOption}
                                                onChange={this.handleChangePaymentOption}
                                                >
                                                    <Grid container spacing={3} style={{ display: 'flex', paddingTop: '0.5em' }}>
                                                        <Grid item xs={12} md={4} lg={3}>
                                                            <Box style={{ border: this.state.checkoutDataObj.paymentOption=='stripe'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="stripe" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={stripe}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='stripe'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} lg={4}>
                                                            <Box style={{ border: this.state.checkoutDataObj.paymentOption=='dragonpay'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="dragonpay" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={dragonPay}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='dragonpay'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.paymentOptionContent}>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Typography>
                                                 </Box>

                                                {/* ================================== Payment methods ===================================== */}
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={{ paddingTop: '1em',fontSize: "22px",...webStyle.mainheadingSubProgramPriceTotalSubject}}>
                                                        Choose Payment Method
                                                    </Typography>
                                                 </Box>
                                                <RadioGroup 
                                                aria-label="paymentMethod"
                                                 name="paymentMethod" 
                                                 defaultValue="mastercard" 
                                                 value={this.state.checkoutDataObj.paymentMethod}
                                                 onChange={this.handleChangePaymentMethod}
                                                 >
                                                    <Grid container spacing={3} style={{ display: 'flex', paddingTop: '0.5em' }}>
                                                        <Grid item xs={12} md={3} lg={2}>
                                                            <Box style={{ border: this.state.checkoutDataObj.paymentMethod=='mastercard'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="mastercard" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={mastercard}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='mastercard'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={2}>
                                                            <Box style={{ border: this.state.checkoutDataObj.paymentMethod=='visa'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="visa" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={visa}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='visa'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={2}>
                                                            <Box style={{ border: this.state.checkoutDataObj.paymentMethod=='ipay'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="ipay" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={ipay}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='ipay'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} lg={2}>
                                                        <Box style={{ border: this.state.checkoutDataObj.paymentMethod=='paypal'? "1px solid #fd0008" : "1px solid #e2e2ea", ...webStyle.GridWrapperBorder}}>
                                                                <FormControlLabel value="paypal" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={paypal}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='paypal'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                                {/* ================================== Payment installments ===================================== */}
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={{ paddingTop: '1em',fontSize: "22px",...webStyle.mainheadingSubProgramPriceTotalSubject}}>
                                                        Choose Payment Method
                                                    </Typography>
                                                 </Box>
                                                
                                                 <RadioGroup
                                                   defaultValue="fullPaymentWithoutReservation"
                                                   aria-label="ReservationFee"
                                                   name="ReservationFee"
                                                   value={this.state.checkoutDataObj.reservationFee}
                                                   onChange={this.handleChangeReservationFee}
                                                    >
                                                <Grid container  style={{display: 'flex', paddingTop: '0.5em',  marginBottom: "1em" }}>
                                                
                                                    <Grid item xs={12} md={6} style={webStyle.installlmentMethods}>
                                                        <Box style={{ marginTop: "1em" ,marginBottom: "0.6em"}}>
                                                            <Typography style={webStyle.paymentMethods}>
                                                                Without Reservation fee</Typography>
                                                        </Box>
                                                            <FormControlLabel value="fullPaymentWithoutReservation" control={<PurpleRadio size="small" />} label="Full Payment" ></FormControlLabel>
                                                            <FormControlLabel  value="twoInstallmentsWithoutReservation" control={<PurpleRadio size="small" />}  label="2 installments"
                                                             disabled={this.state.checkoutDataObj.paymentOption=='dragonpay' ? true : false} ></FormControlLabel>
                                                            <FormControlLabel value="fiveInstallmentsWithoutReservation" control={<PurpleRadio size="small" />} label="5 installments" 
                                                            disabled={this.state.checkoutDataObj.paymentOption=='dragonpay' ? true : false} ></FormControlLabel>
                                                     
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={webStyle.installlmentMethods}>
                                                    <Box style={{ marginTop: "1em" ,marginBottom: "0.6em"}}>
                                                         <Typography style={webStyle.paymentMethods}>
                                                                With Reservation fee</Typography>
                                                        </Box>
                                                            <FormControlLabel value="fullPaymentWithReservation" control={<PurpleRadio size="small" />} label="Full Payment" ></FormControlLabel>
                                                            <FormControlLabel value="threeInstallmentsWithReservation" control={<PurpleRadio size="small" />} label="3 installments" 
                                                            disabled={this.state.checkoutDataObj.paymentOption=='dragonpay' ? true : false} ></FormControlLabel>
                                                            <FormControlLabel value="sixInstallmentsWithReservation" control={<PurpleRadio size="small" />} label="6 installments"
                                                            disabled={this.state.checkoutDataObj.paymentOption=='dragonpay' ? true : false}  ></FormControlLabel>
                                                    </Grid>
                                                  
                                               </Grid>
                                               </RadioGroup>
                                            </Container>
                                        </Container>
                                        <Grid container spacing={4} style={{ marginBottom: '6em' }} >
                                            <Grid item xs={12} style={{ marginTop: '2em', textAlign: "right" }}>
                                                <Button variant="contained" style={{ textTransform: "none", textAlign: "center", ...webStyle.Accreditation }}
                                                >Switch Program / Accreditation </Button>
                                                <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.finalCheckout }} 
                                                //this.finalCheckoutForPayment will lead to stripe intgration screen
                                                onClick={this.handleCheckoutFormseven}>
                                                    {this.state.isP ? <CircularProgress /> : "Checkout"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Allignment1: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '2.7em'
    },
    Allignment: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '2.7em'
    },
    Accreditation: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#9267ac",
    },
    finalCheckout: {
        width: "200px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px", marginLeft: '1em',
    },
    mainheadingSubProgram1: {
        color: " #192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
    },
    mainheader: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
    },
    GridWrapperBorder: {
        borderRadius: "15px",
        background: "#ffffff",
        padding: "1em",
        marginTop: '2em',
    },
    mainheadingSubProgramPriceTotalSubject: {
        color: "#192126",
        fontFamily: "Poppins",
        fontWeight: 600,
    },
    paymentMethods: {
        color: "#171725",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize:"16px",
    },
    paymentOptionContent: {
        color: "#192126",
        fontFamily: "Poppins",
        fontWeight: 300,
        fontSize:"14px",
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
        padding: '0em',
    },
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "2em",
        marginTop: "2em",
    },
    stepperContainer:{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      },
      stepperGridItem:{ 
        display: 'flex',  
        alignItems: 'center', 
        justifyContent: 'center',
        flexDirection:'column' as 'column',
      },
      stepperMenuBox:{
        width:'100%',
        padding: '1.3vw', 
        marginTop: '1.3vw', 
        marginBottom: '0.6vw', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between' 
      },
      stepperMenuTextStyle: {
        fontFamily: 'Poppins',
        fontSize: '0.9vw',
        fontWeight: 600,
        color: '#99cb51',
      },
      stepperLineBox:{
        width:'98%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
         borderBottom:'0.2vw solid #99cb51'
      },
      installlmentMethods:{
        display: 'flex',
        flexDirection:'column' as 'column',
    },
};
// Customizable Area End
