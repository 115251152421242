import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { GlobalContext } from "../../customform/src/useContext";
let Country:any = require('country-state-city').Country;
let City = require('country-state-city').City;


export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)",
};

const options = {
  title: "Select Image",
  storageOptions: {
    skipBackup: true,
    path: "images",
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  postSellerDetailsMessageId: any;
  sellerID: any;
  shopName: string;
  address: string;
  gstin: string;
  selectedServices: number[];
  showSuccessModal: boolean;
  token: string;
  lat: any;
  long: any;
  ///studentProfileStart///
  studentFormData: {
    ///studentdetailsStart///
    studentFirstName: string;
    studentMiddleName: string;
    studentLastName: string;
    studentGender: string;
    studentDate: any;
    studentCountry: any;
    studentBirth: any;
    studentCountryAutoCompleteValue:any;
    studentBirthAutoCompleteValue:any;
    studentAge: any;
    studentReligion: any;
    studentReligionOthers: any;
    studentNationality: any;
    studentCountryOfResidence: any;
    countryResiAutoCompleteValue:any;
    studentCountryOfResidenceArray: [];
    studentCityOfResidence: any;
    cityResiAutoCompleteValue:any; 
    barangaySubData: any;
    barangayBirth: any;
    studentChildsFirstLanguage: any;
    studentEnterChildsFirstLanguage: any;
    studentWouldYouLikeToApplyForAScholarShip: boolean;
    studentScholarShopType: any;
    studentTypesOfScholarShip: any;
    studentProofOfRelationship: any;
    studentCauseOfDeath: any;
    studentfatherDeath: any;
    studentSpecification: any;
    studentRefferalProgram: boolean;
    studentAdvocatecode: any;
    studentPwd: boolean;
    studentPwdIdNumber: any;
    studentParentDiscount: boolean;
    studentSoloParentId: any;
    studentErrors: any;
    studentResidentialAddress: string;
    ///studentdetailsEnd///
    ///previousSchoolStart///
    previousSchoolLastGradeLevel: any;
    previousSchoolCurriculum: any;
    previousSchoolCurriculumOthers: any;
    previousSchoolAttended: string;
    previousSchoolContact: string;
    previousSchoolLastSchoolYear: string;
    previousSchoolEmail: string;
    previousSchoolAddress: string;
    previousSchoolLearnerReferenceNumber: string;
    previousSchoolErrors: any;
    ///previousSchoolStart///
    ///programDetailsStart///
    programDetailsHub: any;
    programDetailsCommunity: any;
    programDetailsIncomingGradeLevel: any;
    programDetailsProgram: any;
    programDetailsAccreditation: any;
    programDetailsCycle: any;
    programDetailsShsTrack: any;
    programDetailsErrors: any,
    ///programDetailsEnd///
    ///parentsInfoStart///
    parentsInfoFathersFirstName: string;
    parentsInfoFathersMiddleName: string;
    parentsInfoFathersLastName: string;
    parentsInfoFathersOccupation: string;
    parentsInfoFathersCountryCode: string;
    parentsInfoFathersPhoneNumber: string;
    parentsInfoFathersEmailAddress: string;
    parentsInfoMothersFirstName: string;
    parentsInfoMothersMiddleName: string;
    parentsInfoMothersLastName: string;
    parentsInfoMothersOccupation: string;
    parentsInfoMothersCountryCode: string;
    parentsInfoMothersPhoneNumber: string;
    parentsInfoMothersEmailAddress: string;
    parentsInfoMaritalStatus: string;
    parentsInfoGuardiansName: string;
    parentsInfoGuardiansAddress: string;
    parentsInfoGuardiansOccupation: string;
    parentsInfoGuardiansCountryCode: string;
    parentsInfoGuardiansPhoneNumber: string;
    parentsInfoGuardiansEmailAddress: string;
    parentsInfoRelationshipToTheStudent: string;
    parentsValidationErrors: any;
    ///parentsInfoEnd///
    //////////////file ulplad start/////
    fileLetterRecommendation: any;
    filePwd: any;
    fileBirthCertificate: any;
    fileReportCard: any;
    fileSchoolRecords: any;
    fileLearnerReference: any;
    filePicture: any;
    fileSoloParent: any;
    fileParentValid: any;
    fileAchivementsProof: any;
    fileChurchLetter: any;
    fileIncomeProof: any;
    fileFatherDeathCertificate: any;
    fileStudentBirthCertificate: any;
    fileFamilyDetails: any;
    fileElectricBill: any;
    fileBirthCertificateLuovaStaff: any;
    fileParentBirthCertificateLuovaStaff: any;
    fileErrors: any;
    ///////////file upload end ////////////
    ///reviweFormStart///
    reviewReasonForFinallyRegistering: any;
    reviewValidationErrors: any;
    ///reviweFormEnd///
    ///TuitionFeeTermsAndConditionsStart///
    TermsAndConditionsAgreeGeneralTerms: boolean,
    TermsAndConditionsRefundPolicy: boolean,
    TermsAndConditionsPrivacyPolicy: boolean,
    TermsAndConditionsPrivacyPolicyPictureAuthorization: boolean,
    TermsAndConditionsValidations: any;
    ///TuitionFeeTermsAndConditionsEnd///
    ///syatus Start///
    studentStatus:any;
    ///status End///
  };

  countryCodeSelected: string;
  countryCodeSelectedData: any;
  uniqueCountryCodeSelectedData: any;
  country: string;
  countriesData: any;
  city: string;
  cityData: any;
  countriesResidenceData: any;
  countryResidenceCodeSelectedData: any;
  uniqueCountryResidenceCodeSelectedData: any;
  cityDataResidence: any;
  nationalitiesData: any;
  nationalitiesSelectedData: any;
  religionSelectedData: any;
  religionData: any;
  languagesSelectedData: any;
  languagesData: any;
  scholarshipSelectedData: any;
  scholarshipData: any;
  hubSelectedData: any;
  hubData: any;
  programDetailgradesSelectedData: any;
  programDetailgradesData: any;
  programDetailprogramSelectedData: any;
  programDetailprogramSelectedDataMinistry: any;
  programDetailprogramData: any;
  programDetailcommunitiesSelectedData: any;
  programDetailcommunitiesData: any;
  programDetailaccreditationsSelectedData: any;
  programDetailaccreditationsData: any;
  parentsInfoFathercountriesSelectedData: any;
  parentsInfoFathercountriesData: any;
  parentsInfoFathercountriesuniqueCountryCodeSelectedData: any;
  parentsInfoMothercountriesSelectedData: any;
  parentsInfoMothercountriesData: any;
  parentsInfoGuardiancountriesSelectedData: any;
  parentsInfoGuardiancountriesData: any;
  reasonForRegisterData:any;
   //Program level starts///
  programLevelData:any;
  programLevelHubData:any;
  programLevelCycleData:any;
  programLevelAccData:any;
  programLevelGradesData:any;
  programLevelSHSData:any;
  dynamicHub:any;
  dynamicCycle:any;
  dynamicAcc:any;
  dynamicGrade:any;
  //Program level ends///


   //Program level ends///
  reasonForRegisterSelectedData:any;
  gradeData: any;
  gradeSelectedData: any;
  curriculumData: any;
  curriculumSelectedData: any;
  count: any;
  onApicall: any;
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarMessage:any;
  ///files upload state
  files: any;
  filePwd: any;
  //modal open
  open: boolean;
  //Stepper start///
  activeStep: any;
  ///Stepper end///
  displayModal: boolean;
  //Drawer
  openD: any;
  drawerWidth: any;
  getAllCountries: any;
  getAllCountriesOne: any;
  getAllCities: any;
  getAllCitiesOne: any;
  cityLoader: any;
  uniqueCountryCodeData: any;
  uniqueCountryCodeDataOne: any;
  byrangayData: any;
  byrangayDataBirth: any;
  singleByrangayData: any[];
  filePending:any;
  loadingPendingStudent:any;
  provincesData:any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  static contextType = GlobalContext
  prevSchoolApiCallId: any
  apiCountryCallId: any
  apiGradeCallId: string = "";
  apiCurriculumCallId: string = "";
  apiReligionCallId: any;
  apiNationalitiesCallId: any;
  apilanguagesCallId: any;
  scholarshipCallId: any;
  apiCountryResidenceCallId: any;
  apiHubCallId: any;
  apiProgramDetailGradesCallId: any;
  apiProgramDetailProgramCallId: any;
  apiProgramDetailCommunitiesCallId: any;
  apiProgramDetailAccreditationsCallId: any;
  apiReasonForRegisterCallId: any;
  apiParentsInfoFathercountriesDataCallId: any;
  apiParentsInfoMothercountriesDataCallId: any;
  apiParentsInfoMothercountriesGuardianDataCallId: any;
  apiBarangaysCallId: any;
  apiPendingStudentDetailId:any;
  apiProgramLevelsCallId:any;
  apiProgramLevelsDynamicCallId:any;
  apiProvincesCallId: string = "";
  apiBarangaysBirthCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      getSellerDetailsMessageId: "",
      postSellerDetailsMessageId: "",
      sellerID: "",
      shopName: "",
      address: "",
      gstin: "",
      selectedServices: [],
      token: "",
      showSuccessModal: false,
      lat: 0,
      long: 0,
      /////studentFormStart////
      studentFormData: {
        studentFirstName: "",
        studentMiddleName: "",
        studentLastName: "",
        studentGender: "choose",
        studentDate: "",
        studentCountry: { id: '', value: "choose" },
        studentBirth: { id: '', value: "choose" },
        studentCountryAutoCompleteValue: {value: "" },
        studentBirthAutoCompleteValue: {value: "" },
        studentAge: "",
        studentReligion: { id: '', value: "choose" },
        studentReligionOthers: '',
        studentNationality: { id: '', value: "choose" },
        studentCountryOfResidence: { id: '', value: "choose" },
        countryResiAutoCompleteValue:{value: "" },
        studentCountryOfResidenceArray: [],
        studentCityOfResidence: { id: '', value: "choose" },
        cityResiAutoCompleteValue:{value: "" },
        barangaySubData: { id: '', value: "choose" },
        barangayBirth: { id: '', value: "choose" },
        studentChildsFirstLanguage: { id: '', value: "choose" },
        studentEnterChildsFirstLanguage: '',
        studentWouldYouLikeToApplyForAScholarShip: false,
        studentScholarShopType: "choose",
        studentTypesOfScholarShip: { id: '', value: "choose" },
        studentProofOfRelationship: 'choose',
        studentfatherDeath: '',
        studentCauseOfDeath: 'choose',
        studentSpecification: 'choose',
        studentRefferalProgram: false,
        studentAdvocatecode: "",
        studentPwd: false,
        studentPwdIdNumber: '',
        studentParentDiscount: false,
        studentSoloParentId: "",
        studentResidentialAddress: '',
        studentErrors: {
          studentFirstName: "",
          studentMiddleName: "",
          studentLastName: "",
          studentGender: "",
          studentDate: "",
          studentCountry: "",
          studentBirth: "",
          studentAge: "",
          studentReligion: "",
          studentNationality: "",
          studentCountryOfResidence: "",
          studentCityOfResidence: "",
          studentChildsFirstLanguage: "",
          studentEnterChildsFirstLanguage: '',
          studentWouldYouLikeToApplyForAScholarShip: "",
          studentScholarShopType: "",
          studentTypesOfScholarShip: "",
          studentProofOfRelationship: '',
          studentfatherDeath: '',
          studentRefferalProgram: "",
          studentAdvocatecode: "",
          studentPwd: "",
          studentPwdIdNumber: '',
          studentParentDiscount: "",
          studentSoloParentId: "",
          studentResidentialAddress: '',
        },
        /////studentFormend////
        /////previousSchoolStart//////
        previousSchoolLastGradeLevel: { id: "", value: "choose" },
        previousSchoolCurriculum: { id: "", value: "choose" },
        previousSchoolCurriculumOthers : '',
        previousSchoolAttended: '',
        previousSchoolContact: '',
        previousSchoolLastSchoolYear: '',
        previousSchoolEmail: '',
        previousSchoolAddress: '',
        previousSchoolLearnerReferenceNumber: '',
        previousSchoolErrors: {},
        ///previousSchoolend///
        ///programDetailsStart///
        programDetailsHub: { id: "", value: "choose" },
        programDetailsCommunity: { id: "", value: "choose" },
        programDetailsIncomingGradeLevel: { id: "", value: "choose" },
        programDetailsProgram: { id: "", value: "choose" },
        programDetailsAccreditation: { id: "", value: "choose" },
        programDetailsCycle: "choose",
        programDetailsShsTrack: 'choose',
        programDetailsErrors: {},
        ///programDetailsEnd///
        ///ParentsinfoStart///
        parentsInfoFathersFirstName: "",
        parentsInfoFathersMiddleName: "",
        parentsInfoFathersLastName: "",
        parentsInfoFathersOccupation: "",
        parentsInfoFathersCountryCode: "choose",
        parentsInfoFathersPhoneNumber: "",
        parentsInfoFathersEmailAddress: "",
        parentsInfoMothersFirstName: "",
        parentsInfoMothersMiddleName: "",
        parentsInfoMothersLastName: "",
        parentsInfoMothersOccupation: "",
        parentsInfoMothersCountryCode: "choose",
        parentsInfoMothersPhoneNumber: "",
        parentsInfoMothersEmailAddress: "",
        parentsInfoMaritalStatus: "choose",
        parentsInfoGuardiansName: "",
        parentsInfoGuardiansAddress: "",
        parentsInfoGuardiansOccupation: "",
        parentsInfoGuardiansCountryCode: "choose",
        parentsInfoGuardiansPhoneNumber: "",
        parentsInfoGuardiansEmailAddress: "",
        parentsInfoRelationshipToTheStudent: "",
        parentsValidationErrors: {},
        ///ParentsinfoStart///
        //////////////file upload start/////
        fileLetterRecommendation: { dummy: null, filenm: new File([""], "") },
        filePwd: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileReportCard: { dummy: null, filenm: new File([""], "") },
        fileSchoolRecords: { dummy: null, filenm: new File([""], "") },
        fileLearnerReference: { dummy: null, filenm: new File([""], "") },
        filePicture: { dummy: null, filenm: new File([""], "") },
        fileSoloParent: { dummy: null, filenm: new File([""], "") },
        fileParentValid: { dummy: null, filenm: new File([""], "") },
        fileAchivementsProof: { dummy: null, filenm: new File([""], "") },
        fileChurchLetter: { dummy: null, filenm: new File([""], "") },
        fileIncomeProof: { dummy: null, filenm: new File([""], "") },
        fileFatherDeathCertificate: { dummy: null, filenm: new File([""], "") },
        fileStudentBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileFamilyDetails: { dummy: null, filenm: new File([""], "") },
        fileElectricBill: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },
        fileParentBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },
        fileErrors: {
          fileLetterRecommendation: "",
          filePwd: "",
          fileBirthCertificate: "",
          fileReportCard: "",
          fileSchoolRecords: "",
          fileLearnerReference: "",
          filePicture: "",
          fileSoloParent: "",
          fileParentValid: "",
          fileAchivementsProof: "",
          fileChurchLetter: "",
          fileIncomeProof: "",
          fileFatherDeathCertificate: "",
          fileStudentBirthCertificate: "",
          fileFamilyDetails: "",
          fileElectricBill: "",
          fileBirthCertificateLuovaStaff: "",
          fileParentBirthCertificateLuovaStaff: "",
        },
        ///////////file upload end ////////////
        ///reviweFormStart///
        reviewReasonForFinallyRegistering: { id: "", value: "choose" },
        reviewValidationErrors: {},
        ///reviweFormEnd///

        ///Terms and conditons
        TermsAndConditionsAgreeGeneralTerms: false,
        TermsAndConditionsRefundPolicy: false,
        TermsAndConditionsPrivacyPolicy: false,
        TermsAndConditionsPrivacyPolicyPictureAuthorization: false,
        TermsAndConditionsValidations: {},
        //status
        studentStatus:'pending',
      },
      countriesData: [],
      countryCodeSelectedData: [],
      countryCodeSelected: "",
      uniqueCountryCodeSelectedData: [],
      cityData: [],
      country: "choose",
      city: "choose",
      countriesResidenceData: [],
      countryResidenceCodeSelectedData: [],
      uniqueCountryResidenceCodeSelectedData: [],
      cityDataResidence: [],
      religionData: [],
      religionSelectedData: [],
      nationalitiesData: [],
      nationalitiesSelectedData: [],
      languagesSelectedData: [],
      languagesData: [],
      scholarshipSelectedData: [],
      scholarshipData: [],
      hubSelectedData: [],
      hubData: [],
      programDetailgradesSelectedData: [],
      programDetailgradesData: [],
      programDetailprogramSelectedData: [],
      programDetailprogramSelectedDataMinistry: [],
      programDetailprogramData: [],
      programDetailcommunitiesSelectedData: [],
      programDetailcommunitiesData: [],
      programDetailaccreditationsSelectedData: [],
      programDetailaccreditationsData: [],
      parentsInfoFathercountriesSelectedData: [],
      parentsInfoFathercountriesData: [],
      parentsInfoFathercountriesuniqueCountryCodeSelectedData: [],
      parentsInfoMothercountriesSelectedData: [],
      parentsInfoMothercountriesData: [],
      parentsInfoGuardiancountriesSelectedData: [],
      parentsInfoGuardiancountriesData: [],
      reasonForRegisterData: [],
      //Program level starts///
      programLevelData: {},
      programLevelHubData: [],
      programLevelCycleData: [],
      programLevelAccData:[],
      programLevelGradesData:[],
      programLevelSHSData:[],
      dynamicHub:'',
      dynamicCycle:'',
      dynamicAcc:'',
      dynamicGrade:'',
       //Program level ends///
      reasonForRegisterSelectedData: [],
      gradeData: [],
      gradeSelectedData: [],
      curriculumData: [],
      curriculumSelectedData: [],
      onApicall: false,
      isLoading: false,
      snackbarOpen:false,
      snackbarMessage:{},
      //////files upload state
      files: null,
      filePwd: null,
      count: 0,
      open: false,
      activeStep: 0,
      displayModal: true,
      openD: true,
      drawerWidth: 300,
      getAllCountries: [],
      getAllCountriesOne: [],
      getAllCities: [],
      getAllCitiesOne: [],
      cityLoader: '',
      uniqueCountryCodeData: [],
      uniqueCountryCodeDataOne: [],
      byrangayData: [],
      byrangayDataBirth:[],
      singleByrangayData: [],
      filePending:new File([""], ""),
      loadingPendingStudent:false,
      provincesData:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    ///Testing form///

    if (this.prevSchoolApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        localStorage.setItem("studentID", apiResponse?.data?.id)
        this.setState({ snackbarOpen: true, snackbarMessage: "Saved to Draft Successfully" });

        if (this.state.activeStep == Number(0) && this.formOneValidate()) {
          this.setState({ isLoading: false })
        }
        if (this.state.activeStep == Number(1) && this.formTwoValidate()) {
          this.setState({ isLoading: false })
        }
        if (this.state.activeStep == Number(2) && this.formThreeValidate()) {
          this.setState({ isLoading: false })
        }
        if (this.state.activeStep == Number(3) && this.formFourValidate()) {
          this.setState({ isLoading: false })
        }
        if (this.state.activeStep == Number(4) && this.formFiveValidate()) {
          this.setState({ isLoading: false })
        }
        if (this.state.activeStep == Number(5) && this.formSixValidation()) {
          this.setState({ isLoading: false })
        }

        if (this.state.activeStep == Number(6) && this.formSixValidation()) {
          this.setState({ isLoading: false })
          this.handleOpen()
        }
      }
    }
    //------------------------- Religion api  call response ------------------
    if (this.apiReligionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ religionData: apiResponse?.data })
        this.state.religionData?.map((item: any) => {
          this.state.religionSelectedData.push({
            id: item?.id,
            religion: item?.attributes.religion,
          })
        })
       }

      else {
        console.log('religion error', apiResponse?.errors)
      }
    }
  //------------------------- pending  api  call response start ------------------
  if (this.apiPendingStudentDetailId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiResponse?.data?.attributes?.status=='pending') {
      this.setState({loadingPendingStudent:false})
      localStorage.setItem("studentID", apiResponse?.data?.id)
     this.getCityDynamic(
      apiResponse?.data?.attributes?.country_of_residence,
       apiResponse?.data?.attributes?.country_of_birth,
       apiResponse?.data?.attributes?.city_of_residence,
       apiResponse?.data?.attributes?.city_of_birth,
       apiResponse?.data?.attributes?.date_of_birth);  
  
    this.programLevelsDataDynamic(apiResponse?.data?.attributes?.program_detail?.data?.attributes?.program);
  
      this.setState({
      dynamicHub:apiResponse?.data?.attributes?.program_detail.data?.attributes?.hub,
      dynamicCycle:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.cycle,
      dynamicAcc:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.accreditation,
      dynamicGrade:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.grade,
    })
    
      this.setState({
        studentFormData: {
          ...this.state.studentFormData, 
          studentLastName:apiResponse?.data?.attributes?.last_name,
          studentFirstName:apiResponse?.data?.attributes?.first_name,
          studentMiddleName:apiResponse?.data?.attributes?.middle_name,
            studentGender:apiResponse?.data?.attributes?.gender,
            studentDate: apiResponse?.data?.attributes?.date_of_birth,
            studentReligionOthers: apiResponse?.data?.attributes?.other_religion,
            studentCountry: { value:apiResponse?.data?.attributes?.country_of_birth},
            studentBirth: { value:apiResponse?.data?.attributes?.city_of_birth},
            
            studentAge: this.state.studentFormData.studentAge,
            studentReligion: { id:apiResponse?.data?.attributes?.religion_id, value:apiResponse?.data?.attributes?.religion },
            studentNationality: { id:apiResponse?.data?.attributes?.nationality_id,value:apiResponse?.data?.attributes?.nationality},
            studentCountryOfResidence: { value:apiResponse?.data?.attributes?.country_of_residence},
            studentCityOfResidence: { value:apiResponse?.data?.attributes?.city_of_residence},
            barangaySubData:{ id:apiResponse?.data?.attributes?.barangay_id,value: apiResponse?.data?.attributes?.barangay },
            barangayBirth:{ id:apiResponse?.data?.attributes?.birth_barangay_id,value: apiResponse?.data?.attributes?.birth_barangay },
            studentChildsFirstLanguage: { id:apiResponse?.data?.attributes?.child_first_language,value:apiResponse?.data?.attributes?.child_language},
            studentEnterChildsFirstLanguage: apiResponse?.data?.attributes?.other_language,
            studentWouldYouLikeToApplyForAScholarShip: apiResponse?.data?.attributes?.scholarship,
            studentScholarShopType: apiResponse?.data?.attributes?.scholarship_category,
            studentTypesOfScholarShip: { id:apiResponse?.data?.attributes?.scholarship_type_id,value: apiResponse?.data?.attributes?.scholarship_type},
            studentProofOfRelationship: apiResponse?.data?.attributes?.proof_of_relationship,
            studentfatherDeath: apiResponse?.data?.attributes?.father_death_date,
            studentCauseOfDeath: apiResponse?.data?.attributes?.father_cause_of_death,
            studentSpecification: apiResponse?.data?.attributes?.specification,
            studentRefferalProgram: apiResponse?.data?.attributes?.referral,
            studentAdvocatecode: apiResponse?.data?.attributes?.referral_code,
            studentPwd: apiResponse?.data?.attributes?.pwd_discount,
            studentPwdIdNumber: apiResponse?.data?.attributes?.pwd_id,
            studentParentDiscount: apiResponse?.data?.attributes?.solo_parent_discount,
            studentSoloParentId: apiResponse?.data?.attributes?.solo_parent_id,
            studentResidentialAddress: apiResponse?.data?.attributes?.residential_address,
            ///studentFormend////
            ///previousSchoolStart//////           
            previousSchoolLastGradeLevel: { id :apiResponse?.data?.attributes?.previous_school.data?.attributes?.grade_id,value: (apiResponse?.data?.attributes?.previous_school.data?.attributes?.grade)?apiResponse?.data?.attributes?.previous_school.data?.attributes?.grade:"choose" },
            previousSchoolCurriculum: { id :apiResponse?.data?.attributes?.previous_school.data?.attributes?.school_curriculum_id,value: (apiResponse?.data?.attributes?.previous_school.data?.attributes?.school_curriculum)?apiResponse?.data?.attributes?.previous_school.data?.attributes?.school_curriculum:"choose" },
            previousSchoolCurriculumOthers :apiResponse?.data?.attributes?.previous_school.data?.attributes?.other_curriculum,
            previousSchoolAttended: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.school_attended,
            previousSchoolContact: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.school_contact_number,
            previousSchoolLastSchoolYear: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.last_year_of_school,
            previousSchoolEmail: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.school_email,
            previousSchoolAddress: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.school_address,
            previousSchoolLearnerReferenceNumber: apiResponse?.data?.attributes?.previous_school?.data?.attributes?.learner_reference_number,
              ///previousSchoolend///
            // ///programDetailsStart///
            programDetailsHub: {id:apiResponse?.data?.attributes?.program_detail.data?.attributes?.hub, value: (apiResponse?.data?.attributes?.program_detail.data?.attributes?.hub)?apiResponse?.data?.attributes?.program_detail.data?.attributes?.hub:"choose"},
            programDetailsCommunity: {id:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.community_id, value: (apiResponse?.data?.attributes?.program_detail.data?.attributes?.community)?apiResponse?.data?.attributes?.program_detail.data?.attributes?.community:"choose" },
            programDetailsIncomingGradeLevel: { id:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.grade,value: (apiResponse?.data?.attributes?.program_detail?.data?.attributes?.grade)?apiResponse?.data?.attributes?.program_detail?.data?.attributes?.grade:"choose" },
            programDetailsProgram: {id:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.program, value: (apiResponse?.data?.attributes?.program_detail?.data?.attributes?.program)?apiResponse?.data?.attributes?.program_detail?.data?.attributes?.program:"choose" },
            programDetailsAccreditation: {id:apiResponse?.data?.attributes?.program_detail?.data?.attributes?.accreditation, value:( apiResponse?.data?.attributes?.program_detail?.data?.attributes?.accreditation)? apiResponse?.data?.attributes?.program_detail?.data?.attributes?.accreditation:"choose" },
            programDetailsCycle: apiResponse?.data?.attributes?.program_detail?.data?.attributes?.cycle ==null ? "choose" :apiResponse?.data?.attributes?.program_detail?.data?.attributes?.cycle,
            programDetailsShsTrack:(apiResponse?.data?.attributes?.program_detail?.data?.attributes?.shs_track)?apiResponse?.data?.attributes?.program_detail?.data?.attributes?.shs_track:"choose",
            ///programDetailsEnd///
            ///ParentsinfoStart///
            parentsInfoFathersFirstName: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_first_name,
            parentsInfoFathersMiddleName: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_middle_name,
            parentsInfoFathersLastName: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_last_name,
            parentsInfoFathersOccupation: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_occupation,
            parentsInfoFathersCountryCode: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_country_code,
            parentsInfoFathersPhoneNumber: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_mobile_number,
            parentsInfoFathersEmailAddress: apiResponse?.data?.attributes?.parent?.data?.attributes?.father_email,
            parentsInfoMothersFirstName: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_first_name,
            parentsInfoMothersMiddleName: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_middle_name,
            parentsInfoMothersLastName: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_last_name,
            parentsInfoMothersOccupation: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_occupation,
            parentsInfoMothersCountryCode: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_country_code,
            parentsInfoMothersPhoneNumber: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_mobile_number,
            parentsInfoMothersEmailAddress: apiResponse?.data?.attributes?.parent?.data?.attributes?.mother_email,
            parentsInfoMaritalStatus: apiResponse?.data?.attributes?.parent?.data?.attributes?.marital_status,
            parentsInfoGuardiansName: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_name,
            parentsInfoGuardiansAddress: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_address,
            parentsInfoGuardiansOccupation: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_occupation,
            parentsInfoGuardiansCountryCode: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_country_code,
            parentsInfoGuardiansPhoneNumber: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_mobile_number,
            parentsInfoGuardiansEmailAddress: apiResponse?.data?.attributes?.parent?.data?.attributes?.guardian_email,
            parentsInfoRelationshipToTheStudent: apiResponse?.data?.attributes?.parent?.data?.attributes?.relationship_to_the_student,
             ///ParentsinfoStart///
            ///reviweFormStart///
            reviewReasonForFinallyRegistering: {id:apiResponse?.data?.attributes?.reason_of_registering_id, value:(apiResponse?.data?.attributes?.reason_of_registering)? apiResponse?.data?.attributes?.reason_of_registering:"choose" },
            ///reviweFormEnd///
        }
      })
  //////////////file upload start/////
      
      if(apiResponse?.data?.attributes?.letter_of_recommendation ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileLetterRecommendation: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.letter_of_recommendation.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.letter_of_recommendation.name, { type: apiResponse?.data?.attributes?.letter_of_recommendation.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileLetterRecommendation: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.pwd_id_proof ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  filePwd: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.pwd_id_proof.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.pwd_id_proof.name, { type: apiResponse?.data?.attributes?.pwd_id_proof.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, filePwd: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.psa_birth_certificate ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileBirthCertificate: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.psa_birth_certificate.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.psa_birth_certificate.name, { type: apiResponse?.data?.attributes?.psa_birth_certificate.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileBirthCertificate: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.recent_report_card ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileReportCard: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.recent_report_card.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.recent_report_card.name, { type: apiResponse?.data?.attributes?.recent_report_card.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileReportCard: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.school_records ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileSchoolRecords: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.school_records.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.school_records.name, { type: apiResponse?.data?.attributes?.school_records.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileSchoolRecords: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.learner_reference_number ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileLearnerReference: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.learner_reference_number.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.learner_reference_number.name, { type: apiResponse?.data?.attributes?.learner_reference_number.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileLearnerReference: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.id_picture ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  filePicture: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.id_picture.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.id_picture.name, { type: apiResponse?.data?.attributes?.id_picture.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, filePicture: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.solo_parent_id_proof ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileSoloParent: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.solo_parent_id_proof.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.solo_parent_id_proof.name, { type: apiResponse?.data?.attributes?.solo_parent_id_proof.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileSoloParent: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.parent_valid_id ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileParentValid: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.parent_valid_id.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.parent_valid_id.name, { type: apiResponse?.data?.attributes?.parent_valid_id.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileParentValid: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.proof_of_achievements ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileAchivementsProof: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.proof_of_achievements.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.proof_of_achievements.name, { type: apiResponse?.data?.attributes?.proof_of_achievements.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileAchivementsProof: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.church_affiliation_letter ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileChurchLetter: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.church_affiliation_letter.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.church_affiliation_letter.name, { type: apiResponse?.data?.attributes?.church_affiliation_letter.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileChurchLetter: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.proof_of_income_or_billing ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileIncomeProof: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.proof_of_income_or_billing.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.proof_of_income_or_billing.name, { type: apiResponse?.data?.attributes?.proof_of_income_or_billing.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileIncomeProof: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.father_death_certificate ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileFatherDeathCertificate: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.father_death_certificate.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.father_death_certificate.name, { type: apiResponse?.data?.attributes?.father_death_certificate.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileFatherDeathCertificate: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.student_birth_certificate ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileStudentBirthCertificate: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.student_birth_certificate.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.student_birth_certificate.name, { type: apiResponse?.data?.attributes?.student_birth_certificate.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileStudentBirthCertificate: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.family_details_letter ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileFamilyDetails: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.family_details_letter.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.family_details_letter.name, { type: apiResponse?.data?.attributes?.family_details_letter.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileFamilyDetails: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.electric_bill ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileElectricBill: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.electric_bill.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.electric_bill.name, { type: apiResponse?.data?.attributes?.electric_bill.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileElectricBill: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.parent_birth_certificate ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileBirthCertificateLuovaStaff: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.parent_birth_certificate.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.parent_birth_certificate.name, { type: apiResponse?.data?.attributes?.parent_birth_certificate.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileBirthCertificateLuovaStaff: {dummy: null, filenm: file}}})
         }

      if(apiResponse?.data?.attributes?.luova_staff_birth_certificate ==null) {
        this.setState({ studentFormData: { ...this.state.studentFormData,  fileParentBirthCertificateLuovaStaff: {dummy: null, filenm: new File([""], "") }}})
         }
       else{
          let res:any = await fetch(apiResponse?.data?.attributes?.luova_staff_birth_certificate.url);
          let buf:any  = await res.arrayBuffer();
          let file:any  = new File([buf],apiResponse?.data?.attributes?.luova_staff_birth_certificate.name, { type: apiResponse?.data?.attributes?.luova_staff_birth_certificate.content_type});
          this.setState({studentFormData: {...this.state.studentFormData, fileParentBirthCertificateLuovaStaff: {dummy: null, filenm: file}}})
         }
        ///////////file upload end ////////////
     }
    
    else {
      this.setState({loadingPendingStudent:false})
      console.log('pending error', apiResponse?.errors)
    }
  }
  //------------------------- pending  api  call response  end------------------
    //------------------------- nationality api  call response ------------------
    if (this.apiNationalitiesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ nationalitiesData: apiResponse?.data })
        this.state.nationalitiesData?.map((item: any) => {
          this.state.nationalitiesSelectedData.push({
            id: item?.id,
            name: item?.attributes.name,
          })
        })
      }

      else {
        console.log('name error', apiResponse?.errors)
      }
    }
    //-------------------------languages api  call response ------------------
    if (this.apilanguagesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ languagesData: apiResponse?.data })
        this.state.languagesData?.map((item: any) => {
          this.state.languagesSelectedData.push({
            id: item?.id,
            language: item?.attributes.language,
          })
        })
      }

      else {
        console.log('language error', apiResponse?.errors)
      }
    }

    //-------------------------scholarship api  call response ------------------
    if (this.scholarshipCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ scholarshipData: apiResponse?.data })
        this.state.scholarshipData?.map((item: any) => {
          this.state.scholarshipSelectedData.push({
            id: item?.id,
            scholarship_name: item?.attributes.scholarship_name,
          })
        })
      }

      else {
        console.log('language error', apiResponse?.errors)
      }
    }
    //-------------------------Hub api  call response ------------------
    if (this.apiHubCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ hubData: apiResponse?.data })
        this.state.hubData?.map((item: any) => {
          this.state.hubSelectedData.push({
            id: item?.id,
            name: item?.attributes.name,
          })
        })
      }

      else {
        console.log('Hub error', apiResponse?.errors)
      }
    }
    //------------------------- program deatil Grades api  call response ------------------

    if (this.apiProgramDetailGradesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ programDetailgradesData: apiResponse?.data })
        this.state.programDetailgradesData?.map((item: any) => {
          this.state.programDetailgradesSelectedData.push({
            id: item?.id,
            grade: item?.attributes.grade,
          })
        })
      }

      else {
        console.log('Grades error', apiResponse?.errors)
      }
    }
    //------------------------- program deatil Program api  call response ------------------

    if (this.apiProgramDetailProgramCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ programDetailprogramData: apiResponse?.data })
        this.state.programDetailprogramData?.map((item: any) => {
          this.state.programDetailprogramSelectedData.push({
            id: item?.id,
            program_name: item?.attributes.program_name,
          })
        })

        this.state.programDetailprogramData?.map((item: any) => {
          if (item?.attributes.program_name.includes('Touch')  || item?.attributes.program_name.includes('Tech') ) {
            this.state.programDetailprogramSelectedDataMinistry.push({
              id: item?.id,
              program_name: item?.attributes.program_name,
            })
          }

        })

      }

      else {
        console.log('Program error', apiResponse?.errors)
      }
    }
    //------------------------- program deatil Communities api  call response ------------------

    if (this.apiProgramDetailCommunitiesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ programDetailcommunitiesData: apiResponse?.data })
        this.state.programDetailcommunitiesData?.map((item: any) => {
          this.state.programDetailcommunitiesSelectedData.push({
            id: item?.id,
            community_name: item?.attributes.community_name,
          })
        })
      }

      else {
        console.log('Communities error', apiResponse?.errors)
      }
    }
    //------------------------- program deatil accreditations api  call response ------------------

    if (this.apiProgramDetailAccreditationsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ programDetailaccreditationsData: apiResponse?.data })
        this.state.programDetailaccreditationsData?.map((item: any) => {
          this.state.programDetailaccreditationsSelectedData.push({
            id: item?.id,
            name: item?.attributes.name,
          })
        })
      }

      else {
        console.log('accreditations error', apiResponse?.errors)
      }
    }

    //------------------------- parents Info Fathercountries api  call response ------------------
    //------------------------- reason for register api  call response ------------------

    if (this.apiReasonForRegisterCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ reasonForRegisterData: apiResponse?.data })
        this.state.reasonForRegisterData?.map((item: any) => {
          this.state.reasonForRegisterSelectedData.push({
            id: item?.id,
            reason: item?.attributes?.reason,
          })
        })
      }

      else {
        console.log('Reason for Register error', apiResponse?.errors)
      }
    }

    //------------------------- parents Info Fathercountries api  call response ------------------
    //------------------------- Program level call response start------------------

    if (this.apiProgramLevelsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({programLevelData:apiResponse?.data})
         this.setState({ programLevelHubData: Object.keys(this.state.programLevelData?.hubs) })
      }

      else {
        console.log('PRogram level error Dynamic', apiResponse?.errors)
      }
    }
  //------------------------- Program level call response ends ------------------
    //------------------------- Program level dynamic call response start------------------

    if (this.apiProgramLevelsDynamicCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({programLevelData:apiResponse?.data})
        this.setState({ programLevelHubData: Object.keys(this.state.programLevelData?.hubs) })
        // "============================== cycle  ==================================="

        let newCycleArray:any=[]
        let hubObj:any= Object.entries(this.state.programLevelData?.hubs)
      
         hubObj.map((item:any)=>{
         const [keyHub]=item
    
           if(keyHub == this.state.dynamicHub){
           let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)
    
           newCycleArray= cycleObj.map((itemCycle:any)=>{
             const [keyCycle]=itemCycle
             return keyCycle
            })
    
              let set:any = new Set(newCycleArray);
              let newArr:any = [...set];
              this.setState({programLevelCycleData:newArr})
         }
     })
        
       // "============================== accreditaion  ==================================="
    newCycleArray=[]
    let newAccArray:any=[]
    let accreditationObj:any={}
           
    hubObj.map((item:any)=>{
       const [keyHub]=item

       if(keyHub == this.state.dynamicHub){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         if(keyCycle == this.state.dynamicCycle){

          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          newAccArray= accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc
               return keyAcc
            })

          let set:any = new Set(newAccArray);
          let newArr:any = [...set];
          this.setState({programLevelAccData:newArr})
          }
      })
     }
    })
       // "============================== grades  ========================================="

    accreditationObj={}
    let gradeObj:any={}
        
    hubObj.map((item:any)=>{
       const [keyHub]=item
       if(keyHub == this.state.dynamicHub){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)
      
       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
          if(keyCycle == this.state.dynamicCycle){

          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc
            if(keyAcc == this.state.dynamicAcc){
              gradeObj= this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation[`${keyAcc}`].grades
            
              let newGradesArray:any=gradeObj.map((itemGrades:any)=>{
                
               return itemGrades
                })
                
                let set:any = new Set(newGradesArray);
                let newArr:any = [...set];
                this.setState({programLevelGradesData:newArr})
             }  
            })
          }
      })
     }
    })

 // "============================== ssh track  ========================================="

 
     accreditationObj={}
     gradeObj={}
    let newSHSArray:any=[]
        
    hubObj.map((item:any)=>{
       const [keyHub]=item
       if(keyHub == this.state.dynamicHub){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         if(keyCycle == this.state.dynamicCycle){
          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc

            if(keyAcc == this.state.dynamicAcc){
               gradeObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation[`${keyAcc}`].shs_track)
                             
              gradeObj.map((itemGrades:any)=>{
                 const [keyGrades,valueGrades]=itemGrades
                  if(this.state.dynamicGrade == 'Grade 11' || this.state.dynamicGrade == 'Grade 12' ){
                    newSHSArray= valueGrades
                    console.log(keyGrades)
                 let set:any = new Set(newSHSArray);
               let newArr:any = [...set];
                
               this.setState({programLevelSHSData:newArr})

                }  
              
             })

            }  
            })
          }
      })
     }
    })


  }

      else {
        console.log('PRogram level error', apiResponse?.errors)
      }
    }
  //------------------------- Program level dynamic call response ends ------------------

    //------------------------- parents Info Fathercountries api  call response ------------------

    if (this.apiParentsInfoFathercountriesDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ parentsInfoFathercountriesData: apiResponse?.data })
        this.state.parentsInfoFathercountriesData?.map((item: any) => {
          this.state.parentsInfoFathercountriesSelectedData.push({
            code: item?.attributes.code,
          })
        })
        let dataArr = this.state.parentsInfoFathercountriesSelectedData?.map((item: any) => {
          return [JSON.stringify(item), item]
        });
        let maparr = new Map(dataArr);
        this.setState({ parentsInfoFathercountriesuniqueCountryCodeSelectedData: [...maparr.values()] });
      }
      else {
        console.log('Fathercountries error', apiResponse?.errors)
      }
    }
    //------------------------- Grade api  call response ------------------

    if (this.apiGradeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ gradeData: apiResponse?.data })
        this.state.gradeData?.map((item: any) => {
          this.state.gradeSelectedData.push({
            id: item?.id,
            grade: item?.attributes.grade,
          })
        })
      }

      else {
        console.log('Grade error', apiResponse?.errors)
      }
    }
    //------------------------- Curriculum api  call response ------------------

    if (this.apiCurriculumCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ curriculumData: apiResponse?.data })
        this.state.curriculumData?.map((item: any) => {
          this.state.curriculumSelectedData.push({
            id: item?.id,
            curriculum: item?.attributes.curriculum_name,
          })
        })
      }

      else {
        console.log('Curriculum error', apiResponse?.errors)
      }
    }

    //-------------------------country api  call response ------------------

    if (this.apiCountryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ countriesData: apiResponse?.data })
        this.state.countriesData?.map((item: any) => {
          this.state.countryCodeSelectedData.push({
            code: item?.attributes.code
          })
        })

        let dataArr = this.state.countryCodeSelectedData?.map((item: any) => {
          return [JSON.stringify(item), item]
        });
        let maparr = new Map(dataArr);

        this.setState({ uniqueCountryCodeSelectedData: [...maparr.values()] });

      }
      else {
        console.log('error', apiResponse?.error)
      }
    }

    ////barangay call id  start////

    if (this.apiBarangaysCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({ byrangayData: apiResponse?.data})
      }
      else {
        console.log('Curriculum error', apiResponse?.errors)
      }
    }
    ///barangay call id  End////
    ////barangay Birth call id  start////

    if (this.apiBarangaysBirthCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
       this.setState({ byrangayDataBirth: apiResponse?.data})
      }
      else {
        console.log('barangay birth error', apiResponse?.errors)
      }
    }
    ///barangay Birth call id  End////
// ----------------------------- Provinces api start-------------------------------
if (this.apiProvincesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
  const apiResponse = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  if (apiResponse?.data) {
    this.setState({ provincesData: apiResponse?.data })
    }
  else {
    console.log('province error', apiResponse?.error)
  }
}

// ----------------------------- Provinces api end-------------------------------
    //-------------------------country Residence api  call response ------------------
    if (this.apiCountryResidenceCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({ countriesResidenceData: apiResponse?.data })
        this.state.countriesResidenceData?.map((item: any) => {
          this.state.countryResidenceCodeSelectedData.push({
            code: item?.attributes.code
          })
        })
        let dataArr = this.state.countryResidenceCodeSelectedData?.map((item: any) => {
          return [JSON.stringify(item), item]
        });
        let maparr = new Map(dataArr);

        this.setState({ uniqueCountryResidenceCodeSelectedData: [...maparr.values()] });

      }
      else {
        console.log('error', apiResponse?.error)
      }
    }

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getSellerDetail(token)
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("API REQUEST CALL ID: ", apiRequestCallId);

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.state.postSellerDetailsMessageId) {
          this.showModal();
          console.log("Received from post API: ", responseJson);
        }

        if (apiRequestCallId === this.state.getSellerDetailsMessageId) {
          console.log("Received from get API: ", responseJson);
          if (responseJson.data && responseJson.data.attributes) {
            console.log(
              "responseJson.data.attributes ",
              responseJson.data.attributes
            );
            let isWholesalerSelected =
              responseJson.data.attributes.wholesaler != undefined
                ? responseJson.data.attributes.wholesaler
                : false;
            let isRetailerSelected =
              responseJson.data.attributes.retailer != undefined
                ? responseJson.data.attributes.retailer
                : false;
            let isManufacturerSelected =
              responseJson.data.attributes.manufacturer != undefined
                ? responseJson.data.attributes.manufacturer
                : false;
            let isHallmarking_centerSelected =
              responseJson.data.attributes.hallmarking_center != undefined
                ? responseJson.data.attributes.hallmarking_center
                : false;

            let selectedServices = [];

            if (isWholesalerSelected) {
              selectedServices.push(1);
            }
            if (isManufacturerSelected) {
              selectedServices.push(2);
            }
            if (isHallmarking_centerSelected) {
              selectedServices.push(3);
            }
            if (isRetailerSelected) {
              selectedServices.push(4);
            }

            this.setState({
              sellerID: responseJson.data.id,
              shopName: responseJson.data.attributes.firm_name
                ? responseJson.data.attributes.firm_name
                : "",
              address: responseJson.data.attributes.location
                ? responseJson.data.attributes.location
                : "",
              gstin: responseJson.data.attributes.gstin_number
                ? responseJson.data.attributes.gstin_number
                : "",
              selectedServices: selectedServices,
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getSwicthProgramDetails();  
    this.getPendingStudentDetail();
    this.getGradeData();
    this.getCurriculumData();
    this.getCountryData();
    this.religionData();
    this.nationalitiesData();
    this.languagesData();
    this.scholarshipData();
    this.getCountryResidenceData();
    this.programData();
    this.communitiesData();
    this.fathercountriesData();
    this.getAllCountriesData();
    this.getAllCountriesDataOne();
    this.reasonForRegisterData();
    this.getProvincesData()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  ///Modal Start///
  handleOpen = () => {
    if (this.state.activeStep == Number(6)) {
      this.setState({ open: true })
    } else {
      this.setState({ open: false })
    }

  }
  handleClose = () => {
    this.setState({ open: false })
  }

  onYesClearData = () => {
    this.setState({
      studentFormData: {
        studentFirstName: "",
        studentMiddleName: "",
        studentLastName: "",
        studentGender: "choose",
        studentDate: "",
        studentCountry: { id: '', value: "choose" },
        studentCountryAutoCompleteValue:{ value: "" },
        studentBirthAutoCompleteValue:{ value: "" },
        studentBirth: { id: '', value: "choose" },
        studentAge: "",
        studentReligion: { id: '', value: "choose" },
        studentReligionOthers: '',
        studentNationality: { id: '', value: "choose" },
        studentCountryOfResidence: { id: '', value: "choose" },
        countryResiAutoCompleteValue:{value: "" },
        studentCountryOfResidenceArray: [],
        studentCityOfResidence: { id: '', value: "choose" },
        cityResiAutoCompleteValue:{value: "" },
        barangaySubData: { id: '', value: "choose" },
        barangayBirth: { id: '', value: "choose" },
        studentChildsFirstLanguage: { id: '', value: "choose" },
        studentEnterChildsFirstLanguage: '',
        studentWouldYouLikeToApplyForAScholarShip: false,
        studentScholarShopType: "choose",
        studentTypesOfScholarShip: { id: '', value: "choose" },
        studentProofOfRelationship: 'choose',
        studentfatherDeath: 'choose',
        studentCauseOfDeath: 'choose',
        studentSpecification: 'choose',
        studentRefferalProgram: false,
        studentAdvocatecode: "",
        studentPwd: false,
        studentPwdIdNumber: '',
        studentParentDiscount: false,
        studentSoloParentId: "",
        studentResidentialAddress: '',
        studentErrors: {
          studentFirstName: "",
          studentMiddleName: "",
          studentLastName: "",
          studentGender: "",
          studentDate: "",
          studentCountry: "",
          studentBirth: "",
          studentAge: "",
          studentReligion: "",
          studentNationality: "",
          studentCountryOfResidence: "",
          studentCityOfResidence: "",
          studentChildsFirstLanguage: "",
          studentEnterChildsFirstLanguage: '',
          studentWouldYouLikeToApplyForAScholarShip: "",
          studentScholarShopType: "",
          studentTypesOfScholarShip: "",
          studentProofOfRelationship: '',
          studentfatherDeath: '',
          studentRefferalProgram: "",
          studentAdvocatecode: "",
          studentPwd: "",
          studentPwdIdNumber: '',
          studentParentDiscount: "",
          studentSoloParentId: "",
          studentResidentialAddress: '',
        },
        /////studentFormend////
        /////previousSchoolStart//////
        previousSchoolLastGradeLevel: { id: "", value: "choose" },
        previousSchoolCurriculum: { id: "", value: "choose" },
        previousSchoolCurriculumOthers:'',
        previousSchoolAttended: '',
        previousSchoolContact: '',
        previousSchoolLastSchoolYear: '',
        previousSchoolEmail: '',
        previousSchoolAddress: '',
        previousSchoolLearnerReferenceNumber: '',
        previousSchoolErrors: {},
        ///previousSchoolend///
        ///programDetailsStart///
        programDetailsHub: { id: "", value: "choose" },
        programDetailsCommunity: { id: "", value: "choose" },
        programDetailsIncomingGradeLevel: { id: "", value: "choose" },
        programDetailsProgram: { id: "", value: "choose" },
        programDetailsAccreditation: { id: "", value: "choose" },
        programDetailsCycle: "choose",
        programDetailsShsTrack: 'choose',
        programDetailsErrors: {},
        ///programDetailsEnd///
        ///ParentsinfoStart///
        parentsInfoFathersFirstName: "",
        parentsInfoFathersMiddleName: "",
        parentsInfoFathersLastName: "",
        parentsInfoFathersOccupation: "",
        parentsInfoFathersCountryCode: "choose",
        parentsInfoFathersPhoneNumber: "",
        parentsInfoFathersEmailAddress: "",
        parentsInfoMothersFirstName: "",
        parentsInfoMothersMiddleName: "",
        parentsInfoMothersLastName: "",
        parentsInfoMothersOccupation: "",
        parentsInfoMothersCountryCode: "choose",
        parentsInfoMothersPhoneNumber: "",
        parentsInfoMothersEmailAddress: "",
        parentsInfoMaritalStatus: "choose",
        parentsInfoGuardiansName: "",
        parentsInfoGuardiansAddress: "",
        parentsInfoGuardiansOccupation: "",
        parentsInfoGuardiansCountryCode: "choose",
        parentsInfoGuardiansPhoneNumber: "",
        parentsInfoGuardiansEmailAddress: "",
        parentsInfoRelationshipToTheStudent: "",
        parentsValidationErrors: {},
        ///ParentsinfoStart///
        //////////////file upload start/////
        fileLetterRecommendation: { dummy: null, filenm: new File([""], "") },
        filePwd: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileReportCard: { dummy: null, filenm: new File([""], "") },
        fileSchoolRecords: { dummy: null, filenm: new File([""], "") },
        fileLearnerReference: { dummy: null, filenm: new File([""], "") },
        filePicture: { dummy: null, filenm: new File([""], "") },
        fileSoloParent: { dummy: null, filenm: new File([""], "") },
        fileParentValid: { dummy: null, filenm: new File([""], "") },
        fileAchivementsProof: { dummy: null, filenm: new File([""], "") },
        fileChurchLetter: { dummy: null, filenm: new File([""], "") },
        fileIncomeProof: { dummy: null, filenm: new File([""], "") },
        fileFatherDeathCertificate: { dummy: null, filenm: new File([""], "") },
        fileStudentBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileFamilyDetails: { dummy: null, filenm: new File([""], "") },
        fileElectricBill: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },
        fileParentBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },
        fileErrors: {
          fileLetterRecommendation: "",
          filePwd: "",
          fileBirthCertificate: "",
          fileReportCard: "",
          fileSchoolRecords: "",
          fileLearnerReference: "",
          filePicture: "",
          fileSoloParent: "",
          fileParentValid: "",
          fileAchivementsProof: "",
          fileChurchLetter: "",
          fileIncomeProof: "",
          fileFatherDeathCertificate: "",
          fileStudentBirthCertificate: "",
          fileFamilyDetails: "",
          fileElectricBill: "",
          fileBirthCertificateLuovaStaff: "",
          fileParentBirthCertificateLuovaStaff: "",
        },
        ///////////file upload end ////////////
        ///reviweFormStart///
        reviewReasonForFinallyRegistering: { id: '', value: "choose" },
        reviewValidationErrors: {},
        ///reviweFormEnd///

        ///Terms and conditons
        TermsAndConditionsAgreeGeneralTerms: false,
        TermsAndConditionsRefundPolicy: false,
        TermsAndConditionsPrivacyPolicy: false,
        TermsAndConditionsPrivacyPolicyPictureAuthorization: false,
        TermsAndConditionsValidations: {},
        //status
        studentStatus:'pending',
      },
      activeStep: 0,
      isLoading: false,
    })
    let swtichButtonStatus = {
      programStatus: false,
      activeFormState:0,
     }

    this.context.CheckoutSwitchProgramStateDispatch({ type: 'SWITCH_PROGRAM', payload: swtichButtonStatus })

  }
  handleYes = () => {
    this.setState({ activeStep: Number(0) })
    localStorage.removeItem("studentID")
    this.onYesClearData()
    this.prevSchoolApiCallId = ''
    this.getPendingStudentDetail()
  }

  handleNo = () => {
    this.setState({ activeStep: Number(7) })
    localStorage.removeItem("studentID")
    let swtichButtonStatus = {
      programStatus: false,
      activeFormState:0,
     }

    this.context.CheckoutSwitchProgramStateDispatch({ type: 'SWITCH_PROGRAM', payload: swtichButtonStatus })
  }

  ///Modal End///


  labelShopName: string = "Name of Shop";
  labelAddress: string = "Address";
  labelGstin: string = "GSTIN";
  labelAbout: string = "About Us";
  labelServiceProvided: string = "Services Provided";
  labelDealsIn: string = "Deals in";
  labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
  labelVisitingCard: string = "Upload Visiting Card";
  btnLabel: string = "Submit";
  btnContinueLabel: string = "Continue";

  services = [
    { label: "Wholesaler", value: 1 },
    { label: "Manufacturer", value: 2 },
    { label: "Hallmarker", value: 3 },
    { label: "Retailer", value: 4 },
  ];

  getSellerDetail = async (token: string) => {

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      getSellerDetailsMessageId: requestMessage.messageId,
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellersAPIEndPoint + "/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSellerDetails = async () => {

    let token = this.state.token;

    if (!token || token.length === 0) {
      this.showAlert("Error", "Invaild Token. Plese log in.")
      return;
    }
    if (
      this.state.shopName.trim() == ""
    ) {
      this.showAlert("Error", configJSON.errorMessageShopName);
      return;
    }

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
    };

    let isWholesalerSelected = false;
    let isRetailerSelected = false;
    let isManufacturerSelected = false;
    let isHallmarking_centerSelected = false;

    this.state.selectedServices.forEach((value) => {
      switch (value) {
        case 1:
          isWholesalerSelected = true;
          break;
        case 2:
          isManufacturerSelected = true;
          break;
        case 3:
          isHallmarking_centerSelected = true;
          break;
        case 4:
          isRetailerSelected = true;
          break;
      }
    });

    const httpBody = {
      token: token,
      seller_account: {
        firm_name: this.state.shopName,
        location: this.state.address,
        gstin_number: this.state.gstin,
        wholesaler: isWholesalerSelected,
        retailer: isRetailerSelected,
        manufacturer: isManufacturerSelected,
        hallmarking_center: isHallmarking_centerSelected,
        lat: this.state.lat,
        long: this.state.long,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      postSellerDetailsMessageId: requestMessage.messageId,
    });


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.sellerID.length > 1 ? configJSON.sellersAPIEndPoint + "/" + this.state.sellerID : configJSON.sellersAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.sellerID.length > 1 ? configJSON.sellerDetailsAPIMethodPUT : configJSON.sellerDetailsAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeInput = (name: string, value: any) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  txtInputProps = (key: string) => ({
    // @ts-ignore
    value: this.state[key],
    onChangeText: (text: string) => {
      this.onChangeInput(key, text);
    },
  });

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16,
  };

  onServiceSelected = (serviceId: number) => {
    if (!this.state.selectedServices.includes(serviceId)) {
      this.setState({
        selectedServices: this.state.selectedServices.concat(serviceId),
      });
    }
  };

  onServiceUnSelected = (serviceId: number) => {
    this.setState({
      selectedServices: this.state.selectedServices.filter(
        (selectedService) => selectedService !== serviceId
      ),
    });
  };

  showModal = () => {
    this.setState({ showSuccessModal: true });
  };

  hideModal = () => {
    this.setState({ showSuccessModal: false }, () => {
    });
  };

  ///stepperStart///

  getSteps = () => {
    return ['Student', 'Previous School', 'Program Details', 'Parents', 'Files', 'Review', 'T&C'];
  }
  handleChangePersonal = (e: any) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, [e.target.name]: e.target.value },
   });
    
  }
  previousSchoolContactHandler = (e: any) => {
    let aaa=e.target.value.replace(/^0+/, "")
      this.setState({studentFormData: { ...this.state.studentFormData, previousSchoolContact: aaa } });
   }
  programFatherContactHandler = (e: any) => {
    let aaa=e.target.value.replace(/^0+/, "")
      this.setState({studentFormData: { ...this.state.studentFormData, parentsInfoFathersPhoneNumber: aaa } });
   }
  programMotherContactHandler = (e: any) => {
    let aaa=e.target.value.replace(/^0+/, "")
      this.setState({studentFormData: { ...this.state.studentFormData, parentsInfoMothersPhoneNumber: aaa } });
   }
  programGardianContactHandler = (e: any) => {
    let aaa=e.target.value.replace(/^0+/, "")
      this.setState({studentFormData: { ...this.state.studentFormData, parentsInfoGuardiansPhoneNumber: aaa } });
   }

  handleChangeProgramDetailsShsTrack = (e: any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData, programDetailsShsTrack: e.target.value
      }

    }))
    this.state.studentFormData.programDetailsErrors['programDetailsShsTrack'] = " "
  }

  handleCheckedStudentScholarShip = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentWouldYouLikeToApplyForAScholarShip: e.target.checked,
        studentRefferalProgram: false, studentPwd: false, studentParentDiscount: false,
        studentPwdIdNumber: '', studentSoloParentId: '', studentAdvocatecode: ''
      }
    })

  }
  handleAdvocatecode = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentRefferalProgram: e.target.checked,
        studentPwd: false, studentWouldYouLikeToApplyForAScholarShip: false, studentParentDiscount: false,
        studentPwdIdNumber: '', studentSoloParentId: '', studentScholarShopType: 'choose', studentTypesOfScholarShip: { id: '', value: "choose" },
        studentProofOfRelationship: 'choose', studentfatherDeath: '', studentCauseOfDeath: "choose"
      }
    })
  }

  handlePwdDiscount = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentPwd: e.target.checked,
        studentParentDiscount: false, studentWouldYouLikeToApplyForAScholarShip: false, studentRefferalProgram: false,
        studentSoloParentId: '', studentScholarShopType: 'choose', studentAdvocatecode: '', studentTypesOfScholarShip: { id: '', value: "choose" },
        studentProofOfRelationship: 'choose', studentfatherDeath: '', studentCauseOfDeath: "choose"
      }
    })
  }
  handleParentDiscount = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentParentDiscount: e.target.checked,
        studentWouldYouLikeToApplyForAScholarShip: false, studentRefferalProgram: false, studentPwd: false,
        studentScholarShopType: 'choose', studentAdvocatecode: '', studentPwdIdNumber: '', studentTypesOfScholarShip: { id: '', value: "choose" },
        studentProofOfRelationship: 'choose', studentfatherDeath: '', studentCauseOfDeath: "choose"
      }
    })
  }
  handleClearFatherDeath = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentfatherDeath: e.target.value, studentProofOfRelationship: "choose", studentSpecification: 'choose'
      }

    })
    this.state.studentFormData.studentErrors['studentfatherDeath'] = " "
  }
  handleProofOfRelationship = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentProofOfRelationship: e.target.value, studentfatherDeath: "", studentSpecification: 'choose', studentCauseOfDeath: 'choose'
      }
    })
    this.state.studentFormData.studentErrors['studentProofOfRelationship'] = " "
  }
  handlestudentSpecification = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentSpecification: e.target.value, studentfatherDeath: "", studentProofOfRelationship: "choose", studentCauseOfDeath: 'choose'
      }
    })
    this.state.studentFormData.studentErrors['studentSpecification'] = " "
  }
  handleStudentCauseOfDeath = (e: any) => {
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentCauseOfDeath: e.target.value, studentProofOfRelationship: "choose", studentSpecification: 'choose'
      }
    })
    this.state.studentFormData.studentErrors['studentCauseOfDeath'] = " "
  }
  handleNextMain = async () => {

    let main = {
      studentFirstName: this.state.studentFormData.studentFirstName,
      studentMiddleName: this.state.studentFormData.studentMiddleName,
      studentLastName: this.state.studentFormData.studentLastName,
      studentGender: this.state.studentFormData.studentGender,
      studentDate: this.state.studentFormData.studentDate,
      studentCountry: {
        ...this.state.studentFormData.studentCountry,
        },
      
        studentBirth: {
          ...this.state.studentFormData.studentBirth,
        },
        barangaySubData: {
          ...this.state.studentFormData.barangaySubData,
         id: this.state.byrangayData.find((o: any) => o?.attributes?.barangay_name == this.state.studentFormData.barangaySubData.value)?.id,
        },
        barangayBirth: {
          ...this.state.studentFormData.barangayBirth,
          id: this.state.byrangayDataBirth.find((o: any) => o?.attributes?.barangay_name == this.state.studentFormData.barangayBirth.value)?.id,
        },
        studentAge: this.state.studentFormData.studentAge,
      studentReligion: {
        ...this.state.studentFormData.studentReligion,
        id: this.state.religionSelectedData.find((o: any) => o.religion == this.state.studentFormData.studentReligion.value)?.id
      },
      studentReligionOthers: this.state.studentFormData.studentReligionOthers,
      studentNationality: {
        ...this.state.studentFormData.studentNationality,
        id: this.state.nationalitiesSelectedData.find((o: any) => o.name == this.state.studentFormData.studentNationality.value)?.id
      },
      studentCountryOfResidence: {
        ...this.state.studentFormData.studentCountryOfResidence,
      },
      studentCountryOfResidenceArray: [],
      studentCityOfResidence: {
        ...this.state.studentFormData.studentCityOfResidence,
      },
      studentChildsFirstLanguage: {
        ...this.state.studentFormData.studentChildsFirstLanguage,
        id: this.state.languagesSelectedData.find((o: any) => o.language == this.state.studentFormData.studentChildsFirstLanguage.value)?.id
      },
      studentEnterChildsFirstLanguage: this.state.studentFormData.studentEnterChildsFirstLanguage,
      studentWouldYouLikeToApplyForAScholarShip: this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip,
      studentScholarShopType: this.state.studentFormData.studentScholarShopType,
      studentTypesOfScholarShip: {
        ...this.state.studentFormData.studentTypesOfScholarShip,
        id: this.state.scholarshipSelectedData.find((o: any) => o.scholarship_name == this.state.studentFormData.studentTypesOfScholarShip.value)?.id
      },
      studentProofOfRelationship: this.state.studentFormData.studentProofOfRelationship,
      studentfatherDeath: this.state.studentFormData.studentfatherDeath,
      studentCauseOfDeath: this.state.studentFormData.studentCauseOfDeath,
      studentRefferalProgram: this.state.studentFormData.studentRefferalProgram,
      studentAdvocatecode: this.state.studentFormData.studentAdvocatecode,
      studentPwd: this.state.studentFormData.studentPwd,
      studentPwdIdNumber: this.state.studentFormData.studentPwdIdNumber,
      studentParentDiscount: this.state.studentFormData.studentParentDiscount,
      studentSoloParentId: this.state.studentFormData.studentSoloParentId,
      studentErrors: this.state.studentFormData.studentErrors,
      studentResidentialAddress: this.state.studentFormData.studentResidentialAddress,
      studentSpecification: this.state.studentFormData.studentSpecification,

      //   /////studentFormend////
      //   /////previousSchoolStart//////

      previousSchoolLastGradeLevel: {
        ...this.state.studentFormData.previousSchoolLastGradeLevel,
        id: this.state.gradeSelectedData.find((o: any) => o.grade == this.state.studentFormData.previousSchoolLastGradeLevel.value)?.id
      },
      previousSchoolCurriculum: {
        ...this.state.studentFormData.previousSchoolCurriculum,
        id: this.state.curriculumSelectedData.find((o: any) => o.curriculum == this.state.studentFormData.previousSchoolCurriculum.value)?.id
      },
      previousSchoolCurriculumOthers:this.state.studentFormData.previousSchoolCurriculumOthers,
      previousSchoolAttended: this.state.studentFormData.previousSchoolAttended,
      previousSchoolContact: this.state.studentFormData.previousSchoolContact,
      previousSchoolLastSchoolYear: this.state.studentFormData.previousSchoolLastSchoolYear,
      previousSchoolEmail: this.state.studentFormData.previousSchoolEmail,
      previousSchoolAddress: this.state.studentFormData.previousSchoolAddress,
      previousSchoolLearnerReferenceNumber: this.state.studentFormData.previousSchoolLearnerReferenceNumber,
      previousSchoolErrors: this.state.studentFormData.previousSchoolErrors,
      //   ///previousSchoolend///
      //   ///programDetailsStart///
      programDetailsHub: {
        ...this.state.studentFormData.programDetailsHub,
        id:  this.state.studentFormData.programDetailsHub.value
   
      },
      programDetailsCommunity: {
        ...this.state.studentFormData.programDetailsCommunity,
        id: this.state.programDetailcommunitiesSelectedData.find((o: any) => o.community_name == this.state.studentFormData.programDetailsCommunity.value)?.id
      },
      programDetailsIncomingGradeLevel: {
        ...this.state.studentFormData.programDetailsIncomingGradeLevel,
        id: this.state.studentFormData.programDetailsIncomingGradeLevel.value
        
      },
      programDetailsProgram: {
        ...this.state.studentFormData.programDetailsProgram,
        id: this.state.studentFormData.programDetailsProgram.value
        
      },
      programDetailsAccreditation: {
        ...this.state.studentFormData.programDetailsAccreditation,
        id: this.state.studentFormData.programDetailsAccreditation.value
        
      },
      programDetailsCycle: this.state.studentFormData.programDetailsCycle,
      programDetailsShsTrack: this.state.studentFormData.programDetailsShsTrack,
      programDetailsErrors: this.state.studentFormData.programDetailsErrors,
      //   ///programDetailsEnd///
      //   ///ParentsinfoStart///
      parentsInfoFathersFirstName: this.state.studentFormData.parentsInfoFathersFirstName,
      parentsInfoFathersMiddleName: this.state.studentFormData.parentsInfoFathersMiddleName,
      parentsInfoFathersLastName: this.state.studentFormData.parentsInfoFathersLastName,
      parentsInfoFathersOccupation: this.state.studentFormData.parentsInfoFathersOccupation,
      parentsInfoFathersCountryCode: this.state.studentFormData.parentsInfoFathersCountryCode,
      parentsInfoFathersPhoneNumber: this.state.studentFormData.parentsInfoFathersPhoneNumber,
      parentsInfoFathersEmailAddress: this.state.studentFormData.parentsInfoFathersEmailAddress,
      parentsInfoMothersFirstName: this.state.studentFormData.parentsInfoMothersFirstName,
      parentsInfoMothersMiddleName: this.state.studentFormData.parentsInfoMothersMiddleName,
      parentsInfoMothersLastName: this.state.studentFormData.parentsInfoMothersLastName,
      parentsInfoMothersOccupation: this.state.studentFormData.parentsInfoMothersOccupation,
      parentsInfoMothersCountryCode: this.state.studentFormData.parentsInfoMothersCountryCode,
      parentsInfoMothersPhoneNumber: this.state.studentFormData.parentsInfoMothersPhoneNumber,
      parentsInfoMothersEmailAddress: this.state.studentFormData.parentsInfoMothersEmailAddress,
      parentsInfoMaritalStatus: this.state.studentFormData.parentsInfoMaritalStatus,
      parentsInfoGuardiansName: this.state.studentFormData.parentsInfoGuardiansName,
      parentsInfoGuardiansAddress: this.state.studentFormData.parentsInfoGuardiansAddress,
      parentsInfoGuardiansOccupation: this.state.studentFormData.parentsInfoGuardiansOccupation,
      parentsInfoGuardiansCountryCode: this.state.studentFormData.parentsInfoGuardiansCountryCode,
      parentsInfoGuardiansPhoneNumber: this.state.studentFormData.parentsInfoGuardiansPhoneNumber,
      parentsInfoGuardiansEmailAddress: this.state.studentFormData.parentsInfoGuardiansEmailAddress,
      parentsInfoRelationshipToTheStudent: this.state.studentFormData.parentsInfoRelationshipToTheStudent,
      parentsValidationErrors: this.state.studentFormData.parentsValidationErrors,
      //   ///ParentsinfoStart///
      //   //////////////file upload start/////
      fileLetterRecommendation: this.state.studentFormData.fileLetterRecommendation.filenm,
      filePwd: this.state.studentFormData.filePwd.filenm,
      fileBirthCertificate: this.state.studentFormData.fileBirthCertificate.filenm,
      fileReportCard: this.state.studentFormData.fileReportCard.filenm,
      fileSchoolRecords: this.state.studentFormData.fileSchoolRecords.filenm,
      fileLearnerReference: this.state.studentFormData.fileLearnerReference.filenm,
      filePicture: this.state.studentFormData.filePicture.filenm,
      fileSoloParent: this.state.studentFormData.fileSoloParent.filenm,
      fileParentValid: this.state.studentFormData.fileParentValid.filenm,
      fileAchivementsProof: this.state.studentFormData.fileAchivementsProof.filenm,
      fileChurchLetter: this.state.studentFormData.fileChurchLetter.filenm,
      fileIncomeProof: this.state.studentFormData.fileIncomeProof.filenm,
      fileFatherDeathCertificate: this.state.studentFormData.fileFatherDeathCertificate.filenm,
      fileStudentBirthCertificate: this.state.studentFormData.fileStudentBirthCertificate.filenm,
      fileFamilyDetails: this.state.studentFormData.fileFamilyDetails.filenm,
      fileElectricBill: this.state.studentFormData.fileElectricBill.filenm,
      fileBirthCertificateLuovaStaff: this.state.studentFormData.fileBirthCertificateLuovaStaff.filenm,
      fileParentBirthCertificateLuovaStaff: this.state.studentFormData.fileParentBirthCertificateLuovaStaff.filenm,
      fileErrors: this.state.studentFormData.fileErrors,
      //   ///////////file upload end ////////////
      ///reviweFormStart///
      reviewReasonForFinallyRegistering: {
        ...this.state.studentFormData.reviewReasonForFinallyRegistering,
        id: this.state.reasonForRegisterSelectedData.find((o: any) => o.reason == this.state.studentFormData.reviewReasonForFinallyRegistering.value)?.id
      },
      reviewValidationErrors: this.state.studentFormData.reviewValidationErrors,
      ///reviweFormEnd///

      ///Terms and conditons
      TermsAndConditionsAgreeGeneralTerms: this.state.studentFormData.TermsAndConditionsAgreeGeneralTerms,
      TermsAndConditionsRefundPolicy: this.state.studentFormData.TermsAndConditionsRefundPolicy,
      TermsAndConditionsPrivacyPolicy: this.state.studentFormData.TermsAndConditionsPrivacyPolicy,
      TermsAndConditionsPrivacyPolicyPictureAuthorization: this.state.studentFormData.TermsAndConditionsPrivacyPolicyPictureAuthorization,
      TermsAndConditionsValidations: this.state.studentFormData.TermsAndConditionsValidations,
    }

    if (this.state.activeStep == Number(0)) {
      if (!this.formOneValidate()) {
        this.setState({ activeStep: Number(0) })
      }
      else {
        await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
         this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }
    else if (this.state.activeStep == Number(1)) {
      if (!this.formTwoValidate()) {
        this.setState({ activeStep: Number(1) })
      }
      else {
        await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
         this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }
    else if (this.state.activeStep == Number(2)) {
      if (!this.formThreeValidate()) {
        this.setState({ activeStep: Number(2) })
      }
      else {
        await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
         this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }
    else if (this.state.activeStep == Number(3)) {
      if (!this.formFourValidate()) {
        this.setState({ activeStep: Number(3) })
      }
      else {
        await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
         this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }

    else if (this.state.activeStep == Number(4)) {
      if (!this.formFiveValidate()) {
        this.setState({ activeStep: Number(4) })
      }
      else {
        await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
         this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }
    else if (this.state.activeStep == Number(5)) {
      if (!this.formSixValidation()) {
        this.setState({ activeStep: Number(5) })
      }
      else {
        this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
        this.setState({ isLoading: false, open: false })
        this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }

    else if (this.state.activeStep == Number(6)) {
      if (!this.formSevenValidation()) {
        this.setState({ activeStep: Number(6) })
      }
      else if (this.formSevenValidation()) {
        this.secondFormNext()
        this.setState({ isLoading: true })
      }
    }

    else {
      await this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: main })
       this.setState({ activeStep: this.state.activeStep + 1 })
    }

  };

  handleBackMain = async () => {
       this.setState({ activeStep: this.state.activeStep - 1 })
   };
  ///stepperStartEnd///
  genderChange = (e: any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentGender: e.target.value
      }
    }))
    this.state.studentFormData.studentErrors['studentGender'] = " "
  }
  handleChangeScholarshipType = (e: any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentScholarShopType: e.target.value
      }
    }))
    this.state.studentFormData.studentErrors['studentScholarShopType'] = " "
  }

  datechange = (e: any) => {
    let data: any = e.target.value
    let dob = new Date(data)
    let diff_ms = Date.now() - dob.getTime()
    let age_dt = new Date(diff_ms);
    let calc: any = Math.abs(age_dt.getUTCFullYear() - 1970)
    this.setState({
      studentFormData: {
        ...this.state.studentFormData, studentAge: calc, studentDate: e.target.value
      }
    })

    this.state.studentFormData.studentErrors['studentAge'] = " "

    this.state.studentFormData.studentErrors['studentDate'] = " "
  }
  uploadFilesPwd = () => {

  }

  ///file upload function
  uploadFiles = (file: any, purpose: any) => {
    if (purpose === 'letter') {

      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileLetterRecommendation: {
            ...prevState.studentFormData.fileLetterRecommendation,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'pwd') {

      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          filePwd: {
            ...prevState.studentFormData.filePwd,
            filenm: file
          }
        }
      }))

    }

    if (purpose === 'psa') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileBirthCertificate: {
            ...prevState.studentFormData.fileBirthCertificate,
            filenm: file
          }
        }
      }))
    }


    if (purpose === 'report') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileReportCard: {
            ...prevState.studentFormData.fileReportCard,
            filenm: file
          }
        }
      }))
    }
    if (purpose === 'transcript') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileSchoolRecords: {
            ...prevState.studentFormData.fileSchoolRecords,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'lrn') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileLearnerReference: {
            ...prevState.studentFormData.fileLearnerReference,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'picture') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          filePicture: {
            ...prevState.studentFormData.filePicture,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'solo') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileSoloParent: {
            ...prevState.studentFormData.fileSoloParent,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'validId') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileParentValid: {
            ...prevState.studentFormData.fileParentValid,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'achievements') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileAchivementsProof: {
            ...prevState.studentFormData.fileAchivementsProof,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'chruch') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileChurchLetter: {
            ...prevState.studentFormData.fileChurchLetter,
            filenm: file
          }
        }
      }))
    }

    if (purpose === 'income') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileIncomeProof: {
            ...prevState.studentFormData.fileIncomeProof,
            filenm: file
          }
        }
      }))

    }
    if (purpose === 'DeathCertificate') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileFatherDeathCertificate: {
            ...prevState.studentFormData.fileFatherDeathCertificate,
            filenm: file
          }
        }
      }))
    }
    if (purpose === 'BirthCertificate') {

      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileStudentBirthCertificate: {
            ...prevState.studentFormData.fileStudentBirthCertificate,
            filenm: file
          }
        }
      }))

    }
    if (purpose === 'familyDetails') {

      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileFamilyDetails: {
            ...prevState.studentFormData.fileFamilyDetails,
            filenm: file
          }
        }
      }))

    }
    if (purpose === 'electricBill') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileElectricBill: {
            ...prevState.studentFormData.fileElectricBill,
            filenm: file
          }
        }
      }))

    }
    if (purpose === 'BirthCertificateLuovaStaff') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileBirthCertificateLuovaStaff: {
            ...prevState.studentFormData.fileBirthCertificateLuovaStaff,
            filenm: file
          }
        }
      }))

    }
    if (purpose === 'BirthCertificateParent') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          fileParentBirthCertificateLuovaStaff: {
            ...prevState.studentFormData.fileParentBirthCertificateLuovaStaff,
            filenm: file
          }
        }
      }))
    }
  }
/// get api for pending data start ///
getPendingStudentDetail = () => {
  this.setState({loadingPendingStudent:true})
  const header = {
    "Content-Type": configJSON.pendingStudDetAPiContentType,
    "token": localStorage.getItem("authToken")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiPendingStudentDetailId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.pendingStudDetAPiCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.pendingStudDetAPiGetMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
/// get api for pending data end  ///

  ///TestingFormData///
  secondFormNext = async () => {
    const header = {
      "token": localStorage.getItem("authToken")
    }
    let gettingToken = localStorage.getItem("studentID");
    let formdata = new FormData();
    ///FirstFormDataStart///
    {
      gettingToken &&
        formdata.append("student[id]", localStorage.getItem("studentID") as any);
    }

    formdata.append("student[first_name]", this.state.studentFormData.studentFirstName);
    formdata.append("student[middle_name]", this.state.studentFormData.studentMiddleName);
    formdata.append("student[last_name]", this.state.studentFormData.studentLastName);
    formdata.append("student[gender]", this.state.studentFormData.studentGender);
    formdata.append("student[date_of_birth]", this.state.studentFormData.studentDate);
    formdata.append("student[country_of_birth]", this.state.studentFormData.studentCountry.value);
    formdata.append("student[city_of_birth]", this.state.studentFormData.studentBirth.value);
    formdata.append("student[religion_id]", this.state.studentFormData.studentReligion.id);
    formdata.append("student[other_religion]", this.state.studentFormData.studentReligionOthers);
    formdata.append("student[nationality_id]", this.state.studentFormData.studentNationality.id);
    formdata.append("student[country_of_residence]", this.state.studentFormData.studentCountryOfResidence.value);
    formdata.append("student[city_of_residence]", this.state.studentFormData.studentCityOfResidence.value);
    formdata.append("student[barangay_id]", this.state.studentFormData.barangaySubData.id);
    formdata.append("student[birth_barangay_id]", this.state.studentFormData.barangayBirth.id);
    formdata.append("student[child_first_language]", this.state.studentFormData.studentChildsFirstLanguage.id);
    formdata.append("student[other_language]", this.state.studentFormData.studentEnterChildsFirstLanguage);
    formdata.append("student[scholarship]", this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip.toString());
    formdata.append("student[scholarship_category]", this.state.studentFormData.studentScholarShopType);
    formdata.append("student[scholarship_type_id]", this.state.studentFormData.studentTypesOfScholarShip.id);
    formdata.append("student[father_death_date]", this.state.studentFormData.studentfatherDeath);
    formdata.append("student[father_cause_of_death]", this.state.studentFormData.studentCauseOfDeath);
    formdata.append("student[specification]", this.state.studentFormData.studentSpecification);

    formdata.append("student[proof_of_relationship]", this.state.studentFormData.studentProofOfRelationship);
    formdata.append("student[referral_code]", this.state.studentFormData.studentAdvocatecode);
    formdata.append("student[referral]", this.state.studentFormData.studentRefferalProgram.toString());
    formdata.append("student[pwd_discount]", this.state.studentFormData.studentPwd.toString());
    formdata.append("student[pwd_id]", this.state.studentFormData.studentPwdIdNumber);
    formdata.append("student[solo_parent_discount]", this.state.studentFormData.studentParentDiscount.toString());
    formdata.append("student[solo_parent_id]", this.state.studentFormData.studentSoloParentId);
    formdata.append("student[residential_address]", this.state.studentFormData.studentResidentialAddress);
    ///FirstFormDataEnd///
    ///SecondFormDataStart///
    formdata.append("previous_school[grade_id]", this.state.studentFormData.previousSchoolLastGradeLevel.id);
    formdata.append("previous_school[school_attended]", this.state.studentFormData.previousSchoolAttended);
    formdata.append("previous_school[school_curriculum_id]", this.state.studentFormData.previousSchoolCurriculum.id);
    formdata.append("previous_school[other_curriculum]", this.state.studentFormData.previousSchoolCurriculumOthers);
    formdata.append("previous_school[school_email]", this.state.studentFormData.previousSchoolEmail);
    formdata.append("previous_school[school_contact_number]", this.state.studentFormData.previousSchoolContact);
    formdata.append("previous_school[school_address]", this.state.studentFormData.previousSchoolAddress);
    formdata.append("previous_school[last_year_of_school]", this.state.studentFormData.previousSchoolLastSchoolYear);
    formdata.append("previous_school[learner_reference_number]", this.state.studentFormData.previousSchoolLearnerReferenceNumber);
    ///SecondFormDataEnd///
    ///ThirdFormDataStart///
    formdata.append("program_detail[hub_id]", this.state.studentFormData.programDetailsHub.id);
    formdata.append("program_detail[community_id]", this.state.studentFormData.programDetailsCommunity.id);
    formdata.append("program_detail[grade_id]", this.state.studentFormData.programDetailsIncomingGradeLevel.id);
    formdata.append("program_detail[program_id]", this.state.studentFormData.programDetailsProgram.id);
    formdata.append("program_detail[accreditation_id]", this.state.studentFormData.programDetailsAccreditation.id);
    formdata.append("program_detail[cycle]", this.state.studentFormData.programDetailsCycle);
    formdata.append("program_detail[shs_track]", this.state.studentFormData.programDetailsShsTrack);
    ///ThirdFormDataEnd///
    ///FourthFormDataStart///
    formdata.append("parent[father_first_name]", this.state.studentFormData.parentsInfoFathersFirstName)
    formdata.append("parent[father_middle_name]", this.state.studentFormData.parentsInfoFathersMiddleName)
    formdata.append("parent[father_last_name]", this.state.studentFormData.parentsInfoFathersLastName)
    formdata.append("parent[father_occupation]", this.state.studentFormData.parentsInfoFathersOccupation)
    formdata.append("parent[father_country_code]", this.state.studentFormData.parentsInfoFathersCountryCode)
    formdata.append("parent[father_mobile_number]",  this.state.studentFormData.parentsInfoFathersPhoneNumber)
    formdata.append("parent[father_email]", this.state.studentFormData.parentsInfoFathersEmailAddress)
    formdata.append("parent[mother_first_name]", this.state.studentFormData.parentsInfoMothersFirstName)
    formdata.append("parent[mother_middle_name]", this.state.studentFormData.parentsInfoMothersMiddleName)
    formdata.append("parent[mother_last_name]", this.state.studentFormData.parentsInfoMothersLastName)
    formdata.append("parent[mother_occupation]", this.state.studentFormData.parentsInfoMothersOccupation)
    formdata.append("parent[mother_country_code]", this.state.studentFormData.parentsInfoMothersCountryCode)
    formdata.append("parent[mother_mobile_number]",  this.state.studentFormData.parentsInfoMothersPhoneNumber)
    formdata.append("parent[mother_email]", this.state.studentFormData.parentsInfoMothersEmailAddress)
    formdata.append("parent[marital_status]", this.state.studentFormData.parentsInfoMaritalStatus)
    formdata.append("parent[guardian_name]", this.state.studentFormData.parentsInfoGuardiansName)
    formdata.append("parent[guardian_address]", this.state.studentFormData.parentsInfoGuardiansAddress)
    formdata.append("parent[guardian_occupation]", this.state.studentFormData.parentsInfoGuardiansOccupation)
    formdata.append("parent[guardian_country_code]", this.state.studentFormData.parentsInfoGuardiansCountryCode)
    formdata.append("parent[guardian_mobile_number]",  this.state.studentFormData.parentsInfoGuardiansPhoneNumber)
    formdata.append("parent[guardian_email]", this.state.studentFormData.parentsInfoGuardiansEmailAddress)
    formdata.append("parent[relationship_to_the_student]", this.state.studentFormData.parentsInfoRelationshipToTheStudent)
    ///FourthFormDataEnd///
    // ///FifthFormDataStart///
    formdata.append("student[letter_of_recommendation]", this.state.studentFormData.fileLetterRecommendation.filenm)
    formdata.append("student[pwd_id_proof]", this.state.studentFormData.filePwd.filenm);
    formdata.append("student[psa_birth_certificate]", this.state.studentFormData.fileBirthCertificate.filenm)
    formdata.append("student[recent_report_card]", this.state.studentFormData.fileReportCard.filenm);
    formdata.append("student[school_records]", this.state.studentFormData.fileSchoolRecords.filenm);
    formdata.append("student[learner_reference_number]", this.state.studentFormData.fileLearnerReference.filenm);
    formdata.append("student[solo_parent_id_proof]", this.state.studentFormData.fileSoloParent.filenm);
    formdata.append("student[parent_valid_id]", this.state.studentFormData.fileParentValid.filenm);
    formdata.append("student[proof_of_achievements]", this.state.studentFormData.fileAchivementsProof.filenm)
    formdata.append("student[church_affiliation_letter]", this.state.studentFormData.fileChurchLetter.filenm)
    formdata.append("student[proof_of_income_or_billing]", this.state.studentFormData.fileIncomeProof.filenm)
    formdata.append("student[father_death_certificate]", this.state.studentFormData.fileFatherDeathCertificate.filenm)
    formdata.append("student[student_birth_certificate]", this.state.studentFormData.fileStudentBirthCertificate.filenm)
    formdata.append("student[family_details_letter]", this.state.studentFormData.fileFamilyDetails.filenm)
    formdata.append("student[electric_bill]", this.state.studentFormData.fileElectricBill.filenm)
    formdata.append("student[parent_birth_certificate]", this.state.studentFormData.fileBirthCertificateLuovaStaff.filenm)
    formdata.append("student[luova_staff_birth_certificate]", this.state.studentFormData.fileParentBirthCertificateLuovaStaff.filenm)
    formdata.append("student[id_picture]", this.state.studentFormData.filePicture.filenm)
    // ///FifthFormDataEnd///
    // ///SixthFormDataStart///
    formdata.append("student[reason_of_registering_id]", this.state.studentFormData.reviewReasonForFinallyRegistering.id)
    // ///SixthFormDataEnd///
    /// status//
    if (this.state.activeStep == Number(6)) {
      formdata.append("student[status]", 'submitted_for_review')
    }
    else{
      formdata.append("student[status]", this.state.studentFormData.studentStatus)
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.prevSchoolApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.enrolmentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PrevSchoolAPiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  onClickNextOfPrevSchool = () => {
    if (this.state.activeStep == Number(0)) {
      if (!this.formOneValidate()) {
        this.setState({ activeStep: Number(0) })

      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }
    else if (this.state.activeStep == Number(1)) {
      if (!this.formTwoValidate()) {

        this.setState({ activeStep: Number(1) })
      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }
    else if (this.state.activeStep == Number(2)) {
      if (!this.formThreeValidate()) {
        this.setState({ activeStep: Number(2) })
      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }
    else if (this.state.activeStep == Number(3)) {
      if (!this.formFourValidate()) {
        this.setState({ activeStep: Number(3) })
      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }

    else if (this.state.activeStep == Number(4)) {
      if (!this.formFiveValidate()) {
        this.setState({ activeStep: Number(4) })
      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }
    else if (this.state.activeStep == Number(5)) {
      if (!this.formSixValidation()) {
        this.setState({ activeStep: Number(5) })
      }
      else {
        this.setState({ isLoading: true })
        this.secondFormNext()
      }
    }
  }
  handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };

  ///secondFormEnd///
  hideError = (event: any) => {

    this.state.studentFormData.previousSchoolErrors[event.target.name] = '';
    this.setState({
      studentFormData: { ...this.state.studentFormData, previousSchoolErrors: this.state.studentFormData.previousSchoolErrors }
    })

    this.state.studentFormData.programDetailsErrors[event.target.name] = '';
    this.setState({
      studentFormData: { ...this.state.studentFormData, programDetailsErrors: this.state.studentFormData.programDetailsErrors }
    })

    this.state.studentFormData.studentErrors[event.target.name] = '';
    this.setState({
      studentFormData: { ...this.state.studentFormData, studentErrors: this.state.studentFormData.studentErrors }
    })
    this.state.studentFormData.parentsValidationErrors[event.target.name] = '';
    this.setState({
      studentFormData: { ...this.state.studentFormData, parentsValidationErrors: this.state.studentFormData.parentsValidationErrors }
    })

  }

  ///form 1 validation starts ///
  formOneValidate = () => {
    let isFormValid = true;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;

    if (this.state.studentFormData.studentLastName === '') {
      this.state.studentFormData.studentErrors['studentLastName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.studentLastName)) {
      this.state.studentFormData.studentErrors['studentLastName'] = "Incorrect format - Only alphabets and period are allowed"
      isFormValid = false
    }

    if (this.state.studentFormData.studentFirstName === '') {
      this.state.studentFormData.studentErrors['studentFirstName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.studentFirstName)) {
      this.state.studentFormData.studentErrors['studentFirstName'] = "Incorrect format - Only alphabets and period are allowed"
      isFormValid = false
    }

    if (this.state.studentFormData.studentMiddleName === '') {
      this.state.studentFormData.studentErrors['studentMiddleName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.studentMiddleName)) {
      this.state.studentFormData.studentErrors['studentMiddleName'] = "Incorrect format - Only alphabets and period are allowed"
      isFormValid = false
    }

    if (this.state.studentFormData.studentGender === 'choose') {
      this.state.studentFormData.studentErrors['studentGender'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.studentDate === '') {
      this.state.studentFormData.studentErrors['studentDate'] = "Required *"
      isFormValid = false
    }
    if (Number(this.state.studentFormData.studentAge) < 2) {
      this.state.studentFormData.studentErrors['studentAge'] = "Child should be minimum 2 years old"
      isFormValid = false
    }

    if (this.state.studentFormData.studentCountry.value === 'choose' || this.state.studentFormData.studentCountry.value === '') {
      this.state.studentFormData.studentErrors['studentCountry'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.studentBirth.value === 'choose' || this.state.studentFormData.studentBirth.value === '') {
      this.state.studentFormData.studentErrors['studentBirth'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.studentBirth.value !="" && this.state.byrangayDataBirth.length > 0) {
      if (this.state.studentFormData.barangayBirth.value === 'choose') {
        this.state.studentFormData.studentErrors['barangayBirth'] = "Required *"
        isFormValid = false
      }
    }
    if (this.state.studentFormData.studentReligion.value === 'choose') {
      this.state.studentFormData.studentErrors['studentReligion'] = "Required *"
      isFormValid = false
    }


    if (this.state.studentFormData.studentReligion.value == "Other") {
      if (this.state.studentFormData.studentReligionOthers === '') {
        this.state.studentFormData.studentErrors['studentReligionOthers'] = "Required *"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.studentNationality.value === 'choose') {
      this.state.studentFormData.studentErrors['studentNationality'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.studentCountryOfResidence.value === 'choose' || this.state.studentFormData.studentCountryOfResidence.value === '') {
      this.state.studentFormData.studentErrors['studentCountryOfResidence'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.studentCityOfResidence.value === 'choose' || this.state.studentFormData.studentCityOfResidence.value === '') {
      this.state.studentFormData.studentErrors['studentCityOfResidence'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.studentCityOfResidence.value !="" && this.state.byrangayData.length > 0) {
      if (this.state.studentFormData.barangaySubData.value === 'choose') {
        this.state.studentFormData.studentErrors['barangaySubData'] = "Required *"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.studentChildsFirstLanguage.value === 'choose') {
      this.state.studentFormData.studentErrors['studentChildsFirstLanguage'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.studentChildsFirstLanguage.value == "Others") {
      if (this.state.studentFormData.studentEnterChildsFirstLanguage === '') {
        this.state.studentFormData.studentErrors['studentEnterChildsFirstLanguage'] = "Required *"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip) {
      if (this.state.studentFormData.studentScholarShopType === 'choose') {
        this.state.studentFormData.studentErrors['studentScholarShopType'] = "Required *"
        isFormValid = false
      }
      if (this.state.studentFormData.studentTypesOfScholarShip.value === 'choose') {
        this.state.studentFormData.studentErrors['studentTypesOfScholarShip'] = "Required *"
        isFormValid = false
      }
      else if (this.state.studentFormData.studentTypesOfScholarShip.value == "Scholarship for HG Servant Leaders") {
        if (this.state.studentFormData.studentSpecification === 'choose') {
          this.state.studentFormData.studentErrors['studentSpecification'] = "Required *"
          isFormValid = false
        }
      }
      else if (this.state.studentFormData.studentTypesOfScholarShip.value == "HG Staff Student Scholarship") {
        if (this.state.studentFormData.studentProofOfRelationship === 'choose') {
          this.state.studentFormData.studentErrors['studentProofOfRelationship'] = "Required *"
          isFormValid = false
        }
      }
      else if (this.state.studentFormData.studentTypesOfScholarShip.value == "The Jovi Villote Memorial Scholarship") {
        if (this.state.studentFormData.studentfatherDeath === '') {
          this.state.studentFormData.studentErrors['studentfatherDeath'] = "Required *"
          isFormValid = false
        }

        if (this.state.studentFormData.studentCauseOfDeath === 'choose') {
          this.state.studentFormData.studentErrors['studentCauseOfDeath'] = "Required *"
          isFormValid = false
        }
      }
    }
    if (this.state.studentFormData.studentRefferalProgram) {
      if (this.state.studentFormData.studentAdvocatecode === '') {
        this.state.studentFormData.studentErrors['studentAdvocatecode'] = "Required *"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.studentPwd) {
      if (this.state.studentFormData.studentPwdIdNumber === '') {
        this.state.studentFormData.studentErrors['studentPwdIdNumber'] = "Required *"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.studentParentDiscount) {
      if (this.state.studentFormData.studentSoloParentId === '') {
        this.state.studentFormData.studentErrors['studentSoloParentId'] = "Required *"
        isFormValid = false
      }
    }
    if (this.state.studentFormData.studentResidentialAddress === '') {
      this.state.studentFormData.studentErrors['studentResidentialAddress'] = "Required *"
      isFormValid = false
    }


    return isFormValid
  }
  ///form 1 validation ends ///
  ///form 2 validation starts ///
  formTwoValidate = () => {
    let isFormValid = true;
    const regexSchool = /^[A-Z @~`!#$%^&*()_=+';:\"\\/?>.<,-]*$/i;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{6,11}$/;
    const regexYear = /^\d+$/;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const regexAlphaNumeric = /^[a-zA-Z0-9_]*$/;

    if (this.state.studentFormData.previousSchoolLastGradeLevel.value === 'choose') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolLastGradeLevel'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.previousSchoolCurriculum.value === 'choose') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolCurriculum'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.previousSchoolCurriculum.value == "Other") {
      if (this.state.studentFormData.previousSchoolCurriculumOthers === null) {
        this.state.studentFormData.previousSchoolErrors['previousSchoolCurriculumOthers'] = "Required *"
        isFormValid = false
      }
    }


    if (this.state.studentFormData.previousSchoolContact === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolContact'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.previousSchoolContact.toUpperCase() !== "N/A") {
      if (!regexPhone.test(this.state.studentFormData.previousSchoolContact)) {
        this.state.studentFormData.previousSchoolErrors['previousSchoolContact'] = "Min 6 to Max 11 digits only"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.previousSchoolLastSchoolYear === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolLastSchoolYear'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.previousSchoolLastSchoolYear.toUpperCase() !== "N/A") {
      if (!regexYear.test(this.state.studentFormData.previousSchoolLastSchoolYear)) {
        this.state.studentFormData.previousSchoolErrors['previousSchoolLastSchoolYear'] = "Only digits allowed"
        isFormValid = false
      }
    }

    if (this.state.studentFormData.previousSchoolAttended === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolAttended'] = "Required *"
      isFormValid = false
    } 
    else if (this.state.studentFormData.previousSchoolAttended.toUpperCase() !== "N/A"){
      if (!regexName.test(this.state.studentFormData.previousSchoolAttended)) {
        this.state.studentFormData.previousSchoolErrors['previousSchoolAttended'] = "Incorrect format - Only characters are allowed"
        isFormValid = false
      }
    }
    // else if (!regexSchool.test(this.state.studentFormData.previousSchoolAttended)) {
    //   this.state.studentFormData.previousSchoolErrors['previousSchoolAttended'] = "Numbers not allowed"
    //   isFormValid = false
    // }
     
    if (this.state.studentFormData.previousSchoolEmail === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolEmail'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.previousSchoolEmail.toUpperCase() !== "N/A") {
      if (!regexEmail.test(this.state.studentFormData.previousSchoolEmail)) {
        isFormValid = false;
        this.state.studentFormData.previousSchoolErrors['previousSchoolEmail'] = "Incorrect Email"
      }
    }
 
    if (this.state.studentFormData.previousSchoolAddress === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolAddress'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.previousSchoolLearnerReferenceNumber === '') {
      this.state.studentFormData.previousSchoolErrors['previousSchoolLearnerReferenceNumber'] = "Required *"
      isFormValid = false
    }
    if (!regexAlphaNumeric.test(this.state.studentFormData.previousSchoolLearnerReferenceNumber)) {
      isFormValid = false;
      this.state.studentFormData.previousSchoolErrors['previousSchoolLearnerReferenceNumber'] = "Incorrect format - Only AlphaNumeric are allowed"
    }

    return isFormValid
  }
  ///form 2 validation ends ///

  ///form 3 validation starts ///
  formThreeValidate = () => {
    let isFormValid = true;
    if (this.state.studentFormData.programDetailsHub.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsHub'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsCommunity.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsCommunity'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsIncomingGradeLevel.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsIncomingGradeLevel'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsProgram.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsProgram'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsAccreditation.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsAccreditation'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsAccreditation.value === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsAccreditation'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsCycle === 'choose') {
      this.state.studentFormData.programDetailsErrors['programDetailsCycle'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.programDetailsIncomingGradeLevel.value == "Grade 11" &&
        this.state.studentFormData.programDetailsAccreditation.value == 'PH Accreditation') {
      if (this.state.studentFormData.programDetailsShsTrack === 'choose') {
        this.state.studentFormData.programDetailsErrors['programDetailsShsTrack'] = "Required *"
        isFormValid = false
      }
    }
    if (this.state.studentFormData.programDetailsIncomingGradeLevel.value == "Grade 12" &&
        this.state.studentFormData.programDetailsAccreditation.value == 'PH Accreditation') {
      if (this.state.studentFormData.programDetailsShsTrack === 'choose') {
        this.state.studentFormData.programDetailsErrors['programDetailsShsTrack'] = "Required *"
        isFormValid = false
      }
    }
    return isFormValid
  }
  ///form 3 validation ends ///
  ///form 4 validation starts ///
  formFourValidate = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{6,11}$/;
    const regexPhone1 = /^\d{1,11}$/;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

    //fathersStart//
    if (this.state.studentFormData.parentsInfoFathersFirstName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersFirstName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoFathersFirstName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersFirstName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }

    if (this.state.studentFormData.parentsInfoFathersMiddleName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersMiddleName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoFathersMiddleName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersMiddleName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoFathersLastName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersLastName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoFathersLastName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersLastName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoFathersOccupation === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersOccupation'] = "Required *"
      isFormValid = false
    }
    // else if (!regexName.test(this.state.studentFormData.parentsInfoFathersOccupation)) {
    //   this.state.studentFormData.parentsValidationErrors['parentsInfoFathersOccupation'] = "Incorrect format - Only characters are allowed"
    //   isFormValid = false
    // }

    if (this.state.studentFormData.parentsInfoFathersCountryCode === 'choose') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersCountryCode'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.parentsInfoFathersPhoneNumber === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersPhoneNumber'] = "Required *"
      isFormValid = false
    }
    else if (!regexPhone.test(this.state.studentFormData.parentsInfoFathersPhoneNumber)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersPhoneNumber'] = "Min 6 to Max 11 digits only"
      isFormValid = false
    }

    if (this.state.studentFormData.parentsInfoFathersEmailAddress === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoFathersEmailAddress'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.parentsInfoFathersEmailAddress.toUpperCase() !== "N/A") {
      if (!regexEmail.test(this.state.studentFormData.parentsInfoFathersEmailAddress)) {

        this.state.studentFormData.parentsValidationErrors['parentsInfoFathersEmailAddress'] = "Incorrect Email"
        isFormValid = false;
      }
    }
  

    if (this.state.studentFormData.parentsInfoMothersFirstName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersFirstName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoMothersFirstName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersFirstName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoMothersMiddleName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersMiddleName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoMothersMiddleName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersMiddleName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoMothersLastName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersLastName'] = "Required *"
      isFormValid = false
    }
    else if (!regexName.test(this.state.studentFormData.parentsInfoMothersLastName)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersLastName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoMothersOccupation === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersOccupation'] = "Required *"
      isFormValid = false
    }
    // else if (!regexName.test(this.state.studentFormData.parentsInfoMothersOccupation)) {
    //   this.state.studentFormData.parentsValidationErrors['parentsInfoMothersOccupation'] = "Incorrect format - Only characters are allowed"
    //   isFormValid = false
    // }

    if (this.state.studentFormData.parentsInfoMothersCountryCode === 'choose') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersCountryCode'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.parentsInfoMothersPhoneNumber === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersPhoneNumber'] = "Required *"
      isFormValid = false
    }
    else if (!regexPhone.test(this.state.studentFormData.parentsInfoMothersPhoneNumber)) {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersPhoneNumber'] = "Min 6 to Max 11 digits only"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoMothersEmailAddress === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMothersEmailAddress'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.parentsInfoMothersEmailAddress.toUpperCase() !== "N/A") {
      if (!regexEmail.test(this.state.studentFormData.parentsInfoMothersEmailAddress)) {

        this.state.studentFormData.parentsValidationErrors['parentsInfoMothersEmailAddress'] = "Incorrect Email"
        isFormValid = false;
      }
    }

    if (this.state.studentFormData.parentsInfoMaritalStatus === 'choose') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoMaritalStatus'] = "Required *"
      isFormValid = false
    }
    ///marriedStatusEnd///
    ///GuardiansStart///
    if (this.state.studentFormData.parentsInfoGuardiansName === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansName'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.parentsInfoGuardiansName.toUpperCase() !== "N/A") {
      if (!regexName.test(this.state.studentFormData.parentsInfoGuardiansName)) {
        this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansName'] = "Incorrect format - Only characters are allowed"
        isFormValid = false
      }
    }
   
    if (this.state.studentFormData.parentsInfoGuardiansAddress === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansAddress'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.parentsInfoGuardiansOccupation === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansOccupation'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.parentsInfoGuardiansOccupation.toUpperCase() !== "N/A") {
      if (!regexName.test(this.state.studentFormData.parentsInfoGuardiansOccupation)) {
        this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansOccupation'] = "Incorrect format - Only characters are allowed"
        isFormValid = false
      }
    }
    

    if (this.state.studentFormData.parentsInfoGuardiansCountryCode === 'choose') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansCountryCode'] = "Required *"
      isFormValid = false
    }

    if (this.state.studentFormData.parentsInfoGuardiansPhoneNumber === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansPhoneNumber'] = "Required *"
      isFormValid = false
    } 
    // else if (!regexPhone1.test(this.state.studentFormData.parentsInfoGuardiansPhoneNumber)) {
    //   this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansPhoneNumber'] = "Max 11 digits only"
    //   isFormValid = false
    // } 
    if (this.state.studentFormData.parentsInfoGuardiansPhoneNumber !== "0"){
      if (!regexPhone.test(this.state.studentFormData.parentsInfoGuardiansPhoneNumber)) {
       this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansPhoneNumber'] = "Min 6 to Max 11 digits only"
       isFormValid = false
     }}
   
    if (this.state.studentFormData.parentsInfoGuardiansEmailAddress === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansEmailAddress'] = "Required *"
      isFormValid = false
    }

    else if (this.state.studentFormData.parentsInfoGuardiansEmailAddress.toUpperCase() !== "N/A") {
      if (!regexEmail.test(this.state.studentFormData.parentsInfoGuardiansEmailAddress)) {

        this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansEmailAddress'] = "Incorrect Email"
        isFormValid = false;
      }
    }

    if (this.state.studentFormData.parentsInfoRelationshipToTheStudent === '') {
      this.state.studentFormData.parentsValidationErrors['parentsInfoRelationshipToTheStudent'] = "Required *"
      isFormValid = false
    }
    else if (this.state.studentFormData.parentsInfoRelationshipToTheStudent.toUpperCase() !== "N/A") {
      if (!regexName.test(this.state.studentFormData.parentsInfoRelationshipToTheStudent)) {
        this.state.studentFormData.parentsValidationErrors['parentsInfoRelationshipToTheStudent'] = "Incorrect format - Only characters are allowed"
        isFormValid = false
      }
    }
    ///RelationshipToTheStudentEnd///
    return isFormValid
  }
  ///form 4 validation ends ///

  /// form 5 validation starts///
  formFiveValidate = () => {
    let isFormValid = true;
    if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'Chrissie Gracia Scholarship') {

     if (!this.state.studentFormData.fileLetterRecommendation.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLetterRecommendation: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
    
      return isFormValid
    }

    ///----------------------------------International scholarship--------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'The International/National Achievement Scholarship') {

      if (!this.state.studentFormData.fileLetterRecommendation.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLetterRecommendation: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileAchivementsProof.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileAchivementsProof: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }

    ///----------------------------------The Ministry Leaders Assistance---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'The Ministry Leaders Assistance') {

      if (!this.state.studentFormData.fileChurchLetter.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileChurchLetter: "Required *",
            }
          }
        }))
        isFormValid = false
      }


      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }


    ///----------------------------------Scholarship for HG Servant Leaders---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'Scholarship for HG Servant Leaders'
      && this.state.studentFormData.studentSpecification !== 'choose') {

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }
    ///----------------------------------Financial Aid---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'Financial Aid') {


      if (!this.state.studentFormData.fileLetterRecommendation.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLetterRecommendation: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileIncomeProof.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileIncomeProof: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }

    ///----------------------------------The Jovi Villote Memorial Scholarship---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'The Jovi Villote Memorial Scholarship' &&
      this.state.studentFormData.studentfatherDeath !== '' &&
      this.state.studentFormData.studentCauseOfDeath !== 'choose') {

      if (!this.state.studentFormData.fileFatherDeathCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileFatherDeathCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileStudentBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileStudentBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileFamilyDetails.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileFamilyDetails: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileFamilyDetails.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileFamilyDetails: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileElectricBill.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileElectricBill: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }

    ///----------------------------------HG Staff Student Scholarship child---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'HG Staff Student Scholarship' &&
      this.state.studentFormData.studentProofOfRelationship == 'Child') {


      if (!this.state.studentFormData.fileBirthCertificateLuovaStaff.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificateLuovaStaff: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }

    ///----------------------------------HG Staff Student Scholarship others---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'Individual' &&
      this.state.studentFormData.studentTypesOfScholarShip.value == 'HG Staff Student Scholarship' &&
      (this.state.studentFormData.studentProofOfRelationship == 'Sister' ||
        this.state.studentFormData.studentProofOfRelationship == 'Brother' ||
        this.state.studentFormData.studentProofOfRelationship == 'Nephew' ||
        this.state.studentFormData.studentProofOfRelationship == 'Niece'
      )) {

      if (!this.state.studentFormData.fileBirthCertificateLuovaStaff.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificateLuovaStaff: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentBirthCertificateLuovaStaff.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentBirthCertificateLuovaStaff: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }

    ///----------------------------------mandatory forms files---------------------------------
    else if (this.state.studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
      this.state.studentFormData.studentScholarShopType == 'choose' ||
      this.state.studentFormData.studentScholarShopType == 'Family' ||
      this.state.studentFormData.studentTypesOfScholarShip.value == 'choose' ||
      this.state.studentFormData.studentTypesOfScholarShip.value == null) {

     
      if (!this.state.studentFormData.fileBirthCertificate.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileBirthCertificate: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePwd.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePwd: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileReportCard.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileReportCard: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSchoolRecords.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSchoolRecords: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileLearnerReference.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileLearnerReference: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.filePicture.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              filePicture: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileSoloParent.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileSoloParent: "Required *",
            }
          }
        }))
        isFormValid = false
      }

      if (!this.state.studentFormData.fileParentValid.filenm.name) {
        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            fileErrors: {
              ...prevState.studentFormData.fileErrors,
              fileParentValid: "Required *",
            }
          }
        }))
        isFormValid = false
      }
      return isFormValid
    }


  }

  ///for 5 validation ends ///
  ///form 6 validation start ///
  formSixValidation = () => {
    let isFormValid = true;
    if (this.state.studentFormData.reviewReasonForFinallyRegistering.value === 'choose') {
      this.state.studentFormData.reviewValidationErrors['reviewReasonForFinallyRegistering'] = "Required *"
      isFormValid = false
    }
    return isFormValid
  }
  
  ///form 6 validation ends ///
  ///form 7 validation start ///
  formSevenValidation = () => {
    let isFormValid = true;
    if (this.state.studentFormData.TermsAndConditionsAgreeGeneralTerms === false) {
      this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsAgreeGeneralTerms'] = "Required *"
      isFormValid = false

    }
    if (this.state.studentFormData.TermsAndConditionsPrivacyPolicy === false) {
      this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsPrivacyPolicy'] = "Required *"
      isFormValid = false
    }
    if (this.state.studentFormData.TermsAndConditionsRefundPolicy === false) {
      this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsRefundPolicy'] = "Required *"
      isFormValid = false
    }
    return isFormValid
  }
  ///form 7 validation ends ///
  ///terms change handlers start///
  handleCheckedGeneralTerms = (e: any) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, TermsAndConditionsAgreeGeneralTerms: e.target.checked }
    })
    this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsAgreeGeneralTerms'] = " "

  }
  handleCheckedRefundTerms = (e: any) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, TermsAndConditionsRefundPolicy: e.target.checked }
    })
    this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsRefundPolicy'] = " "

  }
  handleCheckedPrivacyTerms = (e: any) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, TermsAndConditionsPrivacyPolicy: e.target.checked }
    })
    this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsPrivacyPolicy'] = " "

  }
  handleCheckedPicturePolicy = (e: any) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, TermsAndConditionsPrivacyPolicyPictureAuthorization: e.target.checked }
    })
    this.state.studentFormData.TermsAndConditionsValidations['TermsAndConditionsPrivacyPolicyPictureAuthorization'] = " "

  }
  ///terms change hadler end///
  ///previous school change handler start///
  lastGradeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        previousSchoolLastGradeLevel: {
          ...prevState.studentFormData.previousSchoolLastGradeLevel,
          value: e.target.value,
          id: this.state.gradeSelectedData.find((o: any) => o.grade == e.target.value)?.id

        }
      }
    }),)
    this.state.studentFormData.previousSchoolErrors['previousSchoolLastGradeLevel'] = " "
  }


  curriculumChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        previousSchoolCurriculum: {
          ...prevState.studentFormData.previousSchoolCurriculum,
          value: e.target.value,
          id: this.state.curriculumSelectedData.find((o: any) => o.curriculum == e.target.value)?.id

        }
      }
    }))
    this.state.studentFormData.previousSchoolErrors['previousSchoolCurriculum'] = " "
  }
  ///previous change handler end////


  HANDLE = () => {
    this.props.navigation.navigate('EnrollmentPrevSchool')
  }
  Handle2 = () => {
    this.props.navigation.navigate('Customform')
  }
  /// barangays api call request Start///
  getBarangaysData = (cityName: string) => {

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      "token": localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBarangaysCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_barangays?city=${cityName}`
     
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  /// barangays api call request End///
  /// barangays api call request Start///
  getBarangaysDataBirth = (cityBirthName: string) => {

    const header = {
      "Content-Type": configJSON.barangayBirthAPiContentType,
      "token": localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBarangaysBirthCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_barangays?city=${cityBirthName}`
     
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.barangayBirthAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  /// barangays api call request End///

  //---------------------- country api call request ----------------------
  getCountryData = () => {

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCountryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCountryCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  changeHandlerGetCountries = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentCountry: {
          ...prevState.studentFormData.studentCountry,
          value: newInputValue,
        }
      }
    }))
    this.state.studentFormData.studentErrors['studentCountry'] = " ";

    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentBirth: {
          ...prevState.studentFormData.studentBirth,
          value: "",
          id: ""
        }
      }
    }))

     this.setState({byrangayDataBirth:[]})
     this.state.studentFormData.studentErrors['barangayBirth'] = " "
   
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangayBirth: {
          ...prevState.studentFormData.barangayBirth,
          value: "choose",
          id: ""
        }
      }
    }))

      this.state.getAllCountries && this.state.getAllCountries?.filter(async (item: any) => {
        if(newInputValue){
        if (item.name == newInputValue) {
          let data: any = [];
          let dataMsg: any = [{ name: 'No City found' }]
          const key = 'name';
          if(newInputValue == 'Philippines'){
            this.state.provincesData?.map((city:any)=>{
                data?.push({
                name: city,
                })
               })
           }
           else{
          await City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
            data?.push({
              name: city?.name,
            })
          })
        }
          const arrayUniqueByKey = [...new Map(data.map((item:any) =>
            [item[key], item])).values()];
          if (data.length == 0) {
            this.setState({ getAllCities: dataMsg })
          }
          else {
            this.setState({ getAllCities: arrayUniqueByKey })
          }
        }
        }
        else{
          this.setState(prevState => ({
            studentFormData: {
              ...prevState.studentFormData,studentBirthAutoCompleteValue: {value: ""}}
          }))

        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            studentBirth: {
              ...prevState.studentFormData.studentBirth,
              value: "",
              id: ""
            }
          }
        }))
       this.setState({getAllCities:[]})  
        }
      })
   
  }
  
  //---------------------- country Residence api call request ----------------------
  getCountryResidenceData = () => {

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCountryResidenceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCountryCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //---------------------- Provinces api call request start ----------------------
  getProvincesData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiProvinces,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvincesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiProvincesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiProvincesCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
    //---------------------- Provinces api call request end ----------------------
  changeHandlerAutoGetCountriesResidence=(e: React.ChangeEvent<HTMLInputElement>,newValue:any)=>{
               this.setState((prevState:any) => ({
                          studentFormData: {
                            ...prevState.studentFormData,
                            countryResiAutoCompleteValue: {
                            value: newValue,
                            }}
              }))
  }
  changeHandlerAutoGetCountriesBirth=(e: React.ChangeEvent<HTMLInputElement>,newValue:any)=>{
    console.log("BACK newVAl country",newValue)
               this.setState((prevState:any) => ({
                          studentFormData: {
                            ...prevState.studentFormData,
                            studentCountryAutoCompleteValue: {
                            value: newValue,
                            }}
              }))
              
  }
  changeHandlerAutoGetCitiesResidence=(e: React.ChangeEvent<HTMLInputElement>,newValue:any)=>{
               this.setState((prevState:any) => ({
                          studentFormData: {
                            ...prevState.studentFormData,
                            cityResiAutoCompleteValue: {
                            value: newValue,
                            }}
              }))
  }
  changeHandlerAutoGetCitiesBirth=(e: React.ChangeEvent<HTMLInputElement>,newValue:any)=>{
    console.log("BACK newVAl city",newValue)
               this.setState((prevState:any) => ({
                          studentFormData: {
                            ...prevState.studentFormData,
                            studentBirthAutoCompleteValue: {
                            value: newValue,
                            }}
              }))
  }

  changeHandlerGetCountriesResidence = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentCountryOfResidence: {
          ...prevState.studentFormData.studentCountryOfResidence,
          value: newInputValue,
        }}
    }))

    this.state.studentFormData.studentErrors['studentCountryOfResidence'] = " ";
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentCityOfResidence: {
          ...prevState.studentFormData.studentCityOfResidence,
          value: "",
          id: ""
        }
      }
    }))

    this.setState({byrangayData:[]})
    this.state.studentFormData.studentErrors['barangaySubData'] = " "
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangaySubData: {
          ...prevState.studentFormData.barangaySubData,
          value: "choose",
          id: ""
        }
      }
    }))
   
      this.state.getAllCountriesOne && this.state.getAllCountriesOne?.filter(async (item: any) => {
        if(newInputValue){
          if (item.name ==newInputValue) {
              let data:any=[];
              let dataMsg:any=[{name:'No City found'}]
              const key = 'name';
              if(newInputValue == 'Philippines'){
                this.state.provincesData?.map((city:any)=>{
                    data?.push({
                    name: city,
                    })
                   })
               }
               else{
                await City.getCitiesOfCountry(item?.isoCode)?.map((city:any)=>{
                  data?.push({
                  name: city?.name,
                  })
                 })
              }
            
                  const arrayUniqueByKey1 = [...new Map(data.map((item:any) =>
                    [item[key], item])).values()];
                  if(data.length == 0){
                     this.setState({getAllCitiesOne:dataMsg})  
                    }
                  else{
                    this.setState({getAllCitiesOne:arrayUniqueByKey1})  
                    }
                }
            }
        else{
          this.setState(prevState => ({
            studentFormData: {
              ...prevState.studentFormData,cityResiAutoCompleteValue: {value: ""}}
          }))

        this.setState(prevState => ({
          studentFormData: {
            ...prevState.studentFormData,
            studentCityOfResidence: {
              ...prevState.studentFormData.studentCityOfResidence,
              value: "",
              id: ""
            }
          }
        }))
       this.setState({getAllCitiesOne:[]})  
        }
      })
  
  }
 
  changeHandlerGetCities = async (e: React.ChangeEvent<HTMLInputElement>) => {

    await this.state.getAllCountries && this.state.getAllCountries?.filter(async (item: any) => {
      if (item.name == e.target.value) {
        let data: any = [];
        let dataMsg: any = [{ name: 'No City found' }]
        const key = 'name';
        await City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
          data?.push({
            name: city?.name,
          })
        })
        const arrayUniqueByKey = [...new Map(data.map((item:any) =>
          [item[key], item])).values()];
        if (data.length == 0) {
          this.setState({ getAllCitiesOne: dataMsg })
        }
        else {
          this.setState({ getAllCitiesOne: arrayUniqueByKey })
        }

      }
    })

  }



  handleChangeCityData = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.setState({byrangayData:[]})
    this.state.studentFormData.studentErrors['barangaySubData'] = " "
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangaySubData: {
          ...prevState.studentFormData.barangaySubData,
          value: "choose",
          id: ""
        }
      }
    }))
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentCityOfResidence: {
          ...prevState.studentFormData.studentCityOfResidence,
          value: newInputValue,
          id: this.state.getAllCitiesOne.find((o: any) => o.name == newInputValue)?.id
        }
      }
    }), () => { this.getBarangaysData(this.state.studentFormData?.studentCityOfResidence?.value)})
   
    this.state.studentFormData.studentErrors['studentCityOfResidence'] = " ";
  }
  barangayChange=(e:any)=>{
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangaySubData: {
          ...prevState.studentFormData.barangaySubData,
          value: e.target.value,
          id: this.state.byrangayData.find((o: any) => o?.attributes?.barangay_name == e.target.value)?.id,
        }
      }
    }))
   
    this.state.studentFormData.studentErrors['barangaySubData'] = " "
  }
  barangayBirthChange=(e:any)=>{
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangayBirth: {
          ...prevState.studentFormData.barangayBirth,
          value: e.target.value,
          id: this.state.byrangayDataBirth.find((o: any) => o?.attributes?.barangay_name == e.target.value)?.id,
        }
      }
    }))
   
    this.state.studentFormData.studentErrors['barangayBirth'] = " "
  }

  handleCityBirth = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
     this.setState({byrangayDataBirth:[]})
    this.state.studentFormData.studentErrors['barangayBirth'] = " "
   
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        barangayBirth: {
          ...prevState.studentFormData.barangayBirth,
          value: "choose",
          id: ""
        }
      }
    }))
   
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentBirth: {
          ...prevState.studentFormData.studentBirth,
          value: newInputValue,
          id: this.state.getAllCities.find((o: any) => o.name == newInputValue)?.id
        }
      }
    }),() => {this.getBarangaysDataBirth(this.state.studentFormData?.studentBirth?.value)})
   
    this.state.studentFormData.studentErrors['studentBirth'] = " "
  }
  //---------------------- Religion api call request ----------------------
  religionData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReligionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointReligionDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  religionDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentReligion: {
          ...prevState.studentFormData.studentReligion,
          value: e.target.value,
          id: this.state.religionSelectedData.find((o: any) => o.religion == e.target.value)?.id
        }
      }
    }))
    this.state.studentFormData.studentErrors['studentReligion'] = " "
  }
  //---------------------- languages api call request ----------------------

  languagesData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apilanguagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointlanguagesDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  languageDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentChildsFirstLanguage: {
          ...prevState.studentFormData.studentChildsFirstLanguage,
          value: e.target.value,
          id: this.state.languagesSelectedData.find((o: any) => o.language == e.target.value)?.id
        }
      }
    }))
    this.state.studentFormData.studentErrors['studentChildsFirstLanguage'] = " "
  }

  //---------------------- scholarship api call request ----------------------

  scholarshipData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scholarshipCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointScholarshipDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  scholarshipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentTypesOfScholarShip: {
          ...prevState.studentFormData.studentTypesOfScholarShip,
          value: e.target.value,
          id: this.state.scholarshipSelectedData.find((o: any) => o.scholarship_name == e.target.value)?.id
        }
      }
    }))
    this.state.studentFormData.studentErrors['studentTypesOfScholarShip'] = " "

    if (e.target.value === 'The Ministry Leaders Assistance') {
      this.setState(prevState => ({
        studentFormData: {
          ...prevState.studentFormData,
          programDetailsProgram: {
            ...prevState.studentFormData.programDetailsProgram,
            value: 'choose',
            id: '',
          }}
      }))
    }
  }

  //---------------------- nationalities api call request ----------------------

  nationalitiesData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNationalitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointNationalitiesDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  nationalitiesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentNationality: {
          ...prevState.studentFormData.studentNationality,
          value: e.target.value,
          id: this.state.nationalitiesSelectedData.find((o: any) => o.name == e.target.value)?.id
        }
      }
    }))
    this.state.studentFormData.studentErrors['studentNationality'] = " "
  }


  //---------------------- program deatil Hub api call request ----------------------

  HubData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHubCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.programDetailsHubsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  hubDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsHub: {
          ...prevState.studentFormData.programDetailsHub,
          value: e.target.value,
          id: e.target.value,
        },
        programDetailsCommunity: {
          ...prevState.studentFormData.programDetailsCommunity,
          value: 'choose',
          id: '',
        },
        programDetailsCycle: 'choose',
        programDetailsAccreditation: {
          ...prevState.studentFormData.programDetailsAccreditation,
          value: 'choose',
          id: '',
        },
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: 'choose',
          id: '',
        },
      },
    }))

    this.state.studentFormData.programDetailsErrors['programDetailsHub'] = " "
    this.setState({programLevelSHSData:[]})

    let newCycleArray:any=[]
    let hubObj:any= Object.entries(this.state.programLevelData?.hubs)
  
     hubObj.map((item:any)=>{
       const [keyHub]=item

       if(keyHub == e.target.value){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       newCycleArray= cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         return keyCycle
        })

          let set:any = new Set(newCycleArray);
          let newArr:any = [...set];
          this.setState({programLevelCycleData:newArr})
     }
 })

  }

  cycleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
     this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsCycle: e.target.value,
        programDetailsAccreditation: {
          ...prevState.studentFormData.programDetailsAccreditation,
          value: 'choose',
          id: '',
        },
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: 'choose',
          id: '',
        },
      },
    }))

    
    this.state.studentFormData.programDetailsErrors['programDetailsCycle'] = " "
    this.setState({programLevelSHSData:[]})

    let newCycleArray:any=[]
    let newAccArray:any=[]
    let accreditationObj:any={}
    let hubObj:any= Object.entries(this.state.programLevelData?.hubs)
       
    hubObj.map((item:any)=>{
       const [keyHub]=item

       if(keyHub == this.state.studentFormData.programDetailsHub.value){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         if(keyCycle == e.target.value){
          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          newAccArray= accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc
               return keyAcc
            })

          let set:any = new Set(newAccArray);
          let newArr:any = [...set];
          this.setState({programLevelAccData:newArr})
          }
      })
     }
    })
     console.log('PROGRAM lllll ACCCCCC array',this.state.programLevelAccData)
  }


  //---------------------- program deatil Grades api call request ----------------------

  GradesData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramDetailGradesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.programDetailsgradesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  gradesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: e.target.value,
          id: e.target.value,
         },
        programDetailsShsTrack:'choose',
      }
    }))
    this.state.studentFormData.programDetailsErrors['programDetailsIncomingGradeLevel'] = " "
    this.setState({programLevelSHSData:[]})

    let newAccArray:any=[]
    let accreditationObj:any={}
    let gradeObj:any={}
    let newSHSArray:any=[]
    let hubObj:any= Object.entries(this.state.programLevelData?.hubs)
       
    hubObj.map((item:any)=>{
       const [keyHub]=item
       if(keyHub == this.state.studentFormData.programDetailsHub.value){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         if(keyCycle == this.state.studentFormData.programDetailsCycle){
          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc

            if(keyAcc == this.state.studentFormData.programDetailsAccreditation.value){
               gradeObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation[`${keyAcc}`].shs_track)
                             
              gradeObj.map((itemGrades:any)=>{
                 const [keyGrades,valueGrades]=itemGrades
                  if(e.target.value == 'Grade 11' || e.target.value == 'Grade 12' ){
                    newSHSArray= valueGrades
                    console.log(keyGrades)
                 let set:any = new Set(newSHSArray);
               let newArr:any = [...set];
                
               this.setState({programLevelSHSData:newArr})
 
                }  
              })
            }  
            })
          }
      })
     }
    })
  }

  //---------------------- program deatil program api call request ----------------------

  programData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramDetailProgramCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.programDetailsprogramsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  programDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsProgram: {
          ...prevState.studentFormData.programDetailsProgram,
          value: e.target.value,
          id: e.target.value,
         },
        programDetailsHub: {
          ...prevState.studentFormData.programDetailsHub,
          value: 'choose',
          id: '',
        },
        programDetailsCommunity: {
          ...prevState.studentFormData.programDetailsCommunity,
          value: 'choose',
          id: '',
        },
        programDetailsCycle: 'choose',
        programDetailsAccreditation: {
          ...prevState.studentFormData.programDetailsAccreditation,
          value: 'choose',
          id: '',
        },
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: 'choose',
          id: '',
        },
      },
      programLevelData:{},
      programLevelHubData:[],
      programLevelCycleData:[],
      programLevelAccData:[],
      programLevelGradesData:[],
      programLevelSHSData:[],

    }),()=>{
      this.programLevelsData();
    })

    this.state.studentFormData.programDetailsErrors['programDetailsProgram'] = " "
    this.state.studentFormData.programDetailsErrors['programDetailsHub'] = " "
    this.state.studentFormData.programDetailsErrors['programDetailsCommunity'] = " "
    this.state.studentFormData.programDetailsErrors['programDetailsCycle'] = " "
    this.state.studentFormData.programDetailsErrors['programDetailsAccreditation'] = " "
    this.state.studentFormData.programDetailsErrors['programDetailsIncomingGradeLevel'] = " "
  }

  //---------------------- program deatil Communities api call request ----------------------

  communitiesData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramDetailCommunitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.programDetailscommunitiesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  communitiesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsCommunity: {
          ...prevState.studentFormData.programDetailsCommunity,
          value: e.target.value,
          id: this.state.programDetailcommunitiesSelectedData.find((o: any) => o.community_name == e.target.value)?.id
        },
        programDetailsCycle: 'choose',
        programDetailsAccreditation: {
          ...prevState.studentFormData.programDetailsAccreditation,
          value: 'choose',
          id: '',
        },
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: 'choose',
          id: '',
        },
      }
    }))
    this.state.studentFormData.programDetailsErrors['programDetailsCommunity'] = " "
    this.setState({programLevelSHSData:[]})
  }
  //---------------------- program deatil  accreditations api call request ----------------------

  accreditationsData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramDetailAccreditationsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.programDetailsAccreditationsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  accreditationsDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        programDetailsAccreditation: {
          ...prevState.studentFormData.programDetailsAccreditation,
          value: e.target.value,
          id: e.target.value,
          
        },
        programDetailsIncomingGradeLevel: {
          ...prevState.studentFormData.programDetailsIncomingGradeLevel,
          value: 'choose',
          id: '',
        },
      }
    }))
    this.state.studentFormData.programDetailsErrors['programDetailsAccreditation'] = " "
    this.setState({programLevelSHSData:[]})

    let accreditationObj:any={}
    let gradeObj:any={}
    let hubObj:any= Object.entries(this.state.programLevelData?.hubs)
       
    hubObj.map((item:any)=>{
       const [keyHub]=item
       if(keyHub == this.state.studentFormData.programDetailsHub.value){
       let cycleObj:any= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles)

       cycleObj.map((itemCycle:any)=>{
         const [keyCycle]=itemCycle
         if(keyCycle == this.state.studentFormData.programDetailsCycle){
          accreditationObj= Object.entries(this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation)
          accreditationObj.map((itemAcc:any)=>{
            const [keyAcc]=itemAcc
            if(keyAcc == e.target.value){
              gradeObj= this.state.programLevelData?.hubs[`${keyHub}`].cycles[`${keyCycle}`].accreditation[`${keyAcc}`].grades
               
              let newGradesArray:any=gradeObj.map((itemGrades:any)=>{
                
               return itemGrades
                })
                
                let set:any = new Set(newGradesArray);
                let newArr:any = [...set];
                this.setState({programLevelGradesData:newArr})
                console.log('PROGRAM lllll Grades',newArr)  
                console.log('PROGRAM lllll GradesOBJ',gradeObj) 
            }  
            })
          }
      })
     }
    })
  }

  //---------------------- parents Info Father Country api call request ----------------------

  fathercountriesData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiParentsInfoFathercountriesDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.parentsInfoFathercountriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentEnrollAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  fathercountriesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({
      studentFormData: { ...this.state.studentFormData, parentsInfoFathersCountryCode: e.target.value }
    })
    this.state.studentFormData.parentsValidationErrors['parentsInfoFathersCountryCode'] = " "

  }
  mothercountriesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, parentsInfoMothersCountryCode: e.target.value }
    })
    this.state.studentFormData.parentsValidationErrors['parentsInfoMothersCountryCode'] = " "

  }
  GuardianscountriesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      studentFormData: { ...this.state.studentFormData, parentsInfoGuardiansCountryCode: e.target.value }
    })
    this.state.studentFormData.parentsValidationErrors['parentsInfoGuardiansCountryCode'] = " "
  }
  MaritalStatusChange = (e: any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        parentsInfoMaritalStatus: e.target.value
      }
    }))
    this.state.studentFormData.parentsValidationErrors['parentsInfoMaritalStatus'] = " "
  }

  //---------------------- Grade api call request ----------------------
  getGradeData = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GradeAPiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GradeAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //---------------------- Curriculum api call request ----------------------
  getCurriculumData = () => {

    const header = {
      "Content-Type": configJSON.curriculumApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCurriculumCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumApiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.curriculumApiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  //---------------------- reason for regiter call request start ----------------------

  reasonForRegisterData = () => {

    const header = {
      "Content-Type": configJSON.reasonForRegisterAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReasonForRegisterCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reasonForRegisterAPiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reasonForRegisterAPiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  FinallyRegisteringChange = (e: any) => {
    this.setState(prevState => ({
      studentFormData: {
        ...prevState.studentFormData,
        reviewReasonForFinallyRegistering: {
          ...prevState.studentFormData.reviewReasonForFinallyRegistering,
          value: e.target.value,
          id: this.state.reasonForRegisterSelectedData.find((o: any) => o.reason == e.target.value)?.id
        }
      }
    }))
    this.state.studentFormData.reviewValidationErrors['reviewReasonForFinallyRegistering'] = " "
  }
 
  //---------------------- reason for regiter call request ends----------------------
    //---------------------- PROGRAM LEVELS call request start ----------------------

    programLevelsData = () => {
      const header = {
        "Content-Type": configJSON.programLevelsAPiContentType,
        "token": localStorage.getItem("authToken")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  

      this.apiProgramLevelsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `fetch_program_levels?program=${this.state.studentFormData?.programDetailsProgram?.value}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.programLevelsAPiGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }
  
    //---------------------- PROGRAM LEVELS call request ends----------------------
    //---------------------- PROGRAM LEVELS Dynamic call request start ----------------------

    programLevelsDataDynamic = (programname:any) => {
      const header = {
        "Content-Type": configJSON.programLevelsAPiContentType,
        "token": localStorage.getItem("authToken")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  

      this.apiProgramLevelsDynamicCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `fetch_program_levels?program=${programname}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.programLevelsAPiGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }
  
    //---------------------- PROGRAM LEVELS Dynamic call request ends----------------------

  dateFunction = () => {
    let today: any = new Date();
    let yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }
 
  getAllCountriesData = () => {
    Country.getAllCountries().map((item: any) => {
      this.state.getAllCountries.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode: item?.isoCode
      })

    })
    let dataArr = this.state.getAllCountries?.map((item: any) => {
      return [JSON.stringify(item), item]
    });
    let maparr = new Map(dataArr);

    this.setState({ uniqueCountryCodeData: [...maparr.values()] });
  }
  getAllCountriesDataOne = () => {
    Country.getAllCountries().map((item: any) => {
      this.state.getAllCountriesOne.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode: item?.isoCode
      })

    })
    this.state.getAllCountries?.map((item: any) => {
        if(item?.phonecode !== '' && !item?.phonecode.includes('+') && !item?.phonecode.includes('0055') ){  
        this.state.uniqueCountryCodeData.push({
          phonecode:item?.phonecode
        })
      }
     })


    this.state.uniqueCountryCodeData.unshift({...this.state.uniqueCountryCodeData.phonecode, phonecode:'0'})
   
    this.state.uniqueCountryCodeData.sort(function(a:any, b:any){return a.phonecode - b.phonecode}); 
     
    let dataArr = this.state.uniqueCountryCodeData?.map((item: any) => {
      return [JSON.stringify(item), item]
    });
    let maparr = new Map(dataArr);

    this.setState({ uniqueCountryCodeDataOne: [...maparr.values()] });

  }

  getCityDynamic= async( countryResi:string,countryBirth:string, cityResi:string, cityBirth:string,birthDate:Date)=>{
  
      let data: any = birthDate
      let dob = new Date(data)
      let diff_ms = Date.now() - dob.getTime()
      let age_dt = new Date(diff_ms);
      let calc: any = Math.abs(age_dt.getUTCFullYear() - 1970)
      this.setState({
        studentFormData: {
          ...this.state.studentFormData, studentAge: calc, 
        }
      })

//=====================================================================================
      this.state.getAllCountries.map((item:any)=>{
      if(item.name==countryBirth){
        this.setState((prevState:any) => ({
          studentFormData: {
            ...prevState.studentFormData,
            studentCountryAutoCompleteValue: {
            value: item,
            }}
        }))

        let data: any = [];
        let dataMsg: any = [{ name: 'No City found' }]
        const key = 'name';
        if(item.name == 'Philippines'){
          this.state.provincesData?.map((city:any)=>{
              data?.push({
              name: city,
              })
              })
          }
          else{
          City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
          data?.push({
            name: city?.name,
          })
        })
      }
        const arrayUniqueByKey = [...new Map(data.map((item:any) =>
          [item[key], item])).values()];
        if (data.length == 0) {
          this.setState({ getAllCities: dataMsg })
        }
        else {this.setState({ getAllCities: arrayUniqueByKey })}
        }
      })

      this.state.getAllCities.map((item:any)=>{
      if(item.name==cityBirth){
      this.setState((prevState:any) => ({
      studentFormData: {
        ...prevState.studentFormData,
        studentBirthAutoCompleteValue: {
        value: item,
        }}
      }))
      }})

      this.getBarangaysDataBirth(cityBirth)
 //=============================================================================
      this.state.getAllCountriesOne.map((item:any)=>{
        if(item.name==countryResi){
          this.setState((prevState:any) => ({
            studentFormData: {
              ...prevState.studentFormData,
              countryResiAutoCompleteValue: {
              value: item,
              }}
        }))
      
          let data: any = [];
          let dataMsg: any = [{ name: 'No City found' }]
          const key = 'name';
          if(item.name == 'Philippines'){
            this.state.provincesData?.map((city:any)=>{
                data?.push({
                name: city,
                })
              })
          }
          else{
          City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
            data?.push({
              name: city?.name,
            })
          })
        }
          const arrayUniqueByKey = [...new Map(data.map((item:any) =>
            [item[key], item])).values()];
          if (data.length == 0) {
            this.setState({ getAllCitiesOne: dataMsg })
          }
          else {this.setState({ getAllCitiesOne: arrayUniqueByKey }) }
        }
      })
        
      this.state.getAllCitiesOne.map((item:any)=>{
      if(item.name==cityResi){
      this.setState((prevState:any) => ({
        studentFormData: {
          ...prevState.studentFormData,
          cityResiAutoCompleteValue: {
          value: item,
          }}
      }))
      }})
      
      this.getBarangaysData(cityResi)
  }
    
  getSwicthProgramDetails=()=>{
   
    if(this.context.CheckoutSwitchProgram.programStatus){
      this.setState({activeStep:this.context.CheckoutSwitchProgram.activeFormState})
     }
  }


getFormFiveValidationForMandatoryFiles=()=>{
  let isFormValid = true;
  
  return isFormValid
}

   // Customizable Area End
}
