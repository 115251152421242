import React, { Component } from "react";

import {
    // Customizable Area Start
  Box,
  InputLabel,
  Typography,
  TextField,
  Grid,
  MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);

export default class CustomEnroll2 extends Component<any> {



  render() {
    const {
      studentFormData,
      handleChangePersonal,
      lastGradeChangeHandler,
      gradeSelectedData,
      curriculumChangeHandler,
      curriculumSelectedData,
      hideError,
    } = this.props;

    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>


        <Grid item xs={12}>
          <Typography style={webStyle.gridTextStyle}>Previous School information</Typography>
        </Grid>

     
        <Grid container spacing={4} xs={12} style={{padding:'2em'}}>

          {/* ------------------------ Left Grid --------------------------     */}
      
          <Grid item xs={12} md={6}>
            <Box style={webStyle.inputBoxStyle}>
              <InputLabel style={webStyle.inputSelectLabelStyle}>Last Grade Level * </InputLabel>
              <StyledTextField
                select
                required
                InputLabelProps={{ shrink: false }}
                fullWidth={true}

                name='previousSchoolLastGradeLevel'
                value={studentFormData.previousSchoolLastGradeLevel.value}
                onChange={(e: any) => lastGradeChangeHandler(e)}
              >
                <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgrey" }}>Please select</span></MenuItem>
                {
                  gradeSelectedData?.map((item: any, index: number) => (
                    <MenuItem
                      key={item?.id}
                      value={item?.grade}
                      selected={item.value == studentFormData.previousSchoolLastGradeLevel.value}
                    >
                      {item?.grade}
                    </MenuItem>
                  ))
                }
              </StyledTextField>
            </Box>
            <Box>
              {
                (studentFormData.previousSchoolErrors.previousSchoolLastGradeLevel)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolLastGradeLevel}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box style={webStyle.inputBoxStyle}>
              <InputLabel style={webStyle.inputSelectLabelStyle}>Previous School Curriculum (If not applicable, select "N/A")* </InputLabel>
              <StyledTextField
                select
                required
                InputLabelProps={{ shrink: false }}
                fullWidth={true}
                name='previousSchoolCurriculum'
                value={studentFormData.previousSchoolCurriculum.value}
                onChange={(e: any) => curriculumChangeHandler(e)}
              >
                <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgrey" }}>Please Select</span></MenuItem>
                {
                  curriculumSelectedData?.map((item: any, index: number) => (
                    <MenuItem
                      key={item?.id}
                      value={item?.curriculum}
                      selected={item.value == studentFormData.previousSchoolCurriculum.value}
                    >
                      {item?.curriculum}
                    </MenuItem>
                  ))
                }
              </StyledTextField>
            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolCurriculum)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolCurriculum}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          {studentFormData.previousSchoolCurriculum.value == "Other" ?
            <Grid item xs={12} md={6}>
              <Box style={webStyle.inputBoxStyle}>
              <InputLabel style={webStyle.inputSelectLabelStyle}>Enter Curriculum *</InputLabel>
              <StyledTextField
                placeholder="Curriculum"
                fullWidth
                name='previousSchoolCurriculumOthers'
                value={studentFormData.previousSchoolCurriculumOthers}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              </Box>
              <Box >
                {
                  (studentFormData.previousSchoolErrors.previousSchoolCurriculumOthers)
                    ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolCurriculumOthers}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null
          }


          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Previous School Contact Number (if not applicable, input "N/A") * </InputLabel>
              <StyledTextField
              inputProps={{maxLength: 11}}
                placeholder="0123456789"
                fullWidth={true}
                required
                name='previousSchoolContact'
                value={studentFormData.previousSchoolContact}
               onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolContact)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolContact}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>


          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Last School Year (If not applicable, input "N/A")*</InputLabel>
              <StyledTextField
                placeholder="0"
                required
                fullWidth={true}
                name='previousSchoolLastSchoolYear'
                value={studentFormData.previousSchoolLastSchoolYear}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolLastSchoolYear)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolLastSchoolYear}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>

          </Grid>

          {/* ------------------------ Right Grid --------------------------              */}
          {/* <Grid item xs={12} sm={6} style={webStyle.gridItemsRightStyle}> */}
          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Previous School Attended (if not applicable, input "N/A") * </InputLabel>
              <StyledTextField
                placeholder="Previous School Attended"

                fullWidth={true}
                required
                name='previousSchoolAttended'
                type="text"
                value={studentFormData.previousSchoolAttended}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolAttended)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolAttended}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>


          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Previous School Email Address (if not applicable, input "N/A") * </InputLabel>
              <StyledTextField
                placeholder="Email Address"
                fullWidth={true}
                required
                name='previousSchoolEmail'
                value={studentFormData.previousSchoolEmail}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolEmail)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolEmail}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>


          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Previous School Address (if not applicable, input "N/A") * </InputLabel>
              <StyledTextField
                placeholder="Address"
                fullWidth={true}
                required
                name='previousSchoolAddress'
                value={studentFormData.previousSchoolAddress}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolAddress)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolAddress}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>


          <Grid item xs={12} md={6}>
            <Box style={{ marginTop: '30px' }}>
              <InputLabel style={webStyle.inputLabelStyle}>Learner Reference Number (If not applicable, input "0") * </InputLabel>
              <StyledTextField
                placeholder="0"
                fullWidth={true}
                required
                name='previousSchoolLearnerReferenceNumber'
                value={studentFormData.previousSchoolLearnerReferenceNumber}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />

            </Box>
            <Box>

              {
                (studentFormData.previousSchoolErrors.previousSchoolLearnerReferenceNumber)
                  ? <small style={webStyle.errorStyle}>{studentFormData.previousSchoolErrors.previousSchoolLearnerReferenceNumber}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>

          </Grid>
          </Grid>





      </ThemeProvider>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {

  FormAlignment: { marginTop: '3px' },
  gridItemsRightStyle: {
    //backgroundColor: 'yellow',
    //marginTop: '30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginLeft: '20px',
    marginBottom: '30px',

  },


  gridTextStyle: {
    // marginTop: '30px',
    // color: '#171725',
    // fontFamily: 'Poppins',
    // fontSize: '20px',
    // fontWeight: 600,
    // lineHeight: '30px',
    // lineSpacing: '0.08333328px',
    left: "53px",
    top: "57px",
    // width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    // marginBottom: "1em",
    paddingLeft:'1.4em',
  },
  inputBoxStyle: {
    marginTop: '30px',
  },

  inputLabelStyle: {
     marginBottom: '5px',
    // width: '220px',
    //height: '22px',
    // width:'400px',
    color: '#757575',
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: 400,
    //lineHeight: '22px',
  },

  inputSelectLabelStyle: {
    //width: '350px',
   // height: '22px',
    // width:'400px',
    color: '#757575',
    fontFamily: 'Roboto',
    fontSize: '11px',
    // fontSize: '1vw',
    fontWeight: 400,
    //lineHeight: '22px',
    marginBottom: '5px',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  }

};



// Customizable Area End
