import React, { useState, useCallback, useMemo } from "react";

import {
    // Customizable Area Start
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const TicketUploadComponent = ({ uploadFiles, purpose, acceptType,ticketFile}: any) => {

  const [fileRecommendation, setFileRecommendation] = useState({
    name: '',
    size: '',
    type: '',
  })
  const [isError, setIsError] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsError(false)
      let sizeInMB = (Number(acceptedFiles[0]?.size) / (1024 * 1024)).toFixed(2);
      if (Number(sizeInMB) <= 5) {
        setFileRecommendation({ ...fileRecommendation, name: acceptedFiles[0].name, size: sizeInMB + 'MB', type: acceptedFiles[0].type })
        console.log("filesss accepteddd",acceptedFiles[0])
        uploadFiles(acceptedFiles[0], purpose)
      }
      else {
        setIsError(true)
      }
    },

    [],
  )

  const { getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isFocused,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptType
  })

  const style = useMemo(() => ({
    ...webStyle.fileStyle,
    ...(isFocused ? webStyle.focusedStyle : {})
  }), [

    isFocused
  ]);

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
        <Grid item xs={12} >



          <Box {...getRootProps({ style })}  >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon style={{ color: '#b1b1b1' }} />
            {!isDragActive && (<>
            <Typography style={webStyle.fileBoxTextStyle}>Drag and drop a file here or click</Typography>
            <Typography style={webStyle.fileBoxBlueTextStyle}>Browse Here</Typography>
            </>
            )}

            {isDragAccept && (<Typography style={webStyle.fileBoxAcceptedTextStyle}>This file will be accepted</Typography>)}

          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>

              {
                purpose === 'ticketFile' && <Typography variant='subtitle2'> {ticketFile?.filenm?.name}</Typography>
              }

            </Box>
            <Box>

             
              {isError && purpose !== 'picture' && (<Typography style={webStyle.errorStyle}>Error: Only .pdf/.docx/.jgeg/.png files supported. File should not be more than 5MB.</Typography>)}
              {isError && purpose === 'picture' && (<Typography style={webStyle.errorStyle}>Error: Only jpeg file is supported. File should not be more than 5MB.</Typography>)}

            </Box>
          </Box>

        </Grid>
      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  fileTextStyle: {
    marginBottom: '10px',
    color: '#555555',
    fontFamily: 'Roboto;',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },

  fileStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    cursor: 'pointer',
    border: '2px dashed #eeeeee',
  },

  focusedStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: '#f2f2f2',
    width: '100%',
    cursor: 'pointer',
    border: '2px dashed #eeeeee',
  },

  fileBoxTextStyle: {
    color: '#b1b1b1',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  fileBoxBlueTextStyle: {
    color: '#009aff',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '22px',
    textDecoration: 'underline',
  },
  errorStyle: {
    color: 'red',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },

  fileBoxAcceptedTextStyle: {
    color: '#00e676',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  
};

export default TicketUploadComponent;
// Customizable Area End
