import React, { useState} from "react";

import {
   Box,
   Typography,
   // Customizable Area Start
   // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import {  makeStyles } from '@material-ui/core/styles';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },


});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",

    // borderRadius: "20px !important",


  },
  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
   


  },
  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',

    },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },

});


const OrientationVideoPlayer = ({ introVideoURL}: any) => {

  const classes = useStyles();
  const [playing, setPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false);


  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
      setIsReady(true);
    }
  }, [isReady]);
  return (

    // Customizable Area Start
    <>

      <ThemeProvider theme={theme}>

        <Box style={{ display: 'flex', alignItems: 'center' }} >

          <div className={classes.playerWrapper}>
            <ReactPlayer
            config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate', disablepictureinpicture: 'true' } } }}
            // Disable right click
            onContextMenu={(e: any) => {
              e.preventDefault()
            }}
              width="100%"
              height="90%"
              url={introVideoURL}
              muted={true}
              playing={playing}
              controls={true}
              onReady={onReady}
              style={{ borderRadius: '20px', overflow: 'hidden'}}
              pip={false}

            />
            {isReady ?
              <div className={classes.controlWrapper}>


                <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  {
                    playing ? <PauseIcon onClick={handlePlayPause} style={{ marginTop: '9vw', cursor: 'pointer', fontSize: '4.5vw', color: 'rgba(0,0,0,0.5)'  }} />
                      : <PlayCircleFilledIcon onClick={handlePlayPause} style={{ marginTop: '10vw', cursor: 'pointer', fontSize: '6vw', color: 'rgba(0,0,0,0.5)' }} />
                  } 
                  
                  </Box>

              </div>
              : null
            }
           
            <Box style={webStyle.boxWhite}>
              <Typography style={webStyle.whiteBoxTextStyle} >Welcome to Homeschool Orientation</Typography>
              <Typography style={webStyle.whiteBoxSubTextStyle} >Pursue what matters through flexible and personalized education. Over 18,000 students across Nursery to Grade 12 programs..</Typography>
            </Box>
           
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  
  boxWhite: {
    display: 'flex',
    boxSizing: 'border-box' as 'border-box',
    alignItems: 'flex-start',
     flexDirection: 'column' as 'column',
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding:'1vw',
      
  },
  whiteBoxTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '1.2vw',
    fontWeight: 600,
    marginBottom:'0.5vw',
  },
  whiteBoxSubTextStyle: {
    marginTop: '0.5vw',
    color: '#7d7d7e;',
    fontFamily: 'Roboto',
    fontSize: '1vw',
    fontWeight: 400,
  },

};

export default OrientationVideoPlayer;
// Customizable Area End
