import React, { useState} from "react";

import {
    // Customizable Area Start
  Box,
  Typography,
  IconButton,
   Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },
    MuiCardContent:{
      root:{
        paddingTop:0,
        paddingBottom:0,
        paddingLeft:'16px',
        paddingRight:'16px',
      },
    },
    MuiIconButton:{
      root:{
        padding:'12px',
      },
    },
  },
});

const PurpleCheckbox = withStyles({
  root: {
      color: '#9267ac',
    '&$checked': {
      color: '#9267ac',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CheckoutCheckbox = ({checkbox_img,handlChangeCheckBox,RecommSubEnglishGrammar }: any) => {
  const [expanded, setExpanded] = useState(false)
  
  const handleExpandClick = () => {
    setExpanded(!expanded)
  };

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

            <Box style={{ display: 'flex', alignItems: 'center' ,justifyContent: 'space-between' }}>
                <FormControlLabel
                            control={
                            <PurpleCheckbox
                            checked={RecommSubEnglishGrammar}
                            onChange={handlChangeCheckBox}
                            name="RecommSubEnglishGrammar"
                />
                  }
                  label={<span style={webStyle.checkboxSubLabelStyle}>Targeting Mathematics 1A (TB + WB + TM)</span>}
                />
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  {expanded? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </IconButton>
            </Box>

            <Typography style={webStyle.cardAmtStyle}>  ₱ 1,239.00 </Typography>
          </Box>

          <Grid container >
          <Grid item xs={8}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

              <Card elevation={1} style={webStyle.cardStyle}>
                <Box>
                  <CardMedia
                    component="img"
                    alt="card img"
                    image={checkbox_img}
                    title="card image"
                  />
                </Box>
                <Box>
                  <Typography style={{marginLeft: '0.5em',...webStyle.cardAmtStyle}}>  BJU Phonics and English (3rd Edition) Phonics
                    Practice Book (Optional) </Typography>
                  <Typography style={{marginLeft: '0.5em',...webStyle.cardAmtStyle}}> Edition - 2nd Edition </Typography>
                </Box>
              </Card>
            </Collapse>

          </Grid>
        </Grid>
    </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {
  cardAmtStyle: {
     color: ' #5e5e5e',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },

  cardStyle: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    borderRadius: '15px',
    border:'1px solid #e2e2ea',
  },
  checkboxSubLabelStyle: {
     color: '#171725',
     fontFamily: 'Roboto',
     fontSize: '12px',
     fontWeight: 300,
     lineHeight: '14px',
   },

};

export default CheckoutCheckbox;
// Customizable Area End
