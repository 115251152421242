

import React from "react";

import {
   // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
   Grid,
     // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Drawer from "../../../components/src/Drawer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
  // Customizable Area End
export default class HomeschoolProgram extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>

                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}
                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingLeft: '0', paddingRight: '0', marginBottom: '6em' }}>
                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Homeschool Program</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.SubQuestionList}>1. {this.programDetailsQuestions?.questions?.firstQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="childGradeLevel" value={this.state.childGradeLevel}
                                                        onFocus={this.hideError}
                                                        onChange={this.firsthandleChnage} >
                                                        {this.programDetailsQuestions?.answerOptions?.firstQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.childGradeLevel)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.childGradeLevel}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.secondQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>2. In our homeschool journey, I'd like to have....</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="supportLevel" value={this.state.supportLevel}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ supportLevel: event.target.value })}
                                                    >
                                                        {this.state.childGradeLevel == "Pre-Kinder (4 years old)" || this.state.childGradeLevel == "Grades 11-12" ? <>
                                                            <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option1}</span>} />
                                                            <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option2}</span>} />
                                                        </>
                                                            :
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option2}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option3}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.secondQuestionOptions.option4}</span>} />
                                                            </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.supportLevel)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.supportLevel}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.thirdQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>3. In terms of parental involvement, I can commit ...</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="parentalInvolvement" value={this.state.parentalInvolvement}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ parentalInvolvement: event.target.value })} >
                                                        {this.state.childGradeLevel == "Pre-Kinder (4 years old)" || this.state.childGradeLevel == "Grades 11-12" ?
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option2}</span>} />
                                                            </> :
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option2}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.thirdQuestionOptions.option3}</span>} />
                                                            </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.parentalInvolvement)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.parentalInvolvement}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.fourthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>4. In terms of parental involvement, I can commit ...</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="HomeschoolCurriculum" value={this.state.HomeschoolCurriculum}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ HomeschoolCurriculum: event.target.value })} >
                                                        {this.programDetailsQuestions?.answerOptions?.fourthQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.HomeschoolCurriculum)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.HomeschoolCurriculum}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.fifthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>5. I prefer my child to learn with.....</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="primaryMedium" value={this.state.primaryMedium}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ primaryMedium: event.target.value })} >
                                                        {this.state.childGradeLevel == "Pre-Kinder (4 years old)" ?
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option2}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option3}</span>} />
                                                            </> :
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option2}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option3}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.fifthQuestionOptions.option4}</span>} />
                                                            </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.primaryMedium)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.primaryMedium}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.sixthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>6. As a parent, I would like to meet with an advisor  </Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="parentAdvisor" value={this.state.parentAdvisor}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ parentAdvisor: event.target.value })} >
                                                        {this.state.childGradeLevel == "Grades 11-12" ?
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3}</span>} />

                                                            </> : this.state.childGradeLevel == "Pre-Kinder (4 years old)" ?
                                                                <>
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option11} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option11}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4}</span>} />
                                                                </> :
                                                                <>

                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option1}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option2}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option3}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.sixthQuestionOptions.option4}</span>} />
                                                                </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.parentAdvisor)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.parentAdvisor}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.questions?.seventhQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>7. As a parent, I would like to know the Progress of my child on a _______ basis </Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="learningProgress" value={this.state.learningProgress}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ learningProgress: event.target.value })} >
                                                        {this.state.childGradeLevel == "Pre-Kinder (4 years old)" ?
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3}</span>} />
                                                            </>
                                                            : this.state.childGradeLevel == "Grades 11-12" ?
                                                                <>
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option5} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option5}</span>} />
                                                                </>
                                                                :
                                                                //  this.state.childGradeLevel == "Kinder (5 years old)" || this.state.childGradeLevel == "Grades 1-2" || this.state.childGradeLevel == "Grades 3-6" ?
                                                                <>
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option1}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option2}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option3}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option4}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option5} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.seventhQuestionOptions.option5}</span>} />
                                                                </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.learningProgress)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.learningProgress}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.eighthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>8. I would like to my child to be assessed through...</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="assessment" value={this.state.assessment}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ assessment: event.target.value })} >
                                                        {this.state.childGradeLevel == "Pre-Kinder (4 years old)" ?
                                                            <>
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option2}</span>} />
                                                                <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3}</span>} />

                                                            </> : this.state.childGradeLevel == "Grades 7-10" ?
                                                                <>
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3Sub} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3Sub}</span>} />
                                                                    <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4}</span>} />
                                                                </> : this.state.childGradeLevel == "Grades 11-12" ?
                                                                    <>
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1}</span>} />
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4}</span>} />
                                                                    </> :
                                                                    <>
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option1}</span>} />
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option2} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option2}</span>} />
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3Sub} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option3Sub}</span>} />
                                                                        <FormControlLabel value={this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{this.programDetailsQuestions?.answerOptions?.eighthQuestionOptions.option4}</span>} />
                                                                    </>
                                                        }
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.assessment)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.assessment}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.SubQuestionList}>9. {this.programDetailsQuestions?.questions?.ninthQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="howManyChildrenAreYouPlanningToEnroll" value={this.state.howManyChildrenAreYouPlanningToEnroll}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ howManyChildrenAreYouPlanningToEnroll: event.target.value })} >
                                                        {this.programDetailsQuestions?.answerOptions?.ninthQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.howManyChildrenAreYouPlanningToEnroll)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.howManyChildrenAreYouPlanningToEnroll}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.QuestionList}> {this.programDetailsQuestions?.questions?.tenthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>10. {this.programDetailsQuestions?.questions?.tenthSubQuestion}
                                                </Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="tlcFilter" value={this.state.tlcFilter}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ tlcFilter: event.target.value })} >
                                                        {this.programDetailsQuestions?.answerOptions?.tenthQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.tlcFilter)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.tlcFilter}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.SubQuestionList}>11. {this.programDetailsQuestions?.questions?.eleventhQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="extendedSupportForMyChild" value={this.state.extendedSupportForMyChild}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ extendedSupportForMyChild: event.target.value })} >
                                                        {this.programDetailsQuestions?.answerOptions?.eleventhQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.extendedSupportForMyChild)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.extendedSupportForMyChild}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            {this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" ?
                                                <>
                                                    <Box style={webStyle.Allignment}>
                                                        <Typography style={webStyle.SubQuestionList}>12. {this.programDetailsQuestions?.questions?.thirteenthQuestion}</Typography>
                                                        <Box style={webStyle.BoxStyle}>
                                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="yearsHomeSchooling" value={this.state.yearsHomeSchooling}
                                                                onFocus={this.hideError}
                                                                onChange={(event: any) => this.setState({ yearsHomeSchooling: event.target.value })} >
                                                                {this.programDetailsQuestions?.answerOptions?.thirteenthQuestionOptions.map((item: any) => {
                                                                    return (
                                                                        <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                                    )
                                                                })}
                                                            </RadioGroup>
                                                        </Box>
                                                        <Box>
                                                            {
                                                                (this.state.errors.yearsHomeSchooling)
                                                                    ? <small style={webStyle.errorStyle}>{this.state.errors.yearsHomeSchooling}</small>
                                                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </> : null
                                            }
                                            {this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" && this.state.childGradeLevel != "Grades 11-12" ?
                                                <>
                                                    <Box style={webStyle.Allignment}>
                                                        <Typography style={webStyle.SubQuestionList}>13. {this.programDetailsQuestions?.questions?.fourteenthQuestion}</Typography>
                                                        <Box style={webStyle.BoxStyle}>
                                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="liveOnlineClasses" value={this.state.liveOnlineClasses}
                                                                onFocus={this.hideError}
                                                                onChange={(event: any) => this.setState({ liveOnlineClasses: event.target.value })} >
                                                                {this.programDetailsQuestions?.answerOptions?.fourteenthQuestionOptions.map((item: any) => {
                                                                    return (
                                                                        <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                                    )
                                                                })}
                                                            </RadioGroup>
                                                        </Box>
                                                        <Box>
                                                            {
                                                                (this.state.errors.liveOnlineClasses)
                                                                    ? <small style={webStyle.errorStyle}>{this.state.errors.liveOnlineClasses}</small>
                                                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </> : null
                                            }
                                            <Box style={webStyle.Allignment}>
                                                <Typography style={webStyle.SubQuestionList}>{this.state.childGradeLevel == "Grades 11-12" ? "13" : this.state.childGradeLevel == "" || this.state.childGradeLevel == "Nursery (2-3 years old)" || this.state.childGradeLevel == "Pre-Kinder (4 years old)" ? "12" : "14"}. {this.programDetailsQuestions?.questions?.twelvethQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="doYouHaveAnotherChild" value={this.state.doYouHaveAnotherChild}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ doYouHaveAnotherChild: event.target.value })} >
                                                        {this.programDetailsQuestions?.answerOptions?.twelvethQuestionOptions.map((item: any) => {
                                                            return (
                                                                <FormControlLabel value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.doYouHaveAnotherChild)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.doYouHaveAnotherChild}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                            </Box>
                                         
                                            {/* if it is preKinder  start*/}



                                            {/* if it is preKinder  end*/}
                                            <Box style={{
                                                textAlign: "left",
                                                paddingBottom: '4em',
                                                marginTop: '2em',
                                                display: "flex",
                                                justifyContent: "end"
                                            }}>
                                          <Snackbar anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }} open={this.state.snackbarOpenProgram} autoHideDuration={2000} onClose={this.handleCloseProgram} >
                                                    <Alert severity="success">
                                                    Saved to Draft Successfully
                                                    </Alert>
                                                </Snackbar>
                                                <Box style={{ paddingRight: '1em' }}>
                                                <Button style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyleSaveToDraft }}
                                                        onClick={this.homeSchoolProgramRequestSaveAsDraftButton}>Save to Draft</Button>
                                                </Box>

                                                <Button style={{
                                                    textTransform: "none", textAlign: "center", ...webStyle.handleProgramSubmitAnswer
                                                }}
                                                    onClick={this.handleProgramSubmitAnswers}
                                                >{this.state.homeSchoolProgramSubmit ? <CircularProgress /> : "Go to Homeschool recommender"}
                                                </Button>
                                            </Box>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    homeSchoolStyleSaveToDraft: {
        height: "52px",
        width: '200px',
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#b9b9b9",
    },
   
    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    BoxStyle: {
        marginTop: '0.5em'
    },
    Allignment: {
        paddingTop: '2em', paddingLeft: '1em'
    },
    handleProgramSubmitAnswer: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#fab03d",
        width: "35%",
    },
    SubQuestionList: {
        color: "#171725",
        paddingTop: '1em',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500
    },
    QuestionList: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600
    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: 'Roboto',
        fontSize: ' 12px',
    },
    radio: {
        color: "#171725",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: "2em",
    },
};
// Customizable Area End
