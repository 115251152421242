import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;

  videoPlayedValue: any;
  introVideoURL: any;
  grade:any;
  program:any;
  accreditation:any;

  openD: any;
  openOrientation: any;
  mopen: any;
  dopen: any;
  dopenOne: any;
  drawerWidth: any;
  gradeData: any;
  programData: any;
  accreditaionData: any;
  currentVideoTime: any;
  totalVideoTime: any;
  calculateErrors: any;
  openverify: boolean;
  openverifyVideo: boolean;
  gradeApiData: any;
  programApiData: any;
  accreditationApiData: any;
  gradeSelectedData: any;
  accreditationData: any;
  programSelectedApiData: any;
  accreditationSelectedApiData: any;
  recommVideoURL: any;
  userData: any;
  testimonial: any;
  introVideoData:any;
  postIntroVideoData:any;
  isLoading:boolean;
  onDashboardPage:boolean;
  allVideos:any;
  allVideos_introVideoID:any;
  allVideos_introVideoURL:any;
  allVideos_homeSchoolerVideoURL:any;
  allVideos_homeSchoolerVideoName:any;
  allVideos_orientationVideoURL:any;
  snackbarOpen: any;
  snackbarOpenFalse:any;
  msg:any;
  allVideo_types:any;
  testVideosData:any;
  allVideosLoader:any;
  hgFormula_PursueWhatMatters:any;
  hgFormula_TheHgFormula:any;
  hgFormula_MakingTheChoice:any;
  hgFormula_ExplorePrograms:any;
  hgFormula_AboutHg:any;
  feeCalculatorLoader:boolean;
  toggleFeeCaculator:boolean;
  loaderResendLink:boolean;
  openModalProgramVideo: boolean;
  selectedProgramDetails: any;
  selectedProgramTitle:string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";

  apiVideoCallId: string = "";
  apiAllVideoCallId: string = "";
  apiGradeCallId: string = "";
  apiProgramCallId: string = "";
  apiaccreditationCallId: string = "";

  apiUserCallId:string = "";
  apiCalacCallId:string = "";
  apiPostIntroVideoCallId:string = "";
  apiGetIntroVideoTimeCallId:string = "";
  apiPostResendLinkCallId:string = "";
  apiHgFormulaCallId:string = "";
  
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,

      videoPlayedValue: 0,
      introVideoURL: '',
      grade:'choose',
      program:'choose',
      accreditation:'choose',

      openD: false,
      openOrientation: true,
      mopen: true,
      dopen: false,
      dopenOne: false,
      drawerWidth: 300,
      gradeData: [],
      programData: [],
      accreditaionData: [],
      currentVideoTime: 0,
      totalVideoTime: 0,
      calculateErrors: {},
      openverify: false,
      openverifyVideo:false,
      gradeApiData: [],
      programApiData: [],
      accreditationApiData: [],
      gradeSelectedData: [],
      accreditationData: [],
      programSelectedApiData: [],
      accreditationSelectedApiData: [],
      recommVideoURL: '',
     
     
      userData: { id: '', fullName: '', isActive: '' },
      testimonial: { one: false, two: true, three: true, four: true, five: true },
      introVideoData: { id: '', introVideoURL: ''},
      postIntroVideoData: { id: '', watchTime: 0, isCompleted:false},
      isLoading:false,
      onDashboardPage:false,
      allVideos:[],
      allVideos_introVideoID:'',
      allVideos_introVideoURL:'',
      allVideos_homeSchoolerVideoURL:'',
      allVideos_homeSchoolerVideoName:'',
      allVideos_orientationVideoURL:'',
      snackbarOpen: false,
      snackbarOpenFalse:false,
      msg:'',
      allVideo_types:['onboarding','orientation','testimonial','homeschooler'],
      testVideosData:[],
      allVideosLoader:true,
      hgFormula_PursueWhatMatters:[],
      hgFormula_TheHgFormula:[],
      hgFormula_MakingTheChoice:[],
      hgFormula_ExplorePrograms:[],
      hgFormula_AboutHg:[],
      feeCalculatorLoader:false,
      toggleFeeCaculator:false,
      loaderResendLink:false,
      openModalProgramVideo:false,
      selectedProgramDetails:{},
      selectedProgramTitle:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
     this.getAllVideos();
     this.getIntroVideoTime();
     this.getUserInfo();
     this.gradeCalculatorPage();
     this.programCalculatorPage()
     this.accreditationCalculatorPage()

    if(localStorage.getItem('hglink')=='link1'){
      location.replace("#link1")
    }
    if(localStorage.getItem('hglink')=='link2'){
      location.replace("#link2")
    }
    if(localStorage.getItem('hglink')=='link3'){
      location.replace("#link3")
    }
    if(localStorage.getItem('hglink')=='link4'){
      location.replace("#link4")
    }
     this.getHgFormulaData()
        
    
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

     // --------------------- intro ALL videos response start -----------------------
     if (this.apiAllVideoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({allVideosLoader:true})
      if (apiResponse?.data) {
        this.setState({allVideosLoader:false})
        apiResponse?.data?.map((item: any) => {
          this.state.allVideos.push({
            id:item?.id,
            videoURL:item?.attributes?.video_file?.url,
            videoName:item?.attributes?.video_name,
            videoType:item?.attributes?.video_type,
            videoDesc:item?.attributes?.description,
            priority:item?.attributes?.priority,
            thumbnail:item?.attributes?.thumbnail_image?.url,
          })
        })
     
        this.state.allVideos?.map((item:any)=>{
            if(item?.videoType==this.state.allVideo_types[0]){
              this.setState({allVideos_introVideoID:item?.id})
              this.setState({allVideos_introVideoURL:item?.videoURL})
            }
            if(item?.videoType==this.state.allVideo_types[3]){
                this.setState({allVideos_homeSchoolerVideoURL:item?.videoURL})
                this.setState({allVideos_homeSchoolerVideoName:item?.videoName})
                
            }
            if(item?.videoType==this.state.allVideo_types[1]){
                this.setState({allVideos_orientationVideoURL:item?.videoURL})
            }
          })

          let testVidoes1=this.state.allVideos?.filter((data:any)=>data.videoType == this.state.allVideo_types[2])
          let testVidoes=testVidoes1?.filter((data:any)=>data.priority !== null)
          testVidoes.sort(function(a:any, b:any){return a.priority - b.priority}); 
          this.setState({testVideosData:testVidoes})          
       }

       
       else {
        this.setState({allVideosLoader:false})
        console.log('error ALL videos', apiResponse?.error)
      }
    }
    // --------------------- intro ALL videos response end -----------------------
     // --------------------- intro vdeo Time response start -----------------------
    
     if (this.apiGetIntroVideoTimeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiResponse?.data) {
         this.setState({
            postIntroVideoData:{
            ...this.state.postIntroVideoData,
            id:apiResponse?.data.attributes?.video_id,
            watchTime:apiResponse?.data.attributes?.watch_time,
            isCompleted:apiResponse?.data.attributes?.is_completed
          }
        })
       
        }
       else {
        this.setState({ isLoading: false })
        console.log('error video Time response', apiResponse?.message)
      }
    }
    // --------------------- intro vdeo Time response end -----------------------
     // --------------------- POST intro vdeo response start -----------------------
   
      if (this.apiPostIntroVideoCallId == message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
       if (apiResponse?.data) {
        localStorage.setItem('curentTime',String(apiResponse?.data.attributes?.watch_time))
        localStorage.setItem('complete',String(apiResponse?.data.attributes?.is_completed))
        if(apiResponse?.data.attributes?.is_completed){
          this.props.navigation.navigate('DashBoardDayInLifeVideo')
        }
        else{
          this.props.navigation.navigate('DashBoardNonExpandablePage')
        }
       
      }
       else {
        console.log('error Post Video', apiResponse?.error)
      }
    }
    
    // ---------------------POST intro vdeo response end -----------------------

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false
          });
        }
      }
    }
  
    // ---------------------  resend link response start -----------------------


    if (this.apiPostResendLinkCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {
        this.setState({ loaderResendLink: false})
        this.setState({ snackbarOpen: true, msg:'Activation link sent' },()=>{
        });
                
      }

      else {
        this.setState({ loaderResendLink: false })
         console.log('resend error', apiResponse?.errors)
        this.setState({ snackbarOpenFalse: true,msg:'wrong'  });
       }
    }
    // ---------------------  resend link response end -----------------------
    // ---------------------  user response start -----------------------

    if (this.apiUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({
          userData: {
            ...this.state.userData,
            id: apiResponse.data?.id,
            fullName: apiResponse.data?.attributes?.first_name + " " + apiResponse.data?.attributes?.last_name,
            isActive: apiResponse.data?.attributes?.activated,
          }
        })

        localStorage.setItem('username',String(apiResponse.data?.attributes?.first_name + " " + apiResponse.data?.attributes?.last_name))
      }

      else {
        console.log('error for user', apiResponse?.error)
      }
    }
    // ---------------------  user response end -----------------------
    // --------------------- grade response end -----------------------
    if (this.apiGradeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {

        this.setState({ gradeApiData: apiResponse?.data })
        this.state.gradeApiData?.map((item: any) => {
          this.state.gradeSelectedData.push({
            grade: item?.attributes.grade,
          })
        })
      }

      else {
        console.log('error grade', apiResponse?.error)
      }
    }
    // --------------------- grade response end -----------------------
    // --------------------- program response end -----------------------
    if (this.apiProgramCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {

        this.setState({ programApiData: apiResponse?.data })
        this.state.programApiData?.map((item: any) => {
          this.state.programSelectedApiData.push({
            program_name: item?.attributes.program_name,
          })
        })

      }


      else {
        console.log('error program', apiResponse?.error)
      }
    }
    // --------------------- program response end -----------------------
    // --------------------- accreditation  response end -----------------------
    if (this.apiaccreditationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {

        this.setState({ accreditationApiData: apiResponse?.data })
        this.state.accreditationApiData?.map((item: any) => {
          this.state.accreditationSelectedApiData.push({
            name: item?.attributes.name,
          })
        })
      
      }

      else {
        console.log('error accreditation', apiResponse?.error)
      }
    }
    // --------------------- accreditation  response end -----------------------
    // --------------------- hg formula  response start -----------------------
    if (this.apiHgFormulaCallId=== message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({hgFormula_PursueWhatMatters:apiResponse?.data[0]?.attributes})
        this.setState({hgFormula_TheHgFormula:apiResponse?.data[1]?.attributes})
        this.setState({hgFormula_MakingTheChoice:apiResponse?.data[2]?.attributes})
        this.setState({hgFormula_ExplorePrograms:apiResponse?.data[3]?.attributes})
        this.setState({hgFormula_AboutHg:apiResponse?.data[4]?.attributes})
       }
    
      else {
        console.log('error hg', apiResponse?.error)
      }
    }
    // --------------------- hg formula   response end -----------------------

    // Customizable Area End
  }

  // Customizable Area Start
  studentEnrollment = () => {
    this.props.navigation.navigate('StudentEnrollmentFormStepper')
  }

// ------------------------- hg formula  api start --------------------------
getHgFormulaData = () => {

  const header = {
    "Content-Type": configJSON.contentTypeApiHgFormula,
    "token": localStorage.getItem("authToken")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiHgFormulaCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.hgFormulaUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.hgFormulaMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// // ------------------------- hg formula  api  end --------------------------
  //-----------------calling api for calculator Start--------------------------//
  gradeCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiVideo,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcGradePage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVideoCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///Program start///
  programCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiVideo,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcProgramPage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVideoCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///Program end///
  ///accreditation  start ///
  accreditationCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiVideo,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiaccreditationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcAccreditionPage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVideoCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///accreditation  end ///


  //-----------------calling api for calculator End--------------------------//
  
  // ------------------------- calling the api for all the video start --------------------------
  getAllVideos = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAllVideo,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllVideoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAllVideoCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiAllVideoCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // ------------------------- calling the api for all the video end --------------------------

// ------------------------- calling the api to get user info start --------------------------
getUserInfo = () => {

  const header = {
    "Content-Type": configJSON.contentTypeApiUser,
    "token": localStorage.getItem("authToken")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiUserCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_customisableuserprofiles/profiles/6'
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiUserCallMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// // ------------------------- calling the api to get user info end --------------------------
// ------------------------- calling the api to get video info time start --------------------------
getIntroVideoTime = () => {
  
  this.setState({ isLoading: true })
  const header = {
    "Content-Type": configJSON.contentTypeApiVideoTime,
    "token": localStorage.getItem("authToken")
  };

  const httpBody = {
    video_detail: {
     video_id: this.state?.allVideos_introVideoID,
    //  video_id: 8
  }

  };
 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
 
  this.apiGetIntroVideoTimeCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.apiVideoTimeCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiVideoTimeCallMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// // ------------------------- calling the api to get video time info end --------------------------
// ------------------------- calling the api to post video start --------------------------
postVideoApi = (videoCurrentTime:any,watchComplete:any) => {
  console.log("TEST DEBUG VIDEO", localStorage.getItem("authToken"))
  const header = {
    "Content-Type": configJSON.contentTypeApiPostIntroVideo,
    "token": localStorage.getItem("authToken")
  };

  const httpBody = {
      video_detail: {
      video_id: this.state?.allVideos_introVideoID,
      watch_time: videoCurrentTime,
      is_completed: watchComplete
  }

  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiPostIntroVideoCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.apiPostIntroVideoCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiPostIntroVideoCallMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// // ------------------------- calling the api to post video end --------------------------
// ------------------------- calling the api to resend linkstart --------------------------

  resendLink = () => {
    this.setState({ loaderResendLink: true })
  const header = {
    "Content-Type": configJSON.contentTypeApiResendLink,
    "token": localStorage.getItem("authToken")
  };
 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiPostResendLinkCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.apiResendLinkCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiResendLinkCallMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// // ------------------------- calling the api to resend link end --------------------------
  handleNext = (videoCurrentTime:any,watchComplete:any) => {
    localStorage.setItem('curentTime', String(videoCurrentTime))
    localStorage.setItem('complete', String(watchComplete))
    setTimeout(() => {
      this.postVideoApi(videoCurrentTime,watchComplete)
      this.props.navigation.navigate('DashBoardDayInLifeVideo')
    }, 5000);
  }
  handleWatchLater = (videoCurrentTime:any,watchComplete:any) => {
    localStorage.setItem('curentTime', String(videoCurrentTime))
    localStorage.setItem('complete', String(watchComplete))
     this.postVideoApi(videoCurrentTime,watchComplete)
  }
  handleGetIntroVideoBtn=()=>{

  }
  gradeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({ grade: e.target.value })
    this.state.calculateErrors['grade'] = " "
  }
  
  programChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({ program: e.target.value })
    this.state.calculateErrors['program'] = " "
  }
  
  accredationChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({ accreditation: e.target.value })
    this.state.calculateErrors['accreditation'] = " "
  }
  handleOpen = () => {
    this.setState({ openverify: true })
  }
  handleOpenProgramVideo = () => {       
    this.setState({openModalProgramVideo: true })
  }
  handleClose = () => {
    this.setState({ snackbarOpen: false})
    this.setState({ openverify: false })
  }
  handleCloseProgramVideo = () => {
    this.setState({ openModalProgramVideo: false })
  }
  handleCloseVideoModal= () => {
    this.setState({ openverifyVideo: false })
  }
  handleOpenVideoModal= () => {
    this.setState({ openverifyVideo: true })
  }
  handleVerify = () => {
    this.resendLink()
  }
  handleVerifyVideo = () => {
    this.setState({ openverifyVideo: false })
  }
  handleEnroll = async () => {
     this.getUserInfo();
    
    let videoCurrentTime=localStorage.getItem('curentTime')
    let watchComplete=localStorage.getItem('complete')
    this.postVideoApi(videoCurrentTime,watchComplete)

   setTimeout(()=>{
    if (this.state?.userData?.isActive !=='' &&  this.state?.userData?.isActive==false){
        
      this.handleOpen()
      
      }
      else{
        if(localStorage.getItem('complete')==='false'){
          this.handleOpenVideoModal();
        }
        else{
          this.props.navigation.navigate('StudentEnrollmentFormStepper')
        }
      }
   },2000)
     
  }
  handleCloseLink = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleCloselink1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenFalse: false })
  };

  postVideoDetails = (videoCurrentTime:any,watchComplete:any) => {
    this.postVideoApi(videoCurrentTime,watchComplete)
 }
  handleTestimonialOne = () => {
    this.setState({
      testimonial: {
        ...this.state.testimonial,
        one: false,
        two: true,
        three: true,
        four: true,
        five: true,
      }
    })
  }
  handleTestimonialTwo = () => {
    this.setState({
      testimonial: {
        ...this.state.testimonial,
        one: true,
        two: false,
        three: true,
        four: true,
        five: true,
      }
    })
  }
  handleTestimonialThree = () => {
    this.setState({
      testimonial: {
        ...this.state.testimonial,
        one: true,
        two: true,
        three: false,
        four: true,
        five: true,
      }
    })
  }
  handleTestimonialFour = () => {
    this.setState({
      testimonial: {
        ...this.state.testimonial,
        one: true,
        two: true,
        three: true,
        four: false,
        five: true,
      }
    })
  }
  handleTestimonialFive = () => {
    this.setState({
      testimonial: {
        ...this.state.testimonial,
        one: true,
        two: true,
        three: true,
        four: true,
        five: false,
      }
    })
  }

  feeCalculatorHandler=()=>{
    
  
    if (this. feeCalculatorValidation()) {
      // this.feeCalculatorApi()
        // this.setState({grade: 'choose',program: 'choose',accreditation: 'choose',calculateErrors:{} })
        this.setState({toggleFeeCaculator: true })
      console.log('ssdd grade', this.state.grade)
      console.log('ssdd program', this.state.program)
      console.log('ssdd acc', this.state.accreditation)
    }
    else {
      this.setState({ calculateErrors: this.state.calculateErrors})
     }
   }

  feeCalculatorValidation = () => {
    let isFormValid = true;
      if (this.state.grade === 'choose') {
      this.state.calculateErrors['grade'] = "Required"
      isFormValid = false
    }
      if (this.state.program === 'choose') {
      this.state.calculateErrors['program'] = "Required"
      isFormValid = false
    }
      if (this.state.accreditation === 'choose') {
      this.state.calculateErrors['accreditation'] = "Required"
      isFormValid = false
    }
     return isFormValid
  }
  
  callProgramVideosHandler=(title:any,data:any)=>{
    // console.log("san", data, title);      
    this.setState({selectedProgramDetails:data, selectedProgramTitle:title});
    this.handleOpenProgramVideo();
  }
  
  // Customizable Area End

}
