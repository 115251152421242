import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
let Country:any = require('country-state-city').Country;
let City = require('country-state-city').City;


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  email: string;
  countryCodeSelected: string;
  countryCodeSelectedData: any;
  uniqueCountryCodeSelectedData: any;
  uniqueCountryCodeData: any;
  errors: any;
  countriesData: any;
  reasonForHomeSchoolingData: any;
   otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  snackbarOpen: boolean;
  snackbarMessage: any;
  snackbarOpenError: any;
  AccountFirstName: string;
  AccountLastName: string;
  AccountCountryCode: any;
  AccountMobileNumber: any;
  AccountEmail: any;
  AccountCountry: any;
  AccountCity: any;
  AccountHomeSchoolingId:string;
  AccountHomeSchoolingValue: string;
  AccountAboutUs: any;
  open: boolean;
  openD: any;
  openS: any;
  drawerWidth: any;
  currentPassword: any;
  newPassword: any;
  confirmNewPassword: any;
  isloadingPassword: boolean;
  errorsObj: any;
  accountError: any;
  updateOpen: any;
  isAccountLoading: boolean;
  gettingDetailsOfStudent: boolean;
  getAllCountries:any;
  getAllCities:any;
  uniqueCountryCodeDataNew: any;
  passwordState:boolean;
  passwordInputState:any;
  passwordCreateState:boolean;
  passwordCreateInputState:any;
  passwordConfirmState:boolean;
  passwordConfirmInputState:any;
  countryAutoCompleteValue:any;
  cityAutoCompleteValue:any;
  provincesData:any;
  countryAccountAutoValue:any;
  cityAccountAutoValue:any;
  barangayAccountValue:string,
  barangayAccountId:string,
  byrangayDataAccount: any[],
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  apiReasonForHomeSchoolingCallId: string = "";
  apiCallChangePassword: any;
  apiCallStudent: any;
  postStudentCallId: any;
  apiBarangaysCallId: any;
  apiProvincesCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      countryCodeSelected: "",
      countriesData: [],
      reasonForHomeSchoolingData: [],
      countryCodeSelectedData: [],
      uniqueCountryCodeSelectedData: [],
      uniqueCountryCodeData: [],
      errors: {},
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarOpenError: false,
      AccountFirstName: "",
      AccountLastName: "",
      AccountCountryCode: "choose",
      AccountMobileNumber: "",
      AccountEmail: "",
      AccountCountry: "choose",
      AccountCity: "choose",
      AccountHomeSchoolingId: "",
      AccountHomeSchoolingValue: "choose",
      AccountAboutUs: "choose",
      open: false,
      openD: true,
      openS: true,
      drawerWidth: 300,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isloadingPassword: false,
      errorsObj: "",
      accountError: {},
      updateOpen: false,
      isAccountLoading: false,
      gettingDetailsOfStudent: false,
      getAllCountries:[],
      getAllCities:[],
      uniqueCountryCodeDataNew: [],
      passwordState:true,
      passwordInputState:'password',
      passwordCreateState:true,
      passwordCreateInputState:'password',
      passwordConfirmState:true,
      passwordConfirmInputState:'password',
      countryAutoCompleteValue:"",
      cityAutoCompleteValue:'',
      provincesData:[],
       countryAccountAutoValue:"",
       cityAccountAutoValue:"",
       barangayAccountValue:'choose',
       barangayAccountId:'',
       byrangayDataAccount: [],
      // Customizable Area End
    };

    // Customizable Area Start

    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (this.apiReasonForHomeSchoolingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({ reasonForHomeSchoolingData: apiResponse?.data })
      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    // ----------------------------- Provinces api start-------------------------------
    if (this.apiProvincesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({ provincesData: apiResponse?.data })
        }
      else {
        console.log('error', apiResponse?.error)
      }
    }

    // ----------------------------- Provinces api end-------------------------------

    ////changepassowrd api start////
    if (this.apiCallChangePassword === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ isloadingPassword: true });
      if (apiResponse?.data) {
        this.setState({ snackbarOpen: true, snackbarMessage: "password changed successfully" });
        this.setState({ isloadingPassword: false })
          this.setState({
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          errors: {},
        })
      }
      else {
        this.setState({ isloadingPassword: false })
        console.log('error', apiResponse?.errors)
        this.setState({ errorsObj: apiResponse?.errors });
        this.state.errorsObj.map((item: any) => {
          this.setState({ errorsObj: item.password });
        })
        this.setState({ snackbarOpenError: true, snackbarMessage: this.state.errorsObj });
      }
    }
    ////changepassowrd api end////

    ///get Details Api///
    if (this.apiCallStudent === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({gettingDetailsOfStudent:true})
      if (apiResponse?.data) {
        this.setState({gettingDetailsOfStudent:false})
       
        this.setState({
          AccountFirstName: apiResponse?.data?.attributes?.first_name,
          AccountLastName: apiResponse?.data?.attributes?.last_name,
          AccountCountryCode: apiResponse?.data?.attributes?.country_code,
          AccountMobileNumber: apiResponse?.data?.attributes?.phone_number,
          AccountEmail: apiResponse?.data?.attributes?.email,
          AccountCountry: apiResponse?.data?.attributes?.country,
          AccountCity: apiResponse?.data?.attributes?.city,
          barangayAccountId:apiResponse?.data?.attributes?.barangay_id,
          barangayAccountValue: apiResponse?.data?.attributes?.barangay_name,
          AccountHomeSchoolingId: apiResponse?.data?.attributes?.inquiry_details?.home_schooling_reason_id,
          AccountHomeSchoolingValue: apiResponse?.data?.attributes?.inquiry_details?.home_schooling_reason,
          AccountAboutUs: apiResponse?.data?.attributes?.inquiry_details?.informed_about_us,
         })

         this.getCityDynamic(apiResponse?.data?.attributes?.country,
          apiResponse?.data?.attributes?.city,
         )
      }
      else {
        this.setState({gettingDetailsOfStudent:false})
      }
    }
    ///get Details Api///

    ///post student details start///
    if (this.postStudentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ isAccountLoading: true })
      if (apiResponse?.data) {
        this.setState({ isAccountLoading: false, updateOpen: true, })
      }
     }
    ///post student details end///
   ////barangay call id  start////

   if (this.apiBarangaysCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiResponse?.data) {
        this.setState({ byrangayDataAccount: apiResponse?.data})
    }
    else {
      console.log('Byrangay error', apiResponse?.errors)
    }
  }
  ///barangay call id  End////

    // Customizable Area End
  }

  // Customizable Area Start

  saveLoggedInUserData(token: any) {
    console.log({ token })
    if (token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        token
      );

      this.send(msg);
    }
  }


  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  //---------------------- api call request ----------------------
  getResonForHomeSchoolingData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiReasonForHomeSchooling,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReasonForHomeSchoolingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiReasonForHomeSchoolingCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiReasonForHomeSchoolingCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //---------------------- Provinces api call request start ----------------------
  getProvincesData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiProvinces,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvincesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiProvincesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiProvincesCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
    //---------------------- Provinces api call request end ----------------------
/// barangays api call request start///
  getBarangaysData = (cityName: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiBarangay,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBarangaysCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_barangays?city=${cityName}`
     );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiBarangayCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  /// barangays api call request End///

  ///update pass codeapi start///
  
  updatePasscode = () => {
  
    if (this.changePasswordValidation()) {
      this.updatePasscodeApi()
    }
    else {
      this.setState({
        errors: this.state.errors
      })
      console.log(this.state.errors)
    }
 
  }

  updatePasscodeApi = () => {
    const header = {
      "Content-Type": configJSON.contentTypesignupFormSubmit,
      "token": localStorage.getItem('authToken')
    };

    const httpBody = {
      data: {
        current_password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmNewPassword

      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallChangePassword = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pascodeChange
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apipasscode
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///update pass codeapi end///

  ///get student api///
  getStudentApi = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiStudentsList,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallStudent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getStudentMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///get student api end///
  
  /// post updated details Start ///
  accountSettingApiCall = () => {
    const header = {
      "token": localStorage.getItem("authToken")
    };
    console.log("ACCOUNTSSS learn", this.state.AccountAboutUs)
    let formdata = new FormData();
    formdata.append("data[first_name]", this.state.AccountFirstName);
    formdata.append("data[last_name]", this.state.AccountLastName);
    formdata.append("data[country_code]", this.state.AccountCountryCode.toString());
    formdata.append("data[phone_number]", this.state.AccountMobileNumber);
    formdata.append("data[country]", this.state.AccountCountry);
    formdata.append("data[city]", this.state.AccountCity);
    formdata.append("data[home_schooling_reason_id]", this.state.AccountHomeSchoolingId);
    formdata.append("data[informed_about_us]", this.state.AccountAboutUs);
    formdata.append("data[barangay_id]", this.state.barangayAccountId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postStudentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postStudentMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  /// post updated details End ///

  // ----------------componentDidMount-----------------------



  async componentDidMount() {
   this.getProvincesData()
    this.getResonForHomeSchoolingData()
    this.getAllCountriesData()
    this.getStudentApi() 
  }


  // ----------------form validations-----------------------
  changePasswordValidation = () => {
    let isFormValid = true;
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\]\/{}\\[?:";'|<>,.~`@#$%^&*()_+])[A-Za-z\d!\]\/{}\\[?:";'|<>,.~`@#$%^&*()_+]{8,}$/
     if (this.state.currentPassword === '') {
      this.state.errors['currentPassword'] = "Password Required"
      isFormValid = false
    }

     else if (!passwordRegx.test(this.state.currentPassword)) {
      this.state.errors['currentPassword'] = "Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
      isFormValid = false
    }
    if (this.state.newPassword === '') {
      this.state.errors['newPassword'] = "Password Required"
      isFormValid = false
    }

    else if (!passwordRegx.test(this.state.newPassword)) {
      this.state.errors['newPassword'] = "Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
      isFormValid = false
    }
    if (this.state.confirmNewPassword === '') {
      this.state.errors['confirmNewPassword'] = "Password Required"
      isFormValid = false
    }

    else if (!passwordRegx.test(this.state.confirmNewPassword)) {
      this.state.errors['confirmNewPassword'] = "Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
      isFormValid = false
    }
    return isFormValid

  }



  hideError = (event: any) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }

  accountSettingEdit = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{6,11}$/;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;

    if (this.state.AccountFirstName === '') {
      this.state.errors['AccountFirstName'] = "FirstName Required"
      isFormValid = false
    }
    else if (!regexName.test(this.state.AccountFirstName)) {
      this.state.errors['AccountFirstName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.AccountLastName === '') {
      this.state.errors['AccountLastName'] = "LastName Required"
      isFormValid = false
    }
    else if (!regexName.test(this.state.AccountLastName)) {
      this.state.errors['AccountLastName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.AccountCountryCode === '') {
      this.state.errors['AccountCountryCode'] = "Code Required"
      isFormValid = false
    }
    if (this.state.AccountMobileNumber === '') {
      this.state.errors['AccountMobileNumber'] = "Phone Required"
      isFormValid = false
    }
    else if (!regexPhone.test(this.state.AccountMobileNumber)) {
      this.state.errors['AccountMobileNumber'] = "Min 6 to Max 11 digits only"
      isFormValid = false
    }

    if (this.state.AccountEmail === '') {
      this.state.errors['AccountEmail'] = "Email Required"
      isFormValid = false
    }
    else if (!regex.test(this.state.AccountEmail)) {

      isFormValid = false;
      this.state.errors['AccountEmail'] = "Incorrect Email"
    }
    if (this.state.AccountCountry === '') {
      this.state.errors['AccountCountry'] = "Country Required"
      isFormValid = false
    }
    if (this.state.AccountCity === '') {
      this.state.errors['AccountCity'] = "City Required"
      isFormValid = false
    }
    if (this.state.AccountCity !="" && this.state.byrangayDataAccount.length > 0) {
      if (this.state.barangayAccountValue === 'choose') {
       this.state.errors['barangayAccount'] = "Barangay Required"
       isFormValid = false
     }
   }
    if (this.state.AccountHomeSchoolingValue === '') {
      this.state.errors['AccountHomeSchooling'] = "Reason Required"
      isFormValid = false
    }
    if (this.state.AccountAboutUs === '') {
      this.state.errors['AccountAboutUs'] = "Option Required"
      isFormValid = false
    }
    return isFormValid
  }

  onSubmitAccountSetting = () => {
    if (this.accountSettingEdit()) {
      this.accountSettingApiCall()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
      console.log(this.state.errors)
    }
  }
  changeHandlerGetCitiesAccount = async(e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.state.errors['AccountCountry'] = " "
    this.setState({ AccountCity: '' })
    this.setState({ byrangayDataAccount: [] })
    this.setState ({barangayAccountValue: 'choose', barangayAccountId: ''})

   await this.state.getAllCountries &&  this.state.getAllCountries?.filter(async (item: any) => {
    if(newInputValue){
      if (item.name ==newInputValue) {
          let data:any=[];
          let dataMsg:any=[{name:'No City found'}]
          const key = 'name';
          if(newInputValue == 'Philippines'){
            await this.state.provincesData?.map((city:any)=>{
                data?.push({
                name: city,
                })
               })
           }
           else{
            await City.getCitiesOfCountry(item?.isoCode)?.map((city:any)=>{
              data?.push({
              name: city?.name,
              })
             })
           }
        
              const arrayUniqueByKey1 = [...new Map(data.map((item:any) =>
                [item[key], item])).values()];
              if(data.length == 0){
                 this.setState({getAllCities:dataMsg})  
                }
              else{
                this.setState({getAllCities:arrayUniqueByKey1})  
                }
            }
        }
      else{
        this.setState({cityAccountAutoValue:''})
        this.setState({AccountCity:''})
        this.setState({getAllCities:[]})  
      }  
     })
   }
 
  handlePhoneChange=(e: React.ChangeEvent<HTMLInputElement>) => {
    let aaa=e.target.value.replace(/^0+/, "")
   this.setState({ phone: aaa })
   }

   handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleClose1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenError: false })
  };
  
  changeOpen = () => {
    this.setState({ open: true })
  }
  handleModelClose = () => {
    this.setState({ open: false })
  }

    getCityDynamic= async (dynamicCountry:string,dynamicCity:string)=>{

        await this.state.getAllCountries.map(async (item:any)=>{
      if(item.name==dynamicCountry){
        this.setState({countryAccountAutoValue:item})
        let data: any = [];
        let dataMsg: any = [{ name: 'No City found' }]
        const key = 'name';
        if(item.name == 'Philippines'){
          
        await this.state.provincesData?.map((city:any)=>{
              data?.push({
              name: city,
              })
              })
          }
          else{
            await City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
          data?.push({
            name: city?.name,
          })
        })
      }
        const arrayUniqueByKey = [...new Map(data.map((item:any) =>
          [item[key], item])).values()];
        if (data.length == 0) {
          this.setState({ getAllCities: dataMsg })
        }
        else {this.setState({ getAllCities: arrayUniqueByKey })}
        }
      })

        await this.state.getAllCities.map((item:any)=>{
        if(item.name==dynamicCity){
      this.setState({cityAccountAutoValue:item})
        }
      })
    
        this.getBarangaysData(dynamicCity)
  }
  
  cityChangeHandler1 = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.setState({byrangayDataAccount:[]})
    this.setState ({barangayAccountValue: 'choose', barangayAccountId: ''})
    this.setState({ AccountCity: newInputValue },()=>{
        this.getBarangaysData(this.state.AccountCity)
    })
    }
    
  handleCloseAccount = () => {
    this.setState({ updateOpen: false })
    localStorage.setItem("username",this.state.AccountFirstName +" "+this.state.AccountLastName)
  }

  getAllCountriesData=()=>{
    this.getProvincesData()
     Country.getAllCountries().map((item:any)=>{
       this.state.getAllCountries.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode:item?.isoCode
      })
     
    })
    this.state.getAllCountries?.map((item: any) => {
      if(item?.phonecode !== '' && !item?.phonecode.includes('+') && !item?.phonecode.includes('0055') ){
        this.state.uniqueCountryCodeData.push({
          phonecode:item?.phonecode
        })
      }
     })


    this.state.uniqueCountryCodeData.unshift({...this.state.uniqueCountryCodeData.phonecode, phonecode:'0'})
   
    this.state.uniqueCountryCodeData.sort(function(a:any, b:any){return a.phonecode - b.phonecode}); 
     
    let dataArr = this.state.uniqueCountryCodeData?.map((item: any) => {
      return [JSON.stringify(item), item]
    });
    let maparr = new Map(dataArr);

    this.setState({ uniqueCountryCodeDataNew: [...maparr.values()] });
    
  }

  changePasswordState=()=>{
    if(this.state.passwordState){
      this.setState({passwordState:false})
      this.setState({passwordInputState:'text'})
    }
    else{
      this.setState({passwordState:true})
      this.setState({passwordInputState:'password'})
    }
  } 
  changePasswordCreateState=()=>{
    if(this.state.passwordCreateState){
      this.setState({passwordCreateState:false})
      this.setState({passwordCreateInputState:'text'})
    }
    else{
      this.setState({passwordCreateState:true})
      this.setState({passwordCreateInputState:'password'})
    }
  } 
  changePasswordConfirmState=()=>{
    if(this.state.passwordConfirmState){
      this.setState({passwordConfirmState:false})
      this.setState({passwordConfirmInputState:'text'})
    }
    else{
      this.setState({passwordConfirmState:true})
      this.setState({passwordConfirmInputState:'password'})
    }
  } 
  
  barangayChangeAccount=(e:any)=>{
    this.setState ({
         barangayAccountValue: e.target.value,
         barangayAccountId: this.state.byrangayDataAccount.find((o: any) => o?.attributes?.barangay_name == e.target.value)?.id,
         
    })
      this.state.errors['barangayAccount'] = " "
  }

 reasonChangeAccount=(e:any)=>{
    this.setState ({
      AccountHomeSchoolingValue: e.target.value,
      AccountHomeSchoolingId: this.state.reasonForHomeSchoolingData.find((o: any) => o?.attributes?.reason == e.target.value)?.id,
         
    })
      this.state.errors['AccountHomeSchooling'] = " "
  }

  accountCountryAutoHandler=(e: React.ChangeEvent<HTMLInputElement>,newValue:any)=>{
    this.setState({countryAccountAutoValue:newValue})
  }
  // Customizable Area End
}
