

import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Drawer from "../../../components/src/Drawer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
     // Customizable Area End
export default class HomeschoolStyle extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingLeft: '0', paddingRight: '0', marginBottom: '6em' }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Homeschooling Style</Typography>
                                        </Box>

                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>

                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.firstQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>1. {this.programDetailsQuestions?.stylequestions?.subFirstQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleGeneral"
                                                        value={this.state.homeSchoolingStyleGeneral}
                                                        // value="a systematic learning from knowing, understanding, and applying the lesson in real-life situations."
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleGeneral: event.target.value })}  >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.firstQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index} value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleGeneral)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleGeneral}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.secondQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>2. {this.programDetailsQuestions?.stylequestions?.subSecondQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleParentalInvolvement" value={this.state.homeSchoolingStyleParentalInvolvement}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleParentalInvolvement: event.target.value })}  >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.secondQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index} value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleParentalInvolvement)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleParentalInvolvement}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.thirdQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>3. {this.programDetailsQuestions?.stylequestions?.subThirdQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleLearningStyleOfTheChild" value={this.state.homeSchoolingStyleLearningStyleOfTheChild}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleLearningStyleOfTheChild: event.target.value })} >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.thirdQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index} value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleLearningStyleOfTheChild)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleLearningStyleOfTheChild}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.fourthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>4. {this.programDetailsQuestions?.stylequestions?.subFourthQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleCurriculumAndMaterials" value={this.state.homeSchoolingStyleCurriculumAndMaterials}
                                                        // defaultValue=
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleCurriculumAndMaterials: event.target.value })}  >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.fourthQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index} value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleCurriculumAndMaterials)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleCurriculumAndMaterials}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.fifthQuestion}</Typography>
                                                <Typography style={webStyle.SubQuestionList}>5. {this.programDetailsQuestions?.stylequestions?.subFifthQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleEducationalPhilosophyg" value={this.state.homeSchoolingStyleEducationalPhilosophyg}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleEducationalPhilosophyg: event.target.value })}  >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.fifthQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index}value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleEducationalPhilosophyg)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleEducationalPhilosophyg}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{ paddingTop: '2em', paddingLeft: '1em' }}>
                                                <Typography style={webStyle.QuestionList}>{this.programDetailsQuestions?.stylequestions?.sixthQuestion}
                                                </Typography>
                                                <Typography style={webStyle.SubQuestionList}>6.{this.programDetailsQuestions?.stylequestions?.subSixthQuestion}</Typography>
                                                <Box style={webStyle.BoxStyle}>
                                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="homeSchoolingStyleSchedule" value={this.state.homeSchoolingStyleSchedule}
                                                        onFocus={this.hideError}
                                                        onChange={(event: any) => this.setState({ homeSchoolingStyleSchedule: event.target.value })}  >
                                                        {this.programDetailsQuestions?.styleAnswerOptions?.sixthQuestionOptions.map((item: any, index: number) => {
                                                            return (
                                                                <FormControlLabel key={index}value={item} control={<GreenRadio size="small" />} label={<span style={webStyle.radio}>{item}</span>} />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    {
                                                        (this.state.errors.homeSchoolingStyleSchedule)
                                                            ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingStyleSchedule}</small>
                                                            : <small style={{ visibility: 'hidden' }}>""</small>
                                                    }
                                                </Box>
                                                <Box style={webStyle.Breakline}><p></p></Box>
                                            </Box>
                                            <Box style={{
                                                textAlign: "left",
                                                paddingBottom: '4em',
                                                marginTop: '2em',
                                                display: "flex",
                                                justifyContent: "end"
                                            }}>
                                                <Snackbar anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }} open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.handleClose} >
                                                    <Alert severity="success">
                                                    Saved to Draft Successfully
                                                    </Alert>
                                                </Snackbar>
                                                <Box style={{ paddingRight: '1em' }}>
                                                    <Button style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyleSaveToDraft }}
                                                        onClick={this.homeSchoolStylingRequestSaveAsDraftButtonApi}>Save to Draft</Button>
                                                </Box>

                                                <Button style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyle }}
                                                    onClick={this.handleStyleSubmitAnswers}
                                                >{this.state.homeSchoolStyleSubmit ? <CircularProgress /> : "Next"}</Button>
                                            </Box>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
            
        );
    }
}

// Customizable Area Start
const webStyle = {
    homeSchoolStyle: {
        height: "52px",
        width: '200px',
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#fab03d",
    },
    homeSchoolStyleSaveToDraft: {
        height: "52px",
        width: '200px',
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#b9b9b9",
    },
   
    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    BoxStyle: {
        marginTop: '0.5em'
    },
    QuestionList: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600
    },
    SubQuestionList: {
        color: "#171725",
        paddingTop: '1em',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500
    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: 'Roboto',
        fontSize: ' 12px',
    },
    radio: {
        color: "#44444f",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
    },
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",

        marginTop: "2em",

    },

};
// Customizable Area End
