Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderDetailView";
exports.labelBodyText = "OrderDetailView Body";

exports.btnExampleTitle = "CLICK ME";

exports.btnSwitchProgram = "Switch Program / Accreditation";
exports.btnNext = "Next";
exports.btnCheckout = "Checkout";

exports.stepperText1 = "01 PROGRAM INFO";
exports.stepperText2 = "02 CURRICULUM";
exports.stepperText3 = "03 TEXT BOOK SELECTION";
exports.stepperText4 = "04 FAMILY BOOK SELECTION";
exports.stepperText5 = "05 MISCELLANEOUS";

exports.contentTypeApiStudentsList = "application/json";
exports.apiStudentsListMethodType = "GET";
exports.apiStudentsListUrl="bx_block_student/students";
exports.apiStudentsListUrlParams="bx_block_student/students/366";
exports.apiPayment="bx_block_stripe_integration/payment_methods/create_checkout_session";
exports.apiPaymentMethod="POST";
exports.apiHomeschoolRecommendations="bx_block_dashboard/homeschool_recommendations";
exports.apiHomeschoolRecommendationsMethod="POST";
exports.apiHomeSchoolStyleQuestions="bx_block_dashboard/homeschool_recommendations/homeschool_style_questions";
exports.apiHomeSchoolStyleQuestionsType="GET";
exports.apiHomeSchoolProgramAnswerType="GET";
exports.apiHomeSchoolProgramRestPageCall="bx_block_dashboard/homeschool_recommendations/recommend_homeschool_program";
exports.apiHomeSchoolStyleAnswerType="GET";
exports.apiHomeSchoolStyleRestPageCall="bx_block_dashboard/homeschool_recommendations/recommend_homeschool_style";
exports.apiHomeSchoolStyleSaveAsDraftMethod="GET";
exports.apiHomeSchoolStyleSaveAsDraftApiEndPoint="bx_block_dashboard/homeschool_recommendations/draft_homeschool_style";
exports.apiHomeSchoolProgramSaveAsDraftMethod="GET";
exports.apiHomeSchoolProgramSaveAsDraftApiEndPoint="bx_block_dashboard/homeschool_recommendations/draft_homeschool_program"
///FormApi///
exports.enrolmentEndPoint="student_enrolment"
exports.enrolmentMethod="POST";

// Customizable Area End