import React, { useState } from "react";

import {
    // Customizable Area Start
  Box,
  Typography,
  IconButton,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },

    MuiTypography: {
      body1: {
        // fontSize: '0.8rem',
        // fontFamily: "Roboto",
        // color: '#171725',
        // fontWeight: 300,
        paddingLeft:'0.3em'
      }
    },

    MuiRadio: {
      root: {
        color: '#99cb51',
        width: '1rem',
        height: '1rem',
      },
      colorSecondary: {
        color: '#99cb51',

        '&$checked': {
          color: '#99cb51',
        },

      },
    }
  }
});


const CheckoutRadioTextBookFullCurr = ({ value }: any) => {

  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <FormControlLabel
            style={{marginLeft:'0.5px'}}
            value={value}
            control={
            <Radio />
          }
            label={<span style={webStyle.radioLabelStyle}>Full Online Curriculum</span>} />
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </Box>
        <Typography style={webStyle.cardSubTextStyle}> Ignitia Bundled - DepEd </Typography>
        <Grid container >
          <Grid item xs={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

              <Box>
                <Typography style={webStyle.cardCollapseStyle}>
                  Ignitia Online Curriculum with a student and parent teacher account. Student accounts come with Lessons Quizzes, Projects, Unit Tests, and Answer Keys for
                </Typography>
                <Typography style={webStyle.cardCollapseStyle}>Bible 700 </Typography>
                <Typography style={webStyle.cardCollapseStyle}> English Language Arts 700  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Math 700 </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Science 7 DepEd  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Asian Identity in the Global World </Typography>
                <Typography style={webStyle.cardCollapseStyle}> And with Printed Materials For: </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Filipino: Kalinangan 7 (Version 3) </Typography>
                <Typography style={webStyle.cardCollapseStyle}> (Text Book and Teacher's Manual) </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Social Studies: Asian Identity in the Global World </Typography>
                <Typography style={webStyle.cardCollapseStyle}> (Text Book ) </Typography>

              </Box>

            </Collapse>

          </Grid>
        </Grid>
        <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc', marginTop: '1em', marginBottom: '1em' }}><p></p></Box>
        <Typography style={webStyle.cardAmountStyle}> Price : ₱ 45,000.00 </Typography>

      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  cardStyle: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    borderRadius: '15px',
    border: '1px solid #e2e2ea',
  },

  cardCollapseStyle: {
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },

  cardAmountStyle: {
    marginTop: '10px',
    color: '#192126',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
  },
  radioLabelStyle: {
    color: '#171725',
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    },
  cardSubTextStyle: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#171725',
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
  }

};

export default CheckoutRadioTextBookFullCurr;
// Customizable Area End
