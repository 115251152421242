import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import StudentsListCard from "../../../components/src/StudentsListCard.web";
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {

    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiInput: {
      underline: {

        " &:before": {
          borderBottom: 'none',
          transition: 'none',
          content: 'none',
        },

      },
    },

  },
});


// Customizable Area End
   // Customizable Area Start
import OrderDetailViewController, {
  Props,
} from "./OrderDetailViewController";
    // Customizable Area End
export default class StudentsList extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
   
    let noOfPageCount;

    if (this.state.valueShow == 'All') {
      this.setState({valueShow:this.state.studentsList.length })
    }
    if (this.state.studentsList.length % this.state.valueShow == 0) {
      noOfPageCount = (this.state.studentsList.length / this.state.valueShow)
    }
    else {
      noOfPageCount = Math.floor(this.state.studentsList.length / this.state.valueShow) + 1
    }

    let indexOfLastPosts = Number(this.state.pages) * Number(this.state.valueShow);
    let indexOfFirstPosts = Number(indexOfLastPosts) - Number(this.state.valueShow);
    let currPosts = this.state.studentsList.slice(indexOfFirstPosts, indexOfLastPosts)

    const handlerPaginate = (e: any, value: any) => {
      this.setState({ pages: value });
    }



    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                 handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
                modal={this.manage}
              />
            </Grid>


            {/* ---------------------- drawer Content ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >

                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' ,marginBottom: '6em' }}>
                  {/* <Container maxWidth="md" style={{ margin: '0 auto' }}> */}
                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Students </Typography>
                    <Button variant='contained' style={{ textTransform: 'none', ...webStyle.btnProceedCheckout }} onClick={this.proceedCToCheckout}>Proceed to Checkout</Button>
                  </Box>
                  <Grid container style={{ display: 'flex' }}>
                  <HorizontalLine/>
                  </Grid>
                  <Grid container>

                    <Grid item xs={12} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography style={webStyle.textStudentListStyle}>Student List</Typography>
                      <Box>
                        <span style={webStyle.textShowStyle}>Show: </span>
                        <Select
                          value={this.state.valueShow}
                          onChange={(e) => { this.setState({ valueShow: e.target.value }) }}
                          // onChange={this.handleChangeShow}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={this.state.studentsList.length}>All</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid container style={webStyle.gridContentContainerStyle}>
                      {
                        this.state.isLoadingStudentsList ?
                          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                      //     : null
                      // }
                    :<Grid item xs={12}>
                        {
                          currPosts?.map((item: any, index: number) => {

                            return (
                              <StudentsListCard key={item?.id}
                                openPopover={this.openPopover}
                                popoverAnchor={this.state.popoverAnchor}
                                handleClosePopover={this.handleClosePopover}
                                avatar={item?.attributes.id_picture?.url}
                                handlerView={this.handlerView}
                                fullname={item?.attributes.first_name + ' ' + item?.attributes.last_name}
                                program={item?.attributes.program_detail?.data?.attributes?.program}
                                submitted={item?.attributes?.submitted_date}
                                schoolyear={item?.attributes?.school_year}
                                grade={item?.attributes.program_detail?.data?.attributes?.grade}
                                status={item?.attributes?.status}
                                studentID={item?.id}
                                studId={this.state.studID}
                                pendingPay={this.state.pendingPay}
                                btnCheckedPendingPayment={this.btnCheckedPendingPayment}
                                multipleStudID={this.multipleStudID}
                                snackbarOpenError={this.state.snackbarOpenError}
                                snackbarMessage={this.state.snackbarMessage}
                                handleCloseSnackbar={this.handleCloseSnackbar}
                                handlerEdit={this.handlerEdit}
                                btnRejected={this.btnRejected}
                                />
                                )
                          }
                          )
                        }

                      </Grid>}
                      {
                         !this.state.isLoadingStudentsList && this.state.studentsList.length <= 0 && <p>No records found</p>
                      }
                      {
                        this.state.studentsList.length > 3 ?

                          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Pagination
                              variant="outlined"
                              count={noOfPageCount}
                              onChange={handlerPaginate}
                              page={this.state.pages}
                              defaultPage={1}
                              size="large"
                              showFirstButton
                              showLastButton
                            />

                          </Box>
                          : null
                      }

                    </Grid>
                  </Grid>
                </Container>

                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

              </Container>
            </Grid>
          </Grid>

      </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },

  btnProceedCheckout: {
    width: '235px',
    height: '52px',
    borderRadius: '10px',
    border: "none",
    backgroundColor: "#fab03d",
    color: '#fafafb',
    fontFamily: 'Poppins',
    fontSize: ' 16px',
    fontWeight: 600,
    backgroundBlendMode: 'normal',
  },
  gridContentContainerStyle: {
    marginTop: '40px',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '20px',

  },
  textStudentListStyle: {
    height: '36px',
    color: '#171725',
    fontFamily: '  Poppins',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  textShowStyle: {
    height: '22px',
    color: '#92929d',
    fontFamily: ' Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  chatText: {
    color: '#44444f',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
    marginTop:'1em',
  },
  chatSubText: {
    color: '#44444f',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    marginBottom:'2em',
  },
  chatAvatarText: {
    color:  '#474747',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
    marginLeft:'0.6em',
   },
  chatHeader: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
  },
  btnChat: {
    width: '104px',
    height: "28px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    margin: '0.6em'
  },
  btnAsk:{
    width: '244px',
    height: "45px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
     },
     chatAvatarStyle: {
      width: '42px',
      height: '42px',
    },

};
// Customizable Area End
