import React from "react";

import {
    // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
   Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { history, history_b, manners, success, teenage, true_success } from './assets';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
 
 });

 const PurpleCheckbox = withStyles({
  root: {
      color: '#9267ac',
    '&$checked': {
      color: '#9267ac',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

// Customizable Area End
  // Customizable Area Start
import OrderDetailViewController, {
  Props,

} from "./OrderDetailViewController";
// Customizable Area End
export default class CheckoutFour extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
                modal={this.manage}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >
                <Container maxWidth={false} style={{ margin: '0 auto', width: "95%", padding: '0em', marginBottom: '6em' }}>
                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>

                  </Box>
                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>
                 {/* stepper tabs start */}
                 <Grid container style={webStyle.stepperContainer}>
                     <Grid item xs={12} style={webStyle.stepperGridItem}>
                         <Box style={webStyle.stepperMenuBox}>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText1}</Typography>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText2}</Typography>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText3}</Typography>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText4}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText5}</Typography>
                          </Box>
                          <Box style={webStyle.stepperLineBox}></Box>
                      </Grid>
                  </Grid>
                  {/* stepper tabs ends */}
                  <Grid container style={{ marginBottom: '100px' }} >
                    <Grid item xs={12} sm={12} >
                      <Paper elevation={0} style={webStyle.gridContentContainerStyle}>
                        <Typography style={webStyle.gridHeadingStyle}>Family Book</Typography>

          {/* ====================================== Cards starts  ===================================    */}
          <Grid container spacing={2} >
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={success} alt="Textbooks"/>
                                   <Box>
                                      <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkSuccess}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkSuccess"
                                        />
                                        </div>
                                        }
                                          label={<span style={webStyle.checkboxLabelStyle}>Achieving True Success Hardbound </span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={true_success} alt="Textbooks"/>
                                   <Box  >
                                       <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkPower}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkPower"
                                        />
                                        </div>
                                          }
                                          label={<span style={webStyle.checkboxLabelStyle}>Power for True Success Hardbound </span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={history} alt="Textbooks"/>
                                   <Box  >
                                        <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkHistory1A}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkHistory1A"
                                        />
                                        </div>
                                          }
                                          label={<span style={webStyle.checkboxLabelStyle}>The Mystery of  History Volume 1-A Set (3rd Edition) </span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={manners} alt="Textbooks"/>
                                   <Box  >
                                       <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkManners}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkManners"
                                        />
                                        </div>
                                          }
                                          label={<span style={webStyle.checkboxLabelStyle}>The family Book of Manners</span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={history_b} alt="Textbooks"/>
                                   <Box  >
                                       <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkHistory1B}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkHistory1B"
                                        />
                                        </div>
                                          }
                                          label={<span style={webStyle.checkboxLabelStyle}>The mystery of History Volume 1-B Set (3rd edition)</span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box style={{padding:"0.5em" ,display:'flex', alignItems:'stretch', justifyContent:'center', flexDirection:'column'}}>
                                 <img style ={{ objectFit: 'contain', width: '100%', height: '100%',boxShadow:'0px 5px 10px rgba(68,68,79,0.3)', marginBottom:'1.2em'}} 
                                  src={teenage} alt="Textbooks"/>
                                   <Box  >
                                       <FormControlLabel
                                       style={{ display:'table' }}
                                        control= {
                                       <div style={{display:'table-cell'}}>
                                        <PurpleCheckbox
                                        checked={this.state.checkoutDataObj.familyBkTeenage}
                                        onChange={this.handlChangeCheckBox}
                                        name="familyBkTeenage"
                                        
                                        />
                                        </div>
                                          }
                                          labelPlacement="end"
                                          label={<span style={webStyle.checkboxLabelStyle}>Teenage Book of Manners </span>}
                                        />
                                     </Box>
                                    <Typography style={webStyle.cardAmtStyle}>₱ 739.00 </Typography>
                            </Box>
                          </Grid>
                          {/* ----------------------------------------------------------------------------------------------- */}
              </Grid>
          {/* ====================================== Cards ends  ===================================    */}
                      
                        <Grid container spacing={4} >
                          <Grid item xs={12} style={{ marginTop: '60px', textAlign: "right" }}>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnSwitch }}
                            onClick={this.handleSwitchProgram}> 
                            {configJSON.btnSwitchProgram} </Button>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnNext}}
                             onClick={this.handleCheckoutFormFive}>
                            {configJSON.btnNext}
                            </Button>
                         
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
            </Grid>
          </Grid>

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  gridContentContainerStyle: {
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '1.3em 2.7em',
    marginTop: '2em',
  },
  gridHeadingStyle: {
    marginTop: '20px',
    marginBottom: '25px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    lineSpacing: '0.08333328px',
  },
  checkboxLabelStyle: {
    paddingRight:"0.5em",
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '1vw',
    // fontSize: '13px',
    fontWeight: 500,
  },
  cardAmtStyle: {
    marginLeft:"2.2vw",
    color: '#192126',
    fontFamily: 'Poppins',
    // fontSize: '12px',
    fontSize: '0.8vw',
    fontWeight: 400,

  },
  btnSwitch: {
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#9267ac",

  },
  btnNext: {
    width: "200px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#fab03d",
    borderRadius: "10px",
    marginLeft: '1em',
  },
  stepperContainer:{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center' 
  },
  stepperGridItem:{ 
    display: 'flex',  
    alignItems: 'flex-start', 
    justifyContent: 'flex-start',
    flexDirection:'column' as 'column',
  },
  stepperMenuBox:{
    width:'100%',
    padding: '1.3vw', 
    marginTop: '1.3vw', 
    marginBottom: '0.6vw', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between' 
  },
  stepperMenuTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    fontWeight: 600,
  },
  stepperLineBox:{
    width:'78%', 
    marginLeft:"0.9vw",
    borderBottom:'0.2vw solid #99cb51'
  },
};
// Customizable Area End
