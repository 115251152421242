import React, { useState } from "react";

import {
  Box,
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider,makeStyles } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },


});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
   },
  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
   

  },
  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
      
    },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },

});


const HgVideo = ({ introVideoURL}: any) => {

  const classes = useStyles();
  const [playing, setPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false);
  
 
  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
        setIsReady(true);
    }
  }, [isReady]);
  return (

    // Customizable Area Start
    <>

      <ThemeProvider theme={theme}>
    
        <Box style={{ display: 'flex', alignItems: 'center' }} >


          <div className={classes.playerWrapper}>
            <ReactPlayer
            config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate', disablepictureinpicture: 'true' } } }}
            // Disable right click
            onContextMenu={(e: any) => {
              e.preventDefault()
            }}
              width="100%"
              height="90%"
              url={introVideoURL}
              muted={true}
              playing={playing}
              controls={true}
              onReady={onReady}
              pip={false}
              
            />
            {isReady? 
            <div className={classes.controlWrapper}>


              <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
                 {
                    playing ?<PauseIcon onClick={handlePlayPause} style={{marginTop:'60px', cursor:'pointer', fontSize: '50px', color: 'white' }} /> 
                    : <PlayCircleFilledIcon onClick={handlePlayPause} style={{marginTop:'70px', cursor:'pointer', fontSize: '60px', color: 'white' }} />
                  }

               
              </Box>
              
            </div>
             :null
            }
           </div>
        </Box>
         </ThemeProvider>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  checkboxLabelStyle: {
    height: '14px',
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '14px',
  },
  btnWatchLater: {

    width: "235px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '0.5em',
    marginBottom: '1.2em',

  },


};

export default HgVideo;
// Customizable Area End
