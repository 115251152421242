import React, { useState, useCallback, useMemo } from "react";

import {
    // Customizable Area Start
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const CustomEnroll5File = ({ uploadFiles, studentFormData, purpose, heading, acceptType}: any) => {

  const [fileRecommendation, setFileRecommendation] = useState({
    name: '',
    size: '',
    type: '',
  })
  const [isError, setIsError] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsError(false)
      let sizeInMB = (Number(acceptedFiles[0]?.size) / (1024 * 1024)).toFixed(2);
      if (Number(sizeInMB) <= 5) {
        setFileRecommendation({ ...fileRecommendation, name: acceptedFiles[0].name, size: sizeInMB + 'MB', type: acceptedFiles[0].type })
        console.log("filesss accepteddd",acceptedFiles[0])
        uploadFiles(acceptedFiles[0], purpose)
      }
      else {
        setIsError(true)
      }
    },

    [],
  )

  const { getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isFocused,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptType
  })

  const style = useMemo(() => ({
    ...webStyle.fileStyle,
    ...(isFocused ? webStyle.focusedStyle : {})
  }), [

    isFocused
  ]);

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          {purpose === 'letter' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'pwd' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'psa' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'report' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'transcript' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'lrn' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'picture' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'solo' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'validId' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'achievements' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'chruch' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'income' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'DeathCertificate' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'BirthCertificate' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'familyDetails' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'electricBill' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'BirthCertificateParent' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}
          {purpose === 'BirthCertificateLuovaStaff' && <Typography style={webStyle.fileTextStyle}>{heading}</Typography>}

          <Box {...getRootProps({ style })}  >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon style={{ color: '#b1b1b1' }} />
            {!isDragActive && (<Typography style={webStyle.fileBoxTextStyle}>Drag and drop a file here or click</Typography>)}

            {isDragAccept && (<Typography style={webStyle.fileBoxAcceptedTextStyle}>This file will be accepted</Typography>)}

          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>

              {purpose === 'letter' && <Typography variant='subtitle2'> {studentFormData.fileLetterRecommendation?.filenm?.name}</Typography>
              }
             
              {
                purpose === 'pwd' && <Typography variant='subtitle2'> {studentFormData.filePwd?.filenm?.name}</Typography>
              }
              {
                purpose === 'psa' && <Typography variant='subtitle2'> {studentFormData.fileBirthCertificate?.filenm?.name}</Typography>
              }
              {
                purpose === 'report' && <Typography variant='subtitle2'> {studentFormData.fileReportCard?.filenm?.name}</Typography>
              }
              {
                purpose === 'transcript' && <Typography variant='subtitle2'> {studentFormData.fileSchoolRecords?.filenm?.name}</Typography>
              }
              {
                purpose === 'lrn' && <Typography variant='subtitle2'> {studentFormData.fileLearnerReference?.filenm?.name}</Typography>
              }
              {
                purpose === 'picture' && <Typography variant='subtitle2'> {studentFormData.filePicture?.filenm?.name}</Typography>
              }
              {
                purpose === 'solo' && <Typography variant='subtitle2'> {studentFormData.fileSoloParent?.filenm?.name}</Typography>
              }
              {
                purpose === 'validId' && <Typography variant='subtitle2'> {studentFormData.fileParentValid?.filenm?.name}</Typography>
              }
              {
                purpose === 'achievements' && <Typography variant='subtitle2'> {studentFormData.fileAchivementsProof?.filenm?.name}</Typography>
              }
              {
                purpose === 'chruch' && <Typography variant='subtitle2'> {studentFormData.fileChurchLetter?.filenm?.name}</Typography>
              }
              {
                purpose === 'income' && <Typography variant='subtitle2'> {studentFormData.fileIncomeProof?.filenm?.name}</Typography>
              }
              {
                purpose === 'DeathCertificate' && <Typography variant='subtitle2'> {studentFormData.fileFatherDeathCertificate?.filenm?.name}</Typography>
              }
              {
                purpose === 'BirthCertificate' && <Typography variant='subtitle2'> {studentFormData.fileStudentBirthCertificate?.filenm?.name}</Typography>
              }
              {
                purpose === 'familyDetails' && <Typography variant='subtitle2'> {studentFormData.fileFamilyDetails?.filenm?.name}</Typography>
              }
              {
                purpose === 'electricBill' && <Typography variant='subtitle2'> {studentFormData.fileElectricBill?.filenm?.name}</Typography>
              }
              {
                purpose === 'BirthCertificateLuovaStaff' && <Typography variant='subtitle2'> {studentFormData.fileBirthCertificateLuovaStaff?.filenm?.name}</Typography>
              }
              {
                purpose === 'BirthCertificateParent' && <Typography variant='subtitle2'> {studentFormData.fileParentBirthCertificateLuovaStaff?.filenm?.name}</Typography>
              }

            </Box>
            <Box>

              {purpose === 'letter' && !studentFormData.fileLetterRecommendation.filenm.name && studentFormData?.fileErrors?.fileLetterRecommendation && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileLetterRecommendation}</Typography>)}
              {purpose === 'pwd' && !studentFormData.filePwd.filenm.name && studentFormData?.fileErrors?.filePwd && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.filePwd}</Typography>)}
              {purpose === 'psa' && !studentFormData.fileBirthCertificate.filenm.name && studentFormData?.fileErrors?.fileBirthCertificate && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileBirthCertificate}</Typography>)}
              {purpose === 'report' && !studentFormData.fileReportCard.filenm.name && studentFormData?.fileErrors?.fileReportCard && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileReportCard}</Typography>)}
              {purpose === 'transcript' && !studentFormData.fileSchoolRecords.filenm.name && studentFormData?.fileErrors?.fileSchoolRecords && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileSchoolRecords}</Typography>)}
              {purpose === 'lrn' && !studentFormData.fileLearnerReference.filenm.name && studentFormData?.fileErrors?.fileLearnerReference && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileLearnerReference}</Typography>)}
              {purpose === 'picture' && !studentFormData.filePicture.filenm.name && studentFormData?.fileErrors?.filePicture && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.filePicture}</Typography>)}
              {purpose === 'solo' && !studentFormData.fileSoloParent.filenm.name && studentFormData?.fileErrors?.fileSoloParent && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileSoloParent}</Typography>)}
              {purpose === 'validId' && !studentFormData.fileParentValid.filenm.name && studentFormData?.fileErrors?.fileParentValid && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileParentValid}</Typography>)}
              {purpose === 'achievements' && !studentFormData.fileAchivementsProof.filenm.name && studentFormData?.fileErrors?.fileAchivementsProof && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileAchivementsProof}</Typography>)}
              {purpose === 'chruch' && !studentFormData.fileChurchLetter.filenm.name && studentFormData?.fileErrors?.fileChurchLetter && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileChurchLetter}</Typography>)}
              {purpose === 'income' && !studentFormData.fileIncomeProof.filenm.name && studentFormData?.fileErrors?.fileIncomeProof && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileIncomeProof}</Typography>)}
              {purpose === 'DeathCertificate' && !studentFormData.fileFatherDeathCertificate.filenm.name && studentFormData?.fileErrors?.fileFatherDeathCertificate && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileFatherDeathCertificate}</Typography>)}
              {purpose === 'BirthCertificate' && !studentFormData.fileStudentBirthCertificate.filenm.name && studentFormData?.fileErrors?.fileStudentBirthCertificate && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileStudentBirthCertificate}</Typography>)}
              {purpose === 'familyDetails' && !studentFormData.fileFamilyDetails.filenm.name && studentFormData?.fileErrors?.fileFamilyDetails && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileFamilyDetails}</Typography>)}
              {purpose === 'electricBill' && !studentFormData.fileElectricBill.filenm.name && studentFormData?.fileErrors?.fileElectricBill && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileElectricBill}</Typography>)}
              {purpose === 'BirthCertificateLuovaStaff' && !studentFormData.fileBirthCertificateLuovaStaff.filenm.name && studentFormData?.fileErrors?.fileBirthCertificateLuovaStaff && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileBirthCertificateLuovaStaff}</Typography>)}
              {purpose === 'BirthCertificateParent' && !studentFormData.fileParentBirthCertificateLuovaStaff.filenm.name && studentFormData?.fileErrors?.fileParentBirthCertificateLuovaStaff && (<Typography style={webStyle.errorStyle}>{studentFormData?.fileErrors?.fileParentBirthCertificateLuovaStaff}</Typography>)}


              {isError && purpose !== 'picture' && (<Typography style={webStyle.errorStyle}>Error: Only .pdf/.docx/.jgeg/.png files supported. File should not be more than 5MB.</Typography>)}
              {isError && purpose === 'picture' && (<Typography style={webStyle.errorStyle}>Error: Only jpeg file is supported. File should not be more than 5MB.</Typography>)}

            </Box>
          </Box>

        </Grid>
      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  fileTextStyle: {
    marginBottom: '10px',
    color: '#555555',
    fontFamily: 'Roboto;',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },

  fileStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    cursor: 'pointer',
    border: '2px dashed #eeeeee',
  },

  focusedStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: '#f2f2f2',
    width: '100%',
    cursor: 'pointer',
    border: '2px dashed #eeeeee',
  },

  fileBoxTextStyle: {
    color: '#b1b1b1',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  errorStyle: {
    color: 'red',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },

  fileBoxAcceptedTextStyle: {
    color: '#00e676',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  
};

export default CustomEnroll5File;
// Customizable Area End
