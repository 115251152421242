export const logo = require("../assets/logo.png");
export const avatar = require("../assets/avatar.png");
export const Backgroundimg = require("../assets/Backgroundimg.png");
export const HomeSchoolRecomender = require("../assets/HomeSchoolRecomender.png");
export const mother_daughter = require("../assets/mother_daughter.png");
export const bju = require("../assets/bju.png");
export const aop_custom = require("../assets/aop_custom.png");
export const classical = require("../assets/classical.png");
export const mason = require("../assets/mason.png");
export const online_academy1 = require("../assets/online_academy1.png");
export const online_academy2 = require("../assets/online_academy2.png");
export const acc1 = require("../assets/acc1.png");
export const acc2 = require("../assets/acc2.png");
export const acc3 = require("../assets/acc3.png");
export const acc4 = require("../assets/acc4.png");
export const partner_school1 = require("../assets/partner_school1.png");
export const partner_school2 = require("../assets/partner_school2.png");
export const partner_school3 = require("../assets/partner_school3.png");
export const robot = require("../assets/robot.png");
export const call_bubble = require("../assets/call_bubble.png");
export const chat_bubble = require("../assets/chat_bubble.png");
export const homeschooler = require("../assets/homeschooler.png");
export const questions = require("../assets/questions.png");
export const backgroundTwoHgFormula = require("../assets/backgroundTwoHgFormula.png");
export const backgroundOneHgFormula_new = require("../assets/backgroundOneHgFormula_new.png");
export const plus = require("../assets/plus.png");
export const img_bkg = require("../assets/img_bkg.png");
export const img_bkg2 = require("../assets/img_bkg2.png");
export const img_bkg3 = require("../assets/img_bkg3.png");
export const img_bkg4 = require("../assets/img_bkg4.png");
export const img_bkg5 = require("../assets/img_bkg5.png");
export const hg_img1 = require("../assets/hg_img1.png");
export const border_img1 = require("../assets/border_img1.png");
export const orange_bulb = require("../assets/orange_bulb.png");
export const purple_bulb = require("../assets/purple_bulb.png");
export const green_bulb = require("../assets/green_bulb.png");
export const Isolated_white_book_cover = require("../assets/Isolated_white_book_cover.png");
export const HGPH_InfoPack_Cover = require("../assets/HGPH_InfoPack_Cover.png");
export const Chapters = require("../assets/Chapters.png");
export const mission = require("../assets/mission.png");
export const vision = require("../assets/vision.png");
export const alumni1 = require("../assets/alumni1.png");
export const alumni2 = require("../assets/alumni2.png");
export const alumni3 = require("../assets/alumni3.png");
export const alumni4 = require("../assets/alumni4.png");
export const alumni5 = require("../assets/alumni5.png");
export const alumni6 = require("../assets/alumni6.png");
export const alumni7 = require("../assets/alumni7.png");
export const alumni8 = require("../assets/alumni8.png");
export const alumni9 = require("../assets/alumni9.png");
export const alumni10 = require("../assets/alumni10.png");
export const alumni11 = require("../assets/alumni11.png");
export const orientationvideo_bkg = require("../assets/orientationvideo_bkg.png");
export const cover = require("../assets/cover.png");
export const introvideo_thumbnail = require("../assets/introvideo_thumbnail.png");
