import React, { Component } from "react";

import {
    // Customizable Area Start
  Box,
  InputLabel,
  Typography,
  TextField,
  Grid,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiMenu: {
    paper: {
      maxHeight: 'calc(50% - 96px)',
     },
  },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);

export default class CustomEnroll3 extends Component<any> {
  render() {
    const { studentFormData, 
      hubDataChange, gradesDataChange,
      programDetailprogramSelectedData,
      programDetailprogramSelectedDataMinistry,
      programDataChange,
      programDetailcommunitiesSelectedData, communitiesDataChange,
       accreditationsDataChange,
      hideError, handleChangeProgramDetailsShsTrack,
      programLevelHubData,programLevelCycleData,cycleDataChange,programLevelAccData,programLevelGradesData,
      programLevelSHSData
    } = this.props;

    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Typography style={webStyle.heading} >Program Details</Typography>
         <Grid container spacing={4} xs={12} style={{padding:'2em'}}>
          {/* -----------------------program starts---------------------------------- */}
          <Grid item xs={12} md={6}>
             
             <InputLabel style={webStyle.inputLabelStyle}>Program *</InputLabel>
             <StyledTextField
               select
               color="darkgrey"
               fullWidth={true}
               style={{ color: "darkgrey" }}
               name="programDetailsProgram"
               value={studentFormData.programDetailsProgram.value}
               onChange={(e:any)=>programDataChange(e)}
             >
               <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
               
               {
                 studentFormData.studentTypesOfScholarShip.value === "The Ministry Leaders Assistance"?
                 programDetailprogramSelectedDataMinistry?.map((item: any) => {

                   return (
                     <MenuItem
                       key={item?.id}
                       value={item?.program_name}
                       selected={item.value == studentFormData.programDetailsProgram.value}
                     >
                       {item?.program_name}
                     </MenuItem>
                   )
                 })
                 :programDetailprogramSelectedData?.map((item: any) => {

                   return (
                     <MenuItem
                       key={item?.id}
                       value={item?.program_name}
                       selected={item.value == studentFormData.programDetailsProgram.value}
                     >
                       {item?.program_name}
                     </MenuItem>
                   )
                 })
               }
             
             </StyledTextField>
 
           <Box style={{marginTop:'3px'}}>
           {
             (studentFormData.programDetailsErrors.programDetailsProgram)
               ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsProgram}</small>
               : <small style={{ visibility: 'hidden' }}>""</small>
           }
         </Box>

         </Grid>
            {/* -----------------------program ends---------------------------------- */}
          {/* -----------------------Hub starts---------------------------------- */}
            <Grid item xs={12} md={6}>
                <InputLabel style={webStyle.inputLabelStyle}>Hub *</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="programDetailsHub"
                  value={studentFormData.programDetailsHub.value}
                  onChange={(e:any)=>hubDataChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                  {

                    programLevelHubData?.map((item: any) => {
                      return (
                        <MenuItem
                          key={item}
                          value={item}
                          selected={item == studentFormData.programDetailsHub.value}
                        >
                          {item}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>

              <Box style={{marginTop:'3px'}}>
              {
                (studentFormData.programDetailsErrors.programDetailsHub)
                  ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsHub}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>

            </Grid>
              {/* -----------------------Hub ends---------------------------------- */}

              {/* -----------------------community starts---------------------------------- */}
            <Grid item xs={12} md={6}>
              
                <InputLabel style={webStyle.inputLabelStyle}>Community *</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="programDetailsCommunity"
                  value={studentFormData.programDetailsCommunity.value}
                  onChange={(e:any)=>communitiesDataChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                  {

                    programDetailcommunitiesSelectedData?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item?.id}
                          value={item?.community_name}
                          selected={item.value == studentFormData.programDetailsCommunity.value}
                        >
                          {item?.community_name}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>

              <Box style={{marginTop:'3px'}}>
              {
                (studentFormData.programDetailsErrors.programDetailsCommunity)
                  ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsCommunity}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>

            </Grid>
             {/* -----------------------community ends---------------------------------- */}
              {/* -----------------------cycle starts---------------------------------- */}
            <Grid item xs={12} md={6}>
             <InputLabel style={webStyle.inputLabelStyle}>Cycle *</InputLabel>
             <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="programDetailsCycle"
                  value={studentFormData.programDetailsCycle}
                  onChange={(e:any)=>cycleDataChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                  {
                      programLevelCycleData?.map((item: any) => {
                      return (
                        <MenuItem
                          key={item}
                          value={item}
                          selected={item == studentFormData.programDetailsCycle}
                        >
                          {item}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>   
           
            <Box style={{marginTop:'3px'}}>
            {
              (studentFormData.programDetailsErrors.programDetailsCycle)
                ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsCycle}</small>
                : <small style={{ visibility: 'hidden' }}>""</small>
            }
          </Box>
          </Grid>
             {/* -----------------------cycle ends ---------------------------------- */}
              {/* -----------------------accredidation starts---------------------------------- */}
            <Grid item xs={12} md={6}>
              
              <InputLabel style={webStyle.inputLabelStyle}>Accreditation *</InputLabel>
              <StyledTextField
                select
                color="darkgrey"
                fullWidth
                name="programDetailsAccreditation"
                value={studentFormData.programDetailsAccreditation.value}
                onChange={(e:any)=>accreditationsDataChange(e)}
              >
                <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                {
                   programLevelAccData?.map((item: any) => {
                    return (

                      <MenuItem
                        key={item}
                        value={item}
                        selected={item == studentFormData.programDetailsAccreditation.value}
                      >
                        {item}
                      </MenuItem>
                    )

                  })

                }
              </StyledTextField>
           

            <Box style={{marginTop:'3px'}}>
            {
              (studentFormData.programDetailsErrors.programDetailsAccreditation)
                ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsAccreditation}</small>
                : <small style={{ visibility: 'hidden' }}>""</small>
            }
          </Box>
         
          </Grid>
             {/* -----------------------accreditation ends---------------------------------- */}
               {/* -----------------------grades starts---------------------------------- */}
            <Grid item xs={12} md={6}>
             
                <InputLabel style={webStyle.inputLabelStyle}>Incoming Grade Level *</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="programDetailsIncomingGradeLevel"
                  value={studentFormData.programDetailsIncomingGradeLevel.value}
                  onChange={(e:any)=>gradesDataChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                  {

                      programLevelGradesData?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item}
                          value={item}
                          selected={item == studentFormData.programDetailsIncomingGradeLevel.value}
                        >
                          {item}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>
             

              <Box style={{marginTop:'3px'}}>
              {
                (studentFormData.programDetailsErrors.programDetailsIncomingGradeLevel)
                  ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsIncomingGradeLevel}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>

            </Grid>
               {/* -----------------------grades ends---------------------------------- */}

               {/* -----------------------ssh track starts ---------------------------------- */}

           
            <Grid item xs={12} md={6}>
             
            </Grid>

            {programLevelSHSData.length>0 ?
              <Grid item xs={12} md={6}>

                <InputLabel style={webStyle.inputLabelStyle}>SHS Track  *</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="programDetailsShsTrack"
                  value={studentFormData.programDetailsShsTrack}
                  onChange={(e:any)=>handleChangeProgramDetailsShsTrack(e)}
                  onFocus={hideError}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>please select</span></MenuItem>
                   {

                      programLevelSHSData?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item}
                          value={item}
                          selected={item == studentFormData.programDetailsShsTrack}
                        >
                          {item}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>
                <Box style={{marginTop:'3px'}}>
              {
                (studentFormData.programDetailsErrors.programDetailsShsTrack)
                  ? <small style={webStyle.errorStyle}>{studentFormData.programDetailsErrors.programDetailsShsTrack}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
              </Grid> : null}
                      {/* -----------------------ssh track ends---------------------------------- */}

          </Grid>
        {/* </Box> */}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  StudentMenuItems:{
    display: "none", color: 'darkgray' 
  },
  heading: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "1em",
    paddingLeft:'1.4em'

  },
  inputLabelStyle: {
    marginBottom: '10px',
    height: '22px',
    color: "#a1a1a1",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',

  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  }
};
// Customizable Area End
