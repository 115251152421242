

import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    AppBar,
    Toolbar,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logo, avatar } from './assets';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';




const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End
      // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
     // Customizable Area End
export default class Checkoutsucess6 extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>

                    <AppBar position="fixed">
                        <Toolbar style={{ justifyContent: 'space-between' }}>
                            <Box style={{ width: '20%' }}>
                                <img src={logo} alt='logo image' style={webStyle.imgLogoStyle} />
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'end', textAlign: 'end', justifyContent: 'space-between' }}>
                                <Avatar alt="client avatar" src={avatar} style={webStyle.avatarStyle} />
                                <Typography style={{ color: '#000' }}>Client Name</Typography>
                                <Box><ArrowDropDownIcon style={{ color: '#92929d' }} /></Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth={false} style={{ margin: '0 auto', width: '95%' }}>

                        <Container maxWidth={false} style={{ margin: '0 auto' }}>
                            <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            </Box>


                            <Container maxWidth={false} style={webStyle.GridWrapper}>
                                <Box style={webStyle.paymentSucessAlign}>
                                    <Typography align='center' style={webStyle.paymentSucessMessage}>Payment Success</Typography>
                                    <Box style={{ textAlign: "center", paddingTop: '1.5em' }}>
                                        <CheckCircleIcon style={webStyle.CheckCircle} />
                                    </Box>
                                    <Typography align='center' style={webStyle.PaymentMessage}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation</Typography>
                                </Box>
                                <Box style={{
                                    textAlign: "center",
                                    paddingBottom: '4em'
                                }}>
                                    <Button style={{
                                        textAlign: "center", textTransform: "none", ...webStyle.DashBoardDayInLifeVideo
                                    }} onClick={() => { this.props.navigation.navigate("DashBoardDayInLifeVideo") }}>Continue</Button>
                                </Box>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    paymentSucessAlign: {
        paddingTop: "4em",
        paddingLeft: '4em',
        paddingRight: '4em',
        paddingBottom: '2.5em'
    },
    paymentSucessMessage: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "28px",
        fontWeight: 600
    },
    CheckCircle: {
        width: '42px',
        height: '42px',
        color: '99cb51'
    },
    PaymentMessage: {
        paddingTop: '1.5em',
        color: "#555555",
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: 500,
    },
    DashBoardDayInLifeVideo: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#9167ac",
        width: '14em'
    },
    imgLogoStyle: {
        width: '100%',
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",

        marginTop: "2em",

    },
    avatarStyle: {
        width: '32px',
        height: '32px',
        marginRight: '10px'
    }
};
// Customizable Area End
