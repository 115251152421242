import React, { useState, useEffect } from "react";

import {
    // Customizable Area Start
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import Checkbox from "@material-ui/core/Checkbox";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiInput: {
      underline: {

        " &:before": {
          borderBottom: 'none',
          transition: 'none',
          content: 'none',
        },

      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      //  label:{
      //   alignItems: 'flex-start',
      //   justifyContent: 'flex-start'
      //   },
    },
    MuiCardActions: {
      root: {
        padding: '0px',
      }
    },


    MuiIconButton: {
      root: {
        color: '#fff',
        padding: '0px',
        transition: 'none',
      },
    },
    MuiSvgIcon: {
      root: {

        fontSize: '1.3rem',

      }
    },

    MuiContainer: {

      // maxWidthSm: {
      //     maxWidth: '600px !important'
      // },
      // maxWidthMd: {
      //   maxWidth: '1010px !important'
      // },

    }
  },
});

const StudentsListCard = ({ 
  openPopover,
  popoverAnchor,
  handleClosePopover,
  avatar,
  handlerView,
  fullname,
  program,
  submitted,
  schoolyear,
  status,
  grade,
  studentID,
  studId,
  pendingPay,
   btnCheckedPendingPayment,btnRejected,
   multipleStudID,
   snackbarOpenError,snackbarMessage,handleCloseSnackbar,
   handlerEdit
  }: any) => {

 
   const [checkedState , setCheckedState] = useState(false)
   const [checkedValue , setCheckedValue] = useState('')

   useEffect(() => {
    if(snackbarOpenError){
      setCheckedState(false)
    }
      }, [snackbarOpenError])
 
      return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>

        {/* <Card variant="outlined"> */}
          <Card variant="outlined" style={webStyle.cardContainerStyle}>
          <Grid container style={webStyle.cardGridContainerStyle}>
            {/* ---------------grid container starts ----------------------- */}
{
  pendingPay == 'Pending for payment'? 
  <>
  <Grid item md={1}>
              <CardActions style={{ display: 'flex', alignItems: 'flex=start',justifyContent: 'center' }}>
                <Checkbox
                  color="default"
                  checked={checkedState}
                  value={`the value is ${studentID}`}
                   inputProps={{ 'aria-label': 'checkbox with default color' }}
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCheckedState(e.target.checked)
                  setCheckedValue(e.target.value)
                   multipleStudID(e.target.checked, studentID)
                  }}
                />
              </CardActions>
              
            </Grid>
  </>
  :null
}

  {/* <Grid item md={1}>
              <CardActions style={{ display: 'flex', alignItems: 'flex=start',justifyContent: 'center' }}>
                <Checkbox
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
              </CardActions>
            </Grid> */}
            
            <Grid item md={3}>
              <CardContent>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                  <Box>
                    <Typography style={webStyle.cardInnerTextHeader}>{fullname}</Typography>
                    <Typography style={webStyle.cardInnerTextSubText}> {grade}</Typography>
                    {/* <Typography style={webStyle.cardInnerTextAuthor}>Student advisor:Jhon</Typography> */}
                  </Box>
                </Box>
              </CardContent>
            </Grid>

            <Grid item md={1}>
              <CardContent>
                <Box>
                  <Typography style={webStyle.cardInnerTextHeader}>Program</Typography>
                  <Typography style={webStyle.cardInnerTextSubText}>{program}</Typography>
                </Box>
              </CardContent>
            </Grid>

            <Grid item md={2}>
              <CardContent style={{ display: 'flex', alignItems: 'flex=start',justifyContent: 'center'}}>
                <Box>
                  <Typography style={webStyle.cardInnerTextHeader}>School Year</Typography>
                  <Typography style={webStyle.cardInnerTextSubText}>{schoolyear}</Typography>
                </Box>
              </CardContent>
            </Grid>

            <Grid item md={2}>
              <CardContent>
                <Box>
                  <Typography style={webStyle.cardInnerTextHeader}>Submitted</Typography>
                  <Typography style={webStyle.cardInnerTextSubText}>{submitted}</Typography>

                </Box>
              </CardContent>
            </Grid>


            <Grid item md={2}>
              <CardActions>
                {
                  pendingPay=='Pending for payment' &&
                <>
                <Button style={{ textTransform: 'none', backgroundColor: "#64b7e2", ...webStyle.btnStatus}}
                  onClick={() => btnCheckedPendingPayment(checkedValue,checkedState,studentID, avatar, fullname, program)}
                  >
                  Pending for payment</Button>
                 {/* <Button style={{ textTransform: 'none', ...webStyle.btnStatus}}>{status}</Button> */}
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={snackbarOpenError} autoHideDuration={4000} onClose={handleCloseSnackbar} >
                    <Alert severity="error">
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                  
                </>
                } 
                {
                  pendingPay=='Rejected' &&
                <>
                <Button style={{ textTransform: 'none', backgroundColor: "#fb5555", ...webStyle.btnStatus}}
                 onClick={() =>btnRejected(studentID)}
                 >
                  Rejected</Button>
                 </>
                } 
           
              </CardActions>
            </Grid>

            <Grid item md={1}>
              <CardActions style={{ display: 'flex', alignItems: 'flex=start',justifyContent: 'center'}}>
                {/* <IconButton onClick={()=>console.log(studentID)}> */}
                <IconButton onClick={(e: any) => openPopover(e, studentID)}>
                  <MoreHorizIcon style={{ color: '#92929d' }} />
                </IconButton>
                <Popover
                  elevation={1}
                  open={Boolean(popoverAnchor)}
                  anchorEl={popoverAnchor}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  <Box style={webStyle.popoverBoxStyle}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', border: 'none' }}>
                      <Button size='small' variant='text' style={webStyle.popoverButTextStyle} 
                      onClick={() => handlerView(studId)}>
                        View
                      </Button>
                      <Typography align="right" style={webStyle.btnCross} onClick={handleClosePopover}>
                        x
                      </Typography>
                    </Box>
                    <Button size='small' variant='text' style={webStyle.popoverButTextStyle} onClick={() => handlerEdit(studId)}>Edit</Button>
                    <Button size='small' variant='text' style={webStyle.popoverButTextStyle} >Reenroll</Button>

                  </Box>
                </Popover>


              </CardActions>
            </Grid>
            {/* ---------------------- grid containr ends--------------------- */}
          </Grid>
        </Card>
      </ThemeProvider>
      // Customizable Area End
    );
  

}

// Customizable Area Start
const webStyle = {
  popoverBoxStyle: {
    display: 'block',
    alignItems: 'flex-start',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '120px'
  },

  popoverButTextStyle: {
    color: '#1b1b1b',
    fontFamily: 'Roboto',
    fontSize: ' 14px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  cardContainerStyle: {
    margin: '10px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-evenly',
    // flexWrap: 'wrap' as 'wrap',
    // borderSizing: 'border-box'
  },
  cardGridContainerStyle: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderSizing: 'border-box'
  },

  cardAvatarStyle: {
    width: '42px',
    height: '42px',
    marginRight: '10px'
  },
  cardInnerTextHeader: {
    // height: '24px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    // fontSize: '14px',
    fontWeight: 600,
    // lineHeight: '24px',
  },
  cardInnerTextSubText: {
    // height: '17px',
    color: ' #92929d',
    fontFamily: 'Roboto',
    fontSize: '0.8vw',
    // fontSize: '12px',
    fontWeight: 400,
    //letterSpacing: '0.1px',
    // lineHeight: '17px',
  },
  cardInnerTextAuthor: {
    // height: '17px',
    color: ' #4bc0cf',
    fontFamily: 'Roboto',
    fontSize: '0.7vw',
    // fontSize: '11px',
    fontWeight: 400,
    textDecoration: 'underline',
    letterSpacing: '0.1px',
    // lineHeight: '17px',
  },
btnStatus:{
     width: '14em',
    height: '3em',
    borderRadius: '10px',
    border: "none",
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    // fontSize: '0.8vw',
    padding: '10px',
    backgroundBlendMode: 'normal',
},
btnCross:{
  cursor: 'pointer',
  color: '#1b1b1b',
  fontFamily: 'Poppins',
  fontSize: ' 14px',
  padding: '0',
  fontWeight: 700,
},
};


export default StudentsListCard;


// Customizable Area End
