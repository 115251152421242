// Customizable Area Start
import React from "react";
// Customizable Area End
import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  IconButton,
  TextField,
  Grid,
  MenuItem,
  Paper,
  CardMedia,
  Modal,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import DashboardController from "./DashboardController";
// Customizable Area End
// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import Drawer from "../../../components/src/Drawer.web";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import ChatGlobal from "../../../components/src/ChatGlobal.web"
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import TestimonialVideos1 from "../../../components/src/TestimonialVideos1.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Backgroundimg,
  HomeSchoolRecomender,
  mother_daughter,
  bju,
  aop_custom,
  classical,
  mason,
  online_academy1,
  online_academy2,
  questions


} from './assets';
import HgVideo from "../../../components/src/HgVideo.web";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: 'calc(50% - 96px)',
        top: '280px'
      },
    },
  },
});

const StyledTextField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',
      height: '19px',
      color: '#44444f'

    },
  },

})((props: any) => <TextField {...props} />);

// Customizable Area End


export default class DashBoardDayInLifeVideo extends DashboardController {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Grid container>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em', marginRight: '1em' }
                }
                maxWidth={false}
              >
                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}
                <Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>

                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Welcome {this.state?.userData?.fullName}</Typography>
                    {
                      !this.state.userData?.isActive && this.state.userData?.isActive !== '' ?
                        <Typography style={webStyle.verifyTextStyle} >Verify your Email Address to continue with enrollment!</Typography>
                        : null
                    }

                    <Button style={webStyle.btnEnrollNow}
                      onClick={this.handleEnroll} >ENROLL NOW </Button>
                  </Box>
                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>

                  <Container maxWidth={false} style={{ padding: '0' }}>
                    {/* ----------------------------------------- FIRST  GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "flex-start", marginTop: '2em' }}>

                      {/* ------------------------------------------------- PROGRAMS grid ---------------------------------------- */}
                      <Grid item xs={12} lg={7} >
                        <Typography align='left' style={webStyle.headerTextStyle}>Our Programs</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerProgram }}  >

                          <Grid container style={{ display: 'flex', alignItems: 'stretch', boxSizing: 'border-box', padding: '0.5vw' }}>
                            {/* --------------- orange grid ----------------- */}
                            {/* <Grid item xs={12} sm={4} style={{ backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5vw', border: '2px solid #fab03d', borderRadius: '15px' }}> */}

                            <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5vw', border: '2px solid #fab03d', borderRadius: '15px' }}>

                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Touch</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with comprehensive advisor support
                                  involving parental instruction
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={mother_daughter} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Curated</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum with comprehensive support and guidance from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={mason} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Charlotte Mason </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes)}>
                                <Avatar alt="program avatar" src={classical} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Classical</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Classical Christian curriculum
                                      with comprehensive support
                                      and guidance through weekly
                                      community classes, powered
                                      by Classical Conversations
                                      Philippines</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                            {/* </Grid> */}

                            {/* --------------- purple grid ----------------- */}

                            {/* <Grid item xs={12} sm={4} style={{  backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #B98ED6', borderRadius: '15px' }}> */}
                            <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #B98ED6', borderRadius: '15px' }} >
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Tech</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with platform- driven essential advisor support involving parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={bju} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>BJU (Blended)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      High-quality blended curriculum with essential Advisor support</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={aop_custom} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>AOP (Fully Online) </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>High-quality fully online
                                      curriculum with essential
                                      Advisor support</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes)}>
                                <Avatar alt="program avatar" src={aop_custom} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Custom (Open)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable curriculum
                                      with platformdriven essential
                                      Advisor support and basic
                                      academic assistance for
                                      records designed for
                                      home schooling families 3
                                      years and up</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                            {/* </Grid> */}
                            {/* --------------- green grid ----------------- */}
                            {/* <Grid item xs={12} sm={4} style={{  backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #8FBE49', borderRadius: '15px' }}> */}
                            <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #8FBE49', borderRadius: '15px' }}>
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Online Academies</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Flexible online learning with teacher
                                  supervision, grading, and optional
                                  academic classes involving minimal
                                  parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={online_academy1} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum
                                      with comprehensive
                                      support and guidance
                                      from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={online_academy2} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy Plus </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>

                            </Grid>
                            {/* </Grid> */}
                          </Grid>


                        </Paper>
                      </Grid>

                      {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}
                      <Modal
                        open={this.state.openModalProgramVideo}
                      // onClose={this.handleClose}
                      >
                        <Box style={{ ...webStyle.modalStyle, height: "70%" , width:"44%", top:"55%" }}>
                          <IconButton onClick={this.handleCloseProgramVideo} style={{ marginTop: '-20px', marginLeft: '-30px' }}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                              <small>x</small>
                            </Box>
                          </IconButton>
                          <Typography align='left' style={{ ...webStyle.ProgramInnerTextHeader, fontWeight: 500, fontSize: "18px", marginLeft: "25px", paddingLeft: "1rem", color: " rgb(23, 23, 37)" }}>
                            {this.state.selectedProgramTitle} - {this.state.selectedProgramDetails?.program_name}
                          </Typography>
                          <Box style={{ width: '90%', height: '80%', margin: "auto", position: 'relative', padding: '1em', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                            <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'center', }}>
                              <HgVideo introVideoURL={this.state.selectedProgramDetails?.program_video} />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>

                      {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}

                      {/* ------------------------------------------------- HOME RECOMMENDER grid ---------------------------------------- */}
                      <Grid item xs={12} lg={5}  >

                        <Typography align='left' style={webStyle.headerTextStyle}>Homeschool Recommender</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerRecommender }} >
                          <Box style={webStyle.boxOrientation}>
                            <CardMedia
                              component="img"
                              image={HomeSchoolRecomender}
                              title="HomeSchoolRecomender"
                              style={{ height: '70%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '1.2em' }}

                            />
                            <Button
                              variant='contained'
                              style={{ textTransform: "none", ...webStyle.btnRecommendation }}
                              data-test-id='btnHomeRecomm'
                              onClick={() => this.props.navigation.navigate('HomeschoolButtons')}
                            >Get your Personalised Recommendation</Button>
                          </Box>

                        </Paper>

                      </Grid>
                    </Grid>


                    {/* ----------------------------------------- SECOND GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch" }}>

                      {/* ------------------------------------------------- Day in the Life of Homeschooler grid ---------------------------------------- */}

                      <Grid item xs={12} lg={7}  >
                        <Typography align='left' style={webStyle.headerTextStyle}>Day in the Life of Homeschooler</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerTestimonial }}  >

                          <Box style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                            {this.state?.allVideos_homeSchoolerVideoURL ?
                              <> <TestimonialVideos1 VideoURL={this.state?.allVideos_homeSchoolerVideoURL} />
                                <Box style={webStyle.boxGreenHomeSchooler}>
                                  <Typography align='center' style={webStyle.btnHomeSchoolerText}>{this.state?.allVideos_homeSchoolerVideoName}</Typography>
                                  <Typography align='center' style={webStyle.btnHomeSchoolerSubText}>Home Schooler</Typography>
                                </Box>
                              </>
                              :
                              <Box style={webStyle.circularLoader}> <CircularProgress /></Box>
                            }
                          </Box>
                        </Paper>
                      </Grid>

                      {/* ------------------------------------------------- Fees Calculator ---------------------------------------- */}
                      <Grid item xs={12} lg={5}  >

                        <Typography align='left' style={webStyle.headerTextStyle}>Fees Calculator</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerFeesCalc }} >

                          <Grid container style={{ boxSizing: 'border-box', padding: '2em' }}>
                            {/* ---------------- form starts --------------------- */}
                            {/* ---------------- grade --------------------- */}
                            <Grid item xs={12} >
                              <Box style={webStyle.inputBoxStyle}>
                                <InputLabel style={webStyle.inputSelectLabelStyle}>Grade Level * </InputLabel>
                                <StyledTextField
                                  select
                                  required
                                  InputLabelProps={{ shrink: false }}
                                  fullWidth={true}
                                  name='grade'
                                  value={this.state.grade}
                                  onChange={this.gradeChangeHandler}
                                  data-test-id="selectGradeDropdown"
                                >

                                  <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgray" }}>Select Grade Level</span></MenuItem>
                                  {
                                    this.state.gradeSelectedData?.map((item: any, index: number) => (
                                      <MenuItem
                                        key={item?.id + index}
                                        value={item?.grade}
                                      >
                                        {item?.grade}
                                      </MenuItem>
                                    ))
                                  }

                                </StyledTextField>
                              </Box>
                              <Box>
                                {
                                  this.state.calculateErrors.grade
                                    ? <small style={webStyle.errorStyle}>{this.state.calculateErrors.grade}</small>
                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                }
                              </Box>
                            </Grid>

                            {/* ---------------- program --------------------- */}

                            <Grid item xs={12} >
                              <Box style={webStyle.inputBoxStyle}>
                                <InputLabel style={webStyle.inputSelectLabelStyle}>Program * </InputLabel>
                                <StyledTextField
                                  select
                                  required
                                  InputLabelProps={{ shrink: false }}
                                  fullWidth={true}

                                  name='program'
                                  value={this.state.program}
                                  onChange={this.programChangeHandler}
                                  data-test-id="selectProgramDropdown"
                                >

                                  <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgrey" }}>Please select</span></MenuItem>
                                  {
                                    this.state.programSelectedApiData?.map((item: any, index: number) => (
                                      <MenuItem
                                        key={item?.id + index}
                                        value={item?.program_name}

                                      >
                                        {item?.program_name}
                                      </MenuItem>
                                    ))
                                  }

                                </StyledTextField>
                              </Box>
                              <Box>
                                {
                                  this.state.calculateErrors.program
                                    ? <small style={webStyle.errorStyle}>{this.state.calculateErrors.program}</small>
                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                }
                              </Box>
                            </Grid>



                            {/* ---------------- accredication --------------------- */}

                            <Grid item xs={12} >
                              <Box style={webStyle.inputBoxStyle}>
                                <InputLabel style={webStyle.inputSelectLabelStyle}>Accreditation * </InputLabel>
                                <StyledTextField
                                  select
                                  required
                                  InputLabelProps={{ shrink: false }}
                                  fullWidth={true}
                                  name='accreditation'
                                  value={this.state.accreditation}
                                  onChange={this.accredationChangeHandler}
                                  data-test-id="selectAccreditationDropdown"
                                >

                                  <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgrey" }}>Please select</span></MenuItem>
                                  {
                                    this.state.accreditationSelectedApiData?.map((item: any, index: number) => (
                                      <MenuItem
                                        key={item?.id + index}
                                        value={item?.name}
                                      >
                                        {item?.name}
                                      </MenuItem>
                                    ))
                                  }
                                </StyledTextField>
                              </Box>
                              <Box>
                                {
                                  this.state.calculateErrors.accreditation
                                    ? <small style={webStyle.errorStyle}>{this.state.calculateErrors.accreditation}</small>
                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                }
                              </Box>
                            </Grid>

                            {/* ---------------Display calculated Fees -------------------- */}
                            {
                              !this.state.feeCalculatorLoader && this.state.toggleFeeCaculator ?
                                <>
                                  <Grid item xs={12}>
                                    <Typography>The Fees is :  </Typography>
                                  </Grid>
                                </>
                                :
                                <>
                                  <Grid item xs={12}>
                                    <Typography style={{ visibility: 'hidden' }}>"" </Typography>
                                  </Grid>
                                </>

                            }

                            {/* ---------------button-------------------- */}
                            <Grid item xs={12}>
                              <Box style={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                                <Button
                                  variant='contained'
                                  style={{ textTransform: "none", ...webStyle.btnQuestions }}
                                  onClick={this.feeCalculatorHandler}
                                  data-test-id='btnFeeCalc'
                                > Calculate  </Button>
                              </Box>
                            </Grid>


                            {/* ----------------form ends-------------------- */}





                          </Grid>
                        </Paper>

                      </Grid>

                    </Grid>


                    {/* ----------------------------------------- THIRD  GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                      {/* ------------------------------------------------- Got more questions? Meet the HG Community grid ---------------------------------------- */}
                      <Grid item xs={12} lg={7}  >
                        <Typography align='left' style={webStyle.headerTextStyle}>Got more questions? Meet the HG Community</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerQuestions }}  >

                          <Box style={{ width: '100%', minHeight: '15em' }}>
                            <img src={questions} style={{ objectFit: 'cover', width: '100%', height: '100%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} />
                          </Box>

                        </Paper>
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button variant='contained' style={{ textTransform: "none", ...webStyle.btnQuestions }}>
                            <a style={webStyle.btnQuestionsLink} target='_blank' href='https://www.tickettailor.com/events/homeschoolgloballearninginc'>Register for free</a>
                          </Button>
                        </Box>


                      </Grid>


                      {/* ------------------------------------------------- CHATBOT grid ---------------------------------------- */}
                      {/* <Grid item xs={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >


                      <Grid item xs={12} sm={6} style={{ padding: '0.6em', border: '1px solid #f1f1f5', boxShadow: '0px 5px 15px rgba(68,68,79,0.1' }}>
                        <IconButton style={{ marginTop: '-30px', marginLeft: '-30px' }}>
                          <Box style={{ borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '25px', height: '25px' }}>
                            <small>x</small>

                          </Box>
                        </IconButton>
                        <Box >
                          <Typography style={webStyle.chatText}>Got questions about HG or any of our programs</Typography>
                        </Box>
                        <hr></hr>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                          <img src={robot}></img>
                          <Button variant='contained' style={{ textTransform: 'none', ...webStyle.btnChat }}>Chat Now</Button>
                        </Box>



                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <IconButton style={{ marginRight: '0.5em' }}>
                          <img src={chat_bubble}></img>
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <IconButton style={{ marginLeft: '0.5em' }}>
                          <img src={call_bubble}></img>
                        </IconButton>
                      </Grid>

                    </Grid> */}
                      {ChatGlobal()}
                    </Grid>

                  </Container>
                </Container>
              </Container>
            </Grid>
          </Grid>

        </Container>
        {/* -------------------------------------------- modal verify email ------------------------------------------------------------- */}
        <Modal
          open={this.state.openverify}
        // onClose={this.handleClose}
        >
          <Box style={webStyle.modalStyle}>
            <Box style={{ padding: '1em', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography style={webStyle.modalTextTitle}>
                Verify Your Email to Enroll Now</Typography>

              <Typography align='center' style={webStyle.modalTextSubTitle}
              >To continue with your enrollment, kindly verify your email address.<br /><br />
              </Typography>
            </Box>

            <hr />
            <Snackbar anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleCloseLink} >
              <Alert severity="success">
                {this.state.msg}
              </Alert>

            </Snackbar>
            <Snackbar anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} onClose={this.handleCloselink1} >
              <Alert severity="error">
                {this.state.msg}
              </Alert>

            </Snackbar>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant='contained'
                style={webStyle.modalButtonVerify}
                onClick={this.handleVerify}
              // onClick={this.handleVerify}
              >
                Resend Link</Button>
            </Box>
            <IconButton onClick={this.handleClose} style={{ marginTop: '-210px', marginLeft: '-30px' }}>
              <Box style={{ paddingTop: '2px', textAlign: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small style={{ textAlign: 'center' }}>x</small>

              </Box>
            </IconButton>
          </Box>
        </Modal>

        {/* -------------------------------------------- modal check video completion------------------------------------------------------------- */}
        <Modal
          open={this.state.openverifyVideo}
        // onClose={this.handleClose}
        >
          <Box style={webStyle.modalStyle}>
            <Box style={{ padding: '2em', marginTop: '10px' }}>

              <Typography align='center' style={webStyle.modalTextSubTitleVideo}
              >Don't forget to finish watching the
                HG Orientation Video so you can move forward
                to the next step: enrollment!
              </Typography>
            </Box>

            <hr />
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant='contained'
                style={webStyle.modalButtonVerify}
                onClick={this.handleVerifyVideo}
              >
                Proceed</Button>
            </Box>
            <IconButton onClick={this.handleCloseVideoModal} style={{ marginTop: '-230px', marginLeft: '-30px' }}>
              <Box style={{ paddingTop: '2px', textAlign: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small style={{ textAlign: 'center' }}>x</small>

              </Box>
            </IconButton>
          </Box>
        </Modal>
      </ThemeProvider>
    )
  }
}

const webStyle = {
  btnEnrollNow: {
    height: "52px",
    fontFamily: "Poppins",
    width: "235px",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#fab03d"
  },
  verifyTextStyle: {
    color: '#a8a8a8;',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  welcomeTextStyle: {
    //marginTop: '50px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  HomeSchoolRecomender: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: 'left'
  },
  paperContainerProgram: {
    alignText: 'center',
    minHeight: '20vw',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5vw',
  },
  paperContainerTestimonial: {

    alignText: 'center',
    width: '100%',
    minHeight: '400px',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',
    flexDirection: 'column' as 'column'
  },
  paperContainerQuestions: {
    alignText: 'center',
    width: '100%',
    minHeight: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',


  },
  modalTextSubTitleVideo: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',
    // marginTop: '10px'
  },
  paperContainerRecommender: {

    width: '100%',
    minHeight: '300px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignText: 'center',
  },
  paperContainerFeesCalc: {
    alignText: 'center',
    width: '100%',
    minHeight: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: ' 0px 5px 15px rgba(68,68,79,0.1)',
  },

  gridProgramContainerStyle: {

    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
    padding: '10px',

    display: 'flex',
    // margin: '10px',
    marginTop: '10px',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer'
  },

  programAvatarStyle: {
    // position: 'absolute' as 'absolute',
    width: '40px',
    height: '40px',
    // top: '14px',
    // left: '-17px',
  },

  programBoxStyle: {
    marginLeft: '8px',
    marginRight: '2px',
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },

  ProgramInnerTextHeader: {

    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.6em',
    // fontSize: '10px',
    fontWeight: 700,

  },

  programInnerTextSubText: {

    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.5em',
    //fontSize: '9px',
    fontWeight: 300,

  },
  programMainText: {

    color: ' #757575',
    fontFamily: 'Roboto',
    fontSize: '0.8em',
    // fontSize: '13px',
    fontWeight: 800,
    alignText: 'left',

  },
  programMainSubText: {

    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.6em',
    // fontSize: '10px',
    fontWeight: 400,
    marginBottom: '10px',

  },

  headerTextStyle: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "1em",
  },
  btnRecommendation: {
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },

  btnTestimonial: {
    width: '420px',
    height: "60px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#8FBE49",
    marginTop: '-30px',
    // marginTop: '2.2em',
    marginBottom: '0.5em',
  },
  btnQuestions: {
    width: '220px',
    height: "60px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '1em',
  },
  btnQuestionsLink: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    textDecoration: 'none',
  },

  boxOrientation: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chatText: {
    color: '#44444f',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700,
  },
  btnChat: {
    width: '104px',
    height: "28px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    margin: '0.6em'
  },

  inputSelectLabelStyle: {
    marginTop: '2em',
    marginBottom: '1em',
    width: '220px',
    height: '22px',
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },

  inputBoxStyle: {
    marginTop: '14px',
  },

  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    height: '235px',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '24px',

  },
  modalTextSubTitle: {
    color: '#92929d',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '10px'
  },
  modalButtonVerify: {
    marginTop: '10px',
    marginRight: '10px',
    width: '150px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#fab03d',
    fontFamily: 'Poppins',
    color: '#fafafb',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },

  boxGreenHomeSchooler: {
    width: '400px',
    height: "45px",
    borderRadius: "10px",
    backgroundColor: "#8FBE40",
    marginTop: '-10px',
    zIndex: 100,
  },
  btnHomeSchoolerText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
  },
  btnHomeSchoolerSubText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: '#fff',
  },

  circularLoader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  },

}