import React, { useState } from "react";

import {
    // Customizable Area Start
  Box,
    Typography,
  IconButton,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },
   
    MuiTypography: {
      body1: {
        // fontSize: '0.8rem',
        // fontFamily: "Roboto",
        // color: '#171725',
        // fontWeight: 300,
        paddingLeft:'0.3em'
      }
    },

    MuiRadio: {
      root: {
        color: '#99cb51',
        width: '1rem',
        height: '1rem',
      },
      colorSecondary: {
        color: '#99cb51',

        '&$checked': {
          color: '#99cb51',
        },

      },
    }
  }
});


const CheckoutRadioTextBookBlendedCurr = ({ value }: any) => {

  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <FormControlLabel
          style={{marginLeft:'0.5px'}}
            value={value}
            control={<Radio />}
            label={<span style={webStyle.radioLabelStyle}>Blended Curriculum</span>} />
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </Box>
        <Typography style={webStyle.cardSubTextStyle}> BJU Bundle - DepED </Typography>
        <Grid container >
          <Grid item xs={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

              <Box>
                <Typography style={webStyle.cardCollapseStyle}>Online Teaching Materials: </Typography>
                <Typography style={webStyle.cardCollapseStyle}>
                  Teacher's Manual, Teaching Videos w/ Mom's Minutes, Worktext/Activities Answer Keys and Printables, and Earth Science and Pre-Algebra Student Texts
                </Typography>

                <Typography style={webStyle.cardCollapseStyle}> Printed Materials: </Typography>
                <Typography style={webStyle.cardCollapseStyle}> BJU Bible 7. Bible A Student Worktext (3rd ed.)  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Asian Identity in the Global World Textbook   </Typography>
                <Typography style={webStyle.cardCollapseStyle}> BJU English 7. Explorations in Literature Student Text (4th ed)  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> BJU Pre-Algebra Student Text (2nd Ed.)  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> BJU Earth Science Student Lab Manual (4th ed)  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> BJU English Writing and Grammar Student Writet (3rd ed) </Typography>
                <Typography style={webStyle.cardCollapseStyle}> Kalinangan 7 Teacher's Manual  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> MAPEH in Action 7 TB  </Typography>
                <Typography style={webStyle.cardCollapseStyle}> MAPEH in Action 7 TM  </Typography>

              </Box>

            </Collapse>

          </Grid>
        </Grid>
        <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc', marginTop: '1em', marginBottom: '1em' }}><p></p></Box>
        <Typography style={webStyle.cardAmountStyle}> Price : ₱ 50,000.00 </Typography>

      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  cardStyle: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    borderRadius: '15px',
    border: '1px solid #e2e2ea',
  },

  cardCollapseStyle: {
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },

  cardAmountStyle: {
    marginTop: '10px',
    color: '#192126',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
  },
  radioLabelStyle: {
    color: '#171725',
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
  },
  cardSubTextStyle: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#171725',
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
  }

};

export default CheckoutRadioTextBookBlendedCurr;
// Customizable Area End
