

import React from "react";

import {
     // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import { avatar,} from './assets';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End
   // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
 // Customizable Area End
export default class HomeSchoolProgramRecommendationResult extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                                modal={this.manage}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', width: '95%', padding: '0em', marginBottom: '6em' }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto', padding: '0em' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Homeschool Program</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.mainWrapper}>
                                            <Container maxWidth={false} style={webStyle.GridWrapper}>
                                                <Typography style={webStyle.mainHeading}>HG Recommends the below home school programs</Typography>

                                                {this.context.homeSchoolProgram.map((item: any) => {
                                                    return (
                                                        <>
                                                            <Box style={webStyle.subGridWrapper}>
                                                                <Grid item xs={12} style={{ display: "flex", alignItems: "center", }}>
                                                                    <Box style={{ overflow: "unset" }} ><img src={avatar} alt="student avatar" style={webStyle.cardAvatarStyle} /> </Box>
                                                                    <Box style={{ display: 'block' }}>
                                                                        <Box style={webStyle.subjectStyle}>{item?.program_name}</Box>
                                                                        <Box style={{ width: '90%', paddingTop: "0.1em" }}>
                                                                            <small style={webStyle.subSubjectStyle}>{item?.description}</small>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Box>
                                                        </>
                                                    )
                                                })}
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    mainHeading: {
        color: " #192126",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Roboto",
        paddingBottom: '0.7em',
        paddingTop: '1em'
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
        padding: '0em',
    },

    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "1.5em",
        marginTop: "2em",

    },
    subGridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "0.8em",
        marginTop: "1em",
    },

    subjectStyle: {
        marginLeft: '1em',
        fontFamily: "Poppins",
        fontSize: "2.5vh",
        fontWeight: 600,
        color: "#171725",

    },
    subSubjectStyle: {
        fontFamily: "Roboto",
        color: "#171725",
        fontWeight: 300,
        marginLeft: '1em,'


    },

    cardAvatarStyle: {
        width: '55px',
        height: '55px',
        marginRight: '10px',
        overflow: "none",
        borderRadius: "10px"
    },
};
// Customizable Area End
