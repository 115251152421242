import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
let Country:any = require('country-state-city').Country;
let City = require('country-state-city').City;


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  countryCodeSelected: string;
  countryCodeSelectedData: any;
  uniqueCountryCodeSelectedData: any;
  uniqueCountryCodeData: any;
  phone: string;
  password: string;
  FullPhoneNumber: string
  email: string;
  country: string;
  countriesData: any;
  city: string;
  cityData: any;
  reasonForHomeSchooling: string;
  reasonForHomeSchoolingData: any;
  learnAboutUs: string;
  errors: any;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  responseSubmit: any,
  snackbarOpen: boolean;
  snackbarMessage: any;
  snackbarOpenError: any;
  isLoading: any;
  open: boolean;
  openD: any;
  openS: any;
  drawerWidth: any;

  errorsObj: any;
 

  getAllCountries:any;
  getAllCities:any;
  cityLoader:any;
  allVideos_introVideoID:any;
  snackbarOpenFalse: boolean;
  allVideo_types:any;
  uniqueCountryCodeDataNew: any;
  loadingSignup:boolean;
  passwordState:boolean;
  passwordInputState:any;
  passwordCreateState:boolean;
  passwordCreateInputState:any;
  passwordConfirmState:boolean;
  passwordConfirmInputState:any;
  countryAutoCompleteValue:any;
  cityAutoCompleteValue:any;
  barangay:any;
  byrangayData: any;
  provincesData:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;


  apiReasonForHomeSchoolingCallId: string = "";
  apiCallsignupFormSubmitId: string = "";
  apiCallChangePassword: any;
  apiCallStudent: any;
  postStudentCallId: any;
  apiGetIntroVideoTimeCallId: string = "";
  apiAllVideoCallId: string = "";
  apiBarangaysCallId: any;
  apiProvincesCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      countryCodeSelected: "",

      phone: "",
      FullPhoneNumber: '',
      email: "",
      password: "",
      country: "choose",
      city: "choose",
      reasonForHomeSchooling: "choose",
      learnAboutUs: "choose",
      errors: {},
      countriesData: [],
      reasonForHomeSchoolingData: [],
      countryCodeSelectedData: [],
      uniqueCountryCodeSelectedData: [],
      uniqueCountryCodeData: [],
      cityData: [],


      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      responseSubmit: "",
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarOpenError: false,
      isLoading: false,
      open: false,
      openD: true,
      openS: true,
      drawerWidth: 300,
  
      errorsObj: "",
     

      getAllCountries:[],
      getAllCities:[],
      cityLoader:'',

      allVideos_introVideoID:'',
      snackbarOpenFalse:false,
      allVideo_types:['onboarding','orientation','testimonial','homeschooler'],
      uniqueCountryCodeDataNew: [],
      loadingSignup:false,
      passwordState:true,
      passwordInputState:'password',
      passwordCreateState:true,
      passwordCreateInputState:'password',
      passwordConfirmState:true,
      passwordConfirmInputState:'password',
      countryAutoCompleteValue:"",
      cityAutoCompleteValue:'',
      barangay:'choose',
       byrangayData: [],
       provincesData:[],
       // Customizable Area End
    };

    // Customizable Area Start

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (this.apiReasonForHomeSchoolingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({ reasonForHomeSchoolingData: apiResponse?.data })
      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    // ----------------------------- Provinces api start-------------------------------
    if (this.apiProvincesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {

        this.setState({ provincesData: apiResponse?.data })
        }
      else {
        console.log('error', apiResponse?.error)
      }
    }

    // ----------------------------- Provinces api end-------------------------------
    //postrequest
    if (this.apiCallsignupFormSubmitId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({loadingSignup:false})
        this.setState({ responseSubmit: apiResponse?.data })
        this.setState({ snackbarOpen: true, snackbarMessage: "New User Created Successfully!! Email Activation Link sent." });
        this.saveLoggedInUserData(apiResponse?.meta?.token)
         this.getAllVideos();
      }
      else {
        this.setState({loadingSignup:false})
        console.log('error', apiResponse?.errors[0].account)
        this.setState({ snackbarOpenFalse: true, snackbarMessage: apiResponse?.errors[0]?.account });
      }
    }
     // --------------------- intro ALL videos response start -----------------------
   if (this.apiAllVideoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiResponse?.data) {
      apiResponse?.data?.map((item: any) => {
        if(item?.attributes?.video_type==this.state.allVideo_types[0]){
          this.setState({allVideos_introVideoID:item?.id})
        }
      })
      this.getIntroVideoTime()
      }

     else {
      console.log('error', apiResponse?.error)
    }
  }
  // --------------------- intro ALL videos response end ----------------------- 
    if (this.apiGetIntroVideoTimeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        localStorage.setItem('curentTime',String(apiResponse?.data.attributes?.watch_time))
        localStorage.setItem('complete',String(apiResponse?.data.attributes?.is_completed))
        if(apiResponse?.data.attributes?.is_completed===false){
          this.props.navigation.navigate("DashboardIntroVideo")
       }
       
      }
      else {
        console.log('Signup videoTime error ', apiResponse?.message)
      }
    }
   ////barangay call id  start////

   if (this.apiBarangaysCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiResponse?.data) {
      
      this.setState({ byrangayData: apiResponse?.data})
   }
    else {
      console.log('Byrangay error', apiResponse?.errors)
    }
  }
  ///barangay call id  End////


    // Customizable Area End
  }

  // Customizable Area Start

  saveLoggedInUserData(token: any) {
    console.log({ token })
    if (token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        token
      );

      this.send(msg);
    }
  }


  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

 

  //---------------------- api call request ----------------------
  getResonForHomeSchoolingData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiReasonForHomeSchooling,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReasonForHomeSchoolingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiReasonForHomeSchoolingCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiReasonForHomeSchoolingCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //---------------------- Provinces api call request start ----------------------
  getProvincesData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiProvinces,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvincesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiProvincesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiProvincesCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
    //---------------------- Provinces api call request end ----------------------
/// barangays api call request start///
  getBarangaysData = (cityName: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiBarangay,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBarangaysCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_barangays?city=${cityName}`
     
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiBarangayCallMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  /// barangays api call request End///

  //signupcreateuser
  signUpCreateUser = () => {
    this.setState({loadingSignup:true})
    const header = {
      "Content-Type": configJSON.contentTypesignupFormSubmit,
    };

    const httpBody = {
      data: {
        "type": "email_account",
        attributes: {
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          phone_number: this.state.phone,
          country_code:this.state.countryCodeSelected,
          country: this.state.country,
          city: this.state.city,
          home_schooling_reason_id: this.state.reasonForHomeSchooling,
          barangay_id: this.state.barangay,
          informed_about_us: this.state.learnAboutUs,
          inquiry_from: 'signup'
        }
      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallsignupFormSubmitId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupFormSubmitCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apisignupFormSubmitCallMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///update pass codeapi start///
  





  // ------------------------- calling the api for all the video start --------------------------
getAllVideos = () => {

  const header = {
    "Content-Type": configJSON.contentTypeApiAllVideo,
    "token": localStorage.getItem("authToken")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAllVideoCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.apiAllVideoCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiAllVideoCallMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
// ------------------------- calling the api for all the video end --------------------------
// ------------------------- calling the api video time start --------------------------


  getIntroVideoTime = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiVideoTime,
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      video_detail: {
       video_id: this.state.allVideos_introVideoID,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetIntroVideoTimeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiVideoTimeCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVideoTimeCallMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // ------------------------- calling the api video time end --------------------------
 

  // ----------------componentDidMount-----------------------



  async componentDidMount() {
    this.getProvincesData()
    this.getResonForHomeSchoolingData()
    this.getAllCountriesData()

  }


 
  hideError = (event: any) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }


  formValidate = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{6,11}$/;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\]\/{}\\[?:";'|<>,.~`@#$%^&*()_+])[A-Za-z\d!\]\/{}\\[?:";'|<>,.~`@#$%^&*()_+]{8,}$/

    if (this.state.firstName === '') {
      this.state.errors['firstName'] = "FirstName Required"
      isFormValid = false
    }
    else if (!regexName.test(this.state.firstName)) {
      this.state.errors['firstName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.lastName === '') {
      this.state.errors['lastName'] = "LastName Required"
      isFormValid = false
    }
    else if (!regexName.test(this.state.lastName)) {
      this.state.errors['lastName'] = "Incorrect format - Only characters are allowed"
      isFormValid = false
    }
    if (this.state.countryCodeSelected === '') {
      this.state.errors['countryCodeSelected'] = "Code Required"
      isFormValid = false
    }
    if (this.state.phone === '') {
      this.state.errors['phone'] = "Phone Required"
      isFormValid = false
    }
    else if (!regexPhone.test(this.state.phone)) {
      this.state.errors['phone'] = "Min 6 to Max 11 digits only"
      isFormValid = false
    }

    if (this.state.email === '') {
      this.state.errors['email'] = "Email Required"
      isFormValid = false
    }
    else if (!regex.test(this.state.email)) {

      isFormValid = false;
      this.state.errors['email'] = "Incorrect Email"
    }

    if (this.state.password === '') {
      this.state.errors['password'] = "Password Required"
      isFormValid = false
    }

    else if (!passwordRegx.test(this.state.password)) {
      this.state.errors['password'] = "Min 8 Alphanumeric characters with atleast one uppercase, one digit and one special character required"
      isFormValid = false
    }
    
    if (this.state.country === 'Please Select' || this.state.country === '') {
      this.state.errors['country'] = "Country Required"
      isFormValid = false
    }

    if (this.state.city === 'choose' || this.state.city === '') {
      this.state.errors['city'] = "City Required"
      isFormValid = false
    }
    if (this.state.city !="" && this.state.byrangayData.length > 0) {
       if (this.state.barangay === 'choose') {
        this.state.errors['barangay'] = "Barangay Required"
        isFormValid = false
      }
    }
    if (this.state.reasonForHomeSchooling === 'choose') {
      this.state.errors['reasonForHomeSchooling'] = "Reason Required"
      isFormValid = false
    }
   
    if (this.state.learnAboutUs === 'choose') {
      this.state.errors['learnAboutUs'] = "Option Required"
      isFormValid = false
    }
    return isFormValid
  }



  changeHandlerGetCities = async(e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.state.errors['country'] = " "
    this.setState({ city: '' })
    this.setState({ byrangayData: [] })
    this.setState({barangay:'choose'})

   await this.state.getAllCountries &&  this.state.getAllCountries?.filter(async (item: any) => {
    if(newInputValue){
      if (item.name ==newInputValue) {
          let data:any=[];
          let dataMsg:any=[{name:'No City found'}]
          const key = 'name';
          if(newInputValue == 'Philippines'){
            await this.state.provincesData?.map((city:any)=>{
                data?.push({
                name: city,
                })
               })
           }
           else{
            await City.getCitiesOfCountry(item?.isoCode)?.map((city:any)=>{
              data?.push({
              name: city?.name,
              })
             })
           }
        
              const arrayUniqueByKey1 = [...new Map(data.map((item:any) =>
                [item[key], item])).values()];
              if(data.length == 0){
                 this.setState({getAllCities:dataMsg})  
                }
              else{
                this.setState({getAllCities:arrayUniqueByKey1})  
                }
            }
        }
      else{
        this.setState({cityAutoCompleteValue:''})
        this.setState({city:''})
        this.setState({getAllCities:[]})  
      }  
     })
     
  }
  
  learnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ learnAboutUs: e.target.value })
    this.state.errors['learnAboutUs'] = " "
  }
  reasonChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ reasonForHomeSchooling: e.target.value })
    this.state.errors['reasonForHomeSchooling'] = " "
  }
  cityChangeHandler = (e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    this.setState({byrangayData:[]})
    this.setState({barangay:'choose'})
    this.setState({ city: newInputValue },()=>{

        this.getBarangaysData(this.state.city)
      
      
     })
     
    
     this.state.errors['city'] = " "
     
   }

  handlePhoneChange=(e: React.ChangeEvent<HTMLInputElement>) => {
    let aaa=e.target.value.replace(/^0+/, "")
   this.setState({ phone: aaa })
   }

  signupFormSubmit = () => {
     if (this.formValidate()) {
      this.signUpCreateUser()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
      console.log(this.state.errors)
    }
  }

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleClose1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenError: false })
  };
  handleClose2 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenFalse: false })
  };

  changeOpen = () => {
    this.setState({ open: true })
  }
  handleModelClose = () => {
    this.setState({ open: false })
  }

  getAllCountriesData=()=>{
    
     Country.getAllCountries().map((item:any)=>{
       this.state.getAllCountries.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode:item?.isoCode
      })
     
    })
    this.state.getAllCountries?.map((item: any) => {
      if(item?.phonecode !== '' && !item?.phonecode.includes('+') && !item?.phonecode.includes('0055') ){
        this.state.uniqueCountryCodeData.push({
          phonecode:item?.phonecode
        })
      }
     })


    this.state.uniqueCountryCodeData.unshift({...this.state.uniqueCountryCodeData.phonecode, phonecode:'0'})
   
    this.state.uniqueCountryCodeData.sort(function(a:any, b:any){return a.phonecode - b.phonecode}); 
     
    let dataArr = this.state.uniqueCountryCodeData?.map((item: any) => {
      return [JSON.stringify(item), item]
    });
    let maparr = new Map(dataArr);

    this.setState({ uniqueCountryCodeDataNew: [...maparr.values()] });
    
  }

  changePasswordState=()=>{
    if(this.state.passwordState){
      this.setState({passwordState:false})
      this.setState({passwordInputState:'text'})
    }
    else{
      this.setState({passwordState:true})
      this.setState({passwordInputState:'password'})
    }
  } 
  changePasswordCreateState=()=>{
    if(this.state.passwordCreateState){
      this.setState({passwordCreateState:false})
      this.setState({passwordCreateInputState:'text'})
    }
    else{
      this.setState({passwordCreateState:true})
      this.setState({passwordCreateInputState:'password'})
    }
  } 
  changePasswordConfirmState=()=>{
    if(this.state.passwordConfirmState){
      this.setState({passwordConfirmState:false})
      this.setState({passwordConfirmInputState:'text'})
    }
    else{
      this.setState({passwordConfirmState:true})
      this.setState({passwordConfirmInputState:'password'})
    }
  } 
  
  barangayChange=(e:any)=>{
    this.setState({ barangay: e.target.value })
       this.state.errors['barangay'] = " "
  }

  // Customizable Area End
}
