import React, { useState, useRef} from "react";

import {
    // Customizable Area Start
  Box,
  Button,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
  },

  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },

  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
       },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },

});


const DashboardVideo = ({ introVideoURL, watchTime,handleNext }: any) => {

  const classes = useStyles();
  const [watchCompleted, setWatchCompleted] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [videoTotalTime, setVideoTotalTime] = useState(0)
  const [videoCurrentTime, setVideoCurrentTime] = useState(watchTime)
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef<any>(null)

  const handleWatchComplete = ({ played }: any) => {
    if (played > 0.99) {
      setWatchCompleted(true)
      setVideoTotalTime(playerRef.current.getDuration())
      setVideoCurrentTime(playerRef.current.getCurrentTime())
      localStorage.setItem('curentTime',String(videoCurrentTime))
    localStorage.setItem('complete',String(watchCompleted))
    console.log(videoTotalTime)
    }
    else {

      setVideoTotalTime(playerRef.current.getDuration())
      setVideoCurrentTime(playerRef.current.getCurrentTime())
      setWatchCompleted(false)
      localStorage.setItem('curentTime',String(videoCurrentTime))
      localStorage.setItem('complete',String(watchCompleted))
    }
  }

  const handlePlayPause = () => {
    setPlaying(!playing)
    console.log('VIDEOAPI1',localStorage.getItem('curentTime'))
    console.log('VIDEOAPI2',localStorage.getItem('complete'))
  }
  const handlePlay = () => {
   
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
      playerRef.current.seekTo(watchTime, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  return (

    // Customizable Area Start

    <>

      <ThemeProvider theme={theme}>
        <Box style={{ display: 'flex', alignItems: 'center' }} >

          <div className={classes.playerWrapper}>
            <ReactPlayer
            config={{ file: { attributes: { 
              controlsList: 'nodownload noplaybackrate',
               disablepictureinpicture: 'true',
               } 
            } }}
            // Disable right click
            onContextMenu={(e: any) => {
              e.preventDefault()
            }}
              ref={playerRef}
                width="100%"
               height="100%"
              url={introVideoURL}
              muted={true}
              playing={playing}
              controls={true}
              onProgress={handleWatchComplete}
              onReady={onReady}
              pip={false}
              onEnded={()=>{
                handleNext(videoCurrentTime, watchCompleted)
           }}
              style={{ borderRadius: '20px', overflow: 'hidden' }}

            />
            <div className={classes.controlWrapper}>

            {isReady? 
              <Box style={{  height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    playing ?<PauseIcon onClick={handlePlayPause} style={{marginTop:'55px', cursor:'pointer', fontSize: '50px', color: 'white' }} /> 
                    : <PlayCircleFilledIcon onClick={handlePlayPause} style={{marginTop:'55px', cursor:'pointer', fontSize: '60px', color: 'white' }} />
                  }
              </Box>
              :null}
            </div>
            {isReady? 
            <Box style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', position: "absolute" }}>
              <Button variant='contained' style={{ textTransform: "none", ...webStyle.btnWatchLater }} onClick={handlePlay}>Click to watch video </Button>
            </Box>
            :null}
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  btnWatchLater: {
    width: "235px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '0.5em',
    marginBottom: '1.3em',
  },
};

export default DashboardVideo;
// Customizable Area End
