import React from "react";

import {
 // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
   // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web";
import ChatGlobal from "../../../components/src/ChatGlobal.web"

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {

    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiInput: {
      underline: {

        " &:before": {
          borderBottom: 'none',
          transition: 'none',
          content: 'none',
        },

      },
    },

  },
});


// Customizable Area End
    // Customizable Area Start
import OrderDetailViewController, {
  Props,
} from "./OrderDetailViewController";
    // Customizable Area End
export default class StudentRejectedReason extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
   
    
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
                modal={this.manage}
              />
            </Grid>


            {/* ---------------------- drawer Content ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >

                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                   <Box style={{ marginTop: '100px'}}>
                    <Typography style={webStyle.welcomeTextStyle} >Reason For Rejection </Typography>
                    </Box>
                  <Grid container style={{ display: 'flex' }}>
                  <HorizontalLine/>
                  </Grid>
                  <Grid container>


                    <Grid container style={webStyle.gridContentContainerStyle}>
                    <Grid item xs={12} style={{ padding:'3.5em' }}>
                         <Typography style={webStyle.reasonTextStyle} >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Uteniamffger enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  </Typography>
                     </Grid>     
                         <Grid item xs={12} style={{ padding:'3.5em',textAlign: "right" }}>
                    
                    <Button variant="contained" style={{ textTransform: "none", backgroundColor: "#9167ac", ...webStyle.btnReason }} onClick={()=>{this.props.navigation.navigate('StudentsList')}}>Back</Button>
                     <Button variant="contained" style={{ textTransform: "none",  backgroundColor: "#fab03d", ...webStyle.btnReason }} onClick={()=>{this.handlerEdit(localStorage.getItem('studentID'))}} >Edit Details</Button>
                     </Grid>
                    
                    </Grid>
                  </Grid>
                  {ChatGlobal()}
                </Container>

                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

              </Container>
            </Grid>
          </Grid>

      </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  reasonTextStyle: {
    color: '#171725',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 400,
  },

  gridContentContainerStyle: {
    marginTop: '40px',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    },
  
   btnReason: {
      height: "52px",
      width:'200px',
      fontFamily: "Poppins",
      fontSize: "16px",
      color: "#fafafb",
      fontWeight: 600,
      borderRadius: "10px",
      margin:'40px 10px 10px 10px',
   },

};
// Customizable Area End
