import React from "react";

import {
    // Customizable Area Start
  Container,
  Box,
  Typography,
  IconButton,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Drawer from "../../../components/src/Drawer.web";
import FaqCard from "../../../components/src/FaqCard.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
  // Customizable Area Start
  import AssessmentTestController, {
    Props,
  } from "./AssessmentTestController";
  // Customizable Area End
  export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>
              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >
                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingLeft: '0', paddingRight: '0', marginBottom: '6em' }}>

                  <Container maxWidth={false} style={{ margin: '0 auto' }}>
                    <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography style={webStyle.welcomeTextStyle} >FAQs</Typography>
                    </Box>

                    <Grid container style={{ display: 'flex' }}>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#fab03d' }}><p></p></Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#9167ac' }}><p></p></Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#99cb51' }}><p></p></Box>
                      </Grid>
                    </Grid>


                    <Container maxWidth={false} style={webStyle.GridWrapper}>

                      <Box style={{ marginTop: '1em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={webStyle.styleHeadingText}>Frequently Asked Questions </Typography>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={this.handleViewClick}
                          >
                            {this.state.view ? <VisibilityOff /> : <Visibility />}

                          </IconButton>
                          <Typography style={webStyle.styleViewAll}>View All </Typography>
                        </Box>
                      </Box>
                      {
                        this.state?.faqData && this.state?.faqData?.map((item: any) => {
                          return <FaqCard key={item?.attributes?.question}
                          view={this.state.view}
                            questionNo={item?.attributes?.question}
                            answer={item?.attributes?.faq_answer}
                          />
                        })
                      }

                    </Container>

                  </Container>

                </Container>
              </Container>

            </Grid>
          </Grid>

        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  GridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    marginTop: "2em",
  },
  styleHeadingText: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
  },
  styleViewAll: {
    color: ' #92929d',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  }

};
// Customizable Area End
