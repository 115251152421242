import React, { Component } from "react";

import {
   // Customizable Area Start
  Container,
  Box,
  InputLabel,
  Typography,
   TextField,
  Grid,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles  } from "@material-ui/core/styles";
import moment from "moment";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);

export default class CustomEnroll6 extends Component<any> {
  render() {
    const { studentFormData,FinallyRegisteringChange,reasonForRegisterSelectedData } = this.props;
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Typography style={webStyle.mainheading1} >Review</Typography>
        <Container maxWidth={false} style={{padding: '2em' }}>
        <Container maxWidth={false} style={webStyle.GridWrapper}>
     
          <Typography style={webStyle.mainheading} >Student Information</Typography>

          {/* <Grid container    > */}
          <Grid container spacing={4} xs={12} style={{padding:'1em'}} >
            <Grid  item xs={6} lg={3}>

              <Typography style={webStyle.heading} >Student's First Name:</Typography>
              <Typography style={webStyle.heading}>Student's Middle Name: </Typography>
              <Typography style={webStyle.heading}>Student's Last Name:</Typography>
              <Typography style={webStyle.heading}>Gender:</Typography>
              <Typography style={webStyle.heading}>Date of Birth:</Typography>
              <Typography style={webStyle.heading}>Age:</Typography>
              <Typography style={webStyle.heading}>Place of Birth (City):</Typography>
              <Typography style={webStyle.heading}>Place of Birth (Country):</Typography>
              <Typography style={webStyle.heading}>Religion:</Typography>
              <Typography style={webStyle.heading}>Nationality:</Typography>
              <Typography style={webStyle.heading}>Country of Residence:</Typography>
              <Typography style={webStyle.heading}>City:</Typography>
              <Typography style={webStyle.heading}>Barangay:</Typography>
              <Typography style={webStyle.heading}>Residential Address:</Typography>
              <Typography style={webStyle.heading}>Scholarship:</Typography>
              <Typography style={webStyle.heading}>Advocate / Referral Program:</Typography>

            </Grid>

            <Grid  item xs={6} lg={3}>
             <Typography style={webStyle.heading}>{studentFormData.studentFirstName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentMiddleName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentLastName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentGender}</Typography>
              <Typography style={webStyle.heading}>{moment(studentFormData.studentDate).format("MM/DD/YYYY")}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentAge}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentBirth.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentCountry.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentReligion.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentNationality.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentCountryOfResidence.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentCityOfResidence.value}</Typography>
              {studentFormData.barangaySubData.value=="choose"?
              <Typography style={webStyle.heading}>NA</Typography>:
              <Typography style={webStyle.heading}>{studentFormData.barangaySubData.value}</Typography>
            }
              <Typography style={webStyle.heading}>{studentFormData.studentResidentialAddress}</Typography>
             { studentFormData.studentScholarShopType=="choose"?
             <Typography style={webStyle.heading}>NA</Typography>:
             <Typography style={webStyle.heading}>{studentFormData.studentScholarShopType }</Typography>
             
            }
              {studentFormData.studentRefferalProgram?
              <Typography style={webStyle.heading}>Yes</Typography>:
              <Typography style={webStyle.heading}>No</Typography>
              
            }
             </Grid>


             <Grid  item xs={6} lg={3}>

              <Typography style={webStyle.heading} >Hub: </Typography>
              <Typography style={webStyle.heading} >Incoming Grade Level: </Typography>
              <Typography style={webStyle.heading}>Program:</Typography>
              <Typography style={webStyle.heading}>Accreditation:</Typography>
              <Typography style={webStyle.heading}>Cycle:</Typography>
              <Typography style={webStyle.heading}>Previous School Attended:</Typography>
              <Typography style={webStyle.heading}>Previous School Address:</Typography>
              <Typography style={webStyle.heading}>Previous School Email:</Typography>
              <Typography style={webStyle.heading}>Previous School Contact:</Typography>
              <Typography style={webStyle.heading}>Last Grade Level:</Typography>
              <Typography style={webStyle.heading}>Last School Year:</Typography>
              <Typography style={webStyle.heading}>Child's First Language:</Typography>
              <Typography style={webStyle.heading}>Learner Reference Number:</Typography>
              <Typography style={webStyle.heading}>Scholarship Type:</Typography>
              <Typography style={webStyle.heading}>Type of Scholarship:</Typography>
              <Typography style={webStyle.heading}>Advocate / Referral Code:</Typography>
            </Grid>


            <Grid  item xs={6} lg={3}>


              <Typography style={webStyle.heading}>{studentFormData.programDetailsHub.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.programDetailsIncomingGradeLevel.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.programDetailsProgram.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.programDetailsAccreditation.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.programDetailsCycle}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolAttended}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolAddress}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolEmail}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolContact}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolLastGradeLevel.value}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.previousSchoolLastSchoolYear}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.studentChildsFirstLanguage.value}</Typography>
              {studentFormData.previousSchoolLearnerReferenceNumber.lenght > 0 ?
                <Typography style={webStyle.heading}>{studentFormData.previousSchoolLearnerReferenceNumber}</Typography> :
                <Typography style={webStyle.heading}>NA</Typography>
              }
              {studentFormData.studentWouldYouLikeToApplyForAScholarShip?
                <Typography style={webStyle.heading}>Yes</Typography> :
                <Typography style={webStyle.heading}>No</Typography>}
              {studentFormData.studentTypesOfScholarShip.value == "choose" ?
                <Typography style={webStyle.heading}>NA</Typography> :
                <Typography style={webStyle.heading}>{studentFormData.studentTypesOfScholarShip.value}</Typography>
              }

              {studentFormData.studentRefferalProgram ?
                <Typography style={webStyle.heading}>{studentFormData.studentAdvocatecode}</Typography> :
                <Typography style={webStyle.heading}>NA</Typography>}

            </Grid>
          </Grid>


          <Typography style={webStyle.subheading} >Family Details</Typography>
          {/* <Grid container style={{ display: 'flex', marginTop: '2em' }} > */}
          <Grid container spacing={4} xs={12}style={{padding:'1em', display: 'flex'}}>
          <Grid  item xs={6} lg={3}>
              <Typography style={webStyle.heading} >Father's First Name:</Typography>
              <Typography style={webStyle.heading}>Father's Middle Name: </Typography>
              <Typography style={webStyle.heading}>Father's Last Name:</Typography>
              <Typography style={webStyle.heading}>Father's Occupation:</Typography>
              <Typography style={webStyle.heading}>Father's Mobile Number:</Typography>
              <Typography style={webStyle.heading}>Father's Email Address: </Typography>
              <Typography style={webStyle.heading}>Mother's First Name:</Typography>
              <Typography style={webStyle.heading}>Mother's Middle Name:</Typography>
              <Typography style={webStyle.heading}>Mother's Last Name:</Typography>
              <Typography style={webStyle.heading}>Mother's Occupation: </Typography>
            </Grid>

            <Grid  item xs={6} lg={3}>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersFirstName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersMiddleName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersLastName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersOccupation}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersPhoneNumber}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoFathersEmailAddress}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersFirstName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersMiddleName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersLastName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersOccupation}</Typography>

            </Grid>
            
            <Grid  item xs={6} lg={3}>
              <Typography style={webStyle.heading} >Mother's Mobile Number:</Typography>
              <Typography style={webStyle.heading}>Mother's Email Address:</Typography>
              <Typography style={webStyle.heading}>Marital Status:</Typography>
              <Typography style={webStyle.heading}>Guardian's Name:</Typography>
              <Typography style={webStyle.heading}>Guardian's Address:</Typography>
              <Typography style={webStyle.heading}>Guardian's Occupation:</Typography>
              <Typography style={webStyle.heading}>Guardian's Mobile Number:</Typography>
              <Typography style={webStyle.heading}>Guardian's Email Address:</Typography>
              <Typography style={webStyle.heading}>Relationship to the student:</Typography>
            </Grid>
            <Grid  item xs={6} sm={3}>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersPhoneNumber}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMothersEmailAddress}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoMaritalStatus}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoGuardiansName}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoGuardiansAddress}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoGuardiansOccupation}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoGuardiansPhoneNumber}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoGuardiansEmailAddress}</Typography>
              <Typography style={webStyle.heading}>{studentFormData.parentsInfoRelationshipToTheStudent}</Typography>

            </Grid>
          </Grid>
        </Container>
        </Container>
        <Grid  item xs={12} sm={6} style={{paddingLeft:'3em'}}>
          
            <InputLabel style={webStyle.inputLabelStyle}>Reason for finally registering</InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="reviewReasonForFinallyRegistering"
              value={studentFormData.reviewReasonForFinallyRegistering.value}
              onChange={FinallyRegisteringChange}
            >
              <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose"> <span style={{ color: "darkgrey" }}> please select </span></MenuItem>
             
               {
                reasonForRegisterSelectedData?.map((item: any) => {
                  return (

                    <MenuItem
                      key={item?.id}
                      value={item?.reason}
                      selected={item.value == studentFormData.reviewReasonForFinallyRegistering.value}
                    >
                      {item?.reason}
                    </MenuItem>
                  )

                })

                }
            </StyledTextField>
         
          <Box style={{marginTop:'3px'}}>
              {
                (studentFormData.reviewValidationErrors.reviewReasonForFinallyRegistering)
                  ? <small style={webStyle.errorStyle}>{studentFormData.reviewValidationErrors.reviewReasonForFinallyRegistering}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  GridWrapper: {
    borderRadius: "20px",
    border: "solid #b186cd",
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingTop: "1em",
    paddingBottom: "3em",
  },
  subheading: {
    color: "#424244",
    fontFamily: " Poppins",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: '2em',
    paddingLeft:'1em',
    marginBottom: '1em'
   
  },
  heading: {
    color: " #555555",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "22px",
    margin:'revert',
   
  },
  mainheading1: {

    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "1em",
    paddingLeft:'1.4em'

  },
  mainheading: {

    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "1em",
    marginBottom: "1em",
    paddingLeft:'0.6em'

  },
  inputLabelStyle: {
    marginBottom: '10px',
    marginTop: '2em',
    width: '220px',
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  }
};
// Customizable Area End
