Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";

exports.btnExampleTitle = "CLICK ME";
exports.contentTypeApiFaq = "application/json";
exports.apiFaqCallMethodType = "GET";
exports.apiFaqCallUrl="faqs"
// Customizable Area End