import React, { Component } from "react";

import {
   // Customizable Area Start
  Box,
  Button,
  Typography,
  IconButton,
   Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CancelIcon from '@material-ui/icons/Cancel';



const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


export default class CustomEnroll7 extends Component<any> {

  render() {
    const {
      studentFormData,
      handleCheckedGeneralTerms,
      handleCheckedRefundTerms,
      handleCheckedPrivacyTerms,
      handleCheckedPicturePolicy,
      handleClose,
      open,
      handleYes,
      handleNo,
    } = this.props;


    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Grid item xs={12}>
          <Typography style={webStyle.gridTextStyle}>Tuition Fee Terms &amp; Conditions</Typography>
        </Grid>

        <Grid container style={{ padding: '2em' }}>

          <Grid item xs={12}>

            {/* ----------------------first accordian ------------------------ */}

            <Accordion>
              <AccordionSummary
                style={webStyle.accordionSummaryStyle}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>1. General T&amp;C</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Typography style={webStyle.accordianSubTitle}>
                  1. Academic Matters
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. I commit to read and understand the HG Parent Handbook and Student Code of Conduct..<br />
                  2. I will be actively involved in educating my child/children<br />
                  3. I will commit to teach/facilitate the required subjects and allot 2 hours a day for Preschool, 2-4 hours for Primary Grade, and 4-6 hours a day for Intermediate and High School students.<br />
                  4. I will teach the standard number of subjects diligently covering the learning competencies required by my child/children’s school of accreditation.<br />
                  5. I will do an accurate and honest recording of grades.<br />
                  6. I will cover the prescribed Bible Curriculum of Homeschool Global.<br />
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  HG welcomes and serves homeschooling families regardless of faith or religious background.Seeing through the years the genuine transformation in the lives of many families and students
                  who apply biblical principles in everyday living, HG promotes Bible-based values education(EPP/GMRC/CLE) for homeschoolers as a curricular requirement by its accrediting schools. We have recommended Bible-based values education textbooks required of every student in all
                  grade levels.<br />
                  The Bible curriculum is an integral textbook in history and values subjects across all grade levels and is central in all the curricular offerings of HG and all its accrediting schools (VCIS, TMA, andWCFS).<br />
                  Other values of education materials specific to the religion or belief of a homeschooling family may be included as a supplement as deemed necessary by the parent and is optional.
                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  Additional provisions applicable to TECH families ONLY:
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. I will be in-charge of teaching/facilitating the education of my children with the help of an online curriculum prescribed by the Tech program my child/children is/are enrolled in.<br />
                  2. I will make sure that my child will take the regular Online BJU DLO/Moodle Exams or AOP Ignitia Unit Tests in the prescribed fashion. (Not applicable for Preschool)<br />
                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  2. Submission of Requirements
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. I will submit all grades via the Online Grading System/Grading Sheet on or before the deadline. In the event that I am unable to submit on time, I should update HG of our quarterly status. (Not Applicable for Preschool)<br />
                  2. I will ensure that my child takes the required Pre and Post Adaptive Diagnostic Tests 2 weeks after the receipt of notification. (Not Applicable for Preschool)<br />
                  3. I understand that the Report Card (Form 138)/Student Academic Report or High School Academic Report (SAR or HSAR) may be requested only upon the completion of all the requirements and at the official end of the school year. Furthermore, the official Report Card will be released in 10-15 working days upon request.<br />
                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  3. Systems Requirement (APPLICABLE TO TECH FAMILIES ONLY)
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  I am aware that a major part of our learning resources is accessed and done through an online learning portal. Therefore, I will ensure that our internet browser has the latest updates before logging into theportal. The systems requirements are any one the following:<br />
                  A. Google Chrome version 64 or higher (recommended for DLO)<br />
                  B. Firefox version 57 or higher<br />
                  C. Microsoft Internet Explorer version 11 (For users of Internet Explorer on Windows 8, DLO must be operated in the Desktop version of Internet Explorer.) <br />
                  D. Safari version 9 or higher (recommended for Ignitia)<br />
                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  4. School Year Extension/ Dropping
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  I understand that Homeschool Global offers a 3-month extension for those who haven’t met the target year-end date with a corresponding fee for each month of extension. Beyond this 3-month period, I understand that my child/children will be automatically dropped from the program.<br />

                  Refund policy applies.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  5. Communication
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. I understand that failure to communicate with my Advisor for 3 consecutive months means that my child can be dropped from the program.<br />
                  2. I understand that I should proactively inform my Advisor of our homeschooling status, and of any changes such as contact numbers, address, marital status, etc.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  6. Target Periods
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  I understand that my child’s homeschool target months for Portfolio (for Touch) and/or Grade submissions are as follows:<br />
                </Typography>
                <Table >
                    <TableHead style={{ backgroundColor: '#9167ac' }}>
                      <TableRow>

                        <TableCell style={{ color: '#fff' }} align="center">Official Enrollment Cycle:</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">1st Quarter (ending month)</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">2nd Quarter (ending month)</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">3rd Quarter(ending month)</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">4th Quarter (ending month)</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">Official End Month</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">Extension up to the 12th month</TableCell>
                        <TableCell style={{ color: '#fff' }} align="center">Extension up to the 15th month(Pro-rata fees may apply)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                        <TableCell style={webStyle.tableBody} align="center">March 2022</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">May</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Jul</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Oct</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Dec</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Dec 2022</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Jan-Feb</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Mar,Apr,May</TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: '#f0f0f4' }}>
                        <TableCell style={webStyle.tableBody} align="center">June 2022</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Aug</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Oct</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Jun</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Mar</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Mar 2023</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Apr-May</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Jun,July,Aug</TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                        <TableCell style={webStyle.tableBody} align="center">Sept 2022</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Nov</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Jan</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Apr</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">June</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">June 2023</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">July-Aug</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">July-Aug</TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: '#f0f0f4' }}>
                        <TableCell style={webStyle.tableBody} align="center">Dec 2022 (for US accreditation only)</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Feb</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Apr</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">July</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Sept</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Sept 2023</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Oct-Nov</TableCell>
                        <TableCell style={webStyle.tableBody} align="center">Oct-Nov</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                <Typography style={webStyle.accordianBody}>
                  *For US Accreditation only.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  <span style={{ color: '#9167ac' }}>IMPORTANT NOTE:</span>
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  One Grade Level Per School Year: Each student can only cover one grade level per school year.Follow the 10 weeks per quarter or 40 weeks per school year cycle.<br />
                  For TOUCH families, conveniently schedule your child's PORTFOLIO REVIEW with your Advisor on thelast month of each quarter.<br />
                  For TECH BJU and ONLINE ACADEMY families, conveniently schedule your child's QUARTERLY EXAM inthe last month of each quarter.<br />
                  For TLC, coordinate with your Advisor on the schedule of your child's assessment.<br />
                  Quarterly GRADES are due in the last month of the quarter.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  7. Full Disclosure
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. I have given HG full disclosure of each child’s former and current school liabilities, as well as each child’s learning and developmental needs, physical and learning disability/ies (if any) which HGmay use as a basis for recommending The Learning Curve (TLC) services as needed.<br />
                  2. I understand that non-compliance may result in delays, dropping of the student, additional requirements and settlement of The Learning Curve fee when applicable. Should the need arise or become apparent for my child to transfer to The Learning Curve services, I understand that this may entail additional requirements and fees. No reimbursements will apply if I decide to pull out my child.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  8. Clubs and Competition
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  Clubs and Competitions My Child/Children may participate in student-led clubs, subject to parental permission. In the event that my child/children join school-based competitions, the application forms shall bear the name and address of the school of accreditation – not Homeschool Global.<br />
                </Typography>

                <Typography style={webStyle.accordianSubTitle}>
                  9. Accident Disclaimer
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  In agreement that HG is our partner in nurturing and protecting our children, I hereby recognize the possibility of accidents that could happen inside and outside the Homeschool Global (HG) and Hubs’ vicinity and areas of control, such as outside the office, on the street, event venues, and the like.I understand that in the event of such accidents, HG will not be held liable, but is allowed to take necessary measures for our child’s treatment and medication, in case the venue of accident is within the premises of HG. In circumstances when I may not be reached or may not respond immediately upon notification about the incident, I allow the staff to transport our child to a medical facility for immediate treatment and prevention of grave outcomes. Parental presence is always required for field trips and major events. (Not Applicable for Preschool)<br />
                </Typography>

              </AccordionDetails>
            </Accordion>

            {/* ------------------------------ second accordian -------------------------- */}

            <Accordion>
              <AccordionSummary
                style={webStyle.accordionSummaryStyle}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>2. Refund</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Typography style={webStyle.accordianSubTitle}>
                  Refund Policy
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1. The Program fee covers services only until the 12th month from the date of Payment. An extension fee of one (1) months'
                  worth of Program Fee will be charged for every month, or fraction of a month, of extended stay in the program (only up to 3 months allowable extension). Beyond the 3-month period, the student/s will be automatically dropped from the program.<br />
                  2. A student who transfers or otherwise withdraws, in writing the following refund policy will be applicable:
                  <br />
                </Typography>


                <Table>
                  <TableHead style={{ backgroundColor: '#9167ac' }}>
                    <TableRow>

                      <TableCell style={{ color: '#ffffff' }} >Refund Period</TableCell>
                      <TableCell style={{ color: '#ffffff' }}>Percentage of Refund</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell style={webStyle.tableBody}>Within First Week After Payment</TableCell>
                      <TableCell style={webStyle.tableBody}>90%</TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: '#f0f0f4' }}>
                      <TableCell style={webStyle.tableBody}>Within Two Weeks After Payment</TableCell>
                      <TableCell style={webStyle.tableBody}>80%</TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell style={webStyle.tableBody} >Three Weeks and Beyond</TableCell>
                      <TableCell style={webStyle.tableBody}>0%</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
                <br />
                <Typography style={webStyle.accordianBody}>
                  The student may be charged all the school fees in full if he withdraws any time after the second week. However, if the transfer or withdrawal is due to a justifiable reason, the student shall be charged the pertinent fees only up to and including the month of attendance (DepEd policy on refund as stipulated in the 1992 Manual of Regulations for private schools, 8th Edition). This request should be done in writing.
                  No Refund will be given if dropping/withdrawal is processed after the third week of initial payment.<br />
                  3. Our return policy for printed materials is within seven (7) days upon receipt of the product. Books that have been used
                  cannot be returned or exchanged and will be considered sold.<br />
                  4. Due to the nature of online accounts, refunds for the BJU DLO, Ignitia, Mobymax, Stanford 10 and Labster can no longer
                  be made after credentials have been released and the accounts accessed.<br />
                  5. For applicable discounts, a student will be granted with the one that has the higher deduction from fees.<br />

                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  <span style={{ color: '#9167ac ' }}>
                    ADDITIONAL POLICY APPLICABLE ONLY FOR ENROLLEES BASED IN THE PHILIPPINES:
                  </span>
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  6. For installment Initial payment is required plus Post-Dated Check (PDC) for succeeding payment balance/s (dates and
                  amounts on the payment link). At the back of each check, the following information must be indicated: NAME OF STUDENT/S AND PARENT'S CONTACT NUMBER.<br />

                  7. For two-part and five part payment schemes, the next payment shall be every 15th of the following month from the initial
                  payment<br />

                  Returned checks should be settled immediately and will incur a penalty charge of PHP1,000.00. For every week or a fraction thereof of non-payment,
                  a two percent (2%) surcharge will be added to the amount indicated in returned or held check/s. Please take note of the provisions and penalties stated in Batas Pambansa
                  Blg. 22 regarding the making or drawing and issuance of a check without sufficient funds or credit and for other purposes.<br /><br />

                  The student may be charged all the school fees in full if he withdraws any time after the second week. However, if the transfer or withdrawal is due to a justifiable reason, the student shall be charged the pertinent fees only up to and including the month of attendance (DepEd policy on refund as stipulated in the 1992 Manual of Regulations for private schools, 8th Edition). This request should be done in writing.
                  No Refund will be given if dropping/withdrawal is processed after the third week of initial payment.

                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* ---------------------------- third accordian ------------------------------ */}

            <Accordion>
              <AccordionSummary
                style={webStyle.accordionSummaryStyle}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>3. Data Privacy</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Typography style={webStyle.accordianSubTitle}>
                  1. Data Privacy
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  The protection of your personal and sensitive information is essential to us Homeschool Global as a homeschool service provider, we comply with all applicable data protection, DATA PRIVACY ACT OF 2012. As part of being with HG Family, who provide personal and sensitive information to Homeschool Global, you authorize and consent to the use and transfer of said information to valid and legit purposes.<br /><br />
                  Homeschool Global also commits to maintaining the integrity and confidentiality of personal data against any incidents or breaches while ensuring the protection of the fundamental rights of privacy. Homeschool Global employees are obliged to treat all information confidentially.
                  <br />
                </Typography>

              </AccordionDetails>
            </Accordion>

            {/* --------------------------------- fourth accordian --------------------------- */}
            <Accordion>
              <AccordionSummary
                style={webStyle.accordionSummaryStyle}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>4. Privacy Policy - Picture Authorization</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Typography style={webStyle.accordianSubTitle}>
                  1. Picture Authorization (note: You may choose to leave this box unchecked if you do not agree)
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  1.  HG may use my child/ren’s photo/s captured in its various events, for marketing purposes upon my approval. Beginning School Year 2020-2021, the HG Team will formally request for this via email. I recognize that the pictures or images may be used beyond the time that our child is part of the student body, when needed <br />
                </Typography>
                <Typography style={webStyle.accordianSubTitle}>
                  2. Shared HG Resources
                </Typography>
                <Typography style={webStyle.accordianBody}>
                  All access is given by virtue of utmost trust.<br />

                  I understand that all HG shared passwords and access to resources, links, files and other resources, both paid and unpaid, will be for my child/children only and will not be shared with others. <br />
                </Typography>

              </AccordionDetails>
            </Accordion>

          </Grid>
          <Grid container style={webStyle.gridGeneralTerms}>
            <FormControlLabel style={webStyle.formlabelStyle}
              control={
                <Checkbox
                  style={{ color: '#282828', height: '14px', width: '14px' }}
                  checked={studentFormData.TermsAndConditionsAgreeGeneralTerms}
                  onChange={handleCheckedGeneralTerms}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ TermsAndConditionsAgreeGeneralTerms: e.target.checked })}
                />}
              label="   I Agree to The General Terms and Conditions"
            />
            <Box>
              {
                (studentFormData.TermsAndConditionsValidations.TermsAndConditionsAgreeGeneralTerms)
                  ? <small style={webStyle.errorStyle}>{studentFormData.TermsAndConditionsValidations.TermsAndConditionsAgreeGeneralTerms}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
            <FormControlLabel style={webStyle.formlabelStyle}
              control={
                <Checkbox
                  style={{ color: '#282828', height: '14px', width: '14px' }}
                  checked={studentFormData.TermsAndConditionsRefundPolicy}
                  onChange={handleCheckedRefundTerms}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ TermsAndConditionsRefundPolicy: e.target.checked })}
                />}
              label="   I Agree to The Refund Policy"
            />
            <Box>
              {
                (studentFormData.TermsAndConditionsValidations.TermsAndConditionsRefundPolicy)
                  ? <small style={webStyle.errorStyle}>{studentFormData.TermsAndConditionsValidations.TermsAndConditionsRefundPolicy}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
            <FormControlLabel style={webStyle.formlabelStyle}
            
              control={
                <Checkbox
                  style={{ color: '#282828', height: '14px', width: '14px' }}
                  checked={studentFormData.TermsAndConditionsPrivacyPolicy}
                  onChange={handleCheckedPrivacyTerms}
                
                />}
              label="   I Agree to The Privacy Policy"
            />
            <Box>
              {
                (studentFormData.TermsAndConditionsValidations.TermsAndConditionsPrivacyPolicy)
                  ? <small style={webStyle.errorStyle}>{studentFormData.TermsAndConditionsValidations.TermsAndConditionsPrivacyPolicy}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
            <FormControlLabel style={webStyle.formlabelStyle}
              control={
                <Checkbox
                  style={{ color: '#282828', height: '14px', width: '14px' }}
                  checked={studentFormData.TermsAndConditionsPrivacyPolicyPictureAuthorization}
                  onChange={handleCheckedPicturePolicy}
               
                />}
              label="   I Agree to the Privacy Policy - Picture Authorization (optional)"
            />
            <Box>
              
            </Box>
          </Grid>
        </Grid>

        <Modal

          open={open}
          onClose={handleClose}
        >
          <Box style={webStyle.modalStyle}>
            <Box style={webStyle.modalBox}>
              <Typography style={webStyle.modalTextTitle}>
                Your Student Enrollment</Typography>
              <Typography style={webStyle.modalTextTitle}>Form has been submitted.</Typography>
              <Typography style={webStyle.modalTextSubTitle}
              >Would you like to enroll another student<br /><br /></Typography>
            </Box>

            <hr />
            <Box style={webStyle.modalInnerBox}>


              <Button variant='contained'
                style={webStyle.modalButtonYes}
                onClick={handleYes}
              >
                Yes</Button>

              <Button
                variant='contained'
                style={webStyle.modalButtonNo}
                onClick={handleNo}
              >No
              </Button>
            </Box>

            <IconButton
              onClick={handleClose}
              style={{ marginTop: -205, marginLeft: -23 }}
            >
              <CancelIcon style={webStyle.cancelIconStyle} />
            </IconButton>
          </Box>
        </Modal>

      </ThemeProvider>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  gridTextStyle: {
    left: "53px",
    top: "57px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "1em",
    paddingLeft: '1.4em',
  },
  accordionSummaryStyle: {
    backgroundColor: '#9167ac',
    borderRadius: '5px',
    color: '#fff',

  },

  accordianSubTitle: {
    marginBottom: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',

  },
  accordianBody: {
    marginBottom: '10px',
    color: '#555555;',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22px',

  },
  tableBody: {
    marginBottom: '10px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '24px',
    alignText: 'justify',
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    height: '200px',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',

  },
  modalTextSubTitle: {
    color: '#92929d',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    alignText: 'center',
    marginTop: '10px'
  },
  modalButtonYes: {
    marginTop: '10px',
    marginRight: '10px',
    width: '150px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#fab03d',
    fontFamily: 'Poppins',
    color: '#fafafb',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  modalButtonNo: {
    marginTop: '10px',
    width: '150px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#9167ac',
    fontFamily: 'Poppins',
    color: '#fafafb',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  },

  gridGeneralTerms:{
    marginTop: '20px', 
    display: 'flex',
     flexDirection: 'column' as 'column',
      paddingLeft: '1em' ,
  },

 
  formlabelStyle:{ 
    display: 'block',
    marginTop: '10px',
    color: '#848484',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400 
    },
modalBox:{ 
  marginTop: '20px',
   display: 'flex', 
   alignItems: 'center',
    justifyContent: 'center',
     flexDirection: 'column' as 'column',
    },
    modalInnerBox:{
       display: 'flex', 
       alignItems: 'center', 
       justifyContent: 'center'
       },
cancelIconStyle:{ 
  zIndex: 1,
   color: '#9167ac',
    width: '40px',
     height: '40px'
     },
};




// Customizable Area End
