import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Grid,
  Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { forgotPasswordScreen } from "./assets";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import { LazyLoadImage } from "react-lazy-load-image-component"
import CircularProgress from '@material-ui/core/CircularProgress';


const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;
    console.log(this.state.responseEmail)

    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ margin: "20px auto", width: '95%' }}>
          <Grid container style={webStyle.mainWrapper}>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.leftWrapper}>
                <Box sx={webStyle.innerLeftStyle}>
                  <Box style={{ width: "50%" }}>
                    <LazyLoadImage
                      alt="connect"
                      src={forgotPasswordScreen} // use normal <img> attributes as props
                      style={webStyle.imgStyle}
                      effect="blur"
                    />
                  </Box>

                  <Typography
                    variant="h5"
                    component={"h5"}
                    style={webStyle.textStyleLeft}
                  >
                    Everything you need to
                    <span style={{ color: "#171725" }}>
                      {" "}
                      maximize your HG homeschooling journey{" "}
                    </span>{" "}
                    right at your fingertips
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.rightWrapper}>
                <Box sx={webStyle.innerRightStyle}>
                  <Typography
                    variant="h6"
                    component={"h6"}
                    style={webStyle.textStyleRight}
                  >
                    Create Your Password
                  </Typography>
                  <Typography

                    style={webStyle.textStyleRightSub}
                  >
                    Please enter the new password to be created
                  </Typography>
                  <Box>
                    <InputLabel style={webStyle.inputLabelStyle}>
                      {" "}
                      Enter New Password {" "}
                    </InputLabel>
                    <StyledTextField
                      placeholder="Enter Password"
                      type="password"
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({ password: e.target.value })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon style={{ color: "#b5b5be" }} />
                          </InputAdornment>
                        ),
                      }}
                      name='password'
                      onFocus={this.hideError}
                    />
                    {/* <small style={webStyle.errorStyle}>
                      {this.state.error}
                    </small> */}
                    <Box>
                    {
                      (this.state.error.password)
                        ? <small style={webStyle.errorStyle}>{this.state.error.password}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  </Box>

                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.sucessChangePassword} autoHideDuration={4000} onClose={this.handleClosePassword} >
                    <Alert severity="success">
                      reset password successfully please login
                    </Alert>

                  </Snackbar>

                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.DeclinedChangePassword} autoHideDuration={6000} onClose={this.handleClosepasswordfail} >
                  {/* }} open={this.state.DeclinedChangePassword} autoHideDuration={4000} onClose={this.handleClosepasswordfail} > */}
                    <Alert severity="error">
                      {/* something went wrong please try again */}
                      {this.state.snackbarMessage}
                    </Alert>

                  </Snackbar>
                  <Button
                    type="submit"
                    style={{
                      textTransform: "none", ...webStyle.onNewPassword
                    }}
                    fullWidth
                    onClick={this.onNewPassword}
                  >{this.state.loadingPassword ? <CircularProgress /> : " Create Password"}
                    {/* Create Password */}
                  </Button>
                  <Box
                    style={webStyle.LoginPage}
                  >
                    <Typography style={webStyle.textStyleInquiry}>
                      Back to{" "}
                      <span style={webStyle.btnStyleInquiry}>
                        <Link to="EmailAccountLoginBlock"><span style={{color:'black'}}>Login</span> </Link>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Container>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  LoginPage:{
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  onNewPassword: {
    width: "100%",
    height: "52px",
    marginTop: "40px",
    marginBottom: "26px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: "#fafafb",
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontWeight: 600,
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    height: "100%",
    justifyContent: "center",
    boxShadow: "3px -5px 40px rgba(205,205,212,0.1)",
  },

  mainInnerWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
  },

  textStyleRight: {
    marginTop: "3em",
    marginBottom: "36px",
    height: "42px",
    color: "#171725",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "42px",
    letterSpacing: "0.12px",
  },
  textStyleRightSub: {
    color: "#555555",
    fontFamily: " Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    height: "44px"
  },

  leftWrapper: {
    flex: 1,
    border: "1px solid #f1f1f5",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textStyleLeft: {
    marginTop: "22px",
    color: "#757575",
    fontFamily: "Poppins",
    fontSize: " 25px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0.1px",
    alignText: "center",
  },
  innerRightStyle: {
    paddingLeft: "50px",
    paddingRight: "50px",
  }, imgStyle: {
    width: '100%',
  },
  inputLabelStyle: {
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '2em'
  },

  innerLeftStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "90px",
    textAlign: "center",
  },
  rightWrapper: {
    flex: 1,
    //border: '2px solid #996699',
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    backgroundColor: "#fbfbfb",
  },
  textStyleInquiry: {
    marginTop: "0.5em",
    color: "#696974",
    fontFamily: "Roboto",
    fontSize: " 16px",
    fontWeight: 400,
    lineHeight: "19px",
    letterSpacing: "0.1px",
  },
  btnStyleInquiry: {
    marginTop: "25px",
    textDecoration: "underline",
    fontFamily: "Roboto",
    fontSize: " 16px",
    fontWeight: 500,
    lineHeight: "19px",
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Roboto",
    fontSize: " 12px",
  },
};
// Customizable Area End
