

import React from "react";

import {
   // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
     // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles  } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

// Customizable Area End
  // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
// Customizable Area End
export default class HomeschoolResults extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Homeschool Recommender</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            <Box style={{
                                                paddingBottom: '4em',
                                                marginTop: '2em',
                                            }}>
                                                <Typography style={{
                                                    color: "#171725",
                                                    fontFamily: "Poppins",
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    marginRight: '2em',
                                                }}>Result</Typography>
                                                <Typography style={{
                                                    color: "#171725",
                                                    fontFamily: "Poppins",
                                                    fontSize: "18px",
                                                    fontWeight: 400
                                                }}>As per the answers marked , you have scored <span style={{ color: "#99cb51" }}>95%</span></Typography>
                                            </Box>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",

        marginTop: "2em",

    },
};
// Customizable Area End
