import React, { createContext, useReducer } from 'react';
import StudentReducer from './Reducer';
import SudentInitialState, {HomeSchoolProgramInitialState,HomeSchoolStyleInitialState,CheckoutPages,CheckoutSwitchProgramInitialState} from './GlobalProvider'
export const GlobalContext: any = createContext({});
const GlobalProvider = ({ children }: any) => {
  
    const [StudentnameState, StudentDispatch] = useReducer(StudentReducer, SudentInitialState)
    const [homeSchoolProgram, homeSchoolProgramDispatch] = useReducer(StudentReducer, HomeSchoolProgramInitialState)
    const [homeSchoolStyle, homeSchoolStyleDispatch] = useReducer(StudentReducer, HomeSchoolStyleInitialState)
    const [checkoutState, checkoutStateDispatch] = useReducer(StudentReducer, CheckoutPages)
    const [CheckoutSwitchProgram, CheckoutSwitchProgramStateDispatch] = useReducer(StudentReducer, CheckoutSwitchProgramInitialState)

    const [StudentStateUpdate, StudentStateUpdateDispatch] = useReducer(StudentReducer, false)
    return (
        <GlobalContext.Provider value={{
            StudentnameState,
            StudentStateUpdate,
            homeSchoolProgram,
            homeSchoolStyle,
            checkoutState,
            CheckoutSwitchProgram, 
            checkoutStateDispatch,
            StudentDispatch,
            StudentStateUpdateDispatch,
            homeSchoolProgramDispatch,
            homeSchoolStyleDispatch,
            CheckoutSwitchProgramStateDispatch
            
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;