import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
   Grid,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import { avatar } from './assets';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {

    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiInput: {
      underline: {

        " &:before": {
          borderBottom: 'none',
          transition: 'none',
          content: 'none',
        },

      },
    },

  },
});


// Customizable Area End
// Customizable Area Start
import TicketSystemController, {
  Props,
} from "./TicketSystemController";
 // Customizable Area End
export default class Tickets extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
              />
            </Grid>


            {/* ---------------------- drawer Content ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >

                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                  <Box style={{ marginTop: '100px' }}>
                    <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Tickets </Typography>
                  </Box>
                  <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', backgroundColor: '#dcdcdc' }}></hr>


                  <Grid container spacing={2}>
                    {/* ------------------------- Submitted  start----------------------------------- */}
                    <Grid item xs={12} lg={6} style={{ marginTop: '30px' }}>
                      <Typography style={webStyle.textTicketStyle}>Submitted</Typography>
                      <Grid container style={webStyle.gridContentContainerStyle} >
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Under Review</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: '#eac543', ...webStyle.btnStatus }}>Under Review: 10-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                                <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Typography style={webStyle.avatarTitleTextStyle}>Review by</Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                                <Typography style={webStyle.avatarTitleTextStyle}>Pawan Sandhu</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', height: "1%" }}></hr>
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Under Review</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: '#eac543', ...webStyle.btnStatus }}>Under Review: 10-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                                <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Typography style={webStyle.avatarTitleTextStyle}>Review by</Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                                <Typography style={webStyle.avatarTitleTextStyle}>Pawan Sandhu</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>


                    {/* ------------------------- Submitted  end----------------------------------- */}
                    {/* ------------------------- Accepted  start----------------------------------- */}
                    <Grid item xs={12} lg={6} style={{ marginTop: '30px' }}>
                      <Typography style={webStyle.textTicketStyle}>Accepted</Typography>
                      <Grid container style={webStyle.gridContentContainerStyle} >
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Accepted</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: ' #99cb51', ...webStyle.btnStatus }}>Accepted: 11-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>
                        <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', height: "1%" }}></hr>
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Accepted</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: ' #99cb51', ...webStyle.btnStatus }}>Accepted: 11-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                    {/* ------------------------- Accepted  end----------------------------------- */}
                    {/* ------------------------- Rejected  start----------------------------------- */}
                    <Grid item xs={12} lg={6} style={{ marginTop: '30px' }}>
                      <Typography style={webStyle.textTicketStyle}>Rejected</Typography>
                      <Grid container style={webStyle.gridContentContainerStyle} >
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Rejected</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                            <Button style={{ textTransform: 'none', backgroundColor: '#fb5555', ...webStyle.btnStatus }}>Rejected: 12-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>
                        <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', height: "1%" }}></hr>
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Rejected</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: '#fb5555', ...webStyle.btnStatus }}>Rejected: 12-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                    {/* ------------------------- Rejected  end----------------------------------- */}
                    {/* ------------------------- Enrolled  start----------------------------------- */}
                    <Grid item xs={12} lg={6} style={{ marginTop: '30px' }}>
                      <Typography style={webStyle.textTicketStyle}>Enrolled</Typography>
                      <Grid container style={webStyle.gridContentContainerStyle} >
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Enrolled</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                            <Button style={{ textTransform: 'none', backgroundColor: '#9167ac', ...webStyle.btnStatus }}>Enrolled: 10-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>
                        <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', height: "1%" }}></hr>
                        {/* -------------------- first ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingTextStyle}>Enrolled</Typography>
                        </Grid>

                        {/* -------------------- second ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography style={webStyle.headingPinTextStyle}>Enrollment PIN-012345 Submitted</Typography>
                            </Grid>
                            <Grid item>
                              <Button style={{ textTransform: 'none', backgroundColor: '#9167ac', ...webStyle.btnStatus }}>Enrolled: 10-Mar-2022</Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* -------------------- third ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.headingStatusTextStyle}>Your enrollment for “Student Name” with PIN-012345 is under review.</Typography>
                        </Grid>

                        {/* -------------------- fourth ---------------------- */}
                        <Grid item xs={12} style={{ marginBottom: '1em' }}>
                          <Typography style={webStyle.avatarTitleTextStyle}>Submitted by</Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Typography style={webStyle.avatarTitleTextStyle}>Client Name</Typography>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                    {/* ------------------------- Enrolled  end----------------------------------- */}
                  </Grid>

                </Container>

                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

              </Container>
            </Grid>
          </Grid>


        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  welcomeTextStyle: {
    //marginTop: '50px',
    // color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
   },

  btnStatus: {
    width: '200px',
    height: '24px',
    borderRadius: '10px',
    border: "none",
    color: '#fafafb',
    fontFamily: 'Poppins',
    fontSize: ' 11px',
    fontWeight: 500,
    backgroundBlendMode: 'normal',
    padding: '1.2em 0.5em'
  },

  gridContentContainerStyle: {
    marginTop: '14px',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '20px',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between'


  },
  textTicketStyle: {
    height: '36px',
    color: '#171725',
    fontFamily: '  Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  headingTextStyle: {
    height: '18px',
    color: '#92929d',
    fontFamily: '  Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
   },

  headingPinTextStyle: {
    height: '21px',
    color: '#171725',
    fontFamily: '  Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
  },
  headingStatusTextStyle: {

    color: '#383838',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,

  },
  avatarTitleTextStyle: {
    height: '14px',
    color: ' #92929d',
    fontFamily: '   Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginBottom: '0.6em'
  },
  cardAvatarStyle: {
    width: '24px',
    height: '24px',
    marginRight: '10px'
  },
  
};
// Customizable Area End
