  // Customizable Area Start
import React from "react";
// Customizable Area End
import {
    // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  CardMedia,
  // Customizable Area End
} from "@material-ui/core";
    // Customizable Area Start
import DashboardController from "./DashboardController";
  // Customizable Area End
import { Backgroundimg, HomeSchoolRecomender } from '../../dashboard/src/assets'
import Avatar from '@material-ui/core/Avatar';
import { avatar } from './assets';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

  // Customizable Area End




export default class DashBoardPageNonExpandable extends DashboardController {
  render() {
    return (
      <Container maxWidth={false}>


        <Grid container>
          {/* ---------------------- drawer ------------------------- */}
          <Grid item>
            <Drawer openD={this.state?.openD}
              handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
              navigation={this.props.navigation}
            />
          </Grid>
          {/* ---------------------- drawer Content ------------------------- */}
          <Grid item>
            <Container
              style={
                this.state?.openD ?
                  {
                    marginLeft: `${this.state?.drawerWidth}px`,
                    maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`
                  } :
                  { maxWidth: '90%' }
              }
              maxWidth="lg"
            >
              {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

              <Container maxWidth={false} style={{ margin: '0 auto', width: "95%", padding: '0em' }}>
                <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography style={webStyle.welcomeTextStyle} >Welcome Client!</Typography>
                  <Button style={{
                    height: "52px",
                    fontFamily: "Poppins",
                    width: "235px",
                    fontSize: "16px",
                    color: "#fafafb",
                    fontWeight: 600,
                    textAlign: "center",
                    borderRadius: "10px",
                    backgroundColor: "#fab03d"
                  }}>ENROLL NOW</Button>

                </Box>
                <Grid container style={{ display: 'flex' }}>
                <HorizontalLine/>
                </Grid>
                <Container maxWidth={false} style={{ padding: '0' }}>
                  <Grid container spacing={3} style={{ display: "flex", textAlign: "center", marginTop: '2em' }}>
                    <Grid item xs={12} md={5}  >
                      <Typography style={{
                        color: " #171725",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingBottom: "1em"
                      }}>Homeschool Orientation</Typography>
                      <CardMedia
                        component="img"
                        image={Backgroundimg}
                        title="Contemplative Reptile"
                        style={{ width: '100%', height: '90%', borderRadius: '20px' }}
                      />

                    </Grid>

                    {/* ------------------------------------------------- PROGRAMS grid ----------------- */}
                    <Grid item xs={12} md={7}  >
                      <Typography style={{
                        color: " #171725",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingBottom: "1em"
                      }}>Our Programs</Typography>
                      <Paper elevation={0} style={webStyle.paperContainer} >
                        <Grid container style={{ display: 'flex', alignItems: 'stretch',justifyContent:'space-evenly', boxSizing: 'border-box' }}>
                          {/* --------------- orange grid ----------------- */}
                          <Grid item xs={12} md={3} style={{ backgroundColor: 'rgba(255,255,255,0.5)', padding: '1em', border: '2px solid #fab03d', borderRadius: '15px' }}>
                            <Box >
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Touch</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with comprehensive advisor support
                                  involving parental instruction
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Curated</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum with comprehensive support and guidance from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Charlotte Mason </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Classical</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Classical Christian curriculum
                                      withcomprehensive support
                                      and guidance through weekly
                                      community classes, powered
                                      by Classical Conversations
                                      Philippines</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Grid>

                          {/* --------------- purple grid ----------------- */}

                          <Grid item xs={12} md={3} style={{marginRight:'1em', backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #B98ED6', borderRadius: '15px' }}>
                            <Box >
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Tech</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with platform-
                                  driven essential advisor support
                                  involving parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>BJU (Blended)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      High-quality blended
                                      curriculum with essential
                                      Advisor support                              </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>AOP (Fully Online) </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>High-quality fully online
                                      curriculum with essential
                                      Advisor support</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Custom (Open)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable curriculum
                                      with platformdriven essential
                                      Advisor support and basic
                                      academic assistance for
                                      records designed for
                                      home schooling families 3
                                      years and up</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Grid>
                          {/* --------------- green grid ----------------- */}
                          <Grid item xs={12} md={3} style={{ backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #8FBE49', borderRadius: '15px' }}>
                            <Box >
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Online Academies</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Flexible online learning with teacher
                                  supervision, grading, and optional
                                  academic classes involving minimal
                                  parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum
                                      with comprehensive
                                      support and guidance
                                      from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                <Box style={{ marginLeft: '8px', display: 'flex' }}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy Plus </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>

                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography style={{
                        color: " #171725",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingBottom: "1em"
                      }}>Homeschool Recommender</Typography>
                      <CardMedia
                        component="img"
                        image={HomeSchoolRecomender}
                        title="Contemplative Reptile"
                        style={{ width: '100%', borderRadius: '20px' }}

                      />
                      <Box style={{ paddingTop: '1em' }}>
                        <Button style={{
                          height: "52px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#fafafb",
                          fontWeight: 600,
                          textAlign: "center",
                          borderRadius: "10px",
                          backgroundColor: "#fab03d",
                          textTransform: "none"
                        }}>Get your Personalised Recommendation</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography style={{
                        color: " #171725",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingBottom: "1em"
                      }}>Testimonials</Typography>
                      <CardMedia
                        component="img"
                        image={Backgroundimg}
                        title="Contemplative Reptile"
                        style={{ width: '100%', borderRadius: '20px', height: '90%' }}
                      />
                    </Grid>
                    <Box style={{ padding: '0.5em' }}>{null}</Box>
                    <Grid container style={webStyle.backGround} sm={5} >

                      <Grid container style={{ display: "flex", padding: '0.5em', }}>

                        <Grid item xs={12} md={5}  >
                          <Typography style={{
                            color: " #171725",
                            fontFamily: "Poppins",

                            fontWeight: 600,
                            fontSize: "14.5px",
                            width: "200px",
                            marginLeft: '-1em',
                            textAlign: "center",
                            paddingBottom: "1em",
                          }}>Our Partner Schools</Typography>
                          <Box style={{ display: "flex" }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} style={{
                          flexGrow: 1

                        }} >
                          <Typography style={{
                            color: " #171725",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "14.5px",
                            width: "300px",
                            textAlign: "left",

                            paddingBottom: "1em",

                          }}>Our Partner Schools’ Accreditations</Typography>
                          <Box style={{ display: "flex", marginBottom: '2em', marginRight: "1em" }}>
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                            <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                          </Box>
                        </Grid>

                      </Grid>

                    </Grid>

                  </Grid>



                </Container>
              </Container>



              {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}



            </Container>
          </Grid>
        </Grid>







      </Container>


    )
  }
}

const webStyle = {
  backGround: {
    backgroundImage: `url(${Backgroundimg})`,
    height: "100%",
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: "20px",
    paddingLeft: '1em'

  },
  cardAvatarStyle: {
    width: '50px',
    height: '50px',
    marginRight: '10px'
  },
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  HomeSchoolRecomender: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: 'left'
  },
  outerProgramsStyle: {
    width: '100%',
    height: '90%',
    borderRadius: '20px',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerProgramsStyle: {
    backgroundColor: 'red',
    position: 'absolute' as 'absolute',
    height: '90%',
    width: '96%',
  },

  paperContainer: {

    alignText: 'center',
    width: '100%',
    height: '83%',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.2em',
  },

  gridProgramContainerStyle: {
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    padding: '10px',
    display: 'flex',
    margin: '15px',

  },

  programAvatarStyle: {
    width: '40px',
    height: '40px',
  },

  ProgramInnerTextHeader: {

    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: 700,
  },

  programInnerTextSubText: {
    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '9px',
    fontWeight: 300,

  },
  programMainText: {
    color: ' #757575',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 800,
    alignText: 'left',

  },
  programMainSubText: {
    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: 400,
  },

}