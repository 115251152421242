// Customizable Area Start
import React from "react";
// Customizable Area End
import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
  CardMedia,
  Modal,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import DashboardController from "./DashboardController";
// Customizable Area End
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import DashboardVideo from "../../../components/src/DashboardVideo.web";
import TestimonialVideos from "../../../components/src/TestimonialVideos.web";
import Drawer from "../../../components/src/Drawer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "@material-ui/core/Snackbar";
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Alert from '@material-ui/lab/Alert';
import {
  Backgroundimg,
  HomeSchoolRecomender,
  mother_daughter,
  bju,
  aop_custom,
  classical,
  mason,
  online_academy1,
  online_academy2,
  acc1, acc2, acc3, acc4,
  partner_school1, partner_school2, partner_school3,
  chat_bubble, call_bubble, robot,
  introvideo_thumbnail
} from './assets';
import HgVideo from "../../../components/src/HgVideo.web";


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});



// Customizable Area End


export default class DashBoardNonExpandablePage extends DashboardController {
  setTimeout: any;
  render() {

    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>
              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
                fullName={this.state?.userData?.fullName}
                postVideoDetails={this.postVideoDetails}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >
                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}
                <Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>
                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Welcome {this.state?.userData?.fullName}</Typography>
                    {
                      !this.state.userData?.isActive ?
                        <Typography style={webStyle.verifyTextStyle} >Verify your Email Address to continue with enrollment! </Typography>
                        : null
                    }

                    <Button style={webStyle.btnEnrollNow}
                      onClick={this.handleEnroll}
                      data-test-id="btnEnroll"
                    >ENROLL NOW </Button>
                  </Box>

                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>

                  <Container maxWidth={false} style={{ padding: '0' }}>
                    {/* ----------------------------------------- FIRST  GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                      {/* ------------------------------------------------- ORIENTATION grid ---------------------------------------- */}
                      <Grid item xs={12} lg={5}   >

                        <Typography align='left' style={webStyle.headerTextStyle}>Homeschool Orientation</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerOrientation }} >
                          <Box style={webStyle.boxOrientation}>

                            {this.state.isLoading && this.state.allVideos_introVideoURL != "" ?


                              <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                              : <DashboardVideo
                                introVideoURL={this.state.allVideos_introVideoURL}
                                watchTime={localStorage.getItem('curentTime')}
                                handleGetIntroVideoBtn={this.handleGetIntroVideoBtn}
                                handleNext={this.handleNext}
                                introvideo_thumbnail={introvideo_thumbnail}
                              />
                            }

                          </Box>
                        </Paper>
                      </Grid>

                      {/* ------------------------------------------------- PROGRAMS grid ---------------------------------------- */}
                      <Grid item xs={12} lg={7} >
                        <Typography align='left' style={webStyle.headerTextStyle}>Our Programs</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerProgram }}  >

                          <Grid container style={{ display: 'flex', alignItems: 'stretch', boxSizing: 'border-box', padding: '0.5vw' }}>
                            {/* --------------- orange grid ----------------- */}

                            <Grid item style={webStyle.gridItemProgramStyle}>
                              {/* <Grid item  style={{ margin:'0.5vw', width:'30%', flexGrow:1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5vw', border: '2px solid #fab03d', borderRadius: '15px' }}> */}

                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Touch</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with comprehensive advisor support
                                  involving parental instruction
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={mother_daughter} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Curated</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum with comprehensive support and guidance from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={mason} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Charlotte Mason </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes)}>
                                <Avatar alt="program avatar" src={classical} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Classical</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Classical Christian curriculum
                                      with comprehensive support
                                      and guidance through weekly
                                      community classes, powered
                                      by Classical Conversations
                                      Philippines</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>

                            {/* --------------- purple grid ----------------- */}

                            <Grid item style={webStyle.gridItemProgramStyle} >
                              {/* <Grid item  style={{ margin:'0.5vw', width:'30%',flexGrow:1,backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #B98ED6', borderRadius: '15px' }} > */}
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Tech</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with platform- driven essential advisor support involving parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={bju} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>BJU (Blended)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      High-quality blended curriculum with essential Advisor support</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={aop_custom} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>AOP (Fully Online) </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>High-quality fully online
                                      curriculum with essential
                                      Advisor support</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes)}>
                                <Avatar alt="program avatar" src={aop_custom} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Custom (Open)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable curriculum
                                      with platformdriven essential
                                      Advisor support and basic
                                      academic assistance for
                                      records designed for
                                      home schooling families 3
                                      years and up</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>

                            {/* --------------- green grid ----------------- */}

                            <Grid item style={webStyle.gridItemProgramStyle}>
                              {/* <Grid item  style={{ margin:'0.5vw',width:'30%',flexGrow:1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #8FBE49', borderRadius: '15px' }}> */}
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Online Academies</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Flexible online learning with teacher
                                  supervision, grading, and optional
                                  academic classes involving minimal
                                  parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes)}>
                                <Avatar alt="program avatar" src={online_academy1} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum
                                      with comprehensive
                                      support and guidance
                                      from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                              {/* ------------------------------------------------------------- */}

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes)}>
                                <Avatar alt="program avatar" src={online_academy2} style={webStyle.programAvatarStyle} />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy Plus </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist</Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>

                          </Grid>

                        </Paper>
                      </Grid>
                    </Grid>


                    {/* ----------------------------------------- SECOND GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                      {/* ------------------------------------------------- HOME RECOMMENDER grid ---------------------------------------- */}
                      <Grid item xs={12} lg={5}  >

                        <Typography align='left' style={webStyle.headerTextStyle}>Homeschool Recommender</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerRecommender }} >
                          <Box style={webStyle.boxOrientation1}>
                            {/* <Box style={webStyle.boxOrientation}> */}
                            <Box>
                              <CardMedia
                                component="img"
                                image={HomeSchoolRecomender}
                                title="HomeSchoolRecomender Image"
                                style={{ width: '100%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
                              />
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Button variant='contained' style={{ textTransform: "none", ...webStyle.btnRecommendation }}
                                onClick={() => this.props.navigation.navigate('HomeschoolButtons')}
                              >Get your Personalised Recommendation</Button>
                            </Box>
                          </Box>

                        </Paper>

                      </Grid>

                      {/* ------------------------------------------------- Testimonials grid ---------------------------------------- */}
                      <Grid item xs={12} lg={7}  >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography align='left' style={webStyle.headerTextStyle}>Testimonials</Typography>

                        </Box>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerTestimonial }}  >

                          <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box style={webStyle.boxTestimonial}>

                              {this.state?.testVideosData.length > 0 && !this.state?.testimonial?.one &&
                                <TestimonialVideos VideoURL={this.state.testVideosData[0].videoURL}
                                  testVideosData={this.state?.testVideosData}
                                  testimonial={this.state?.testimonial}
                                  handleTestimonialOne={this.handleTestimonialOne}
                                  handleTestimonialTwo={this.handleTestimonialTwo}
                                  handleTestimonialThree={this.handleTestimonialThree}
                                  handleTestimonialFour={this.handleTestimonialFour}
                                  handleTestimonialFive={this.handleTestimonialFive}
                                />}
                              {this.state?.testVideosData.length > 0 && !this.state?.testimonial?.two &&
                                <TestimonialVideos VideoURL={this.state.testVideosData[1].videoURL}
                                  testVideosData={this.state?.testVideosData}
                                  testimonial={this.state?.testimonial}
                                  handleTestimonialOne={this.handleTestimonialOne}
                                  handleTestimonialTwo={this.handleTestimonialTwo}
                                  handleTestimonialThree={this.handleTestimonialThree}
                                  handleTestimonialFour={this.handleTestimonialFour}
                                  handleTestimonialFive={this.handleTestimonialFive}

                                />}
                              {this.state?.testVideosData.length > 0 && !this.state?.testimonial?.three &&
                                <TestimonialVideos VideoURL={this.state.testVideosData[2].videoURL}
                                  testVideosData={this.state?.testVideosData}
                                  testimonial={this.state?.testimonial}
                                  handleTestimonialOne={this.handleTestimonialOne}
                                  handleTestimonialTwo={this.handleTestimonialTwo}
                                  handleTestimonialThree={this.handleTestimonialThree}
                                  handleTestimonialFour={this.handleTestimonialFour}
                                  handleTestimonialFive={this.handleTestimonialFive}

                                />}
                              {this.state?.testVideosData.length > 0 && !this.state?.testimonial?.four &&
                                <TestimonialVideos VideoURL={this.state.testVideosData[3].videoURL}
                                  testVideosData={this.state?.testVideosData}
                                  testimonial={this.state?.testimonial}
                                  handleTestimonialOne={this.handleTestimonialOne}
                                  handleTestimonialTwo={this.handleTestimonialTwo}
                                  handleTestimonialThree={this.handleTestimonialThree}
                                  handleTestimonialFour={this.handleTestimonialFour}
                                  handleTestimonialFive={this.handleTestimonialFive}

                                />}
                              {this.state?.testVideosData.length > 0 && !this.state?.testimonial?.five &&
                                <TestimonialVideos VideoURL={this.state.testVideosData[4].videoURL}
                                  testVideosData={this.state?.testVideosData}
                                  testimonial={this.state?.testimonial}
                                  handleTestimonialOne={this.handleTestimonialOne}
                                  handleTestimonialTwo={this.handleTestimonialTwo}
                                  handleTestimonialThree={this.handleTestimonialThree}
                                  handleTestimonialFour={this.handleTestimonialFour}
                                  handleTestimonialFive={this.handleTestimonialFive}

                                />

                              }

                            </Box>

                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>


                    {/* ----------------------------------------- THIRD  GRID ROW ---------------------------------------- */}
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                      {/* ------------------------------------------------- PARTNER SCHOOL grid ---------------------------------------- */}
                      <Grid item xs={12} lg={5}  >

                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer }} >
                          <Grid container spacing={3} style={webStyle.boxPartnerSchool}>
                            <Grid item xs={12} md={5}>
                              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography align='center' style={webStyle.headerTextStyle}>Our Partner <br />Schools</Typography>
                                <Box style={{ width: '100%', backgroundColor: '#E5E5E5', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '1em', boxSizing: 'border-box' }}>
                                  <CardMedia
                                    component="img"
                                    image={partner_school1}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                  <CardMedia
                                    component="img"
                                    image={partner_school2}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                  <CardMedia
                                    component="img"
                                    image={partner_school3}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            {/* --------------------------------------------------------------------------- */}
                            <Grid item xs={12} md={7} >
                              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography align='center' style={webStyle.headerTextStyle}>Our Partner Schools’ <br /> Accreditations</Typography>
                                <Box style={{ width: '100%', backgroundColor: '#E5E5E5', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '1em', boxSizing: 'border-box' }}>
                                  <CardMedia
                                    component="img"
                                    image={acc1}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                  <CardMedia
                                    component="img"
                                    image={acc2}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                  <CardMedia
                                    component="img"
                                    image={acc3}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                  <CardMedia
                                    component="img"
                                    image={acc4}
                                    title="school partner"
                                    style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>

                      {/* ------------------------------------------------- CHATBOT grid ---------------------------------------- */}
                      <Grid item xs={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >


                        <Grid item xs={12} sm={5} style={{ padding: '0.6em', border: '1px solid #f1f1f5', boxShadow: '0px 5px 15px rgba(68,68,79,0.1' }}>
                          <IconButton style={{ marginTop: '-30px', marginLeft: '-30px' }}>
                            <Box style={{ borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '25px', height: '25px' }}>
                              <small>x</small>

                            </Box>
                          </IconButton>
                          <Box >
                            <Typography style={webStyle.chatText}>Got questions about HG or any of our programs</Typography>
                          </Box>
                          <hr></hr>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                            <img src={robot}></img>
                            <Button variant='contained' style={{ textTransform: 'none', ...webStyle.btnChat }}>Chat Now</Button>
                          </Box>



                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <IconButton>
                            <img src={chat_bubble}></img>
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <IconButton>
                            <img src={call_bubble}></img>
                          </IconButton>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Container>
                </Container>
              </Container>
            </Grid>
          </Grid>

        </Container>
        {/* -------------------------------------------- modal verify email ------------------------------------------------------------- */}
        <Modal
          open={this.state.openverify}
        >
          <Box style={webStyle.modalStyle}>
            <Box style={{ padding: '1em', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography style={webStyle.modalTextTitle}>
                Verify Your Email to Enroll Now</Typography>

              <Typography align='center' style={webStyle.modalTextSubTitle}
              >To continue with your enrollment, kindly verify your email address.<br /><br />
              </Typography>
            </Box>

            <hr />
            <Snackbar anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} open={this.state.snackbarOpen}
              autoHideDuration={4000}
              onClose={this.handleCloseLink}
              data-test-id="snackbarCloseEmailLink"
            >
              <Alert severity="success">
                {this.state.msg}
              </Alert>

            </Snackbar>
            <Snackbar anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} open={this.state.snackbarOpenFalse}
              autoHideDuration={4000}
              onClose={this.handleCloselink1}
              data-test-id="snackbarCloseResendLink"
            >
              <Alert severity="error">
                {this.state.msg}
              </Alert>

            </Snackbar>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant='contained'
                style={webStyle.modalButtonVerify}
                onClick={this.handleVerify}
                data-test-id="btnResendLink"
              >
                {this.state.loaderResendLink ? <CircularProgress /> : "Resend Link"}</Button>
            </Box>
            <IconButton data-test-id="btnCloseEmailModal" onClick={this.handleClose} style={{ marginTop: '-210px', marginLeft: '-30px' }}>
              <Box style={{ paddingTop: '2px', textAlign: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small style={{ textAlign: 'center' }}>x</small>

              </Box>
            </IconButton>
          </Box>
        </Modal>

        {/* -------------------------------------------- modal check video completion------------------------------------------------------------- */}
        <Modal
          open={this.state.openverifyVideo}
        >
          <Box style={webStyle.modalStyle}>
            <Box style={{ padding: '2em', marginTop: '10px' }}>

              <Typography align='center' style={webStyle.modalTextSubTitleVideo}
              >Don't forget to finish watching the
                HG Orientation Video so you can move forward
                to the next step: enrollment!
              </Typography>
            </Box>

            <hr />
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant='contained'
                style={webStyle.modalButtonVerify}
                onClick={this.handleVerifyVideo}
                data-test-id="btnVerifyVideo"
              >
                Proceed</Button>
            </Box>
            <IconButton data-test-id="btnCloseVideoModal" onClick={this.handleCloseVideoModal} style={{ marginTop: '-230px', marginLeft: '-30px' }}>
              <Box style={{ paddingTop: '2px', textAlign: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small style={{ textAlign: 'center' }}>x</small>

              </Box>
            </IconButton>
          </Box>
        </Modal>

        {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}
        <Modal
          open={this.state.openModalProgramVideo}
        // onClose={this.handleClose}
        >
          <Box style={{ ...webStyle.modalStyle, height: "70%", width: '44%' }}>
            <IconButton onClick={this.handleCloseProgramVideo} style={{ marginTop: '-20px', marginLeft: '-30px' }}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small>x</small>
              </Box>
            </IconButton>
            <Typography align='left' style={{ ...webStyle.ProgramInnerTextHeader, fontWeight: 500, fontSize: "18px", marginLeft: "25px", paddingLeft: "1rem", color: " rgb(23, 23, 37)" }}>
              {this.state.selectedProgramTitle} - {this.state.selectedProgramDetails?.program_name}
            </Typography>
            <Box style={{ width: '90%', height: '80%', margin: "auto", position: 'relative', padding: '1em', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
              <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'center', }}>
                <HgVideo introVideoURL={this.state.selectedProgramDetails?.program_video} />
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}

      </ThemeProvider>
    )
  }
}

const webStyle = {
  btnEnrollNow: {
    height: "52px",
    fontFamily: "Poppins",
    width: "235px",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#fab03d"
  },
  verifyTextStyle: {
    color: '#a8a8a8;',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  backGround: {
    backgroundImage: `url(${Backgroundimg})`,
    height: "100%",
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: "20px"
  },
  cardAvatarStyle: {
    width: '50px',
    height: '50px',
    marginRight: '10px'
  },
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  HomeSchoolRecomender: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: 'left'
  },
  outerProgramsStyle: {
    width: '100%',
    height: '90%',
    borderRadius: '20px',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerProgramsStyle: {
    backgroundColor: 'red',
    position: 'absolute' as 'absolute',
    height: '90%',
    width: '96%',
  },

  paperContainer: {

    alignText: 'center',

    minHeight: '100px',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5em',
  },
  paperContainerProgram: {
    alignText: 'center',
    minHeight: '20vw',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5vw',
  },
  paperContainerOrientation: {

    alignText: 'center',
    width: '100%',
    minHeight: '27vw',
    // minHeight: '420px',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0.5em',
    paddingBottom: '4em',
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
  paperContainerTestimonial: {
    alignText: 'flex-start',
    width: '100%',
    minHeight: '470px',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    // padding: '0.5em',
  },


  paperContainerRecommender: {

    alignText: 'center',
    width: '100%',
    minHeight: '470px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',

  },

  gridProgramContainerStyle: {

    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
    padding: '0.3vw',

    display: 'flex',
    // margin: '10px',
    marginTop: '0.5vw',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer'
  },
  gridItemProgramStyle: {
    margin: '0.5vw',
    width: '30%', flexGrow: 1,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '0.5vw',
    border: '2px solid #fab03d',
    borderRadius: '15px'
  },
  programAvatarStyle: {
    // position: 'absolute' as 'absolute',
    width: '2.7vw',
    height: '2.7vw',
    // top: '14px',
    // left: '-17px',
  },

  programBoxStyle: {
    marginLeft: '0.8vw',
    marginRight: '0.2vw',
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },

  ProgramInnerTextHeader: {
    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.6vw',
    //fontSize: '10px',
    fontWeight: 700,
  },

  programInnerTextSubText: {
    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.6vw',
    //fontSize: '9px',
    fontWeight: 300,

  },
  programMainText: {

    color: ' #757575',
    fontFamily: 'Roboto',
    fontSize: '0.8vw',
    //fontSize: '13px',
    fontWeight: 800,
    alignText: 'left',

  },
  programMainSubText: {

    color: ' #757575',
    fontFamily: 'Poppins',
    fontSize: '0.7vw',
    //fontSize: '10px',
    fontWeight: 400,
  },

  headerTextStyle: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "1em",
  },

  btnWatchLater: {

    width: "235px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '-10px',
    zIndex: 2,
    // marginTop: '2.5em',
    // marginBottom: '1.5em',
  },
  btnRecommendation: {
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '2em',
    // marginBottom: '1.5em',
  },

  boxGreenTestimonial: {
    width: '80%',
    // width: '350px',
    height: "60px",
    // padding: '0.6em',
    borderRadius: "10px",
    backgroundColor: "#8FBE49",
    marginTop: '-2.1vw',
    // marginTop: '-30px',
    marginBottom: '0.5em',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column'
  },
  btnTestimonial: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
  },
  btnTestimonialSubText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: '#fff',
  },


  boxOrientation: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5em'
  },
  boxOrientation1: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    // padding: '0.5em'
  },
  boxPartnerSchool: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5em',
    width: '100%'
  },
  boxTestimonial: {
    // borderTopLeftRadius: '20px', borderTopRightRadius: '20px',
    //marginTop:'1.5vw',
   //  width: '41.6vw',
    width: '100%',
    // width: '600px',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
   // height: '350px',
    justifyContent: 'center',
    // padding: '0.5em'
    //backgroundColor:'red'
  },

  boxTestimonial1: {
    //backgroundColor:'blue',
    // width: '41.6vw',
    width: '100%',
    //  width: '600px',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '120px',
    // position:'fixed' as 'fixed',
    // justifyContent: 'center',
    // padding: '0.5em'
  },

  chatText: {
    color: '#44444f',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700,
  },
  btnChat: {
    width: '104px',
    height: "28px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    margin: '0.6em'
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    height: '235px',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '24px',

  },
  modalTextSubTitle: {
    color: '#92929d',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '10px'
  },
  modalTextSubTitleVideo: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',
    // marginTop: '10px'
  },
  modalButtonVerify: {
    marginTop: '10px',
    marginRight: '10px',
    width: '150px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#fab03d',
    fontFamily: 'Poppins',
    color: '#fafafb',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  display: {
    display: 'none'
  },
  testimonialBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
  },
  testimonialImage: {
    width: '60px',
    height: '60px',
    // width: '4.5vw',
    // height: '4.5vw',
    borderRadius: '5px',
    position: 'relative' as 'relative',
    cursor: 'pointer',
    // margin:'1vw'
  },
  testimonialIcon: {
    fontSize: '2vw',
    color: 'white',
    position: 'absolute' as 'absolute',
    zIndex: 5,
    cursor: 'pointer',
  },
  styleViewAll: {
    color: ' #92929d',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  }

}