const StudentReducer = (state: any, { type, payload }: any) => {
    switch (type) {
        case 'UPDATE_NAME':

        return payload
        case 'UPDATE_PROGRAM_DATA':

            return payload
        case 'UPDATE_PROGRAM_STYLE_DATA':

            return payload
        case 'UPDATE_CHECKOUT_DATA':

            return payload
        case 'UPDATE_NAME_STATE_UPDATE':

            return payload
        case 'SWITCH_PROGRAM':

            return payload

        default:
            return state;
    }

};

export default StudentReducer;