import React from 'react'

import {
    // Customizable Area Start
    Grid,
    Box,
    // Customizable Area End
} from "@material-ui/core";

const HorizontalLine = () => {
  return (
    <>
    <Grid item xs={4}>
        <Box style={{ width: '100%', height: '3px', backgroundColor: '#fab03d' }}><p></p></Box>
    </Grid>
    <Grid item xs={4}>
        <Box style={{ width: '100%', height: '3px', backgroundColor: '#9167ac' }}><p></p></Box>
    </Grid>
    <Grid item xs={4}>
        <Box style={{ width: '100%', height: '3px', backgroundColor: '#99cb51' }}><p></p></Box>
    </Grid>
</>
  )
}

export default HorizontalLine