// Customizable Area Start
import React from "react";
// Customizable Area End
import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Modal,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

import DashboardController, { Props } from "./DashboardController";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import HgVideo from "../../../components/src/HgVideo.web";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import {
  backgroundTwoHgFormula,
  backgroundOneHgFormula_new,
  img_bkg, img_bkg2, img_bkg3, img_bkg4, img_bkg5,
} from './assets';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});



// Customizable Area End



export default class HgFormula extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <span id='link1'></span>
        <Container maxWidth={false} style={{ margin: '0 auto', width: "100%", padding: '0' }}>

          <AppWithoutDrawer navigation={this.props.navigation} />

          <Grid container style={webStyle.gridContainerStyle}>
            {/* --------------------------- section 1 - PURSUE start ------------------------- */}


            <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer }} >
              <Grid container style={{ margin: '0', display: 'flex', boxSizing: 'border-box' }}>
                <Grid item xs={6} sm={6} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box style={{ width: '80%', paddingLeft: '1.7vw' }}>
                    <Typography style={webStyle.persue_TextStyle}>{this.state.hgFormula_PursueWhatMatters?.header}</Typography>

                    <Typography style={{ fontSize: '2.3vw', ...webStyle.persue_SubTextStyle }}>{this.state.hgFormula_PursueWhatMatters?.description}</Typography>

                    <Typography style={{ fontSize: '2.2vw', ...webStyle.persue_SubTextStyle }}>{this.state.hgFormula_PursueWhatMatters?.hg_formula_sections?.data[0]?.attributes?.description}

                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} lg={6}  >
                  <Box style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                    <LazyLoadImage
                      alt='Persue What Matter image'
                      src={this.state.hgFormula_PursueWhatMatters?.hg_formula_sections?.data[0]?.attributes?.image1}
                      style={{ objectFit: 'cover', ...webStyle.imgPersue }}
                      effect="blur"
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* --------------------------- section 1 - PURSUE end ------------------------- */}
              {/* --------------------------- section 2 - Hg Formula start ------------------------- */}

              <Grid container style={{ margin: '0', display: 'flex', boxSizing: 'border-box' }}>
                <Grid item xs={12} style={webStyle.gridItemStyle}>
                  <Box style={{ marginTop: '-1vw', ...webStyle.orange_TitleBox }}>
                    <Typography style={webStyle.orange_TitleTextStyle}>
                      {this.state.hgFormula_TheHgFormula?.header}
                    </Typography>
                  </Box>

                  <Box style={{ width: '40%', ...webStyle.orange_SubTitleBox }}>
                    <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                      {this.state.hgFormula_TheHgFormula?.description}
                    </Typography>
                  </Box>
                </Grid>
                {/* --------------------------------------------- ORANGE BLOCK ---------------------------------------------------------- */}
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={3} sm={3} lg={3}>
                    <Box style={{ backgroundColor: '#fbaf3c', ...webStyle.block_SideBox }}>
                      <Typography style={webStyle.orange_HeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.orange_SubHeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.sub_title}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                    <Box style={webStyle.orange_ContentBox}>
                      <Typography style={webStyle.hg_ContentTextStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                    <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image6}
                      style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                  </Grid>
                </Grid>

                <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginLeft: '3vw', marginBottom: '2vw' }}>
                  {/* --------------------------------- first image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background}>
                      <img src={img_bkg} style={{ objectFit: 'cover', ...webStyle.imgbkgFrist }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox }}>

                        <LazyLoadImage
                          alt='image1'
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image1}
                          style={{ objectFit: 'cover', ...webStyle.imgFirst }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- second image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background2}>
                      <img src={img_bkg2} style={{ objectFit: 'cover', ...webStyle.imgbkgSecond }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox2 }}>
                        <LazyLoadImage
                          alt='image2'
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image2}
                          style={{ objectFit: 'cover', ...webStyle.imgSecond }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- third image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background3}>
                      <img src={img_bkg3} style={{ objectFit: 'cover', ...webStyle.imgbkgThird }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox3 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image3}
                          alt='image3'
                          style={{ objectFit: 'cover', ...webStyle.imgThird }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fourth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background4}>
                      <img src={img_bkg4} style={{ objectFit: 'cover', ...webStyle.imgbkgFourth }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox4 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image4}
                          alt='image4'
                          style={{ objectFit: 'cover', ...webStyle.imgFourth }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fifth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background5}>
                      <img src={img_bkg5} style={{ objectFit: 'cover', ...webStyle.imgbkgFifth }} />
                      <Box style={{ zIndex: 20, ...webStyle.img_BorderBox5 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image5}
                          alt='image5'
                          style={{ objectFit: 'cover', ...webStyle.imgFifth }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
                {/* --------------------------------------------- PURPLE BLOCK ---------------------------------------------------------- */}

                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={3} sm={3} lg={3}>
                    <Box style={{ backgroundColor: '#936AAF', ...webStyle.block_SideBox }}>
                      <Typography style={webStyle.orange_HeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.orange_SubHeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.sub_title}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                    <Box style={webStyle.orange_ContentBox}>
                      <Typography style={webStyle.hg_ContentTextStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.description}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                    <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image6}
                      style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                  </Grid>
                </Grid>

                <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginLeft: '3vw', marginBottom: '2vw' }}>
                  {/* --------------------------------- first image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background}>
                      <img src={img_bkg} style={{ objectFit: 'cover', ...webStyle.imgbkgFrist }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image1}
                          alt='image1'
                          style={{ objectFit: 'cover', ...webStyle.imgFirst }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- second image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background2}>
                      <img src={img_bkg2} style={{ objectFit: 'cover', ...webStyle.imgbkgSecond }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox2 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image2}
                          alt='image2'
                          style={{ objectFit: 'cover', ...webStyle.imgSecond }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- third image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background3}>
                      <img src={img_bkg3} style={{ objectFit: 'cover', ...webStyle.imgbkgThird }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox3 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image3}
                          alt='image3'
                          style={{ objectFit: 'cover', ...webStyle.imgThird }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fourth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background4}>
                      <img src={img_bkg4} style={{ objectFit: 'cover', ...webStyle.imgbkgFourth }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox4 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image4}
                          alt='image4'
                          style={{ objectFit: 'cover', ...webStyle.imgFourth }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fifth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background5}>
                      <img src={img_bkg5} style={{ objectFit: 'cover', ...webStyle.imgbkgFifth }} />
                      <Box style={{ zIndex: 20, ...webStyle.img_BorderBox5 }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[1].attributes?.image5}
                          alt='image5'
                          style={{ objectFit: 'cover', ...webStyle.imgFifth }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>

                </Grid>

                {/* --------------------------------------------- YELLOW BLOCK ---------------------------------------------------------- */}
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={3} sm={3} lg={3}>
                    <Box style={{ backgroundColor: '#fae03d', ...webStyle.block_SideBox }}>
                      <Typography style={webStyle.orange_HeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.orange_SubHeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.sub_title}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                    <Box style={webStyle.orange_ContentBox}>
                      <Typography style={webStyle.hg_ContentTextStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.description}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                    <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image6}
                      style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                  </Grid>
                </Grid>

                <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginLeft: '3vw', marginBottom: '2vw' }}>
                  {/* --------------------------------- first image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background}>
                      <img src={img_bkg} style={{ objectFit: 'cover', ...webStyle.imgbkgFrist }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox }}>
                        <LazyLoadImage
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image1}
                          alt='image1'
                          style={{ objectFit: 'cover', ...webStyle.imgFirst }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- second image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background2}>
                      <img src={img_bkg2} style={{ objectFit: 'cover', ...webStyle.imgbkgSecond }} />
                      <Box style={{ zIndex: 10, ...webStyle.img_BorderBox2 }}>
                        <LazyLoadImage src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image2}
                          alt='image2'
                          style={{ objectFit: 'cover', ...webStyle.imgSecond }}
                          effect="blur"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- third image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background3}>
                      <img src={img_bkg3} style={{ objectFit: 'cover', ...webStyle.imgbkgThird }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox3 }}>
                        <LazyLoadImage
                          effect="blur"
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image3}
                          alt='image3'
                          style={{ objectFit: 'cover', ...webStyle.imgThird }} />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fourth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background4}>
                      <img src={img_bkg4} style={{ objectFit: 'cover', ...webStyle.imgbkgFourth }} />
                      <Box style={{ zIndex: 0, ...webStyle.img_BorderBox4 }}>
                        <LazyLoadImage
                          effect="blur"
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image4}
                          alt='image4'
                          style={{ objectFit: 'cover', ...webStyle.imgFourth }} />
                      </Box>
                    </Box>
                  </Grid>
                  {/* --------------------------------- fifth image ------------------------------------ */}
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box style={webStyle.img_Background5}>
                      <img src={img_bkg5} style={{ objectFit: 'cover', ...webStyle.imgbkgFifth }} />
                      <Box style={{ zIndex: 20, ...webStyle.img_BorderBox5 }}>
                        <LazyLoadImage
                          effect="blur"
                          src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[2].attributes?.image5}
                          alt='image5'
                          style={{ objectFit: 'cover', ...webStyle.imgFifth }} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {/* --------------------------------------------- GREEN BLOCK ---------------------------------------------------------- */}
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={3} sm={3} lg={3}>
                    <Box style={{ backgroundColor: ' #97c84f', ...webStyle.block_SideBox }}>
                      <Typography style={webStyle.orange_HeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.orange_SubHeadingStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.sub_title}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                    <Box style={webStyle.orange_ContentBox}>
                      <Typography style={webStyle.hg_ContentTextStyle}>
                        {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                    <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image6}
                      style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                  </Grid>
                </Grid>

              </Grid>



            </Paper>
            {/* ------------------------------------ PAPER CONTAINER BACKGROUND 2 start --------------------------------- */}
            <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer2 }} >
              <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginTop: '1.7vw' }}>
                {/* --------------------------------- first image ------------------------------------ */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Box style={webStyle.img_Background}>
                    <img src={img_bkg} style={{ objectFit: 'cover', ...webStyle.imgbkgFrist }} />
                    <Box style={{ zIndex: 10, ...webStyle.img_BorderBox }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image1}
                        alt='image1'
                        style={{ objectFit: 'cover', ...webStyle.imgFirst }} />
                    </Box>
                  </Box>
                </Grid>
                {/* --------------------------------- second image ------------------------------------ */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Box style={webStyle.img_Background2}>
                    <img src={img_bkg2} style={{ objectFit: 'cover', ...webStyle.imgbkgSecond }} />
                    <Box style={{ zIndex: 10, ...webStyle.img_BorderBox2 }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image2}
                        alt='image2'
                        style={{ objectFit: 'cover', ...webStyle.imgSecond }} />
                    </Box>
                  </Box>
                </Grid>
                {/* --------------------------------- third image ------------------------------------ */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Box style={webStyle.img_Background3}>
                    <img src={img_bkg3} style={{ objectFit: 'cover', ...webStyle.imgbkgThird }} />
                    <Box style={{ zIndex: 0, ...webStyle.img_BorderBox3 }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image3}
                        alt='image3'
                        style={{ objectFit: 'cover', ...webStyle.imgThird }} />
                    </Box>
                  </Box>
                </Grid>
                {/* --------------------------------- fourth image ------------------------------------ */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Box style={webStyle.img_Background4}>
                    <img src={img_bkg4} style={{ objectFit: 'cover', ...webStyle.imgbkgFourth }} />
                    <Box style={{ zIndex: 0, ...webStyle.img_BorderBox4 }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image4}
                        alt='image4'
                        style={{ objectFit: 'cover', ...webStyle.imgFourth }} />
                    </Box>
                  </Box>
                </Grid>
                {/* --------------------------------- fifth image ------------------------------------ */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Box style={webStyle.img_Background5}>
                    <img src={img_bkg5} style={{ objectFit: 'cover', ...webStyle.imgbkgFifth }} />
                    <Box style={{ zIndex: 20, ...webStyle.img_BorderBox5 }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image5}
                        alt='image5'
                        style={{ objectFit: 'cover', ...webStyle.imgFifth }} />
                    </Box>
                  </Box>
                </Grid>

              </Grid>

              {/* --------------------------------- testimonial --------------------- */}
              <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}>
                <Typography align="center" style={webStyle.testimonialTextStyle}>
                  {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quote}
                </Typography>
              </Grid>


              <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '80%', marginTop: '20px' }}>

                <Box>
                  <Typography align='right' style={webStyle.cardInnerTextHeader}>
                    {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quoter_name}
                  </Typography>
                  <Typography align='right' style={webStyle.cardInnerTextHeader}>
                    {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quoter_position}
                  </Typography>
                </Box>
                <Avatar alt="student avatar" src={this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.avatar}
                  style={webStyle.cardAvatarStyle} />
              </Grid>

              {/* -------------------------------- section2: hg foormula ends ----------------------------------------    */}
              {/* -------------------------------- section3: making choice starts ----------------------------------------    */}
              <Grid container style={{ marginTop: '160px', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} style={webStyle.gridItemStyle}>
                  <Box style={{ marginTop: '-3vw', marginBottom: '2vw', ...webStyle.orange_TitleBox }}>
                    <Typography style={webStyle.orange_TitleTextStyle}>
                      {this.state.hgFormula_MakingTheChoice?.header}
                    </Typography>
                  </Box>

                  <Box style={{ width: '60%', ...webStyle.orange_SubTitleBox }}>
                    <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                      {this.state.hgFormula_MakingTheChoice?.description}
                    </Typography>
                  </Box>
                </Grid>

                {/* ------------------------------------- CARDS start------------------------------------ */}
                <Grid container style={{ marginTop: '50px', display: 'flex', boxSizing: 'border-box', width: '85%', alignItems: 'stretch', justifyContent: 'center' }}>
                  {/* -------------------------orange card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={{ backgroundColor: '#fbaf3c', ...webStyle.card_TitleBox }}>
                      <Typography style={webStyle.card_TitleTextStyle}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[0].attributes?.title}
                      </Typography>
                    </Box>

                    <Box style={webStyle.card_ContentBox}>
                      <img src={this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[0].attributes?.image1}
                        style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '3.5vw', height: '5vw' }} />
                      <Typography align='justify' style={{ color: '#fbaf3c', ...webStyle.card_ContentTextStyle }}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[0].attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* -------------------------purple card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={{ backgroundColor: '#9167ac', ...webStyle.card_TitleBox }}>
                      <Typography style={webStyle.card_TitleTextStyle}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[1].attributes?.title}
                      </Typography>
                    </Box>

                    <Box style={webStyle.card_ContentBox}>
                      <img src={this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[1].attributes?.image1}
                        style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '3.5vw', height: '5vw' }} />
                      <Typography align='justify' style={{ color: '#9167ac', ...webStyle.card_ContentTextStyle }}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[1].attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* -------------------------green card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={{ backgroundColor: '#97c84f', ...webStyle.card_TitleBox }}>
                      <Typography style={webStyle.card_TitleTextStyle}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[2].attributes?.title}
                      </Typography>
                    </Box>

                    <Box style={webStyle.card_ContentBox}>
                      <img src={this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[2].attributes?.image1}
                        style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '3.5vw', height: '5vw' }} />
                      <Typography align='justify' style={{ color: '#97c84f', ...webStyle.card_ContentTextStyle }}>
                        {this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[2].attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>

                </Grid>
                {/* ------------------------------------- CARDS end------------------------------------ */}

              </Grid>

              {/* -------------------------------- section3: making choice ends ----------------------------------------    */}
              {/* -------------------------------- section4: Explore programs start----------------------------------------    */}
              <span id='link2'></span>
              <Grid container style={{ marginTop: '12vw', marginBottom: '6vw', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} style={webStyle.gridItemStyle}>
                  <Box style={{ marginTop: '-3vw', marginBottom: '2vw', ...webStyle.orange_TitleBox }}>
                    <Typography style={webStyle.orange_TitleTextStyle}>
                      {this.state.hgFormula_ExplorePrograms?.header}
                    </Typography>
                  </Box>

                  <Box style={{ width: '60%', ...webStyle.orange_SubTitleBox }}>
                    <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                      {this.state.hgFormula_ExplorePrograms?.description}
                    </Typography>
                  </Box>
                </Grid>

                {/* ------------------------------------- CARDS start------------------------------------ */}
                <Grid container style={{ marginTop: '4vw', display: 'flex', boxSizing: 'border-box', width: '85%', alignItems: 'stretch', justifyContent: 'center' }}>
                  {/* -------------------------orange card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={webStyle.roundcard_TitleBox}>
                      <Typography style={{ color: '#fbaf3c', ...webStyle.roundcard_TitleTextStyle }}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.roundcard_SubTitleTextStyle}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.description}
                      </Typography>
                    </Box>

                    <Box style={webStyle.roundcard_ContentBox}>
                      {/* ------------------------------------------------------------- */}
                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes)}
                        />
                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>

                          <Box onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes)}>
                            <Typography align='left' style={{ color: '#fbaf3c', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px", color: "#6211cea4" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      {/* ------------------------------------------------------------- */}
                      {/* ------------------------------------------------------------- */}

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes)}
                        />

                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>
                          <Box onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes)}>
                            <Typography align='left' style={{ color: '#fbaf3c', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      {/* ------------------------------------------------------------- */}

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes)}
                        />
                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>
                          <Box onClick={() => this.callProgramVideosHandler("Touch", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes)}>
                            <Typography align='left' style={{ color: '#fbaf3c', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>


                    </Box>
                  </Grid>
                  {/* -------------------------grey card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={webStyle.roundcard_TitleBox}>
                      <Typography style={{ color: '#a8a8a8', ...webStyle.roundcard_TitleTextStyle }}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.roundcard_SubTitleTextStyle}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.description}
                      </Typography>
                    </Box>

                    <Box style={webStyle.roundcard_ContentBox}>
                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes)}
                        />
                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>
                          <Box onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes)}>
                            <Typography align='left' style={{ color: '#a8a8a8', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      {/* ------------------------------------------------------------- */}

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes)}
                        />
                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>

                          <Box onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes)}>
                            <Typography align='left' style={{ color: '#a8a8a8', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.program_description}
                            </Typography>

                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      {/* ------------------------------------------------------------- */}

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                          style={webStyle.programAvatarStyle}
                          onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes)}
                        />
                        <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>

                          <Box onClick={() => this.callProgramVideosHandler("Tech", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes)}>
                            <Typography align='left' style={{ color: '#a8a8a8', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.program_description}
                            </Typography>

                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.learn_more_link}`}target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                  {/* -------------------------green card ------------------------------------- */}
                  <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                    <Box style={webStyle.roundcard_TitleBox}>
                      <Typography style={{ color: '#97c84f', ...webStyle.roundcard_TitleTextStyle }}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.title}
                      </Typography>
                      <Typography style={webStyle.roundcard_SubTitleTextStyle}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.description}
                      </Typography>
                    </Box>

                    <Box style={webStyle.roundcard_ContentBox}>

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                          style={webStyle.programAvatarStyle} 
                          onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes)}
                          />
                             <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>

                          <Box onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes)}>
                            <Typography align='left' style={{ color: '#97c84f', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      {/* ------------------------------------------------------------- */}

                      <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                        <Avatar alt="program avatar"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                          style={webStyle.programAvatarStyle} 
                          onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes)}
                          />
                       <Box style={{ ...webStyle.programBoxStyle, display: "block" }}>                        

                          <Box onClick={() => this.callProgramVideosHandler("Online Academies", this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes)}>
                            <Typography align='left' style={{ color: '#97c84f', ...webStyle.ProgramInnerTextHeader }}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.program_name}
                            </Typography>
                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                              {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.program_description}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "12px" }}>
                              <a href={`${this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.learn_more_link}`} target="_blank" rel="noopener noreferrer">Learn More</a>
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>

                </Grid>
                {/* ------------------------------------- CARDS end------------------------------------ */}
                {/* --------------------------------- testimonial 2--------------------- */}
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2.5vw' }}>
                  <Typography align="center" style={webStyle.testimonialTextStyle}>
                    {this.state.hgFormula_ExplorePrograms?.hg_formula_quote?.data.attributes?.quote}
                  </Typography>
                </Grid>

                <Grid id='link3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '75%', marginTop: '20px' }}>

                  <Box style={{ width: '45%' }}>

                    <Typography align='right' style={webStyle.cardInnerTextHeader}>

                      {this.state.hgFormula_ExplorePrograms?.hg_formula_quote?.data.attributes?.quoter_name}
                    </Typography>

                    <Typography align='right' style={webStyle.cardInnerTextHeader}>
                      {this.state.hgFormula_ExplorePrograms?.hg_formula_quote?.data.attributes?.quoter_position}
                    </Typography>
                  </Box>

                  <Avatar alt="student avatar"
                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_quote?.data.attributes?.avatar}
                    style={webStyle.cardAvatarStyle} />
                </Grid>


                {/* ------------------------------- last pics ------------------------------------- */}
                {/* <span id='link3'></span> */}
                <Grid container style={{ marginTop: '4vw', display: 'flex', boxSizing: 'border-box', width: '85%', alignItems: 'stretch', justifyContent: 'space-between' }}>
                  {/* -------------------------orange card ------------------------------------- */}

                  <Grid item xs={12} sm={6} style={webStyle.gridItemStyle}>
                    <img src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[3].attributes?.image1}
                      style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '36vw', height: '20vw' }} />
                    <Button style={{ textTransform: 'none', ...webStyle.btnExplore }}>
                      <a style={webStyle.downloadLink} target='_blank' href={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[3].attributes?.link}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[3].attributes?.title}
                      </a>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} style={webStyle.gridItemStyle}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[4].attributes?.image1}
                        style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '15vw', height: '20vw' }} />

                      <Box style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column' }}>
                        <LazyLoadImage
                          effect="blur"
                          src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[4].attributes?.image2}
                          style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '12vw', height: '12vw' }} />
                        <Typography align='center' style={webStyle.chapterText}>
                          {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[4].attributes?.description}
                        </Typography>
                      </Box>
                    </Grid>
                    <Button style={{ textTransform: 'none', ...webStyle.btnExplore }}>
                      <a style={webStyle.downloadLink} target='_blank' href={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[4].attributes?.link}>
                        {this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[4].attributes?.title}
                      </a>
                    </Button>
                    <span id='link4'></span>
                  </Grid>
                </Grid>

              </Grid>

              {/* -------------------------------- section4: Explore programs end----------------------------------------    */}

            </Paper>
            {/* ------------------------------------ PAPER CONTAINER BACKGROUND 3 start --------------------------------- */}

            <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer3 }} >

              {/* -------------------------------- section5: About HG starts----------------------------------------    */}

              <Grid container style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} style={webStyle.gridItemStyle}>

                  <Box style={{ marginBottom: '2vw', ...webStyle.orange_TitleBox }}>

                    <Typography style={webStyle.orange_TitleTextStyle}>
                      {this.state.hgFormula_AboutHg?.header}
                    </Typography>

                  </Box>
                </Grid>

                {/* ----------------------------------- HG VIDEO start----------------------------------- */}

                <Grid container style={{ marginTop: '2vw', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', width: '60%' }}>
                  <Grid item xs={12} style={webStyle.boxHgVideo}>
                    {this.state.isLoading && this.state.allVideos_introVideoURL != "" ?
                      <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                      : <HgVideo introVideoURL={this.state.hgFormula_AboutHg?.video} />
                    }

                  </Grid>
                </Grid>

                {/* ----------------------------------- HG VIDEO end----------------------------------- */}
                {/* ----------------------------------- Partner school accreditation logos start----------------------------------- */}
                <Grid container style={webStyle.gridcontainerPartnerLogo}>
                  <Grid item xs={12} sm={8} style={webStyle.boxPartnersLogo}>
                    <Grid item style={webStyle.gridItemStyle}>
                      <Box>
                        <Typography style={webStyle.partnersTitleTextStyle}>
                          {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[0].attributes?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[0].attributes?.image1} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[0].attributes?.image2} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[0].attributes?.image3} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />

                      </Box>
                    </Grid>
                    <Grid item style={webStyle.gridItemStyle}>
                      <Box>
                        <Typography style={webStyle.partnersTitleTextStyle}>
                          {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[1].attributes?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[1].attributes?.image1} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[1].attributes?.image2} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[1].attributes?.image3} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[1].attributes?.image4} style={{ objectFit: 'cover', ...webStyle.stylePartnersLogo }} />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>

                {/* ----------------------------------- Partner school accreditation logos end----------------------------------- */}
                {/* ----------------------------------- About Hg Text start ----------------------------------- */}
                <Grid container style={{ marginTop: '3vw', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={10} sm={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    <Typography align='center' style={webStyle.aboutHgTextStyle}>
                      {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[2].attributes?.description}
                    </Typography>
                    <Typography align='center' style={webStyle.aboutHgTextStyle}>
                      {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[3].attributes?.description}
                    </Typography>

                  </Grid>
                </Grid>
                {/* ------------------------------- mission and vision ------------------------------------- */}
                <Grid container style={{ marginTop: '4vw', display: 'flex', boxSizing: 'border-box', width: '75%', alignItems: 'stretch' }}>
                  {/* -------------------------mission card ------------------------------------- */}
                  <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

                    <Paper elevation={0} style={webStyle.gridMissionVision}>
                      <LazyLoadImage
                        effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[4].attributes?.image1}
                        style={{ marginTop: '-3.5vw', padding: 0, objectFit: 'cover', width: '6vw', height: '6vw' }} />
                      <Typography align='left' style={webStyle.textHeaderMissionVision}>
                        {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[4].attributes?.title}
                      </Typography>
                      <Typography align='left' style={webStyle.subTexttextHeaderMissionVision}>
                        {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[4].attributes?.description}
                      </Typography>
                    </Paper>
                  </Grid>
                  {/* -------------------------vision card ------------------------------------- */}
                  <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

                    <Paper elevation={0} style={webStyle.gridMissionVision}>
                      <LazyLoadImage
                        effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[5].attributes?.image1}
                        style={{ marginTop: '-3.5vw', padding: 0, objectFit: 'cover', width: '6vw', height: '6vw' }} />
                      <Typography align='left' style={webStyle.textHeaderMissionVision}>
                        {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[5].attributes?.title}
                      </Typography>
                      <Typography align='left' style={webStyle.subTexttextHeaderMissionVision}>
                        {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[5].attributes?.description}
                      </Typography>
                    </Paper>
                  </Grid>
                  {/* ----------------------------------- About Hg Text start----------------------------------- */}
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


                    <Typography align='center' style={webStyle.textUniversities}>
                      {this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.title}  </Typography>
                  </Grid>
                </Grid>
                {/* ----------------------------------- About Hg small logos start----------------------------------- */}
                {/* ----------------------------------- Partner school accreditation logos start----------------------------------- */}
                <Grid container style={webStyle.gridcontainerSmallLogo}>
                  <Grid item xs={12} sm={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item >

                      <Box>
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image1} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image2} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image3} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image4} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image5} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image6} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />

                      </Box>
                    </Grid>
                    <Grid item >

                      <Box>
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image7} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image8} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image9} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image10} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                        <LazyLoadImage
                          effect="blur" src={this.state.hgFormula_AboutHg?.hg_formula_sections?.data[6].attributes?.image11} style={{ objectFit: 'cover', ...webStyle.stylesmallLogo }} />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>
                {/* --------------------------------- testimonial 3--------------------- */}
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2.5vw' }}>
                  <Typography align="center" style={webStyle.testimonialTextStyle}>
                    {this.state.hgFormula_AboutHg?.hg_formula_quote?.data.attributes?.quote}
                  </Typography>
                </Grid>


                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '75%', marginTop: '20px' }}>

                  <Box style={{ width: '45%' }}>
                    <Typography align='right' style={webStyle.cardInnerTextHeader}>
                      {this.state.hgFormula_AboutHg?.hg_formula_quote?.data.attributes?.quoter_name}
                    </Typography>
                    <Typography align='right' style={webStyle.cardInnerTextHeader}>
                      {this.state.hgFormula_AboutHg?.hg_formula_quote?.data.attributes?.quoter_position}
                    </Typography>
                  </Box>
                  <Avatar alt="student avatar"
                    src={this.state.hgFormula_AboutHg?.hg_formula_quote?.data.attributes?.avatar}
                    style={webStyle.cardAvatarStyle} />
                </Grid>

                {/* ------------------------------------- last orange strip ----------------------------  */}
                <Grid item xs={12} style={webStyle.gridItemStyle}>
                  <Box style={webStyle.orange_strip}>

                  </Box>
                </Grid>

              </Grid>
              {/* -------------------------------- section5: About HG small logos ends----------------------------------------    */}


            </Paper>
            {/* --------------------------------- PAPER CONTAINER BACKGROUND 3 ends ------------------------------------ */}

          </Grid>
        </Container>
        {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}
        <Modal
          open={this.state.openModalProgramVideo}
        // onClose={this.handleClose}
        >
          <Box style={{ ...webStyle.modalStyle, height: "70%", width: '44%' }}>
            <IconButton onClick={this.handleCloseProgramVideo} style={{ marginTop: '-20px', marginLeft: '-30px' }}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '32px', height: '32px' }}>
                <small>x</small>
              </Box>
            </IconButton>
            <Typography align='left' style={{ ...webStyle.ProgramInnerTextHeader, fontWeight: 500, fontSize: "18px", marginLeft: "25px", color: " rgb(23, 23, 37)" }}>
              {this.state.selectedProgramTitle} - {this.state.selectedProgramDetails?.program_name}
            </Typography>
            <Box style={{ width: '90%', height: '70%', margin: "auto", position: 'relative', padding: '1em', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
              {/* <Typography align='left' style={{color: '#fbaf3c', ...webStyle.ProgramInnerTextHeader, fontWeight: 500, fontSize: "18px" }}>
                {this.state.selectedProgramTitle} - {this.state.selectedProgramDetails?.program_name}
              </Typography> */}
              <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'center', }}>
                <HgVideo introVideoURL={this.state.selectedProgramDetails?.program_video} />
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* -------------------------------------------- modal Program video end------------------------------------------------------------- */}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  gridContainerStyle: {
    width: '100vw',
    height: '100vh',
    position: 'relative' as 'relative',
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // marginTop: '50px',
  },

  btnWatchLater: {

    width: "270px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginBottom: '2em',
  },
  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  },

  imgLogoStyle: {
    width: '100%',
  },

  paperContainer: {
    width: '100%',
    //height: '3000px',
    minHeight: '500px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundOneHgFormula_new})`,
    // backgroundImage: `url(${backgroundOneHgFormula})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    // display: 'flex',
    //  alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: '0.5em',
    // paddingBottom: '4em',
    // paddingRight: '0.5em',
    // paddingLeft: '0.5em',
  },
  paperContainer2: {
    width: '100%',
    //height: '3500px',
    minHeight: '500px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundTwoHgFormula})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',

  },
  paperContainer3: {
    width: '100%',
    //height: '3500px',
    minHeight: '500px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundOneHgFormula_new})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',

  },
  persue_TextStyle: {
    color: '#9167ac',
    fontFamily: 'TuesdayNight-Regular',
    fontSize: '5vw',
    fontWeight: 400,
    marginBottom: '0.6vw',
    marginTop: '7vw',
  },
  persue_SubTextStyle: {
    color: '#a8a8a8',
    fontFamily: 'Poppins',
    fontWeight: 500,
    marginBottom: '1em'
  },
  orange_TitleTextStyle: {
    color: '#ffffff',
    fontSize: '3vw',
    fontFamily: 'Poppins',
    fontWeight: 800,
    letterSpacing: '10px',
  },

  orange_TitleBox: {
    width: '100%',
    height: '7vw',
    backgroundColor: '#fbaf3c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },


  orange_SubTitleBox: {
    marginTop: '1.7vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  orange_SubTitleTextStyle: {
    color: '#757575',
    fontSize: '1.8vw',
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  block_SideBox: {
    width: '26vw',
    height: '18vw',
    // width: '25em',
    // minHeight: '19em',
    // paddingTop:'20px',
    // paddingBottom:'20px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  orange_HeadingStyle: {
    color: '#ffffff',
    fontSize: '4vw',
    // fontSize: '3.8em',
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  orange_SubHeadingStyle: {
    color: '#ffffff',
    fontSize: '2vw',
    // fontSize: '1.8em',
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  hg_ContentTextStyle: {
    color: '#757575',
    fontSize: '1.3vw',
    fontFamily: 'Poppins',
    fontWeight: 400,
  },

  orange_ContentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    //  minHeight: '5.5em',
    //backgroundColor: '#fff'
  },

  img_Background: {
    position: 'relative' as 'relative',
    transform: 'rotate(-5deg)',
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.2vw'
  },

  img_BorderBox: {
    position: 'absolute' as 'absolute',
    top: '46%',
    left: '56%',
    transform: 'translate(-50%, -50%) rotate(-1deg)',
    border: '1px solid black',
    // width: '80%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  img_Background2: {
    position: 'relative' as 'relative',
    transform: 'rotate(-5deg)',
    // width: '105%',
    // height: 'auto',
    display: 'flex',
    marginLeft: '-1.3vw',
    marginBottom: '1vw',
  },
  img_BorderBox2: {
    position: 'absolute' as 'absolute',
    top: '46%',
    left: '53%',
    transform: 'translate(-50%, -50%) rotate(13deg)',
    border: '1px solid black',
    // width: '64%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  img_Background3: {
    position: 'relative' as 'relative',
    // transform: 'rotate(-5deg)',
    width: '100%',
    height: 'auto',
    display: 'flex',
    //  marginLeft: '-80px',
    marginBottom: '2vw',
    marginLeft: '-3.3vw'
  },
  img_BorderBox3: {
    position: 'absolute' as 'absolute',
    top: '47%',
    left: '58%',
    transform: 'translate(-50%, -50%) rotate(-5deg)',
    border: '1px solid black',
    // width: '80%',
    // height: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  img_Background4: {
    position: 'relative' as 'relative',
    // transform: 'rotate(-5deg)',
    width: '100%',
    height: 'auto',
    display: 'flex',
    marginLeft: '-3vw',
    // marginBottom: '15px',
  },
  img_BorderBox4: {
    position: 'absolute' as 'absolute',
    top: '47%',
    left: '55%',
    transform: 'translate(-50%, -50%) rotate(1deg)',
    border: '1px solid black',
    // width: '75%',
    // height: 'auto',
    display: 'flex',
    alignItems: 'center',

  },
  img_Background5: {
    position: 'relative' as 'relative',
    // transform: 'rotate(-5deg)',
    width: '105%',
    height: 'auto',
    display: 'flex',
    marginLeft: '-5vw',
    marginBottom: '2vw',
  },

  img_BorderBox5: {
    position: 'absolute' as 'absolute',
    top: '48%',
    left: '52%',
    transform: 'translate(-50%, -50%) rotate(13deg)',
    border: '1px solid black',
    // width: '65%',
    // height: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  testimonialTextStyle: {
    color: '#9369ae',
    fontSize: '3.5vw',
    fontFamily: 'LucidaGrande',
    fontWeight: 400,
    width: '75%',
    // marginTop:'20px'
  },

  cardAvatarStyle: {
    width: '10vw',
    height: '10vw',
    marginLeft: '10px'
  },
  cardInnerTextHeader: {
    color: '#b8b8b8',
    // color: '#757575',
    fontFamily: 'Poppins',
    fontSize: '1.7vw',
    // fontSize: '1.5em',
    fontWeight: 700,
  },

  card_TitleBox: {
    height: '4vw',
    width: '27vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.2vw'

  },

  card_TitleTextStyle: {
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '1.7vw',
    fontWeight: 700,
  },

  card_ContentBox: {
    marginTop: '12px',
    width: '27vw',
    minHeight: '30vw',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },

  card_ContentTextStyle: {
    fontFamily: 'MontserratRoman-Regular',
    fontSize: '1.2vw',
    fontWeight: 600,
    paddingLeft: '2vw',
    paddingRight: '2vw',
    marginTop: '1.5vw',
  },

  roundcard_TitleBox: {
    height: '8vw',
    width: '24vw',
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column' as 'column',
  },

  roundcard_TitleTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '2.4vw',
    fontWeight: 600,
  },
  roundcard_SubTitleTextStyle: {
    fontFamily: 'Roboto',
    fontSize: '1vw',
    fontWeight: 400,
    marginBottom: '1.5vw',
    color: '#92929d',
  },

  roundcard_ContentBox: {
    // marginTop: '0.3vw',
    width: '24vw',
    height: '38vw',
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    borderRadius: '10px',
    border: '1px solid #fae03d',
    padding: '1vw'
  },

  gridProgramContainerStyle: {

    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
    padding: '0.5vw',
    display: 'flex',
    marginLeft: '2vw',
    marginBottom: '1vw',
    marginTop: '1vw',
    boxSizing: 'border-box' as 'border-box',
    width: '20vw',
    alignItems: 'center',
    cursor: 'pointer'
  },

  programAvatarStyle: {
    width: '5.5vw',
    height: '5.5vw',
    marginLeft: '-3.5vw',
  },

  programBoxStyle: {
    marginLeft: '8px',
    marginRight: '2px',
    display: 'flex',
    // flexWrap: 'wrap' as 'wrap',
  },

  ProgramInnerTextHeader: {
    fontFamily: 'Poppins',
    fontSize: '1.2vw',
    fontWeight: 600,
  },

  programInnerTextSubText: {
    color: ' #92929d',
    fontFamily: 'Roboto',
    fontSize: '0.9vw',
    fontWeight: 400,
  },

  chapterText: {
    color: '  #757575',
    fontFamily: 'Poppins',
    fontSize: '1.1vw',
    fontWeight: 400,
    width: '12vw'
  },
  btnExplore: {
    marginTop: '1vw',
    width: '22vw',
    height: '3.5vw',
    borderRadius: '10px',
    backgroundColor: '#fab03d',
    color: '  #fff',
    fontFamily: 'Poppins',
    fontSize: '1vw',
    fontWeight: 600,
  },
  downloadLink: {
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '1vw',
    fontWeight: 600,
    textDecoration: 'none',
  },

  boxHgVideo: {
    width: '75vw',
    height: '36vw'
  },
  boxPartnersLogo: {
    height: '14vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stylePartnersLogo: {
    padding: '0.3vw',
    width: '8vw',
    height: '8vw'
  },

  partnersTitleTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '1.7vw',
    fontWeight: 600,
    marginBottom: '1vw',
  },
  gridcontainerPartnerLogo: {
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    backgroundColor: '#fff',
    marginTop: '1.5vw',
    display: 'flex',
    boxSizing: 'border-box' as 'border-box',
    alignItems: 'center',
    justifyContent: 'center'
  },


  aboutHgTextStyle: {
    color: '#757575',
    fontFamily: 'MontserratRoman-Regular',
    fontSize: '1.7vw',
    fontWeight: 400,
    marginBottom: '1vw',
  },

  gridMissionVision: {

    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
    padding: '1vw',
    display: 'flex',
    flexDirection: 'column' as 'column',
    boxSizing: 'border-box' as 'border-box',
    width: '34vw',
    alignItems: 'center',
  },

  textHeaderMissionVision: {
    fontFamily: 'Poppins',
    fontSize: '1.7vw',
    fontWeight: 600,
    color: '#171725',
    marginTop: '1vw'
  },

  subTexttextHeaderMissionVision: {
    color: '#7d7d7e',
    fontFamily: 'Roboto',
    fontSize: '1.3vw',
    fontWeight: 400,
    marginTop: '1vw'
  },

  textUniversities: {
    color: '#9167ac',
    fontFamily: 'Poppins',
    fontSize: '2.7vw',
    fontWeight: 700,
    marginTop: '3vw',
    marginBottom: '3vw',
  },

  gridcontainerSmallLogo: {
    marginTop: '1.5vw',
    display: 'flex',
    boxSizing: 'border-box' as 'border-box',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stylesmallLogo: {
    padding: '2vw',
    width: '5vw',
    height: '5vw'
    // width: '3vw',
    // height: '3vw'
  },

  orange_strip: {
    marginTop: '3vw',
    width: '100%',
    height: '5vw',
    backgroundColor: '#fbaf3c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgPersue: {
    margin: 0,
    padding: 0,
    width: '52vw',
    height: '42vw'
  },
  gridItemStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center'
  },
  gridItemStyle2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItemStyle3: {
    //backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  plusStyle: {
    margin: 0,
    padding: 0,
    width: '5.5vw',
    height: '5.5vw',
  },

  imgbkgFrist: {
    margin: 0,
    padding: 0,
    width: '18vw',
    height: '21vw',
  },
  imgFirst: {
    margin: 0,
    padding: 0,
    width: '15vw',
    height: '16vw',
  },
  imgbkgSecond: {
    margin: 0,
    padding: 0,
    width: '18vw',
    height: '21vw',
  },
  imgSecond: {
    margin: 0,
    padding: 0,
    width: '12.5vw',
    height: '13vw',
  },

  imgbkgThird: {
    margin: 0,
    padding: 0,
    width: '19vw',
    height: '21vw',
  },

  imgThird: {
    margin: 0,
    padding: 0,
    width: '15vw',
    height: '16vw',
  },
  imgbkgFourth: {
    margin: 0,
    padding: 0,
    width: '18vw',
    height: '21vw',
  },
  imgFourth: {
    margin: 0,
    padding: 0,
    width: '14vw',
    height: '16vw',
  },
  imgbkgFifth: {
    margin: 0,
    padding: 0,
    width: '18vw',
    height: '19vw',
  },
  imgFifth: {
    margin: 0,
    padding: 0,
    width: '11vw',
    height: '12vw'
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    // height: '90vh',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '24px',
  },


};
// Customizable Area End
