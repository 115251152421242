import React, { useState } from "react";

import {
    // Customizable Area Start
  Typography,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },

    MuiTypography: {
      body1: {
        fontSize: '0.8rem',
        fontFamily: "Roboto",
        color: '#171725',
        fontWeight: 300,
      }
    },

    MuiRadio: {
      root: {
        color: '#99cb51',
        width: '1rem',
        height: '1rem',
      },
      colorSecondary: {
        color: '#99cb51',

        '&$checked': {
          color: '#99cb51',
        },

      },
    }
  }
});


const CheckoutRadioTextBookCustom = ({ value }: any) => {

  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>

            <FormControlLabel 
            style={{marginLeft:'0.5px'}}
            value={value} 
            control={<Radio />} 
            label={<span style={webStyle.radioLabelStyle}>Custom</span>} 
            />
           
          <Typography style={webStyle.cardAmtStyle}> Fully customize your child’s learning. Select and compare every book,Test and other curricular activities accredited in the program </Typography>
         
      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  cardStyle: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    borderRadius: '15px',
    border: '1px solid #e2e2ea',
  },

  cardAmtStyle: {
    marginTop: '10px',
    marginRight: '28px',
    marginLeft: '5px',
    color: ' #5e5e5e',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
  },
  radioLabelStyle: {
    marginLeft: '0.7em',
    color: '#171725',
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
  },

};

export default CheckoutRadioTextBookCustom;
// Customizable Area End
