import React from "react";

import {
      // Customizable Area Start
    Container,
    Box,
    Typography,
     Grid,
    Paper,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { orientationvideo_bkg, cover } from './assets';
import OrientationVideoPlayer from "../../../components/src/OrientationVideoPlayer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"


const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});



// Customizable Area End

import DashboardController, { Props } from "./DashboardController";

export default class OrientationVideo extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>

                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openOrientation}
                                handleDrawerOpen={() => this.setState({ openOrientation: !this.state?.openOrientation })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openOrientation ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}


                                <Container maxWidth={false} style={webStyle.mainGridContainer}>

                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Program</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                        <HorizontalLine/>
                                        </Grid>
                                    </Container>
                                    {/* -------------------------------- video starts --------------------------------- */}
                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Typography style={webStyle.welcomeSubTextStyle} >Homeschool Orientation</Typography>
                                        <Box style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                                            <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer }} >

                                                <Grid container style={webStyle.videoGridContainer}>

                                                    <Box style={{ width: '95%',display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                                                    {/* <Box style={{ width: '65vw', height: '35vw', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}> */}
                                                        {!this.state.allVideos_orientationVideoURL ?
                                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                                                            : <OrientationVideoPlayer introVideoURL={this.state.allVideos_orientationVideoURL} cover={cover} />
                                                        }
                                                    </Box>

                                                   

                                                </Grid>



                                            </Paper>
                                        </Box>
                                    </Container>
                                    {/* -------------------------------- video ends --------------------------------- */}

                                </Container>
                            </Container>
                        </Grid>
                    </Grid>

                </Container>

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainGridContainer:{ 
        margin: '0 auto', 
        width: '100%', 
        paddingLeft: '0', 
        paddingRight: '0', 
        marginBottom: '6em' 
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    welcomeSubTextStyle: {
        marginTop: '2vw',
        marginBottom: '2vw',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 500,
    },

    paperContainer: {
        // width: '100%',
        // minHeight: '500px',
        width: '70vw',
        height: '48vw',
        //minHeight: '35vw',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${orientationvideo_bkg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: '15px',
    },
    videoGridContainer:{ 
        display: 'flex', 
        boxSizing: 'border-box' as 'border-box', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%', 
        height: '100%' 
    },

};
// Customizable Area End
