

import React from "react";

import {
      // Customizable Area Start
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
     Grid,
    TextField,
      MenuItem,
    Modal,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider ,withStyles} from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Drawer from "../../../components/src/Drawer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
      MuiIconButton: {
        root: {
           padding: 0
        },
      },
    },
});

const StyledTextField = withStyles({
    root: {
        maxWidth: '100%',
        fontFamily: 'Roboto',
        fontSize: '16',
        color: '#44444f',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: 'Roboto',
            fontSize: '16',
            fontWeight: 400,
            color: '#44444f'

        }
    }
})((props: any) => <TextField {...props} />);

// Customizable Area End

import AccountSettingController, {
    Props
} from "./AccountSettingController";


export default class AccountSettings extends AccountSettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        console.log("ACCOUNTSSS reason", this.state.reasonForHomeSchoolingData)
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} >
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', padding: '0', marginBottom: '6em' }}>
                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Account Settings </Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                        <HorizontalLine/>
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            {this.state.gettingDetailsOfStudent ?
                                                <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                                                :
                                                <>
                                                    <Grid container style={{ paddingLeft: '1em', marginTop: '2em', }}   >
                                                        <Grid item xs={12} md={7} >
                                                            <InputLabel style={webStyle.inputLabelStyle}>First Name</InputLabel>
                                                            <StyledTextField
                                                                placeholder="Enter First Name"
                                                                fullWidth={true}
                                                                name='AccountFirstName'
                                                                value={this.state.AccountFirstName}
                                                                onChange={(e: any) => this.setState({ AccountFirstName: e.target.value })}
                                                                onFocus={this.hideError}
                                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                            />
                                                            <Box>
                                                                {
                                                                    (this.state.errors.AccountFirstName)
                                                                        ? <small style={webStyle.errorStyle}>{this.state.errors.AccountFirstName}</small>
                                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }} >
                                                            <InputLabel style={webStyle.inputLabelStyle}>Last Name</InputLabel>
                                                            <StyledTextField
                                                                placeholder="Enter Last Name"
                                                                fullWidth={true}
                                                                name='AccountLastName'
                                                                value={this.state.AccountLastName}
                                                                onChange={(e: any) => this.setState({ AccountLastName: e.target.value })}
                                                                onFocus={this.hideError}
                                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                            />
                                                            <Box>
                                                                {
                                                                    (this.state.errors.AccountLastName)
                                                                        ? <small style={webStyle.errorStyle}>{this.state.errors.AccountLastName}</small>
                                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                                                            <Box >
                                                                <InputLabel style={webStyle.inputLabelStyle}>Contact Number</InputLabel>
                                                                <Box style={{ display: 'flex', alignItems: 'end' }}>
                                                                    <StyledTextField
                                                                        select
                                                                        style={{ width: '80px', marginTop: '-15px' }}
                                                                        InputLabelProps={{ shrink: false }}
                                                                        name='AccountCountryCode'
                                                                        value={this.state.AccountCountryCode}
                                                                        onChange={(e: any) => this.setState({ AccountCountryCode: e.target.value })}
                                                                    >
                                                                        <MenuItem style={{ display: "none" }} value={this.state.AccountCountryCode}>{"+" + this.state.AccountCountryCode}</MenuItem>
                                                                        {
                                                                            this.state.uniqueCountryCodeDataNew?.map((item: any, index: number) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={index}
                                                                                        value={item?.phonecode}
                                                                                    >
                                                                                        {
                                                                                            '+' + item?.phonecode
                                                                                        }
                                                                                        
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </StyledTextField>
                                                                    <StyledTextField
                                                                        style={{ marginLeft: '20px' }}
                                                                        placeholder="0123456789"
                                                                        fullWidth
                                                                        name='AccountMobileNumber'
                                                                        value={this.state.AccountMobileNumber}
                                                                        onChange={(e: any) => this.setState({ AccountMobileNumber: e.target.value })}
                                                                        onFocus={this.hideError}
                                                                    />
                                                                </Box>
                                                                <Box style={{ display: 'flex' }}>
                                                                    <Box>
                                                                        {
                                                                            (!this.state.AccountCountryCode)
                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountCountryCode}</small>
                                                                                : <small style={{ visibility: 'hidden' }}>"Code Require"</small>
                                                                        }
                                                                        {
                                                                            (this.state.errors.AccountMobileNumber)
                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountMobileNumber}</small>
                                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                                                            <InputLabel style={webStyle.inputLabelStyle}>Email Address</InputLabel>
                                                            <StyledTextField
                                                                placeholder="email@mail.com"
                                                                fullWidth
                                                                type="email"
                                                                name='AccountEmail'
                                                                value={this.state.AccountEmail}
                                                                disabled
                                                                onChange={(e: any) => this.setState({ AccountEmail: e.target.value })}
                                                                onFocus={this.hideError}
                                                            />
                                                            <Box>
                                                                {
                                                                    (this.state.errors.AccountEmail)
                                                                        ? <small style={webStyle.errorStyle}>{this.state.errors.AccountEmail}</small>
                                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                                }
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                                                            <InputLabel style={webStyle.inputLabelStyle}>Country of Residence</InputLabel>
                                                            <Autocomplete
                                                                value={this.state.countryAccountAutoValue}
                                                                onChange={(e:any, newValue:any)=>{this.accountCountryAutoHandler(e,newValue)}}
                                                                options={ this.state.getAllCountries}
                                                                getOptionLabel={(option:any) => option.name || ""}
                                                                getOptionSelected={(option, value) => option.value === value.value}
                                                                renderInput={(params) => 
                                                                < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                                                                name='AccountCountry'  InputLabelProps={{ shrink: false }} 
                                                                />}
                                                                inputValue={this.state.AccountCountry}
                                                                onInputChange={(e:any, newInputValue:any) => {
                                                                    this.setState({ AccountCountry: newInputValue })
                                                                    this.changeHandlerGetCitiesAccount(e,newInputValue)
                                                                }}
                                                                    />
                                                            <Box>
                                                                {
                                                                    (this.state.errors.AccountCountry)
                                                                        ? <small style={webStyle.errorStyle}>{this.state.errors.AccountCountry}</small>
                                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                                }
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                                                            <InputLabel style={webStyle.inputLabelStyle}>City of Residence</InputLabel>
                                                            <Autocomplete
                                                            value={this.state.cityAccountAutoValue}
                                                            onChange={(e:any, newValue:any)=>{this.setState({cityAccountAutoValue:newValue})}}
                                                            options={this.state.getAllCities}
                                                            getOptionLabel={(option:any) => option.name || ""}
                                                            getOptionSelected={(option, value) => option.value === value.value}
                                                            renderInput={(params) => 
                                                            < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                                                            name='AccountCity'  InputLabelProps={{ shrink: false }} 
                                                            />}
                                                            inputValue={this.state.AccountCity}
                                                            onInputChange={(e:any, newInputValue:any) => {
                                                                this.setState({ AccountCity: newInputValue })
                                                                this.cityChangeHandler1(e,newInputValue)
                                                            }}
                                                                />
                                                            <Box>
                                                                {
                                                                    (this.state.errors.AccountCity)
                                                                        ? <small style={webStyle.errorStyle}>{this.state.errors.AccountCity}</small>
                                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                                }
                                                            </Box>
                                                        </Grid>

                                                        
                                                        {this.state.byrangayDataAccount?.length > 0 && this.state.AccountCity != ""?
                                                        <>
                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.5em',marginBottom: '1em' }}>
                                                       
                                                        {/* <Box style={webStyle.inputLabelStyle}> */}
                                                        <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                                                        <StyledTextField
                                                        select
                                                        color="darkgrey"
                                                        fullWidth
                                                        name="barangayAccount"
                                                        InputLabelProps={{ shrink: false }}
                                                        value={this.state.barangayAccountValue}
                                                        onChange={(e: any) => this.barangayChangeAccount(e)}
                                                        >
                                                        <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                                                        {
                                                            this.state.byrangayDataAccount?.map((item: any) => {
                                                            return (

                                                                <MenuItem
                                                                key={item?.id}
                                                                value={item?.attributes?.barangay_name}
                                                                >
                                                                {item?.attributes?.barangay_name}
                                                                </MenuItem>
                                                            )
                                                            })
                                                        }
                                                        </StyledTextField>
                                                        {/* </Box> */}
                                                       
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.em'}}>
                                                      
                                                        <Box>
                                                            {
                                                            (this.state.errors.barangayAccount)
                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.barangayAccount}</small>
                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                            }
                                                        </Box>
                                                       
                                                        </Grid>
                                                        
                                                        </>
                                                        
                                                        : null}  
                                                       

                                                        <Grid item xs={12} md={7} style={{ marginTop: '1.2em' }}>
                                                            <InputLabel style={webStyle.inputLabelStyle}>Reason for Considering Homeschooling</InputLabel>
                                                            <StyledTextField
                                                                select
                                                                color="darkgrey"
                                                                fullWidth
                                                                name="AccountHomeSchooling"
                                                                InputLabelProps={{ shrink: false }}
                                                                value={this.state.AccountHomeSchoolingValue}
                                                                defaultValue={this.state.AccountHomeSchoolingValue}
                                                                onChange={(e: any) => this.reasonChangeAccount(e)}
                                                            >
                                                              <MenuItem style={{ display: "none" }} value={this.state.AccountHomeSchoolingValue}>{this.state.AccountHomeSchoolingValue}</MenuItem>
                                                                {
                                                                    this.state.reasonForHomeSchoolingData?.map((item: any, index: number) => (
                                                                        <MenuItem
                                                                            style={{ width: '550px', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}

                                                                            key={item?.id}
                                                                            value={item?.attributes?.reason}
                                                                        >
                                                                            {item?.attributes?.reason}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </StyledTextField>
                                                        </Grid>
                                                        <Box>
                                                            {
                                                                (this.state.errors.AccountHomeSchooling)
                                                                    ? <small style={webStyle.errorStyle}>{this.state.errors.AccountHomeSchooling}</small>
                                                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                                            }
                                                        </Box>
                                                        <Grid item xs={12} md={7} style={{ marginTop: '2em' }}>
                                                            <InputLabel style={webStyle.inputLabelStyle}>How did you learn about us?</InputLabel>
                                                            <StyledTextField
                                                                select
                                                                color="darkgrey"
                                                                fullWidth
                                                                name="AccountAboutUs"
                                                                InputLabelProps={{ shrink: false }}
                                                                value={this.state.AccountAboutUs}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ AccountAboutUs: e.target.value })}
                                                            >
                                                         
                                                           <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                                                           <MenuItem value="Homeschool Global Website">Homeschool Global Website</MenuItem>
                                                           <MenuItem value="Google/Internet">Google/Internet</MenuItem>
                                                           <MenuItem value="Family/Friends">Family/Friends</MenuItem>
                                                           <MenuItem value="Facebook">Facebook</MenuItem>
                                                           <MenuItem value="Church">Church</MenuItem>
                                                           <MenuItem value="Others">Others</MenuItem>
                                                           
                                                            </StyledTextField>
                                                        </Grid>
                                                        <Box>
                                                            {
                                                                (this.state.errors.AccountAboutUs)
                                                                    ? <small style={webStyle.errorStyle}>{this.state.errors.AccountAboutUs}</small>
                                                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                                            }
                                                        </Box>
                                                        <Grid item xs={12} md={7} style={{
                                                            marginTop: '1.5em', padding: '0'
                                                        }}>
                                                            <Button onClick={this.changeOpen} style={{ padding: '0' }}><span style={{
                                                                color: '#9167ac', fontFamily: 'Roboto', fontSize: '14px',
                                                                fontWeight: 400, textTransform: 'none'
                                                            }}
                                                            >Change Password</span></Button>
                                                        </Grid>
                                                        <Modal
                                                            open={this.state.open}
                                                            onClose={this.handleModelClose}
                                                        >
                                                            <Box style={webStyle.modalStyle}>
                                                                <Box style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography style={webStyle.modalTextTitle}>
                                                                        Change Password</Typography>
                                                                </Box>
                                                                <Box style={{ paddingLeft: "3em", paddingRight: '4em' }}>
                                                                    <Box style={{ marginTop: '20px' }}>
                                                                        <InputLabel style={webStyle.inputLabelStyle}> Current Password </InputLabel>
                                                                        <StyledTextField
                                                                            placeholder="Current Password"
                                                                            fullWidth={true}
                                                                            //type="password"
                                                                            type={this.state.passwordInputState}
                                                                            name="currentPassword"
                                                                            value={this.state.currentPassword}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ currentPassword: e.target.value })}
                                                                            onFocus={this.hideError}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                  <InputAdornment position="end"  >
                                                                                    <IconButton onClick={this.changePasswordState}> 
                                                                                    {
                                                                                      this.state.passwordState ?
                                                                                      <Visibility style={webStyle.EmailOutlinedIcon} /> 
                                                                                      :<VisibilityOff style={webStyle.EmailOutlinedIcon} /> 
                                                                                    }
                                                                                      
                                                                                    </IconButton>
                                                                                  </InputAdornment>
                                                                                ),
                                                                              }}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        {
                                                                            (this.state.errors.currentPassword)
                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.currentPassword}</small>
                                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                                        }
                                                                    </Box>
                                                                    <Box style={{ marginTop: '1em' }}>
                                                                        <InputLabel style={webStyle.inputLabelStyle}> New Password </InputLabel>
                                                                        <StyledTextField
                                                                            placeholder="New Password"
                                                                            fullWidth={true}
                                                                            name="newPassword"
                                                                            // type="password"
                                                                            type={this.state.passwordCreateInputState}
                                                                            value={this.state.newPassword}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ newPassword: e.target.value })}
                                                                            onFocus={this.hideError}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                  <InputAdornment position="end"  >
                                                                                    <IconButton onClick={this.changePasswordCreateState}> 
                                                                                    {
                                                                                      this.state.passwordCreateState ?
                                                                                      <Visibility style={webStyle.EmailOutlinedIcon} /> 
                                                                                      :<VisibilityOff style={webStyle.EmailOutlinedIcon} /> 
                                                                                    }
                                                                                      
                                                                                    </IconButton>
                                                                                  </InputAdornment>
                                                                                ),
                                                                              }}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        {
                                                                            (this.state.errors.newPassword)
                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.newPassword}</small>
                                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                                        }
                                                                    </Box>
                                                                    <Box style={{ marginTop: '1em' }}>
                                                                        <InputLabel style={webStyle.inputLabelStyle}> Confirm Password </InputLabel>
                                                                        <StyledTextField
                                                                            placeholder="Confirm Password"
                                                                            fullWidth={true}
                                                                            name="confirmNewPassword"
                                                                           // type="password"
                                                                            type={this.state.passwordConfirmInputState}
                                                                            value={this.state.confirmNewPassword}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ confirmNewPassword: e.target.value })}
                                                                            onFocus={this.hideError}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                  <InputAdornment position="end"  >
                                                                                    <IconButton onClick={this.changePasswordConfirmState}> 
                                                                                    {
                                                                                      this.state.passwordConfirmState ?
                                                                                      <Visibility style={webStyle.EmailOutlinedIcon} /> 
                                                                                      :<VisibilityOff style={webStyle.EmailOutlinedIcon} /> 
                                                                                    }
                                                                                      
                                                                                    </IconButton>
                                                                                  </InputAdornment>
                                                                                ),
                                                                              }}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        {
                                                                            (this.state.errors.confirmNewPassword)
                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.confirmNewPassword}</small>
                                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                                        }
                                                                    </Box>
                                                                    <Box style={{ textAlign: 'center', marginBottom: '3em' }}>
                                                                        <Button style={{textTransform: 'none',...webStyle.UpdatePassword}} onClick={this.updatePasscode}>Update Password</Button>
                                                                    </Box>
                                                                </Box>
                                                                <Snackbar anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                                                                    <Alert severity="success">
                                                                        {this.state.snackbarMessage}
                                                                    </Alert>
                                                                </Snackbar>
                                                                <Snackbar anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }} open={this.state.snackbarOpenError} autoHideDuration={4000} onClose={this.handleClose1} >
                                                                    <Alert severity="error">
                                                                        {this.state.snackbarMessage}
                                                                    </Alert>
                                                                </Snackbar>
                                                            </Box>
                                                        </Modal>
                                                    </Grid>
                                                    <Box style={{ textAlign: 'right', marginBottom: "3em", }}>
                                                        <Button
                                                            type='submit'
                                                            disableElevation
                                                            disableRipple
                                                            style={{ textTransform: 'none',...webStyle.UpdateSettings}}
                                                            onClick={this.onSubmitAccountSetting}
                                                        >Update settings
                                                        </Button>
                                                        <Modal
                                                            open={this.state.updateOpen}
                                                        >
                                                            <Box style={webStyle.modalStyle}>
                                                                <Box style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                    <Box style={{ paddingBottom: '1em' }}>
                                                                        <CheckCircleIcon style={webStyle.CheckBoxStyle} />
                                                                    </Box>
                                                                    <Typography align='center' style={webStyle.Successfully}>Successfully
                                                                        <br></br>
                                                                        updated account settings</Typography>
                                                                </Box>
                                                          
                                                                <IconButton onClick={this.handleCloseAccount} style={{ marginTop: -153, marginLeft: -15 }}>
                                                                <Box style={{textAlign: 'center', ...webStyle.boxCancelIcon}}>
                                                                    <small style={{ textAlign: 'center' }}>x</small>
                                                                </Box>
                                                                </IconButton>
                                                            </Box>
                                                        </Modal>
                                                    </Box>
                                                </>
                                            }
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid >
                </Container >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    UpdatePassword:{
    height: "52px",
    marginTop: "40px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: '#fafafb',
    fontFamily: 'Poppins',
    fontSize: ' 16px',
    fontWeight: 600,
    padding: '1.5em',
    },
    Successfully:{
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontWeight: 600,
        width: '333px',

    },
    CheckBoxStyle:{
        width: '42px',
        height: '42px',
        color: ' #99cb51'
    },
    UpdateSettings:{
        height: "52px",
        marginTop: "40px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: " #fab03d",
        color: '#fafafb',
        fontFamily: 'Poppins',
        fontSize: ' 16px',
        fontWeight: 600,
        padding: '1.5em'
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '520px',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
    },
    modalTextTitle: {
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        paddingLeft: '2.6em'

    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: 'Roboto',
        fontSize: ' 12px',
    },
    inputLabelStyle: {
        marginBottom: '10px',
        height: '22px',
        color: "#757575",
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: "2em",
    },
    EmailOutlinedIcon:{
        color: "#b5b5be"
      },
    boxCancelIcon:{
        paddingTop: '2px', 
        borderRadius: '50px', 
        color: '#fff', 
        backgroundColor: '#B98ED6', 
        width: '32px', 
        height: '32px' 
    }
  
};
// Customizable Area End
