

import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    Tabs,
    Tab,
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { stripe, dragonPay, profilePic } from './assets';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"






const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: '#99cb51',
        '&$checked': {
            color: '#99cb51',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);



function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box p={3} id="TabPanelBox" sx={{ marginBottom: "15px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CustomTab = withStyles({
    root: {
        backgroundColor: '#fafafa',
        textTransform: "none"
    },
    selected: {
        backgroundColor: '#ffffff',
    },
})(Tab);

// Customizable Area End
// Customizable Area Start
import OrderDetailViewController, {
    Props, TabPanelProps,

} from "./OrderDetailViewController";

const ProfileInfo = (props: any) => {
    const { ProfileName, GradeLevelValue, ProgramSelected, Accredition, Deduction } = props
    return (
        <Box style={{ margin: "57px 26px 0px" }}>
            <Box style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ececf1", alignItems: "center" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <img src={profilePic} alt="profilePic" />
                    <Typography style={{ paddingLeft: "10px" }}>{ProfileName}</Typography>
                </Box>
                <Typography >Grade Level: {GradeLevelValue}</Typography>
                <Typography>Program Selected: {ProgramSelected}</Typography>

            </Box>
            <Box style={{ display: "flex", borderBottom: "1px solid #ececf1", padding: "20px 10px" }}>
                <Typography style={{ width: "40%" }}>Accredition: {Accredition}</Typography>
                <Typography>Deduction: {Deduction}</Typography>
            </Box>
        </Box>
    )
}

const TableValues = (props: any) => {
    const { labelInstallmentStyle, valueInstallementStyle, discountStyle, labelWidth, valueWidth1, valueWidth2, valueWidth5, tableType, tableData } = props;
    return (
        <Grid container style={{ display: "flex" }} >
            <Grid style={labelWidth} item >
                {
                    tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "22px" : "16px" }}>{i.label}</Grid>)
                }
            </Grid>
            <Grid style={valueWidth1} item >
                {
                    tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", marginLeft: "10px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "19px" : "16px" }}>{i.value1}</Grid>)
                }
            </Grid>
            {(tableType === "secondInstallment" || tableType === "fiveInstallments") &&
                <Grid style={valueWidth2} item >
                    {
                        tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", marginLeft: "10px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "19px" : "16px" }}>{i.value2}</Grid>)
                    }
                </Grid>
            }
            {tableType === "fiveInstallments" &&
                <Grid style={valueWidth5} item >
                    {
                        tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", marginLeft: "10px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "19px" : "16px" }}>{i.value3}</Grid>)
                    }
                </Grid>
            }
            {tableType === "fiveInstallments" &&
                <Grid style={valueWidth5} item >
                    {
                        tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", marginLeft: "10px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "19px" : "16px" }}>{i.value4}</Grid>)
                    }
                </Grid>
            }
            {tableType === "fiveInstallments" &&
                <Grid style={valueWidth5} item >
                    {
                        tableData?.map((i: any) => <Grid style={{ marginBottom: "17px", marginLeft: "10px", color: i.id === 0 ? "red" : "", fontSize: i.id === 11 ? "19px" : "16px" }}>{i.value5}</Grid>)
                    }
                </Grid>
            }

        </Grid>
    )

}

// Customizable Area End
export default class Checkout6 extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start    

    // Customizable Area End

    render() {

        const fullPayment = [
            { id: 1, label: "Program Fee", value1: "₱525.00" },
            { id: 2, label: "TLC Fee", value1: "₱17,500.00" },
            { id: 3, label: "Recommendation Curriculum Bundle", value1: "₱56,224.00" },
            { id: 4, label: "Sub Total", value1: "₱525.00" },
            { id: 0, label: "Discount", value1: "-₱525.00" },
        ]
        const secondInstallment = [
            { id: 11, label: "Easy Installments", value1: "1st Installment", value2: "2nd Installment" },
            { id: 1, label: "Program Fee", value1: "₱525.00", value2: "₱525.00" },
            { id: 2, label: "Installment Fee", value1: "₱17,500.00", value2: "₱17,500.00" },
            { id: 3, label: "TLC Fee", value1: "₱17,500.00", value2: "₱17,500.00" },
            { id: 4, label: "TLC Installment Fee", value1: "₱525.00", value2: "₱525.00" },
            { id: 5, label: "Recommendation Curriculum Bundle", value1: "₱56,224.00", value2: "₱56,224.00" },
            { id: 6, label: "Sub Total", value1: "₱56,224.00", value2: "₱56,224.00" },
            { id: 0, label: "Discount", value1: "- ₱15,000.00", value2: "- ₱15,000.00" },
            // { label: "Total Due", value1: "₱41,224.00", value2: "₱41,224.00" },
        ]
        const fiveInstallments = [
            { id: 11, label: "Easy Installments", value1: "Initial Pay", value2: "2nd Pay", value3: "3rd Pay", value4: "4th Pay", value5: "5th Pay" },
            { id: 1, label: "Program Fee", value1: "₱525.00", value2: "₱525.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 2, label: "Installment Fee", value1: "₱17,500.00", value2: "₱17,500.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 3, label: "TLC Fee", value1: "₱17,500.00", value2: "₱17,500.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 4, label: "TLC Installment Fee", value1: "₱525.00", value2: "₱525.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 5, label: "Recommendation Curriculum Bundle", value1: "₱56,224.00", value2: "₱56,224.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 6, label: "Sub Total", value1: "₱56,224.00", value2: "₱56,224.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
            { id: 0, label: "Discount", value1: "- ₱15,000.00", value2: "- ₱15,000.00", value3: "₱525.00", value4: "₱525.00", value5: "₱525.00" },
        ]



        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            // modal={this.manage}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', width: '95%', padding: "0" }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto', padding: "0" }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        {/* stepper tabs start */}
                                        <Grid container style={webStyle.stepperContainer}>
                                            <Grid item xs={12} style={webStyle.stepperGridItem}>
                                                <Box style={webStyle.stepperMenuBox}>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText1}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText2}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText3}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText4}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText5}</Typography>                                                 
                                                </Box>
                                                <Box style={webStyle.stepperLineBox}></Box>
                                            </Grid>
                                        </Grid>
                                        {/* stepper tabs ends */}
                                        <Container maxWidth={false} style={webStyle.mainWrapper}>
                                            <Container maxWidth={false} style={webStyle.GridWrapper}>
                                                <Typography style={webStyle.checkoutLabel}>Checkout</Typography>
                                                <RadioGroup aria-label="gender" name="gender1" >
                                                    <Grid container spacing={3} style={{ display: 'flex', paddingTop: '0.5em' }}>
                                                        <Grid item xs={12} md={3}>
                                                            <Box style={webStyle.GridWrapper1}>
                                                                <FormControlLabel value="female" control={<GreenRadio size="small" />} label={

                                                                    <img
                                                                        src={stripe}
                                                                        style={{
                                                                            width: '100%',
                                                                            // marginBottom: "3px",
                                                                            // marginTop: "3px"

                                                                        }}
                                                                        alt='tst'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Box style={webStyle.GridWrapper2}>

                                                                <FormControlLabel value="male" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={dragonPay}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='tst'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                                <Typography style={webStyle.noOfInstallementsLabel}>
                                                    Choose number of Installments
                                                </Typography>
                                                <Box sx={{ border: "1px solid #F1F1F5", borderRadius: "15px" }}>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="basic tabs example"
                                                            TabIndicatorProps={{
                                                                style: {
                                                                    borderRadius: "2px",
                                                                    backgroundColor: "#FFFFFF",
                                                                    backgroundBlendMode: "normal",
                                                                    // border: "1px dashed #f1f1f5"
                                                                }
                                                            }}>
                                                            <CustomTab label="Full Payment" style={webStyle.tabName} {...a11yProps(0)} />
                                                            <CustomTab label="2 Installments" style={webStyle.tabName} {...a11yProps(1)} />
                                                            <CustomTab label="5 Installments" style={webStyle.tabName} {...a11yProps(2)} />
                                                        </Tabs>
                                                    </Box>

                                                    {/* fullPayment */}
                                                    <Box style={webStyle.mainBox}>
                                                        <ProfileInfo ProfileName="Owais Feban" GradeLevelValue="Grade 3" ProgramSelected="Touch" Accredition="PH Accredition" Deduction="None" />

                                                        <TabPanel value={this.state.tabValue} index={0}>
                                                            <TableValues tableType="fullPayment" tableData={fullPayment} labelWidth={webStyle.installmentLabelOne} valueWidth1={webStyle.installmentValueOne} />
                                                            <Box sx={webStyle.installmentBox}>
                                                                <Grid style={webStyle.installmentLabelOne}>Total</Grid>
                                                                <Grid style={{ ...webStyle.totalValueOne, textAlign: "right" }}>₱9,120.00</Grid>
                                                            </Box>
                                                        </TabPanel>


                                                        {/* twoInstallments */}

                                                        <TabPanel value={this.state.tabValue} index={1}>
                                                            <TableValues tableType="secondInstallment" tableData={secondInstallment} labelWidth={{ width: "60%" }} valueWidth1={webStyle.installmentValueTwo} valueWidth2={webStyle.installmentValueTwo} />
                                                            <Box sx={webStyle.installmentBox}>
                                                                <Grid style={webStyle.installmentLabelTwo}>Total</Grid>
                                                                <Grid style={{ ...webStyle.totalValueTwo, textAlign: "right" }}>₱9,120.00</Grid>
                                                                <Grid style={{ ...webStyle.totalValueTwo, textAlign: "right" }}>₱590</Grid>
                                                            </Box>
                                                        </TabPanel>

                                                        {/* fiveInstallments */}

                                                        <TabPanel value={this.state.tabValue} index={2}>
                                                            <TableValues tableType="fiveInstallments" tableData={fiveInstallments} labelWidth={webStyle.installmentLabelFive} valueWidth1={webStyle.installmentValueFive} valueWidth2={webStyle.installmentValueFive} valueWidth5={webStyle.installmentValueFive} />
                                                            {/* <TableValues tableType="fiveInstallments" tableData={fiveInstallments} labelWidth={{ width: "40%" }}  /> */}
                                                            <Box sx={webStyle.installmentBox}>
                                                                <Grid style={webStyle.totalLabelFive}>Total</Grid>
                                                                <Grid style={{ ...webStyle.totalValueFive, textAlign: "right" }}>₱9,120.00</Grid>
                                                                <Grid style={{ ...webStyle.totalValueFive, textAlign: "right" }}>₱9,120.00</Grid>
                                                                <Grid style={{ ...webStyle.totalValueFive, textAlign: "right" }}>₱9,120.00</Grid>
                                                                <Grid style={{ ...webStyle.totalValueFive, textAlign: "right" }}>₱590</Grid>
                                                                <Grid style={{ ...webStyle.totalValueFive, textAlign: "right" }}>₱9,120.00</Grid>
                                                            </Box>
                                                        </TabPanel>
                                                    </Box>
                                                </Box>
                                                <Grid container spacing={4} style={{ marginBottom: '6em' }} >
                                                    <Grid item xs={12} style={{ marginTop: '2em', textAlign: "right" }}>

                                                        <Button variant="contained" style={{ textTransform: "none", textAlign: "center", ...webStyle.handleCheckout }} onClick={this.finalCheckoutForPayment} >
                                                            Pay ₱ 60,174.00
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBox: {
        border: "1px solid #e4e4e4",
        borderRadius: "15px",
        margin: "10px"
    },
    installmentBox: {
        display: "flex",
        borderBottom: "2px solid #f5f5f5",
        borderTop: "2px solid #f5f5f5"
    },
    installmentLabelOne: {
        width: "88%",
        margin: "22px 0px"
    },
    installmentValueOne: {
        width: "12%",
        margin: "22px 0px",
        textAlign: "right",
        // paddingRight:"15px"
    },
    installmentLabelTwo: {
        width: "60%",
        margin: "22px 0px"
    },
    installmentValueTwo: {
        width: "20%",
        // padding: "22px 0px",
        borderLeft: "2px solid #f5f5f5",
        textAlign: "right",
        paddingRight: "15px"

    },
    installmentLabelFive: {
        width: "40%",
    },
    installmentValueFive: {
        width: "12%",
        borderLeft: "2px solid #f5f5f5",
        textAlign: "right",

    },
    totalValueOne: {
        width: "10%",
        margin: "22px 0px",
        // textAlign:"right",
        // paddingRight:"15px"
    },
    totalValueTwo: {
        width: "20%",
        padding: "22px 10px",
        borderLeft: "2px solid #f5f5f5",
        // justifyContent: "center",
        // display: "flex"
    },
    totalLabelFive: {
        width: "40%",
        justifyContent: "start",
        display: "flex",
        // padding: "22px 0px",
        margin: "22px 0px",
    },
    totalValueFive: {
        width: "12%",
        padding: "22px 10px",
        borderLeft: "2px solid #f5f5f5",
        // marginLeft:"10px"
        // textAlign: "center",
        // justifyContent: "center",
        // display: "flex"
    },
    noOfInstallementsLabel: {
        paddingTop: "20px",
        paddingBottom: "10px",
        fontSize: "20px",
        fontWeight: 600,
        fontFamily: "poppins"
    },
    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    Allignment1: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em'
    },
    Allignment: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '2.7em'
    },
    Accreditation: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#9167ac",
    },
    finalCheckout: {
        width: "200px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px", marginLeft: '1em',
    },
    mainheadingSubProgram1: {
        color: " #192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
    },
    GridWrapper1: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #e2e2ea",
        padding: "1em",
        marginTop: '2em'
    },
    GridWrapper2: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #e2e2ea",
        padding: "1em",
        marginTop: '2em',
    },
    mainheadingSubProgramPriceTotalSubject: {
        color: "#192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
    },
    mainWrapper: {
        display: 'block',
        // flexDirection: 'row' as 'row',
        justifyContent: 'center',
        boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
        padding: '0em',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "2em",
        marginTop: "2em",
    },
    checkoutLabel: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        color: "#171725",
    },
    stepperContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    stepperGridItem: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column' as 'column',
    },
    stepperMenuBox: {
        width: '100%',
        padding: '1.3vw',
        marginTop: '1.3vw',
        marginBottom: '0.6vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    stepperMenuTextStyle: {
        fontFamily: 'Poppins',
        fontSize: '0.9vw',
        fontWeight: 600,
        color: '#99cb51',
    },
    stepperLineBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        borderBottom: '0.2vw solid #99cb51'
    },
    tabName: {
        fontSize: "22px",
        fontFamily: "Poppins",
        fontWeight: 600
    },
    summaryLabel: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600
    },
    summaryValue: {
        fontFamily: "Helvetica-Bold",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "right"
    },
    labelInstallements: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "22px",
        fontWeight: 600
    },
    ValueInstallements: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "17px",
        fontWeight: 600
    },
    totalDueBox: {
        borderTop: "2px solid #F5F5F5",
        borderBottom: "2px solid #F5F5F5",
        paddingTop: "10px",
        paddingBottom: "5px"
    },
    handleCheckout: {
        width: "200px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px", marginLeft: '1em',
    },
};
// Customizable Area End
