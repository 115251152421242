  // Customizable Area Start
import React from "react";
  // Customizable Area End
import {
    // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

import DashboardController, { Props } from "./DashboardController";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import IntroVideo from "../../../components/src/IntroVideo.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});



// Customizable Area End



export default class DashboardIntroVideo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
   
  return (
       // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ margin: '0 auto', width: "100%", padding: '0em'}}>

         <AppWithoutDrawer navigation={this.props.navigation}/>

          <Grid container style={webStyle.gridContainerStyle}>

            <Box style={{ position: 'absolute', height: '60vh', width: '60vw' }}>
              <Typography align='center' style={webStyle.welcomeTextStyle}>Welcome to our Homeschool Orientation. Learn more about 
                what we do,the heart of our programs, and how you can begin your journey with us. </Typography>
               
                  {this.state.isLoading && this.state.allVideos_introVideoURL !="" ?
                 <Box style={{   display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                 {/* <Box style={{  backgroundColor:'grey',width: '100%',height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                  
                   <CircularProgress /></Box>
                 :  <IntroVideo handleNext={this.handleNext}
                 handleWatchLater={this.handleWatchLater}
                 introVideoURL={this.state.allVideos_introVideoURL}
                 watchTime={localStorage.getItem('curentTime')}
              
                 />

                }
                  
            </Box>
            <Grid item xs={12} style={{ width: '100vw', height: '30vw', backgroundColor: '9167AC' }}>

            </Grid>
            <Grid item xs={12} style={{ width: '100vw', height: '20vw' }}>

            </Grid>

          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  gridContainerStyle: {
    width: '100vw',
    height: '100vh',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   
  },


  welcomeTextStyle: {
    marginBottom: '1em',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '1.4vw',
    // fontSize: '28px',
    fontWeight: 600,
  },

  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  },

  imgLogoStyle: {
    width: '100%',
  },
};
// Customizable Area End
