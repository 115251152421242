import React, { useState} from "react";

import {
   Box,
   Typography,
    // Customizable Area Start
  CardMedia,
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider ,makeStyles} from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },


});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
   },

  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,

  },
  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },

});


const TestimonialVideos = ({VideoURL,testVideosData, testimonial,
  handleTestimonialOne,handleTestimonialTwo,handleTestimonialThree,handleTestimonialFour,handleTestimonialFive}: any) => {

  const classes = useStyles();
  const [playing, setPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false);
  
 
  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
        setIsReady(true);
    }
  }, [isReady]);
  return (

    // Customizable Area Start
    <>

      <ThemeProvider theme={theme}>
    
        <Box style={{display: 'flex', alignItems: 'center' }} >


          <div className={classes.playerWrapper}>
            <ReactPlayer
            config={{ file: { attributes: { 
              controlsList: 'nodownload noplaybackrate', 
              disablepictureinpicture: 'true',
              style: {display:'flex', alignItems:'center', justifyContent:'center', width: '100%', height: '100%' }
             
             
          } 
          } }}
            // Disable right click
            onContextMenu={(e: any) => {
              e.preventDefault()
            }}
             width="100%"
             height="90%"
              url={VideoURL}
              muted={true}
              playing={playing}
              controls={true}
              onReady={onReady}
              style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}
              pip={false}
              
            />
           
            <div className={classes.controlWrapper}>

            {isReady? 
              <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    playing ?<PauseIcon onClick={handlePlayPause} style={{marginTop:'6.5vw', cursor:'pointer', fontSize: '3.5vw', color: 'white' }} /> 
                    : <PlayCircleFilledIcon onClick={handlePlayPause} style={{marginTop:'7vw', cursor:'pointer', fontSize: '4.2vw', color: 'white' }} />
                  }
                  
              </Box>
              :null
            }
            </div>
             
           </div>
        </Box>

       
        <Box style={webStyle.boxTestimonial1}>
                              {isReady && testVideosData.length>0 && !testimonial?.one &&
                              <>
                               <Box style={webStyle.boxGreenTestimonial}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{testVideosData[0].videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>Designation</Typography>
                            </Box>
                            </>
                              }
                             
                              {isReady && testVideosData.length>0 && !testimonial?.two &&
                              <>
                              <Box style={webStyle.boxGreenTestimonial}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{testVideosData[1].videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>Designation</Typography>
                            </Box>
                            </>
                              }

                              {isReady && testVideosData.length>0 && !testimonial?.three &&
                              <>
                              <Box style={webStyle.boxGreenTestimonial}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{testVideosData[2].videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>Designation</Typography>
                            </Box>
                            </>
                              }
                              {isReady && testVideosData.length>0 && !testimonial?.four &&
                              <>
                              <Box style={webStyle.boxGreenTestimonial}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{testVideosData[3].videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>Designation</Typography>
                            </Box>
                            </>
                              }
                              {isReady && testVideosData.length>0 && !testimonial?.five &&
                              <>
                              <Box style={webStyle.boxGreenTestimonial}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{testVideosData[4].videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>Designation</Typography>
                            </Box>
                            </>
                            }
</Box>
   {
  isReady && testVideosData.length>0 &&
 <>
 <Box style={{ marginTop: '1em', width: '75%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

<Box style={!testimonial?.one ? webStyle.display : webStyle.testimonialBox} onClick={handleTestimonialOne}>
  <CardMedia component="img" image={testVideosData[0].thumbnail} title="testimonial"
    style={webStyle.testimonialImage} />
  <PlayCircleFilledIcon style={webStyle.testimonialIcon} />
</Box>

<Box style={!testimonial?.two ? webStyle.display : webStyle.testimonialBox} onClick={handleTestimonialTwo}>
  <CardMedia component="img" image={testVideosData[1].thumbnail} title="testimonial" style={webStyle.testimonialImage} />
  <PlayCircleFilledIcon style={webStyle.testimonialIcon} />
</Box>

<Box style={!testimonial?.three ? webStyle.display : webStyle.testimonialBox} onClick={handleTestimonialThree}>
  <CardMedia component="img" image={testVideosData[2].thumbnail} title="testimonial" style={webStyle.testimonialImage} />
  <PlayCircleFilledIcon style={webStyle.testimonialIcon} />
</Box>

<Box style={!testimonial?.four ? webStyle.display : webStyle.testimonialBox} onClick={handleTestimonialFour}>
  <CardMedia component="img" image={testVideosData[3].thumbnail} title="testimonial" style={webStyle.testimonialImage} />
  <PlayCircleFilledIcon style={webStyle.testimonialIcon} />
</Box>

<Box style={!testimonial?.five ? webStyle.display : webStyle.testimonialBox} onClick={handleTestimonialFive}>
  <CardMedia component="img" image={testVideosData[4].thumbnail} title="testimonial" style={webStyle.testimonialImage} />
  <PlayCircleFilledIcon style={webStyle.testimonialIcon} />
</Box>

</Box>
 </>
   }
       
   </ThemeProvider>
    </>
  );
}

const webStyle = {
  boxTestimonial1: {
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    // height:'80px',

  },

  boxGreenTestimonial: {
    width: '80%',
    height: "60px",
    borderRadius: "10px",
    backgroundColor: "#8FBE49",
    marginTop: '-50px',
    marginBottom: '0.5em',
    zIndex: 10,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column' as 'column'
  },
  btnTestimonial: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
  },
  btnTestimonialSubText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: '#fff',
  },
  display: {
    display: 'none'
  },
  testimonialBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   marginBottom: '1em',
  },
  testimonialImage: {
    width: '90px',
    height: '60px',
    borderRadius: '5px',
    position: 'relative' as 'relative',
    cursor: 'pointer',
   },
   testimonialIcon: {
    fontSize: '2vw',
    color: 'white',
    position: 'absolute' as 'absolute',
    zIndex: 5,
    cursor: 'pointer',
  },
  }


export default TestimonialVideos;

