import React, { Component } from "react";

import {
    // Customizable Area Start
  Box,
  InputLabel,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Switch,
  TextareaAutosize

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import GlobalContext from "../../blocks/customform/src/useContext";
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiInput: {
      input: {
        color: "black",
      }
    }
  }
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);
export default class CustomEnroll1 extends Component<any> {
  static contextType: any = GlobalContext

  render() {
    const { studentFormData,
      handleChangePersonal,
      handleCheckedStudentScholarShip,
      handleAdvocatecode,
      handlePwdDiscount,
      handleParentDiscount,
      changeHandlerGetCountries,
      getAllCountries,
      getAllCities,
      religionDataChange,
      religionSelectedData,
      nationalitiesSelectedData,
      nationalitiesDataChange,
      languagesSelectedData,
      language,
      scholarshipChange,
      scholarshipSelectedData,
      changeHandlerGetCountriesResidence,
      handleClearFatherDeath,
      handleProofOfRelationship,
      datechange,
      handleCityBirth,
      handleChangeCityData,
      hideError,
      genderChange,
      handleChangeScholarshipType,
      handleStudentCauseOfDeath,
      handlestudentSpecification,
      dateFunction,
      getAllCountriesOne,
      getAllCitiesOne,
      byrangayData,
      byrangayDataBirth,
      barangayChange,
      changeHandlerAutoGetCountriesResidence,
      changeHandlerAutoGetCitiesResidence,
      changeHandlerAutoGetCountriesBirth,
      changeHandlerAutoGetCitiesBirth,
      barangayBirthChange,
     } = this.props;

    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>

        <Typography style={webStyle.heading} >Student Profile</Typography>
        <Grid container spacing={4} xs={12} style={{ padding: '2em' }}>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Student’s Last Name </InputLabel>
            <StyledTextField
              placeholder="last name"
              fullWidth
              name='studentLastName'
              value={studentFormData.studentLastName}
               onChange={handleChangePersonal}
               onFocus={hideError}
               inputProps={{ style: { textTransform: "capitalize" } }}
            />

            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentLastName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentLastName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}> Student’s First Name </InputLabel>
            <StyledTextField
              placeholder="first name"
              fullWidth
              name='studentFirstName'
              value={studentFormData.studentFirstName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentFirstName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentFirstName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Student’s Middle Name </InputLabel>
            <StyledTextField
              placeholder="middle name"
              fullWidth
              name='studentMiddleName'
              value={studentFormData.studentMiddleName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />

            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentMiddleName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentMiddleName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Gender </InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="studentGender"
              InputLabelProps={{ shrink: false }}
              value={studentFormData.studentGender}
              onChange={genderChange}
            >
              <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>Choose your Gender</span></MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              
            </StyledTextField>
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentGender)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentGender}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Date of Birth</InputLabel>
            <TextField
              id="date"
              type="date"
              fullWidth
              // defaultValue="yy-mm-dd"

              InputProps={{ inputProps: { max: dateFunction() } }}
              InputLabelProps={{
                shrink: false,
              }}
              name="studentDate"
              value={studentFormData.studentDate}
              onChange={datechange}
            />
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentDate)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentDate}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Country of birth</InputLabel>
            <Autocomplete
                     value={studentFormData.studentCountryAutoCompleteValue.value}
                     onChange={(e:any, newValue:any)=>{
                        changeHandlerAutoGetCountriesBirth(e,newValue)
                     }}
                      options={getAllCountries}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='studentCountry'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={studentFormData.studentCountry.value}
                      onInputChange={(e:any, newInputValue:any) => {
                        changeHandlerGetCountries(e,newInputValue)
                      }}
                        />
                <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentCountry)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentCountry}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>City of birth</InputLabel>
            <Autocomplete
                     value={studentFormData.studentBirthAutoCompleteValue.value}
                     onChange={(e:any, newValue:any)=>{
                        changeHandlerAutoGetCitiesBirth(e,newValue)
                     }}
                      options={getAllCities}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='studentBirth'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={studentFormData.studentBirth.value}
                      onInputChange={(e:any, newInputValue:any) => {
                        handleCityBirth(e,newInputValue)
                      }}
                    />
             <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentBirth)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentBirth}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          {byrangayDataBirth?.length > 0 && studentFormData.studentBirth.value != ""?
            <>
              <Grid item xs={12} md={6}>

                <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="barangayBirth"
                  InputLabelProps={{ shrink: false }}
                  value={studentFormData.barangayBirth.value}
                  onChange={(e: any) => barangayBirthChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                  {

                  byrangayDataBirth?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item?.id}
                          value={item?.attributes?.barangay_name}
                        >
                          {item?.attributes?.barangay_name}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>
                <Box style={webStyle.FormAlignment}>
                  {
                    (studentFormData.studentErrors.barangayBirth)
                      ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.barangayBirth}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Grid>
             </>
            : null
          }

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Age</InputLabel>
            <StyledTextField
              color="darkgrey"
              fullWidth
              name="studentAge"
              disabled
              InputLabelProps={{ shrink: false }}
              value={studentFormData.studentAge}
            >

            </StyledTextField>
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentAge)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentAge}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Religion</InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="studentReligion"
              InputLabelProps={{ shrink: false }}
              value={studentFormData.studentReligion.value}
              onChange={(e: any) => religionDataChange(e)}
            >
              <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> please select </span></MenuItem>
              {
                religionSelectedData?.map((item: any, index: number) => (
                  <MenuItem
                    key={item?.id}
                    value={item?.religion}
                    selected={item.value == studentFormData.studentReligion.value}
                  >
                    {item?.religion}
                  </MenuItem>
                ))
              }
            </StyledTextField>
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentReligion)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentReligion}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          {studentFormData.studentReligion.value == "Other" ?
            <Grid item xs={12} md={6}>

              <InputLabel style={webStyle.inputLabelStyle}>Enter Religion *</InputLabel>
              <StyledTextField
                placeholder="Religion "
                fullWidth
                name='studentReligionOthers'
                value={studentFormData.studentReligionOthers}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentReligionOthers)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentReligionOthers}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null
          }
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Nationality</InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="studentNationality"
              InputLabelProps={{ shrink: false }}
              value={studentFormData.studentNationality.value}
              onChange={(e: any) => nationalitiesDataChange(e)}
            >
              <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> please select </span></MenuItem>
              {
                nationalitiesSelectedData?.map((item: any) => {
                  return (

                    <MenuItem
                      key={item?.id}
                      value={item?.name}
                      selected={item.value == studentFormData.studentNationality.value}
                    >
                      {item?.name}
                    </MenuItem>
                  )
                })
              }
            </StyledTextField>
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentNationality)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentNationality}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Country of Residence</InputLabel>
            <Autocomplete
                     value={studentFormData.countryResiAutoCompleteValue.value}
                     onChange={(e:any, newValue:any)=>{
                        changeHandlerAutoGetCountriesResidence(e,newValue)
                     }}
                      options={getAllCountriesOne}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='studentCountryOfResidence'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={studentFormData.studentCountryOfResidence.value}
                      onInputChange={(e:any, newInputValue:any) => {
                        changeHandlerGetCountriesResidence(e,newInputValue)
                      }}
                        />

            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentCountryOfResidence)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentCountryOfResidence}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>City of Residence</InputLabel>
            <Autocomplete
                     value={studentFormData.cityResiAutoCompleteValue.value}
                     onChange={(e:any, newValue:any)=>{
                        changeHandlerAutoGetCitiesResidence(e,newValue)
                     }}
                      options={getAllCitiesOne}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='studentCityOfResidence'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={studentFormData.studentCityOfResidence.value}
                      onInputChange={(e:any, newInputValue:any) => {
                        handleChangeCityData(e,newInputValue)
                      }}
                    />

            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentCityOfResidence)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentCityOfResidence}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          {byrangayData?.length > 0 && studentFormData.studentCityOfResidence.value != ""?
            <>
              <Grid item xs={12} md={6}>

                <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="barangaySubData"
                  InputLabelProps={{ shrink: false }}
                  value={studentFormData.barangaySubData.value}
                  onChange={(e: any) => barangayChange(e)}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                  {

                    byrangayData?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item?.id}
                          value={item?.attributes?.barangay_name}
                        >
                          {item?.attributes?.barangay_name}
                        </MenuItem>
                      )
                    })
                  }
                </StyledTextField>
                <Box style={webStyle.FormAlignment}>
                  {
                    (studentFormData.studentErrors.barangaySubData)
                      ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.barangaySubData}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Grid>
            </>
            : null
          }

          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Child's First Language *</InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="studentChildsFirstLanguage"
              InputLabelProps={{ shrink: false }}
              value={studentFormData.studentChildsFirstLanguage.value}
              onChange={(e: any) => language(e)}
            >
              <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
              {
                languagesSelectedData?.map((item: any) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={item?.language}
                      selected={item.value == studentFormData.studentChildsFirstLanguage.value}
                    >
                      {item?.language}
                    </MenuItem>
                  )
                })
              }
            </StyledTextField>
            <Box style={webStyle.FormAlignment}>
              {
                (studentFormData.studentErrors.studentChildsFirstLanguage)
                  ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentChildsFirstLanguage}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          {studentFormData.studentChildsFirstLanguage.value == "Others" ?
            <Grid item xs={12} md={6}>

              <InputLabel style={webStyle.inputLabelStyle}> Enter Child's First Language * </InputLabel>
              <StyledTextField
                placeholder="First Language"
                fullWidth
                name='studentEnterChildsFirstLanguage'
                value={studentFormData.studentEnterChildsFirstLanguage}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentEnterChildsFirstLanguage)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentEnterChildsFirstLanguage}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null
          }
        </Grid>
        <Grid container style={{ paddingLeft: '3.1em', paddingRight: '2em' }} spacing={4}  >
          <Grid item xs={12} md={6} style={{ paddingLeft: '0', marginRight: '-0.5em' }}>

            <InputLabel style={webStyle.WouldYouLikeToApplyForaScholarship}>Would you like to apply for a Scholarship? * </InputLabel>
            <Switch
              name="studentWouldYouLikeToApplyForAScholarShip"
              checked={studentFormData.studentWouldYouLikeToApplyForAScholarShip}
              onChange={handleCheckedStudentScholarShip}
              inputProps={{ 'aria-label': 'controlled' }}
              data-test-id='scholarshipCheck'
            />
          </Grid>

          {studentFormData.studentWouldYouLikeToApplyForAScholarShip ?

            <Grid item xs={12} md={6} style={{ paddingRight: '3em', paddingLeft: "0em" }}>

              <InputLabel style={webStyle.inputLabelStyle}>Scholarship Type*</InputLabel>
              <StyledTextField
                select
                color="darkgrey"
                fullWidth
                name="studentScholarShopType"
                InputLabelProps={{ shrink: false }}
                value={studentFormData.studentScholarShopType}
                onChange={handleChangeScholarshipType}
              >
                <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Family">Family</MenuItem>
              </StyledTextField>
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentScholarShopType)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentScholarShopType}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null}
        </Grid>
        {studentFormData.studentWouldYouLikeToApplyForAScholarShip ?
          <Grid container spacing={1} style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingBottom: '0', paddingLeft: '0', paddingRight: '3em' }}>

              <InputLabel style={webStyle.inputLabelStyle}>Types of Scholarship *</InputLabel>
              <StyledTextField
                select
                color="darkgrey"
                fullWidth
                helperText={<span style={webStyle.scholarshipChange}>Please update the required documents in the file upload section to be followed</span>}
                name="studentTypesOfScholarShip"
                InputLabelProps={{ shrink: false }}

                value={studentFormData.studentTypesOfScholarShip.value}
                onChange={(e: any) => scholarshipChange(e)}

              >
                <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                {

                  scholarshipSelectedData?.map((item: any) => {
                    return (
                      <MenuItem
                        key={item?.id}
                        value={item?.scholarship_name}
                        selected={item.value == studentFormData.studentTypesOfScholarShip.value}
                      >
                        {item?.scholarship_name}
                      </MenuItem>
                    )
                  })
                }
              </StyledTextField>

              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentTypesOfScholarShip)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentTypesOfScholarShip}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid>

            {studentFormData.studentTypesOfScholarShip == "" ||
              studentFormData.studentTypesOfScholarShip.value == "HG Staff Student Scholarship"
              ?

              <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingLeft: '0', paddingRight: '2.5em' }}>

                <InputLabel style={webStyle.inputLabelStyle}>Proof of relationship *</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="studentProofOfRelationship"
                  InputLabelProps={{ shrink: false }}
                  value={studentFormData.studentProofOfRelationship}
                  onChange={handleProofOfRelationship}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                  <MenuItem value="Child">Child</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Nephew">Nephew</MenuItem>
                  <MenuItem value="Niece">Niece</MenuItem>
                </StyledTextField>

                <Box style={webStyle.FormAlignment}>
                  {
                    (studentFormData.studentErrors.studentProofOfRelationship)
                      ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentProofOfRelationship}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Grid> : studentFormData.studentTypesOfScholarShip == "" ||
                studentFormData.studentTypesOfScholarShip.value == "The Jovi Villote Memorial Scholarship" ?
                (
                  <>
                    <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingRight: '2em', marginLeft: '-0.3em' }}>

                      <InputLabel style={webStyle.inputLabelStyle}>Date of father's death *</InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        fullWidth
                        defaultValue={<span>yy-mm-dd</span>}
                        InputProps={{ inputProps: { max: dateFunction() } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="studentfatherDeath"
                        value={studentFormData.studentfatherDeath}
                        onChange={handleClearFatherDeath}
                      />

                      <Box style={webStyle.FormAlignment}>
                        {
                          (studentFormData.studentErrors.studentfatherDeath)
                            ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentfatherDeath}</small>
                            : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingTop: '0em', paddingBottom: '0', paddingLeft: '0', paddingRight: '3em' }} >

                      <InputLabel style={webStyle.inputLabelStyle}>Cause of the death *</InputLabel>
                      <StyledTextField
                        select
                        color="darkgrey"
                        fullWidth
                        name="studentCauseOfDeath"
                        InputLabelProps={{ shrink: false }}
                        value={studentFormData.studentCauseOfDeath}
                        onChange={handleStudentCauseOfDeath}
                      >
                        <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Enter Cause </span></MenuItem>
                        <MenuItem value="Illness">Illness</MenuItem>
                        <MenuItem value="Accident">Accident</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </StyledTextField>

                      <Box style={webStyle.FormAlignment}>
                        {
                          (studentFormData.studentErrors.studentCauseOfDeath)
                            ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentCauseOfDeath}</small>
                            : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                      </Box>
                    </Grid>
                  </>
                )

                : studentFormData.studentTypesOfScholarShip.value == "Scholarship for HG Servant Leaders" ?
                  <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingLeft: '0', paddingRight: '2.5em' }} >
                    <InputLabel style={webStyle.inputLabelStyle}> specification *</InputLabel>
                    <StyledTextField
                      select
                      color="darkgrey"
                      fullWidth
                      name="studentSpecification"
                      InputLabelProps={{ shrink: false }}
                      value={studentFormData.studentSpecification}
                      onChange={handlestudentSpecification}
                    >
                      <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                      <MenuItem value="HighUniteRevolutionariesCoreTeam">High Unite Revolutionaries Core Team</MenuItem>
                      <MenuItem value="HGndFAfamilies">HG and FA families</MenuItem>
                      <MenuItem value="HGStaffs"> HG Staffs</MenuItem>

                    </StyledTextField>

                    <Box style={webStyle.FormAlignment}>
                      {
                        (studentFormData.studentErrors.studentSpecification)
                          ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentSpecification}</small>
                          : <small style={{ visibility: 'hidden' }}>""</small>
                      }
                    </Box>
                  </Grid> : null

            }
          </Grid>
          : null
        }
        <Grid container spacing={4} style={{ paddingTop: '2em', paddingLeft: "2em", paddingRight: '2em' }}>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.studentRefferalProgram}>Do you have an Advocate code (Referral Program)? *</InputLabel>
            <Switch
              name="studentRefferalProgram"
              checked={studentFormData.studentRefferalProgram}
              onChange={handleAdvocatecode}
              inputProps={{ 'aria-label': 'controlled' }}
              data-test-id="advocateId"
            />
          </Grid>
          {studentFormData.studentRefferalProgram ?
            <Grid item xs={12} md={6} style={{ paddingRight: '4em' }}>

              <InputLabel style={webStyle.inputLabelStyle}>Advocate code (Referral Program)*</InputLabel>
              <StyledTextField
                placeholder="Enter code"
                fullWidth
                name="studentAdvocatecode"
                value={studentFormData.studentAdvocatecode}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentAdvocatecode)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentAdvocatecode}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null}
        </Grid>
        <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: "2em", paddingRight: '2em' }}>
          <Grid item xs={12} md={6} style={{ paddingRight: '3em' }}>
            <InputLabel style={webStyle.studentRefferalProgram}>Applying for a PWD discount?</InputLabel>
            <Switch
              name="studentPwd"
              checked={studentFormData.studentPwd}
              onChange={handlePwdDiscount}
              inputProps={{ 'aria-label': 'controlled' }}
              data-test-id="pwdId"
            />
          </Grid>
          {studentFormData.studentPwd ?
            <Grid item xs={12} md={6} style={{ paddingRight: '4em' }} >

              <InputLabel style={webStyle.inputLabelStyle}>PWD ID Number *</InputLabel>
              <StyledTextField
                placeholder="Enter ID Number"
                fullWidth
                name="studentPwdIdNumber"
                value={studentFormData.studentPwdIdNumber}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentPwdIdNumber)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentPwdIdNumber}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null}
        </Grid>
        <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: "2em", paddingRight: '2em' }}>
          <Grid item xs={12} md={6} >

            <InputLabel style={webStyle.studentRefferalProgram}>Applying for a solo parent discount?</InputLabel>
            <Switch
              name="studentParentDiscount"
              checked={studentFormData.studentParentDiscount}
              onChange={handleParentDiscount}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
          {studentFormData.studentParentDiscount ?
            <Grid item xs={12} md={6} style={{ paddingRight: '4em' }}>

              <InputLabel style={webStyle.inputLabelStyle}>Solo Parent ID Number *</InputLabel>
              <StyledTextField
                placeholder="Enter Number"
                fullWidth
                name="studentSoloParentId"
                value={studentFormData.studentSoloParentId}
                onChange={handleChangePersonal}
                onFocus={hideError}
              />
              <Box style={webStyle.FormAlignment}>
                {
                  (studentFormData.studentErrors.studentSoloParentId)
                    ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentSoloParentId}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
                }
              </Box>
            </Grid> : null}
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: '1.5em', paddingLeft: "2em", paddingRight: "4em" }}>

          <InputLabel style={webStyle.inputLabelStyle}>Residential Addresss</InputLabel>
          <TextareaAutosize

            placeholder="Unit / Bldg No. , Bldg Name / Lot No. , Street Name"
            aria-label="minimum height" minRows={3}
            style={webStyle.inputAdress}
            name="studentResidentialAddress"
            value={studentFormData.studentResidentialAddress}
            onChange={handleChangePersonal}
            onFocus={hideError}
          ></TextareaAutosize>
          <Box style={{ marginTop: '5px' }}>
            {
              (studentFormData.studentErrors.studentResidentialAddress)
                ? <small style={webStyle.errorStyle}>{studentFormData.studentErrors.studentResidentialAddress}</small>
                : <small style={{ visibility: 'hidden' }}>""</small>
            }
          </Box>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  StudentMenuItems:{
    display: "none", color: 'darkgray' 
  },
  inputAdress:{
    paddingLeft: '20px',
    height: "91px",
    width: '100%',
    background: "#f9f9f9",
    fontFamily: 'Roboto',
    backgroundBlendMode: "normal",
    outline: "none", border: "none"
  },
  studentRefferalProgram:{
    width: "290px", fontWeight: 400, height: "22px",
    color: "#757575",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "22px"
  },
  scholarshipChange: {
    top: " 66px",
    height: "22px",
    color: " #757575",
    fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "22px"
  },
  WouldYouLikeToApplyForaScholarship: {
    fontWeight: 400, height: "22px",
    color: "#757575",
    fontFamily: "Roboto",
    fontSize: "12px",
    width: "247px"
  },
  FormAlignment: { marginTop: '3px' },
  heading: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "1em",
    paddingLeft: '1.4em'

  },
  inputLabelStyle: {
    marginBottom: '10px',
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  }
};
// Customizable Area End
