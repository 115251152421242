import React from "react";

import {
    // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
  Avatar,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import {avatar} from "../../../blocks/dashboard/src/assets";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
    boxSizing:'border-box',
    height:'3vw',
    padding: '0.5vw 1vw',
    margin:0,
    border:'none',
    color: '#92929d',
    fontFamily: 'Roboto',
    fontSize: '1.1vw',
    fontWeight:400,
    },
  },
  MuiOutlinedInput: {
      input: {
        boxSizing:'border-box',
        height:'3vw',
        borderRadius:'1.1vw',
        padding: '0.5vw 1vw',
        margin:0,
        borderColor:'none',
        color: '#92929d',
        fontFamily: 'Roboto',
        fontSize: '1.1vw',
        fontWeight:400,
      },
     root:{
      borderRadius:'1.1vw',
     }
  },
  MuiIconButton: {
    root: {
       padding: '5px'
    },
  },
},
});

const StyledTextField = withStyles({
  root: {
    // fontSize: '14px',
    // fontFamily: 'Roboto',
    // fontSize: '1.2vw',
     width: '70%',
   // width:'26vw',
    height:'3vw',
    // fontWeight:400,
    borderRadius:'1.1vw',

   
    "& .MuiFilledInput-root": {
          
    },
    "& .MuiFilledInput-root:hover": {
        
      "@media (hover: none)": {
          
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
         
    },
    "& input::placeholder": {
      borderRadius:'1.1vw',
      backgroundColor: '#ffffff',
      backgroundBlendMode: 'normal',
       boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
     
    }
  },
})((props: any) => <TextField {...props} />);
// Customizable Area End
 // Customizable Area Start
import Chat9Controller, {
  Props,
} from "./Chat9Controller";
 // Customizable Area End

export default class Chat9 extends Chat9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <Container maxWidth={false}>
          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>
              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >
                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingLeft: '0', paddingRight: '0', marginBottom: '6em' }}>

                  <Container maxWidth={false} style={{ margin: '0 auto' }}>
                    <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography style={webStyle.welcomeTextStyle} >Get in touch with your team</Typography>
                    </Box>

                    <Grid container style={{ display: 'flex' }}>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#fab03d' }}><p></p></Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#9167ac' }}><p></p></Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box style={{ width: '100%', height: '10%', backgroundColor: '#99cb51' }}><p></p></Box>
                      </Grid>
                    </Grid>


                   <Container maxWidth={false} style={webStyle.GridWrapper}>
                    {/* ----------------------------- block Advisor --------------------------------------- */}
                    <Grid container style={webStyle.mainGridContainer}>
                        <Grid item xs={12} md={4} style={webStyle.blockAdvisor}>
                            <Paper elevation={0} style={{boxSizing: 'border-box', ...webStyle.paperContainer }}  >
                               {/* ------------------- active sub block start -------------------- */}
                               <Grid container style={webStyle.advisorPurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#fafafb', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#fff', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#fff', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- active sub block end -------------------- */}
                               {/* ------------------- sub block start -------------------- */}
                               <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}
                               {/* ------------------- sub block start -------------------- */}
                               <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}
                               {/* ------------------- sub block start -------------------- */}
                               <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}


                                {/* ------------------- sub block start -------------------- */}
                                   <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}
                                {/* ------------------- sub block start -------------------- */}
                                <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}
                                {/* ------------------- sub block start -------------------- */}
                                <Grid container style={webStyle.advisorInactivePurpleBlock}>
                                   <Grid item xs={3} style={webStyle.advisorAvatarGrid}>
                                   <Avatar alt="program avatar" src={avatar} style={webStyle.advisorAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={9} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'0.5vw'}}>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorNameStyle}}>Cleo</Typography>
                                    <Typography style={{color:'#92929d', ...webStyle.advisorTimeStyle}}>12:48PM</Typography>
                                    </Box>
                                    <Typography style={{color:'#44444f', ...webStyle.advisorTextStyle}}>Hey Alexa, Could you free now? Can you look… </Typography>
                                   </Grid>
                               </Grid>
                               {/* ------------------- sub block end -------------------- */}

                            </Paper>
                        </Grid>

                        {/* ----------------------------- block Chat msg --------------------------------------- */}
                        <Grid item xs={12} md={8} style={webStyle.blockChatMsg}>
                        <Paper elevation={0} style={{backgroundColor:'#fff', boxShadow: '-1px 5px 30px rgba(0,0,0,0.1)', boxSizing: 'border-box', ...webStyle.paperChatContainer }}  >
                        <Grid container style={webStyle.chatMsgContainerGrid}>
                                <Grid item xs={1} style={webStyle.chatMsgAvatarGrid}>
                                   <Avatar alt="chatMsg avatar" src={avatar} style={webStyle.chatMsgAvatarStyle} />
                                   </Grid>
                                   <Grid item xs={11} style={{padding:'0.5vw'}}>
                                    <Box style={{display:'flex', flexDirection:'column'}}>
                                    <Typography style={webStyle.chatMsgNameStyle}>Cleo</Typography>
                                    <Typography style={webStyle.chatMsgStatusStyle}>Online </Typography>
                                    </Box>
                                </Grid>
                         </Grid>
                         <Box style={{ width: '100%', height: '1px', backgroundColor: '#e2e2ea' }}><p></p></Box>

                        {/* -----------------scrollable chat grid starts--------------------- */}
                        <Paper elevation={0} style={{boxSizing: 'border-box', ...webStyle.paperchatScrollContainer }}  >
                         <Typography align='center' style={webStyle.chatMsgDayStyle}>Today</Typography>
                            {/* ----------------------- advisor Chat message start ---------------- */}
                            <Box style={{marginRight:'3vw',display:'inline-flex', justifyContent:'flex-start'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            Hey Alexa, Could you free now? Can you look and read the brief first… It 
                            sounds interesting. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>09:21AM </Typography>
                            </Box>
                            </Box>

                            <Box style={{marginRight:'3vw',display:'inline-flex', justifyContent:'flex-start'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            We will really enjoy this one.
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>09:23AM </Typography>
                            </Box>
                            </Box>
                            {/* ----------------------- advisor Chat message end---------------- */}
                            {/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}
                            {/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}

{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}{/* ----------------------- Client Chat message start---------------- */}
                            <Box style={{marginLeft:'3vw', display:'inline-flex', justifyContent:'flex-end'}}>
                            <Box style={webStyle.chatAdvisorMessageBox}  >  
                            <Typography style={webStyle.chatMsgAdvisorText}>
                            I am excited too to be part of this one. Its really awesome. 
                            </Typography>
                            <Typography style={webStyle.chatMsgAdvisorTime}>10:53AM </Typography>
                            </Box>
                            </Box>
                            
                             {/* ----------------------- Client Chat message end---------------- */}
                          </Paper>
                                {/* -----------------scrollable chat grid starts--------------------- */}
                        <Box style={{ display:'flex', alignItems:"center", justifyContent:'center'}}> 
                        <Box style={webStyle.sendBoxStyle}>
                        
                   
                    <StyledTextField
                    variant='outlined'
                      placeholder="Type here"
                      fullWidth={true}
                      style={{border:'1px solid #f1f1f5',
                       borderRadius:'1.1vw',
                       backgroundColor: '#ffffff',
                      backgroundBlendMode: 'normal',
                       boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
                     }}
                      // name='typeMessage'
                      // value={this.state.typeMessage}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
                      // onFocus={this.hideError}
                    />
                      <IconButton>
                       <AttachFileOutlinedIcon style={webStyle.iconAtttachBtn}/>
                      </IconButton>
                      <Button style={{textTransform: 'none', ...webStyle.sendMsgBtn}}>
                        Send Message</Button>
                        </Box>

                        </Box>
                       
                        </Paper>
                        </Grid>
                       </Grid>
                    </Container>
                  </Container>
                </Container>
              </Container>

            </Grid>
          </Grid>

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  GridWrapper: {
    marginTop: "2em",
    padding:0,
    height:'100vh',
  },
  mainGridContainer: {
    display:'flex',
    alignItems:'stretch',
    justifyContent:'space-between',
    //removes the space from the bottom of the screen
    maxHeight:'90vh',
    maxWidth:'90vw',
  },
  blockAdvisor:{
  },
    
  blockChatMsg:{
    display:'flex',
    alignItems:'stretch',
    },
  paperContainer: {
    display: 'flex',
    alignText: 'stretch',
    margin: '0.5vw',
    flexDirection:'column' as 'column',
    maxHeight:'100vh',
    maxWidth:'100vw',
    overflow:'auto',
  },
  paperchatScrollContainer: {
    display: 'flex',
    alignText: 'stretch',
    margin: '0.5vw',
    flexDirection:'column' as 'column',
    maxHeight:'100vh',
    maxWidth:'100vw',
    overflow:'auto',
  },
  paperChatContainer: {
    display: 'flex',
    alignText: 'stretch',
    margin: '0.5vw',
    flexDirection:'column' as 'column',
    maxHeight:'100vh',
    maxWidth:'100vw',
   },
  advisorPurpleBlock:{
    //height:'104px', 
    maxHeight:'9vw', 
    padding:'1vw',
    backgroundColor:'#9167ac',
    display:'flex',
    margin: '0.2vw 0',
    },
  advisorInactivePurpleBlock:{
    //height:'104px', 
    maxHeight:'9vw', 
    padding:'1vw',
    backgroundColor:'#fff',
    border:'1px solid #e2e2ea',
    display:'flex',
    margin: '0.2vw 0',
  },
  advisorNameStyle: {
    fontFamily: 'Roboto',
    fontSize: '1.1vw',
    //fontSize: '14px',
    fontWeight: 400,
  },
  advisorTimeStyle: {
    fontFamily: 'Roboto',
   fontSize: '0.9vw',
   // fontSize: '12px',
    fontWeight: 400,
  },
  advisorTextStyle: {
    fontFamily: 'Roboto',
    fontSize: '1.1vw',
    // fontSize: '14px',
    fontWeight: 400,
  },
  advisorAvatarStyle: {
    borderRadius:'0.9vw',
    width: '4vw',
    height: '4vw',
    // borderRadius:'10px',
    // width: '48px',
    // height: '48px',
  },
advisorAvatarGrid:{
  padding:'0.5vw',
  display:'flex',
   justifyContent:'center'
  },
  
  chatMsgContainerGrid:{
    padding:'1vw',
    display:'flex',
  },
  chatMsgAvatarStyle: {
    borderRadius:'2vw',
    width: '3.5vw',
    height: '3.5vw',
    // borderRadius:'24px',
    // width: '42px',
    // height: '42px',
  },
  chatMsgAvatarGrid:{
  // padding:'0.5vw',
  display:'flex',
   alignItems:'center'
  },
  chatMsgNameStyle: {
    fontFamily: 'Poppins',
    fontSize: '1.8vw',
    //fontSize: '22px',
    fontWeight: 600,
    color:'#171725',
  },
  chatMsgStatusStyle: {
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    //fontSize: '12px',
    fontWeight: 500,
    color:'#929292'
  },
  chatMsgDayStyle:{
    margin:'1vw',
    fontFamily: 'Roboto',
    fontSize: '1.1vw',
    // fontSize: '14px',
    fontWeight: 500,
    color:'#7d7d7d',
  },
  chatAdvisorMessageBox:{
    backgroundColor:'#fff', 
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)', 
    borderRadius: '1.1vw',
    // borderRadius: '15px',
    border: '1px solid #f1f1f5',
    margin:'1vw',
    padding:'1vw',
    display:'flex',
    justifyContent:'space-between'
  },

  chatMsgAdvisorText:{
    fontFamily: 'Roboto',
    fontSize: '1.1vw',
    // fontSize: '14px',
    fontWeight: 400,
    color:'#474747',
    display:"inline-block"
  },
  chatMsgAdvisorTime:{
    fontFamily: 'Roboto',
    fontSize: '0.9vw',
    //fontSize: '12px',
    fontWeight: 400,
    color:'#a0a0a0',
    display:"inline-flex",
    alignItems:'center'
  },
  sendMsgBtn:{
    width:'10vw', 
    height:'3vw', 
    backgroundColor: '#fab03d',
    //  borderRadius:'10px',
    borderRadius: '0.9vw',
     fontFamily: 'Poppins',
    fontSize: '0.9vw',
    //fontSize: '12px',
    fontWeight: 600,
    color:'#fafafb',
   },
   iconAtttachBtn:{
    transform: 'rotate(40deg)',
   height:'2vw',
   width:'2vw'
   },

   sendBoxStyle :{
    width:'90%', 
    padding:'1vw',
   // minWidth:'40vw', 
    height:'5vw', 
   backgroundColor: '#fafafb',
    //  borderRadius:'15px',
    borderRadius: '1.1vw',
     display:'flex',
     alignItems:'center',
     justifyContent:'space-between',
     zIndex:100,
   }
};
// Customizable Area End
