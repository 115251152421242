

import React from "react";

import {
  // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { dragonPay, stripe } from './assets';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"






const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,

} from "./OrderDetailViewController";
// Customizable Area End
export default class Checkout6 extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                 handleDrawerOpen={this.handleDrawerOpen}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', width: '95%', padding: "0" }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto', padding: "0" }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                         {/* stepper tabs start */}
                                         <Grid container style={webStyle.stepperContainer}>
                                            <Grid item xs={10} style={webStyle.stepperGridItem}>
                                                <Box style={webStyle.stepperMenuBox}>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>01 PROGRAM INFO</Typography>
                                                    {/* <Button variant='text' style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>01 PROGRAM INFO</Button> */}
                                                    <Typography style={webStyle.stepperMenuTextStyle}>02 CURRICULUM</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>03 TEXT BOOK SELECTION</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>04 MISCELLANEOUS</Typography>
                                                </Box>
                                                <Box style={webStyle.stepperLineBox}></Box>
                                            </Grid>
                                        </Grid>
                                        {/* stepper tabs ends */}
                                        <Container maxWidth={false} style={webStyle.mainWrapper}>
                                            <Container maxWidth={false} style={webStyle.GridWrapper}>
                                                <Typography >Checkout</Typography>
                                                <RadioGroup aria-label="gender" name="gender1" >
                                                    <Grid container spacing={3} style={{ display: 'flex', paddingTop: '0.5em' }}>
                                                        <Grid item xs={12} md={3}>
                                                            <Box style={webStyle.GridWrapper1}>
                                                                <FormControlLabel value="female" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={stripe}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='tst'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <Box style={webStyle.GridWrapper2}>

                                                                <FormControlLabel value="male" control={<GreenRadio size="small" />} label={
                                                                    <img
                                                                        src={dragonPay}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        alt='tst'
                                                                    />} ></FormControlLabel>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                                <Box style={webStyle.Allignment}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Owais Feban</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Program - Touch</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 50,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Curriculum</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 42,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Family Program</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 12,347.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Miscellaneous Add Ons</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 12,347.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Laura Mojid</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 10,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Program - Touch</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 50,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Curriculum</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 42,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Family Program</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 12,347.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Miscellaneous Add Ons</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 12,347.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Allignment1}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Sub Total </Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 2,33,388.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                            </Container>
                                        </Container>
                                        <Grid container spacing={4} style={{ marginBottom: '6em' }} >
                                            <Grid item xs={12} style={{ marginTop: '2em', textAlign: "right" }}>
                                                <Button variant="contained" style={{ textTransform: "none", textAlign: "center", ...webStyle.Accreditation }}
                                                >Switch Program / Accreditation </Button>
                                                <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.finalCheckout }} onClick={this.finalCheckoutForPayment}>
                                                    {this.state.isP ? <CircularProgress /> : "Checkout"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    Allignment1: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em'
    },
    Allignment: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '2.7em'
    },
    Accreditation: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#9167ac",
    },
    finalCheckout: {
        width: "200px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px", marginLeft: '1em',
    },
    mainheadingSubProgram1: {
        color: " #192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
    },
    GridWrapper1: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #e2e2ea",
        padding: "1em",
        marginTop: '2em'
    },
    GridWrapper2: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #e2e2ea",
        padding: "1em",
        marginTop: '2em',
    },
    mainheadingSubProgramPriceTotalSubject: {
        color: "#192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
        padding: '0em',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "2em",
        marginTop: "2em",
    },
    stepperContainer:{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      },
      stepperGridItem:{ 
        display: 'flex',  
        flexDirection:'column' as 'column',
      },
      stepperMenuBox:{
        width:'100%',
        padding: '1.3vw', 
        marginTop: '1.3vw', 
        marginBottom: '0.6vw', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between' 
      },
      stepperMenuTextStyle: {
        fontFamily: 'Poppins',
        fontSize: '1vw',
        fontWeight: 600,
        color: '#99cb51',
      },
      stepperLineBox:{
        width:'100%', 
        display: 'flex', 
        alignItems: 'flex-start', 
        justifyContent: 'flex-start',
        borderBottom:'0.2vw solid #99cb51'
      },
};
// Customizable Area End
