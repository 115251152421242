import React, { Component } from "react";

import {
    // Customizable Area Start
    Box,
  Typography,
   Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


export default class CustomEnroll9 extends Component<any> {

  render() {
   
    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>

<Grid container  xs={12} style={{padding:'2em'}}>
              <Grid item xs={12} >
                <Box style={webStyle.greenBoxStyle} >
                  <Box style={webStyle.greenInnerBoxStyle}>
                    <CheckCircleRoundedIcon style={{
                      width: '42px',
                      height: '42px',
                      color: '#ffffff',
                    }} />
                    <Typography align="center" style={webStyle.TextTitle}>Thank you</Typography>
                    <Typography align="center" style={webStyle.TextTitle2}>Your Student enrollment has been submitted</Typography>
                    <Typography align="center" style={webStyle.TextSubTitle}>
                      Our Admissions officer will get it touch with you regarding your application/s within 1 to 3 working days</Typography>
                    
                    <Typography align="center" style={webStyle.TextSubTitle2}>
                      If you want to know the status of your application
                      click on the student tab on the menu bar
                    </Typography>
                  </Box>

                </Box>
              </Grid>

            </Grid>
        
      </ThemeProvider>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  greenBoxStyle: {
    marginTop: '20px',
    border: '1px solid #f1f1f5',
    borderRadius: '50px',
    backgroundColor: '#99cb51',
    width: '100%',
    height: '316px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  greenInnerBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',

  },

  TextTitle: {
    marginTop: '10px',
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  TextTitle2: {
    marginTop: '30px',
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '24px',
  },

  TextSubTitle: {
    marginTop: '20px',
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    alignText: 'center',
    padding:'0.5em'
  },
  TextSubTitle2: {
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    alignText: 'center',
    marginTop: '20px',
    padding:'0.5em'
  },

};
// Customizable Area End