declare global {
  interface Window {
    HFCHAT_CONFIG: any;
  }
}
const ChatGlobal = () => {
  window.HFCHAT_CONFIG = {
    EMBED_TOKEN: '2be71cf0-c6cb-11ec-83d4-4dd8b5a59cc1',
    ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor'
  };
  (function () {
    var scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.async = true
    scriptTag.src = "https://widget.happyfoxchat.com/v2/visitor" + '/js/widget-loader.js'
    var s: any = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(scriptTag, s)
    window.HFCHAT_CONFIG.onload = function () {
    
      
      // var HappyFoxChat = this;
      // HappyFoxChat.hideBadge();
      // HappyFoxChat.expandChatbox();

      // HappyFoxChat.addCustomStyles
      //   ('.titlebar { height: 4em !important;background-image: none !important;padding-top: 10px !important; padding-bottom: 4em !important;padding-left: 16px !important;color: #171725 !important;}.convertsation-toolbar{height:2em !important}.chatbox-container {padding-top:10em !important} .title { text-align: left !important;font-family: Poppins !important; font-size: 24px !important;font-weight: 600 !important; margin-bottom: 0 !important }', function (err: any) {
      //   });
      //loading false



    };
  })()
}
export default ChatGlobal;