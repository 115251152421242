import React, { useState} from "react";

import {

  Box,
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider,makeStyles } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';


const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },


});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
  },

  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,

  },
  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },

});


const TestimonialVideos1 = ({VideoURL}: any) => {

  const classes = useStyles();
  const [playing, setPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false);
  
 
  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
        setIsReady(true);
    }
  }, [isReady]);
  return (

    // Customizable Area Start
    <>

      <ThemeProvider theme={theme}>
    
        <Box style={{display: 'flex', alignItems: 'center' }} >


          <div className={classes.playerWrapper}>
            <ReactPlayer
            config={{ file: { attributes: { 
              controlsList: 'nodownload noplaybackrate', 
              disablepictureinpicture: 'true',
              style: {display:'flex', alignItems:'center', justifyContent:'center', width: '100%', height: '100%' }
             
             
          } 
          } }}
            // Disable right click
            onContextMenu={(e: any) => {
              e.preventDefault()
            }}
             width="100%"
             height="90%"
              url={VideoURL}
              muted={true}
              playing={playing}
              controls={true}
              onReady={onReady}
              style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}
              pip={false}
              
            />
           
            <div className={classes.controlWrapper}>

            {isReady? 
              <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    playing ?<PauseIcon onClick={handlePlayPause} style={{marginTop:'6.5vw', cursor:'pointer', fontSize: '3.5vw', color: 'white' }} /> 
                    : <PlayCircleFilledIcon onClick={handlePlayPause} style={{marginTop:'7vw', cursor:'pointer', fontSize: '4.2vw', color: 'white' }} />
                  }
         
              </Box>
              :null
            }
            </div>
             
           </div>
        </Box>
         </ThemeProvider>
    </>
  );
}

// Customizable Area Start


export default TestimonialVideos1;
// Customizable Area End
