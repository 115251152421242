

import React from "react";

import {
   // Customizable Area Start
    Container,
    Box,
    Button,
     Typography,
     Grid,
    // Customizable Area End
} from "@material-ui/core";

//assets
import { download_icon } from './assets'
// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});

// Customizable Area End
   // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
 // Customizable Area End
class StudentDetails extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} className={classes.studenContentWrapper} style={{ alignItems: 'center', justifyContent: 'center' }} >
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid className="studenSidebar" item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid className={classes.studenContentBlock} item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '100%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                                    {/* <Container maxWidth={false} style={{ margin: '0 auto', width: '100%',display:'block',padding:'0' }}> */}

                                    <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography style={webStyle.welcomeTextStyle} >Students </Typography>
                                        <Button variant='contained' style={{ textTransform: 'none', ...webStyle.ProceedCheckout }} className={classes.proceedCheckout}>Proceed to Checkout</Button>
                                    </Box>
                                    <Grid container style={{ display: 'flex' }}>
                                        <HorizontalLine />
                                    </Grid>
                                    <Typography style={webStyle.mainheading} >Student Details</Typography>
                                    <Container maxWidth={false} style={webStyle.mainWrapper}>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            <Container maxWidth={false} style={webStyle.GridWrapper1}>
                                                <Container maxWidth={false}>
                                                    <Grid container style={{ display: "flex", paddingTop: '1em' }}>
                                                        <Grid item xs={12} lg={4} style={{ display: "flex", }} >
                                                            <Avatar alt="student avatar" src={this.state.checkoutDataObj?.studentAvatar} style={webStyle.cardAvatarStyle} />
                                                            {/* <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} /> */}
                                                            <Grid style={webStyle.studentDetailsHeading} >{this.state.checkoutDataObj?.studentName}</Grid>
                                                            {/* <Grid style={webStyle.studentDetailsHeading} >{this.state.studentsInd?.attributes?.first_name}</Grid> */}
                                                        </Grid>

                                                        <Grid item xs={12} lg={4} style={webStyle.studentDetailsHeading}><span className={classes.studentDetailsHeadingVal}>Grade Level:</span>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.grade}</Grid>
                                                        <Grid item xs={12} lg={4} style={webStyle.studentDetailsHeading}>
                                                            <span className={classes.studentDetailsHeadingVal}>Program Selected:</span>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.program}
                                                        </Grid>

                                                    </Grid>
                                                    <hr style={{ border: "1px solid #ececf1", height: "1px" }}></hr>
                                                    <Grid container style={{ display: "flex" }}>
                                                        <Grid item xs={12} lg={4} style={webStyle.studentDetailsHeading} ><span className={classes.studentDetailsHeadingVal}>Submitted on:</span>{this.state.studentsInd?.attributes?.submitted_date}</Grid>
                                                        <Grid item xs={12} lg={4} style={webStyle.studentDetailsHeading}><span className={classes.studentDetailsHeadingVal}>Student Advisor:</span></Grid>
                                                        <Grid item xs={12} lg={4} className={classes.studentApproveBtn} >
                                                            <Button variant="outlined"
                                                                style={{

                                                                }}>
                                                                Approved for Reenrollment</Button>
                                                        </Grid>

                                                    </Grid>
                                                    <hr style={{ border: "1px solid #ececf1", height: "1px" }}></hr>

                                                </Container>
                                                <Typography style={webStyle.mainheading} >Student Information</Typography>

                                                <Grid container style={{ padding: '16px 24px', display: 'flex' }} spacing={4} >
                                                    <Grid item xs={6} sm={3}>
                                                        <Typography style={webStyle.heading} >Student's First Name:</Typography>
                                                        <Typography style={webStyle.heading}>Student's Middle Name: </Typography>
                                                        <Typography style={webStyle.heading}>Student's Last Name:</Typography>
                                                        <Typography style={webStyle.heading}>Gender:</Typography>
                                                        <Typography style={webStyle.heading}>Date of Birth:</Typography>
                                                        <Typography style={webStyle.heading}>Age:</Typography>
                                                        <Typography style={webStyle.heading}>Place of Birth (City):</Typography>
                                                        <Typography style={webStyle.heading}>Place of Birth (Country):</Typography>
                                                        <Typography style={webStyle.heading}>Religion:</Typography>
                                                        <Typography style={webStyle.heading}>Nationality:</Typography>
                                                        <Typography style={webStyle.heading}>Country of Residence:</Typography>
                                                        <Typography style={webStyle.heading}>City:</Typography>
                                                        <Typography style={webStyle.heading}>Barangay:</Typography>
                                                        <Typography style={webStyle.heading}>Residential Address:</Typography>
                                                        <Typography style={webStyle.heading}>Scholarship:</Typography>
                                                        <Typography style={webStyle.heading}>Advocate / Referral Program:</Typography>

                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>

                                                        <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.first_name}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.middle_name}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.last_name}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.gender}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.date_of_birth}</Typography>
                                                        <Typography style={webStyle.headingVal}>20</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.city_of_birth}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.country_of_birth}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.religion}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.nationality}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.country_of_residence}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.city_of_residence}</Typography>
                                                        {this.state.studentsInd?.attributes?.barangay == null ?
                                                            <Typography style={webStyle.headingVal}>No barangays present</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.barangay}</Typography>
                                                        }
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.residential_address}</Typography>
                                                        {this.state.studentsInd?.attributes?.scholarship}
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.scholarship.toString()}</Typography>
                                                        <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.referral.toString()}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <Typography style={webStyle.heading} >Hub: </Typography>
                                                        <Typography style={webStyle.heading} >Incoming Grade Level: </Typography>
                                                        <Typography style={webStyle.heading}>Program:</Typography>
                                                        <Typography style={webStyle.heading}>Accreditation:</Typography>
                                                        <Typography style={webStyle.heading}>Cycle:</Typography>
                                                        <Typography style={webStyle.heading}>Previous School Attended:</Typography>
                                                        <Typography style={webStyle.heading}>Previous School Address:</Typography>
                                                        <Typography style={webStyle.heading}>Previous School Email Address:</Typography>
                                                        <Typography style={webStyle.heading}>Previous School Contact:</Typography>
                                                        <Typography style={webStyle.heading}>Last Grade Level:</Typography>
                                                        <Typography style={webStyle.heading}>Last School Year:</Typography>
                                                        <Typography style={webStyle.heading}>Child's First Language:</Typography>
                                                        <Typography style={webStyle.heading}>Learner Reference Number:</Typography>
                                                        <Typography style={webStyle.heading}>Scholarship Type:</Typography>
                                                        <Typography style={webStyle.heading}>Type of Scholarship:</Typography>
                                                        <Typography style={webStyle.heading}>Advocate / Referral Code:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        {this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.hub == null || this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.hub == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.hub}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.grade == null || this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.grade == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.grade}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.program == null || this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.program == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.program}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.accreditation == null || this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.accreditation == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.accreditation}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.cycle_start == null || this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.cycle_start == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.program_detail?.data?.attributes?.cycle_start}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_attended == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_attended == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_attended}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_address == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_address == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_address}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_email == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_email == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_email}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_contact_number == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_contact_number == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.school_contact_number}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.grade == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.grade == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.grade}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.last_year_of_school == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.last_year_of_school == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.last_year_of_school}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.child_language == "" || this.state.studentsInd?.attributes?.child_language == null ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.child_language}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.learner_reference_number == null || this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.learner_reference_number == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.previous_school?.data?.attributes?.learner_reference_number}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.scholarship_type == null || this.state.studentsInd?.attributes?.scholarship_type == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.scholarship_type}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.scholarship_category == null || this.state.studentsInd?.attributes?.scholarship_category == "choose" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.scholarship_category}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.referral_code == null || this.state.studentsInd?.attributes?.referral_code == "" ?
                                                            <Typography style={webStyle.headingVal}>Not selected</Typography> :
                                                            <Typography style={webStyle.headingVal}>{this.state.studentsInd?.attributes?.referral_code}</Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <hr style={{ border: "1px solid #ececf1", margin: "20px 24px", height: "1px" }}></hr>
                                                <Typography style={webStyle.subheading} >Family Details</Typography>
                                                <Grid container style={{ display: 'flex', padding: '24px' }} spacing={4} >
                                                    <Grid item xs={6} sm={3}>
                                                        <Typography style={webStyle.heading} >Father's First Name:</Typography>
                                                        <Typography style={webStyle.heading}>Father's Middle Name: </Typography>
                                                        <Typography style={webStyle.heading}>Father's Last Name:</Typography>
                                                        <Typography style={webStyle.heading}>Father's Occupation:</Typography>
                                                        <Typography style={webStyle.heading}>Father's Mobile Number:</Typography>
                                                        <Typography style={webStyle.heading}>Father's Email Address: </Typography>
                                                        <Typography style={webStyle.heading}>Mother's First Name (Maiden):</Typography>
                                                        <Typography style={webStyle.heading}>Mother's Middle Name (Maiden):</Typography>
                                                        <Typography style={webStyle.heading}>Mother's Last Name (Maiden):</Typography>
                                                        <Typography style={webStyle.heading}>Mother's Occupation: </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_first_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_first_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_first_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_middle_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_middle_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_middle_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_last_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_last_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_last_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_occupation == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_occupation == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_occupation}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_mobile_number == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_mobile_number == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_mobile_number}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_email == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_email == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.father_email}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_first_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_first_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_first_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_middle_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_middle_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_middle_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_last_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_last_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_last_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_occupation == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_occupation == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_occupation}</Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <Typography style={webStyle.heading} >Mother's Mobile Number:</Typography>
                                                        <Typography style={webStyle.heading}>Mother's Email Address:</Typography>
                                                        <Typography style={webStyle.heading}>Marital Status:</Typography>
                                                        <Typography style={webStyle.heading}>Guardian's Name:</Typography>
                                                        <Typography style={webStyle.heading}>Guardian's Address:</Typography>
                                                        <Typography style={webStyle.heading}>Guardian's Occupation:</Typography>
                                                        <Typography style={webStyle.heading}>Guardian's Mobile Number:</Typography>
                                                        <Typography style={webStyle.heading}>Guardian's Email Address:</Typography>
                                                        <Typography style={webStyle.heading}>Relationship to the student:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_mobile_number == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_mobile_number == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_mobile_number}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_email == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_email == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.mother_email}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.marital_status == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.marital_status == "choose" ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.marital_status}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_name == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_name == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_name}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_address == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_address == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_address}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_occupation == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_occupation == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_occupation}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_mobile_number == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_mobile_number == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_mobile_number}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_email == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_email == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.guardian_email}</Typography>
                                                        }
                                                        {this.state.studentsInd?.attributes?.parent?.data?.attributes?.relationship_to_the_student == "" || this.state.studentsInd?.attributes?.parent?.data?.attributes?.relationship_to_the_student == null ?
                                                            <Typography style={webStyle.headingVal} >Not selected</Typography>
                                                            : <Typography style={webStyle.headingVal} >{this.state.studentsInd?.attributes?.parent?.data?.attributes?.relationship_to_the_student}</Typography>
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Box style={{
                                                    justifyContent: 'space-between', marginTop: '1.5em', display: 'flex',
                                                    paddingLeft: "1.5em", paddingRight: "1.5em"
                                                }}>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                            color: "#171725",
                                                            fontSize: "16px"
                                                        }}>Files</Typography>
                                                    <Typography style={{
                                                        fontWeight: 600,
                                                        color: "#171725",
                                                        fontSize: "16px"
                                                    }}>Actions</Typography>
                                                </Box>
                                                <Box style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                                                    <hr style={{ border: "1px solid #ececf1", margin: "0.5em 0.5em 0 0.5em", height: "1px" }}></hr>
                                                </Box>
                                                {/* <Box style={{ padding: '1em' }}>
                                            <Container style={{
                                                backgroundColor: '#9167ac', borderRadius: "10px",
                                                height: '50px', paddingTop: "1em", paddingLeft: "1em", paddingRight: "1em"
                                            }}>
                                                <Grid container spacing={4}
                                                    style={{ display: "flex" }} >
                                                    <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />

                                                    <Grid item sm={3} style={webStyle.namesStyle}>FileName</Grid>
                                                    <Grid item sm={3} style={webStyle.namesStyle}>FileType</Grid>
                                                    <Grid item sm={3} style={webStyle.namesStyle}>Sizes</Grid>
                                                </Grid >
                                            </Container>
                                        </Box> */}
                                                <Box style={{ padding: '1.5em 1.5em 0 1.5em' }}>
                                                    <Container maxWidth={false} style={webStyle.fileNames}>
                                                        <Grid container style={{ display: 'block' }}>
                                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <Grid style={{ width: "300px", display: "flex", alignItems: "center" }}>
                                                                    <Box className={classes.fileNameCheckbox}><Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox', }} /></Box>
                                                                    <Box style={webStyle.namesStyle}>File Name</Box>
                                                                </Grid>
                                                                <Grid style={{ width: "150px" }}>
                                                                    <Box style={webStyle.namesStyle}>File Type</Box>
                                                                </Grid>
                                                                <Grid style={{ width: "100px" }}>
                                                                    <Box style={webStyle.namesStyle}>Sizes</Box>
                                                                </Grid>
                                                                <Grid style={{ width: "100px" }}></Grid>
                                                                {/* <Box style={{ paddingRight: '2em' }}>{null}</Box> */}
                                                            </Box>
                                                        </Grid>
                                                    </Container>
                                                </Box>
                                                <Box style={{ padding: '1em 1.5em' }} >
                                                    {this.state.downloadPdf.map((doc: any) => {
                                                        return <Container maxWidth={false} className={classes.tableRow} key={doc.id}>
                                                            <Grid container>
                                                                <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "25px" }}>
                                                                    <Grid style={{ width: "300px", display: "flex", alignItems: "center" }}>
                                                                        <Box><Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /></Box>
                                                                        <Box className={classes.tableCol1} >{doc.label} </Box>
                                                                    </Grid>
                                                                    <Grid style={{ width: "150px" }}>
                                                                        <Box className={classes.tableCol2}>{doc.content_type.split("/")[1].includes("document")?"document":doc.content_type.split("/")[1]}</Box>
                                                                    </Grid>
                                                                    <Grid style={{ width: "100px" }}>
                                                                        <Box className={classes.tableCol2}>{`${Math.ceil( doc.file_size.split(" ")[0])} ${doc.file_size.split(" ")[1]}`}</Box>
                                                                    </Grid>
                                                                    <Grid style={{ width: "100px" }}>
                                                                        <Box style={{ paddingRight: '3em' }} >
                                                                            <Button className={classes.downloadBtn} onClick={() => this.handlerFileDownload(doc)}>
                                                                                <img src={download_icon} alt='Download Icon' />
                                                                                {/* <CloudDownloadIcon /> */}
                                                                            </Button>
                                                                            {/* <a href={this.state.downloadPdf}  target="_blank" download><CloudDownloadIcon  /></a> */}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid >
                                                        </Container>
                                                    })}
                                                </Box>
                                                <Box style={{ paddingLeft: "1.5em", paddingRight: "1em" }}>
                                                    <hr style={{ border: "1px solid #ececf1", margin: "1em 0.5em", height: "1px" }}></hr>
                                                </Box>
                                                <Box style={{ padding: '1.5em' }}>
                                                    <Button variant='contained' style={{ textTransform: 'none', ...webStyle.Checkout }}>Proceed to Checkout</Button>
                                                </Box>
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container >
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// web css
const StudentDetailStyle = {
    studenContentWrapper: {
        "& .studenSidebar":
        {
            "& .MuiDrawer-paperAnchorLeft":
            {
                "@media (max-width: 768px)": {
                    display: "none",
                },
            }
        },
    },
    studenContentBlock: {
        "@media (max-width: 768px)": {
            "& .MuiContainer-root":
            {
                "@media (max-width: 768px)": {
                    marginLeft: "0 !important",
                    maxWidth: "calc(100%) !important",
                },
            }
        },
    },
    studentDetailsHeadingVal: {
        fontWeight: "600",
        paddingRight: "10px",
        fontSize: "16px",
    },
    downloadBtn: {
        padding: "3px 0",
        "&:hover":
        {
            backgroundColor: "transparent",
        }
    },
    fileNameCheckbox: {
        "& .MuiSvgIcon-root":
        {
            color: "#fff",
        },
    },
    tableCol1: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        flexGrow: 1,
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        "@media (max-width: 1024px)": {
            maxWidth: "100px",
        },
    },
    tableCol2: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        flexGrow: 1,
        textTransform: "capitalize",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        "@media (max-width: 1024px)": {
            maxWidth: "100px",
        },
    },
    tableRow: {
        borderRadius: "10px",
        height: '60px',
        padding: "0",
        border: "1px solid #e2e2ea",
        marginBottom: "5px",
        paddingTop: "5px",
        
        "& .MuiGrid-root":
        {
            marginBottom: "5px",
        },
    },
    studentApproveBtn: {
        "& .MuiButton-root": {
            backgroundColor: "#99CB51 !important",
            color: "#fff", border: "0",
            borderRadius: "10px",
            textTransform: "none",
            height: "37px",
            "@media (max-width: 768px)": {
                marginTop: "10px",
            },
            "&:hover":
            {
                boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            }
        },

    }
}


// Customizable Area Start
const webStyle = {
    ProceedCheckout: {
        minWidth: '235px',
        height: '52px',
        borderRadius: '10px',
        border: "none",
        backgroundColor: "#fab03d",
        color: '#fafafb',
        fontFamily: 'Poppins',
        fontSize: ' 16px',
        fontWeight: 600,
        backgroundBlendMode: 'normal',
    },
    fileNames: {
        borderRadius: "10px",
        height: '50px',
        padding: "0",
        border: "1px solid #e2e2ea",
        backgroundColor: '#9167ac',
    },
    Checkout: {
        width: '235px',
        height: '52px',
        borderRadius: '10px',
        border: "none",
        backgroundColor: "#fab03d",
        color: '#fafafb',
        fontFamily: 'Poppins',
        fontSize: ' 16px',
        fontWeight: 600,
        backgroundBlendMode: 'normal',
    },
    // Certificate: {
    //     borderRadius: "10px",
    //     height: '50px',
    //     padding: "0",
    //     border: "1px solid #e2e2ea",
    // },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        height: '100%',
        justifyContent: 'center',
        boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
        padding: '0em'
    },
    studentDetailsHeading: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: " 14px",
        fontWeight: 400,
        marginTop: '8px',
    },
    namesStyle: {
        color: "#ffff",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        flexGrow: 1,

    },
    // namesStyle1: {
    //     fontFamily: "Poppins",
    //     fontSize: "16px",
    //     fontWeight: 600,
    //     flexGrow: 1,
    // },
    // namesStyle2: {
    //     fontFamily: "Poppins",
    //     fontSize: "14px",
    //     fontWeight: 500,
    //     flexGrow: 1,
    //     textTransform: "capitalize",
    //     // paddingLeft: "3em"
    // },

    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        width: "100%"
    },
    heading: {
        color: " #555555",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "22px",

    },
    headingVal: {
        color: " #555555",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "22px",

    },
    subheading: {
        color: "#424244",
        fontFamily: " Poppins",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: '2em',
        paddingLeft: '24px'
    },
    mainheading: {
        color: "#424244",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.075px",
        marginTop: "2em",
        marginBottom: "1em",
        paddingLeft: '24px'
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "2em",
        maxWidth: "auto",

        // boxShadow: "0px 5px 13px 0px #1a44444f",
    },
    GridWrapper1: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: '0em',
        boxShadow: "1px 0px 14px 2px #e2e2e2",
    },
    cardAvatarStyle: {
        width: '42px',
        height: '42px',
        marginRight: '10px'
    }
}
//@ts-ignore
export default withTranslation()(withStyles(StudentDetailStyle)(withWidth()(
    withRouter(StudentDetails as React.ComponentType<any>)
)))
// Customizable Area End
