import React, { useState} from "react";

import {
    // Customizable Area Start
  Typography,
   Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import CustomEnroll5File from './CustomEnroll5File.web'

const CustomEnroll5Chrissie = ({ uploadFiles, studentFormData }: any) => {
  const [acceptType] = useState( {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc'],
  })
  const [acceptTypeJpeg] = useState( {
     'image/jpeg': ['.jpeg'],
   })

  return (
    // Customizable Area Start
    <>
    
      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'Chrissie Gracia Scholarship' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>The Chrissie Garcia Scholarship</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Letter of Recommendation (letter from a non-relative endorsing the request) *' purpose='letter' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='PWD ID *' purpose='pwd' acceptType={acceptType} />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' acceptType={acceptTypeJpeg}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Solo Parent ID *' purpose='solo' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Parent Valid ID *' purpose='validId' acceptType={acceptType} />
            </Grid>
          </Grid>
        </>
      }
      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'The International/National Achievement Scholarship' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>The International/National Achievement Scholarship</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Letter of Recommendation (scholarship) (Letter from the coach (must be a non-relative) endorsing the request) *' purpose='letter' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Proof of achivements (such as certificates, medals, pictures, news articles, etc.) *' purpose='achievements' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'The Ministry Leaders Assistance' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>The Ministry Leaders Assistance</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Letter from church Affiliation - Certification from the relevant church/mission organization leader *' purpose='chruch' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'Scholarship for HG Servant Leaders' &&
        studentFormData.studentSpecification!=='choose' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>Scholarship for HG Servant Leaders</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
            <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Letter of Recommendation *' purpose='letter' acceptType={acceptType}/>
               <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid> 
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'Financial Aid' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>Financial Aid</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Letter of Recommendation *' purpose='letter' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Proof of Income / Billing (ITR or payslip of both parents or the solo parent) *' purpose='income' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'The Jovi Villote Memorial Scholarship' &&
        studentFormData.studentfatherDeath !== '' &&
        studentFormData.studentCauseOfDeath !== 'choose' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>The Jovi Villote Memorial Scholarship</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Death Certificate of the father *' purpose='DeathCertificate' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Birth Certificate of the student/s *' purpose='BirthCertificate' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='A letter to the committee with the details of the family (Letter from a non-relative endorsing the request) *' purpose='familyDetails' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Electric Bill (most recent) *' purpose='electricBill' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'HG Staff Student Scholarship' &&
        studentFormData.studentProofOfRelationship == 'Child' &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>HG Staff Student Scholarship</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Birth Certificate of the Luova Staff *' purpose='BirthCertificateLuovaStaff' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }

      {
        studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'Individual' &&
        studentFormData.studentTypesOfScholarShip.value == 'HG Staff Student Scholarship' &&
        (studentFormData.studentProofOfRelationship == 'Sister' ||
          studentFormData.studentProofOfRelationship == 'Brother' ||
          studentFormData.studentProofOfRelationship == 'Nephew' ||
          studentFormData.studentProofOfRelationship == 'Niece' 
          ) &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}>HG Staff Student Scholarship</Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Birth Certificate of Parent (related to the Luova staff)  *' purpose='BirthCertificateParent' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Birth Certificate of the Luova Staff *' purpose='BirthCertificateLuovaStaff' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='PWD ID *' purpose='pwd' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptTypeJpeg} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Solo Parent ID *' purpose='solo' />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} acceptType={acceptType} heading='Parent Valid ID *' purpose='validId' />
            </Grid>
          </Grid>
        </>
      }
      
    {
       ( studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
        studentFormData.studentScholarShopType == 'choose' ||
        studentFormData.studentScholarShopType == 'Family' ||
        studentFormData.studentTypesOfScholarShip.value == 'choose' ||
        studentFormData.studentTypesOfScholarShip.value == null ) &&
        <>
          <Grid item xs={12}>
            <Typography style={webStyle.gridTextStyle}>Files</Typography>
            <Typography style={webStyle.gridPurpleTextStyle}></Typography>
          </Grid>
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='PWD ID *' purpose='pwd' acceptType={acceptType} />
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *' purpose='psa' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Original Report Card (Most recent report card (F-138) duly signed by the school head) *' purpose='report' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *' purpose='transcript' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='LRN (Learner Reference Number - Students from Philippine Schools) *' purpose='lrn' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading={"ID Picture (Digital copy of the child's latest photo with white background - JPEG format) *"} purpose='picture' acceptType={acceptTypeJpeg}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Solo Parent ID *' purpose='solo' acceptType={acceptType}/>
              <CustomEnroll5File uploadFiles={uploadFiles} studentFormData={studentFormData} heading='Parent Valid ID *' purpose='validId' acceptType={acceptType} />
            </Grid>
          </Grid>
        </>
      }
     
    </>
  );
}

// Customizable Area Start
const webStyle = {
  gridContainerStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding:'2em'
  },
  gridTextStyle: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
     marginBottom: "1em",
    paddingLeft:'1.4em',
  },

  gridPurpleTextStyle: {
    color: '#9167ac',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    lineSpacing: '0.08333328px',

    
     marginTop: "1.5em",
    paddingLeft:'1.4em',
  },
};

export default CustomEnroll5Chrissie;
// Customizable Area End
