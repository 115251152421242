Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelPhone = "Phone";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";
  
  exports.accountsCountryAPiEndPoint = "account_block/countries"

exports.apiMethodTypeAddDetail = "POST";
exports.apiGETMethodType = "GET";


exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.contentTypeApiCountry = "application/json";
exports.apiCountryCallMethodType = "GET";

exports.contentTypeApiReasonForHomeSchooling = "application/json";
exports.apiReasonForHomeSchoolingCallMethodType = "GET";
exports.apiReasonForHomeSchoolingCallUrl="home_schooling_reasons"

exports.contentTypesignupFormSubmit = "application/json";
exports.apisignupFormSubmitCallMethodType = "POST";
exports.signupFormSubmitCallUrl="account_block/accounts"
exports.pascodeChange="change_password"
exports.apipasscode="PUT"
exports.getStudentMethod="GET"
exports.getStudent="bx_block_customisableuserprofiles/profiles/6"
exports.postStudent="bx_block_customisableuserprofiles/profiles/6"
exports.postStudentMethod="PATCH"

exports.contentTypeApiVideoTime = "application/json"; 
exports.apiVideoTimeCallMethodType = "POST";
exports.apiVideoTimeCallUrl="bx_block_student/track_time/onboarding_video_detail"

exports.contentTypeApiAllVideo = "application/json";
exports.apiAllVideoCallMethodType = "GET";
exports.apiAllVideoCallUrl="bx_block_upload_media/videos";

exports.contentTypeApiProvinces = "application/json";
exports.apiProvincesCallMethodType = "GET";
exports.apiProvincesCallUrl="provinces";

exports.contentTypeApiBarangay = "application/json";
exports.apiBarangayCallMethodType = "GET";


// Customizable Area End
