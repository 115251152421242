import React from "react";

import {
    // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
   Grid,
  Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { avatar } from './assets';
import Avatar from '@material-ui/core/Avatar';
import CheckoutTextBook from "../../../components/src/CheckoutTextBook.web";
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});


// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
  Props,
} from "./OrderDetailViewController";
 // Customizable Area End
export default class CheckoutTwo extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
                modal={this.manage}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >


                <Container maxWidth={false} style={{ margin: '0 auto', width: "95%", padding: '0em', marginBottom: '6em' }}>
                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>

                  </Box>
                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>
                  {/* stepper tabs start */}
                  <Grid container style={webStyle.stepperContainer}>
                     <Grid item xs={12} style={webStyle.stepperGridItem}>
                         <Box style={webStyle.stepperMenuBox}>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText1}</Typography>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText2}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText3}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText4}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText5}</Typography>
                          </Box>
                          <Box style={webStyle.stepperLineBox}></Box>
                      </Grid>
                  </Grid>
                  {/* stepper tabs ends */}
                  <Grid container spacing={2} >
                    {/* ----------------------------------------------- big box -------------------------------------------------------------------- */}
                    <Grid item xs={12} lg={8}>
                      <Paper elevation={0} style={webStyle.gridContentContainerStyle}>
                           <Typography style={webStyle.gridHeadingStyle}>Text Book Selection</Typography>
                        <Paper elevation={0} style={webStyle.gridInnerContentStyle}>

                          {/* -----------------------Textbooks cards start ----------------------------- */}
                          <CheckoutTextBook
                            valueTextBookRadio={this.state.valueTextBookRadio}
                            handleChangeTextBookRadio={this.handleChangeTextBookRadio}
                          />
                          {/* -----------------------Textbooks cards end ----------------------------- */}
                        </Paper>

                        <Grid container spacing={4} >

                          <Grid item xs={12} style={{ marginTop: '1em', textAlign: "right" }}>
                           
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnSwitch }}
                            onClick={this.handleSwitchProgram}> 
                            {configJSON.btnSwitchProgram} </Button>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnNext}}
                             onClick={this.handleCheckoutFormThree}>
                            {configJSON.btnNext}
                            </Button>
                          
                          </Grid>
                        </Grid>
                      </Paper>

                    </Grid>
                    {/* --------------------------------------------- small box----------------------------------------------------------                         */}
                    <Grid item xs={12} lg={4}>
                    {/* <Grid item xs={12} sm={4}> */}
                      <Box style={webStyle.GridWrapper1}>

                        <Typography style={webStyle.mainheading} >Chekout Info</Typography>
                        <Grid container style={{ display: "flex", paddingTop: '1.2em' }}>
                          <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                          <Grid style={webStyle.studentDetailsHeading} >Owais Feban</Grid>
                        </Grid>
                        <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                          <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                          <Box style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Typography style={webStyle.ProgramInnerTextHeader}>Program: Touch - Full</Typography>
                              <Typography style={webStyle.programInnerTextSubText}>Cost: ₱ 50,000.00</Typography>
                            </Box>
                          </Box>
                        </Paper>

                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1.5em' }}>
                          <Typography style={webStyle.mainheadingSubProgramCheckout}>Subtotal</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>₱ 50,000.00</Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em' }}>
                          <Typography style={webStyle.mainheadingSubProgramCheckout}>Shipping</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Free</Typography>
                        </Box>
                        <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc' }}><p></p></Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em' }}>
                          <Typography style={webStyle.mainheadingSubProgramCheckoutTextBook}>Text Book Type</Typography>
                          <Typography style={webStyle.mainheadingSubProgramCheckoutTextBook}>Custom</Typography>
                        </Box>
                        <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc' }}><p></p></Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1em' }}>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Total</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceColor}>₱ 50,000.00</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
            </Grid>
          </Grid>

        </Container>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },
  btnSwitch: {
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#9267ac",
  },

  btnNext: {
    width: "200px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#fab03d",
    borderRadius: "10px",
    marginLeft: '1em',
  },

  gridContentContainerStyle: {
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '1.3em',
    marginTop: '2em',
  },

  gridProgramContainerStyle: {
    position: 'relative' as 'relative',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '20px',
    //width: '80%',
    display: 'flex',
    // marginLeft: '10px',
    // marginTop: '30px',


    marginLeft: '15px',
    marginTop: '20px',
    marginBottom: '5px',



  },
  gridHeadingStyle: {
    marginTop: '20px',
    marginLeft: '0.8em',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    lineSpacing: '0.08333328px',
  },

  gridInnerContentStyle: {
    border: '1px solid #fafaff',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 30px rgba(0,0,0,0.04)',
    backgroundBlendMode: 'normal',
    padding: '10px',
  },
  cardAvatarStyle: {
    width: '42px',
    height: '42px',
    marginRight: '10px'
  },

  programAvatarStyle: {
    position: 'absolute' as 'absolute',
    width: '53px',
    height: '53px',
    top: '14px',
    left: '-17px',
  },

  ProgramInnerTextHeader: {
    color: ' #171725',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },

  programInnerTextSubText: {
    color: ' #7b7b7b',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  mainheading: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.075px",
    marginTop: "1em"
  },
  mainheadingSubProgramCheckout: {
    color: "#757575",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },
  mainheadingSubProgramCheckoutTextBook: {
    color: "#192126",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },
  mainheadingSubProgramPriceTotal: {
    color: "#192126",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,

  },
  mainheadingSubProgramPriceColor: {
    color: "#fab03d",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
  },

  GridWrapper1: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #fab03d",
    padding: "1em",
    marginTop: '2em'
  },

  studentDetailsHeading: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontWeight: 600,
    marginTop: '8px'
  },
  stepperContainer:{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center' 
  },
  stepperGridItem:{ 
    display: 'flex',  
    alignItems: 'flex-start', 
    justifyContent: 'flex-start',
    flexDirection:'column' as 'column',
  },
  stepperMenuBox:{
    width:'100%',
    padding: '1.3vw', 
    marginTop: '1.3vw', 
    marginBottom: '0.6vw', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between' 
  },
  stepperMenuTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    fontWeight: 600,
  },
  stepperLineBox:{
    width:'30%', 
    marginLeft:"0.9vw",
    borderBottom:'0.2vw solid #99cb51'
  },
};
// Customizable Area End
