import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { imgConnect } from "./assets";
export const configJSON = require("./config");
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
         padding: 0
      },
    },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: "Roboto",
    fontSize: "16",
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: "Roboto",
      fontSize: "16",
      fontWeight: 400,
      width: "222.98px",
      height: "19px",
      color: "#44444f",
    },
  },
})((props: any) => <TextField {...props} />);

//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={webStyle.ContainerWidth}>
          <Grid container style={webStyle.mainWrapper}>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.leftWrapper}>
                <Box sx={webStyle.innerLeftStyle}>
                  <Box style={webStyle.BoxImageStyle}>
                    <LazyLoadImage
                      alt="connect"
                      src={imgConnect}
                      style={webStyle.imgStyle}
                      effect="blur"
                    />
                  </Box>
                  <Box style={{ paddingTop:'1em'}}>
                  <Typography
                    variant="h3"
                    component={"h5"}
                    style={webStyle.textStyleLeft}
                  >
                   Everything you need to{" "}
                    <span style={webStyle.fontColour}>maximize your HG homeschooling journey </span>
                    right at your fingertips
                  </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.rightWrapper}>
                <Box sx={webStyle.innerRightStyle}>
                  <Typography
                    variant="h6"
                    component={"h6"}
                    style={webStyle.textStyleRight}
                  >
                    Welcome Back! Sign in here.
                  </Typography>
                  <Box>
                    <InputLabel style={webStyle.inputLabelStyle}>
                      {" "}
                      Email or Username{" "}
                    </InputLabel>
                    <StyledTextField
                      placeholder="sample@mail.com"
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({ email: e.target.value })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlinedIcon style={webStyle.EmailOutlinedIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.email}
                    </small>
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}>
                      {" "}
                      {configJSON.placeHolderPassword}{" "}
                    </InputLabel>
                    <StyledTextField
                      type={this.state.passwordInputState}
                      // type="password"
                      placeholder="Enter Password"
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        {
                          this.setState({ password: e.target.value})
                        }
                      }
                      
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"  >
                            {/* <LockOutlinedIcon style={webStyle.EmailOutlinedIcon} /> */}
                            <IconButton onClick={this.changePasswordState}> 
                            {
                              this.state.passwordState ?
                              <Visibility style={webStyle.EmailOutlinedIcon} /> 
                              :<VisibilityOff style={webStyle.EmailOutlinedIcon} /> 
                            }
                              
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.password}
                    </small>
                  </Box>
                  <Box
                    style={webStyle.formFlex}
                  >
                    <Box>
                      {/* {<CustomCheckBox/>} */}
                      <FormControlLabel
                        style={ webStyle.RembeberLabel }
                        control={
                          <Checkbox
                            style={webStyle.CustomCheckBox}
                            checked={this.state.checkedRememberMe}
                            onChange={(e: any) =>
                              this.setState({
                                checkedRememberMe: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Remember Me"
                      />
                    </Box>
                    <Box
                      style={webStyle.LinkToForgetPassword}
                    >
                      <Link to="ForgotPassword" style={{ textDecoration: "none" }}>Forgot Password</Link>
                    </Box>
                  </Box>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} onClose={this.handleClose1} >
                    <Alert severity="error">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Button
                    type="submit"
                    style={{textTransform: "none",...webStyle.SignupFormSubmitStyle}}
                    fullWidth
                    onClick={this.signupFormSubmit}
                  >
                    {/* Submit */}
                    {this.state.loadingSignin ? <CircularProgress /> : "Login"}
                  </Button>
                  <Box
                    style={webStyle.landingToAnotherPage}
                  >
                    <Typography style={webStyle.textStyleInquiry}>
                      New to HG Connect?
                      <span style={webStyle.btnStyleInquiry}>
                        <Link to="EmailAccountRegistration"> <span style={webStyle.linkStyle}>Sign Up Now</span></Link>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  EmailOutlinedIcon:{
    color: "#b5b5be"
  },
  BoxImageStyle: {
    width: "50%" ,
    paddingTop:'1.5em'
  },
  ContainerWidth: {
    margin: "20px auto",
     width: '95%',
     height:'100vh',
  },
  landingToAnotherPage:{
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  SignupFormSubmitStyle:{
    width: "100%",
    height: "52px",
    marginTop: "50px",
    marginBottom: "35px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: "#fafafb",
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontWeight: 600,
  },
  LinkToForgetPassword:{
    width: "112px",
    height: " 17px",
    color: "#57afeb",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0.1px",
  },
  CustomCheckBox:{
    color: "#282828",
    height: "17px",
    width: "17px",
  },
  RembeberLabel:{
    color: "#696974",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
  },
  formFlex:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "27px",
  },
  fontColour:{
    color: "#171725" 
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    height: "100%",
    justifyContent: "center",
    boxShadow: "3px -5px 40px rgba(205,205,212,0.1)",
  },
  mainInnerWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
  },
  leftWrapper: {
    flex: 1,
    border: "1px solid #f1f1f5",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },

  innerLeftStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "90px",
    textAlign: "center",
  },

  rightWrapper: {
    flex: 1,
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    backgroundColor: "#fbfbfb",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },

  innerRightStyle: {
    paddingLeft: "50px",
    paddingRight: "50px",
  },

  imgStyle: {
    width: "100%",
  },

  textStyleRight: {
    marginTop: "43px",
    marginBottom: "36px",
    height: "42px",
    color: "#171725",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "42px",
    letterSpacing: "0.12px",
  },

  inputLabelStyle: {
    marginBottom: "10px",
    width: "220px",
    height: "22px",
    color: "#555555",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
  },

  inputBoxStyle: {
    marginTop: "27px",
  },
  textStyleInquiry: {
    color: "#696974",
    fontFamily: "Roboto",
    fontSize: " 16px",
    fontWeight: 400,
    lineHeight: "19px",
    letterSpacing: "0.1px",
  },
  btnStyleInquiry: {
    marginTop: "25px",
    textDecoration: "underline",
    fontFamily: "Roboto",
    fontSize: " 16px",
    fontWeight: 500,
    lineHeight: "19px",
  },
  textStyleLeft: {
    marginTop: "15px",
    color: "#757575",
    fontFamily: "Poppins",
    fontSize: " 25px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0.1px",
    alignText: "center",
    width: "443px"
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Roboto",
    fontSize: " 12px",
  },
  linkStyle:{
    color:'black',
    textDecoration:'underline', 
    textDecorationColor: 'black',
  },
};

// Customizable Area End
