import React from "react";

import {
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckoutRadioTextBookFullCurr from "./CheckoutRadioTextBookFullCurr.web";
import CheckoutRadioTextBookBlendedCurr from "./CheckoutRadioTextBookBlendedCurr.web";
import CheckoutRadioTextBookCustom from "./CheckoutRadioTextBookCustom.web";
import RadioGroup from '@material-ui/core/RadioGroup';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },

  },
});


const CheckoutTextBook = ({ valueTextBookRadio, handleChangeTextBookRadio }: any) => {
  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>

        <RadioGroup aria-label="checkoutTextBook" name="checkoutTextBook"
          value={valueTextBookRadio}
          onChange={handleChangeTextBookRadio}>
          <Grid container spacing={2} style={{ marginTop: '0.5em' }}>
            <Grid item xs={12} md={6}>
              <Card elevation={0} style={webStyle.TextBookCardStyle}>
                <CardContent>
                  <CheckoutRadioTextBookFullCurr value={'full_online_cur'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={0} style={webStyle.TextBookCardStyle}>
                <CardContent>
                  <CheckoutRadioTextBookBlendedCurr value={'blended_cur'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={0} style={webStyle.TextBookCardStyle}>
                <CardContent>
                  <CheckoutRadioTextBookCustom value={'custom'} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </RadioGroup>
      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {
  TextBookCardStyle: {
    borderRadius: '15px',
    border: '1px solid #e2e2ea',
  },
};

export default CheckoutTextBook;
// Customizable Area End
