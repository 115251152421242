

import React from "react";

import {
  // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
     Grid,
      // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});


// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,
} from "./OrderDetailViewController";
// Customizable Area End
export default class HomeschoolButtons extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>
                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>

                                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                        <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.welcomeTextStyle} >Homeschool Recommender</Typography>
                                        </Box>
                                        <Grid container style={{ display: 'flex' }}>
                                            <HorizontalLine />
                                        </Grid>
                                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                                            <Box style={{ flexWrap: 'wrap',  ...webStyle.Recommendation }}>
                                                <Box style={{textAlign: "left",...webStyle.boxStyle}}>
                                                <Typography style={webStyle.Personalised}>Get your Personalised Recommendation</Typography>
                                                </Box>
                                                <Box  style={{textAlign: "right",...webStyle.boxStyle}}>
                                                <Button style={{  textTransform: "none", ...webStyle.HomeschoolProgram }}
                                                    onClick={() => this.props.navigation.navigate('HomeschoolProgram')}
                                                >Homeschool Program
                                                </Button>
                                                <Button style={{ textTransform: "none", ...webStyle.HomeschoolStyle }}
                                                    onClick={() => this.props.navigation.navigate('HomeschoolStyle')}
                                                >Homeschool Style
                                                </Button>
                                                </Box>
                                            </Box>
                                         </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Recommendation: {
        paddingBottom: '4em',
        marginTop: '2em',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    Personalised: {
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "1.6vw",
        // fontSize: "20px",
        fontWeight: 600,
        //marginRight: '2em',
        padding:0,
        margin:0
    },
    HomeschoolProgram: {
        height: "4.5vw",
        width: '18vw',
        // height: "52px",
        // width: '240px',
        fontFamily: "Poppins",
        fontSize: "1.35vw",
        // fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "1.4vw",
        // borderRadius: "16px",
        backgroundColor: "#9167ac",
        margin: '0.1em',
        
    },
    boxStyle:{
        width:'50%',
        padding:0,
        margin:0
    },
    HomeschoolStyle: {
        height: "4.5vw",
        width: '15vw',
        // height: "52px",
        // width: '240px',
        fontFamily: "Poppins",
        fontSize: "1.35vw",
        // fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "1.4vw",
        // borderRadius: "16px",
        backgroundColor: "#fab03d",
        margin: '0.1em',
    },
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: "2em",
        padding:'1em'
    },
};
// Customizable Area End
