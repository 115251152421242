import React from "react";

import {
    // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Step, 
  Stepper, 
  StepLabel
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import CustomEnroll2 from "../../../components/src/CustomEnroll2.web";
import CustomEnroll7 from "../../../components/src/CustomEnroll7.web";
import CustomEnroll1 from "../../../components/src/CustomEnroll1.web";
import CustomEnroll3 from "../../../components/src/CustomEnroll3.web";
import CustomEnroll4 from "../../../components/src/CustomEnroll4.web";
import CustomEnroll6 from "../../../components/src/CustomEnroll6.web";
import CustomEnroll9 from "../../../components/src/CustomEnroll9.web";
import CustomEnroll5Chrissie from "../../../components/src/CustomEnroll5Chrissie.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"





const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiStepIcon: {

      root: {
        height: '48px',
        width: '48px',
        textAlign: 'center',
        color: '#fff',
        border: '1px solid #9167ac',
        borderRadius: '50px',
        '&$completed': {
          color: '#9167ac',
          height: '48px',
          width: '48px',
          border: 'none',
        },
        '&$active': {
          color: 'rgba(145, 103, 172, 0.5)',
          border: 'none',
        },

        '&$line': {
          borderColor: 'red',
        },

      },

      text: {
        fill: '#9167ac',
        fontFamily: "Poppins",
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: '24px',
        left: 'calc(-50% + 25px)',
        right: 'calc(50% + 25px)',
      },
      line: {
        borderColor: '#9167ac',
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none',
      }
    }
  },
});
// Customizable Area End
  //Customizable Area Start
import CustomformController, {
  Props,
} from "./CustomformController";
 //Customizable Area End
export default class StudentEnrollmentFormStepper extends CustomformController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  render() {

    console.log('??', this.state.isLoading)
    let getStepContent = (stepIndex: number) => {
      switch (stepIndex) {
        case 0:
          return (
            <CustomEnroll1 studentFormData={this.state.studentFormData}
              handleChangePersonal={this.handleChangePersonal}
              handleCheckedStudentScholarShip={this.handleCheckedStudentScholarShip}
              handleAdvocatecode={this.handleAdvocatecode}
              handlePwdDiscount={this.handlePwdDiscount}
              handleParentDiscount={this.handleParentDiscount}
              changeHandlerGetCountries={this.changeHandlerGetCountries}
              countriesData={this.state.countriesData}
              cityData={this.state.cityData}
              religionDataChange={this.religionDataChange}
              religionSelectedData={this.state.religionSelectedData}
              nationalitiesSelectedData={this.state.nationalitiesSelectedData}
              nationalitiesDataChange={this.nationalitiesDataChange}
              languagesSelectedData={this.state.languagesSelectedData}
              language={this.languageDataChange}
              scholarshipChange={this.scholarshipChange}
              scholarshipSelectedData={this.state.scholarshipSelectedData}
              changeHandlerGetCountriesResidence={this.changeHandlerGetCountriesResidence}
              handleClearFatherDeath={this.handleClearFatherDeath}
              handleProofOfRelationship={this.handleProofOfRelationship}
              datechange={this.datechange}
              handleChangeCityData={this.handleChangeCityData}
              handleCityBirth={this.handleCityBirth}
              hideError={this.hideError}
              genderChange={this.genderChange}
              handleChangeScholarshipType={this.handleChangeScholarshipType}
              handleStudentCauseOfDeath={this.handleStudentCauseOfDeath}
              handlestudentSpecification={this.handlestudentSpecification}
              dateFunction={this.dateFunction}
              getAllCountries={this.state.getAllCountries}
              getAllCities={this.state.getAllCities}
              getAllCountriesOne ={this.state.getAllCountriesOne }
              getAllCitiesOne={this.state.getAllCitiesOne}
              byrangayData={this.state.byrangayData}
              byrangayDataBirth={this.state.byrangayDataBirth}
              barangayChange={this.barangayChange}
              changeHandlerAutoGetCountriesResidence={this.changeHandlerAutoGetCountriesResidence}
              changeHandlerAutoGetCitiesResidence={this.changeHandlerAutoGetCitiesResidence}
              changeHandlerAutoGetCountriesBirth={this.changeHandlerAutoGetCountriesBirth}
              changeHandlerAutoGetCitiesBirth={this.changeHandlerAutoGetCitiesBirth}
              barangayBirthChange={this.barangayBirthChange}
            />);
        case 1:
          return (
            <CustomEnroll2 studentFormData={this.state.studentFormData}
              handleChangePersonal={this.handleChangePersonal}
              lastGradeChangeHandler={this.lastGradeChangeHandler}
              gradeSelectedData={this.state.gradeSelectedData}
              curriculumChangeHandler={this.curriculumChangeHandler}
              curriculumSelectedData={this.state.curriculumSelectedData}
              hideError={this.hideError}

            />
          );
        case 2:
          return (
            <CustomEnroll3 studentFormData={this.state.studentFormData}
              hubDataChange={this.hubDataChange}
              gradesDataChange={this.gradesDataChange}
              programDataChange={this.programDataChange}
              programDetailprogramSelectedData={this.state.programDetailprogramSelectedData}
              programDetailprogramSelectedDataMinistry={this.state.programDetailprogramSelectedDataMinistry}
              communitiesDataChange={this.communitiesDataChange}
              programDetailcommunitiesSelectedData={this.state.programDetailcommunitiesSelectedData}
              accreditationsDataChange={this.accreditationsDataChange}
              hideError={this.hideError}
              handleChangeProgramDetailsShsTrack={this.handleChangeProgramDetailsShsTrack}
              programLevelHubData={this.state.programLevelHubData}
              programLevelCycleData={this.state.programLevelCycleData}
              cycleDataChange={this.cycleDataChange}
              programLevelAccData={this.state.programLevelAccData}
              programLevelGradesData={this.state.programLevelGradesData}
              programLevelSHSData={this.state.programLevelSHSData}
            />
          );
        case 3:
          return (
            <CustomEnroll4 studentFormData={this.state.studentFormData}
              handleChangePersonal={this.handleChangePersonal}
              fathercountriesDataChange={this.fathercountriesDataChange}
              parentsInfoFathercountriesuniqueCountryCodeSelectedData={this.state.parentsInfoFathercountriesuniqueCountryCodeSelectedData}
              mothercountriesDataChange={this.mothercountriesDataChange}
              GuardianscountriesDataChange={this.GuardianscountriesDataChange}
              hideError={this.hideError}
              MaritalStatusChange={this.MaritalStatusChange}
              uniqueCountryCodeData={this.state.uniqueCountryCodeData}
              uniqueCountryCodeDataOne={this.state.uniqueCountryCodeDataOne}
              programFatherContactHandler={this.programFatherContactHandler}
              programMotherContactHandler={this.programMotherContactHandler}
              programGardianContactHandler={this.programGardianContactHandler}
            />
          );
        case 4:
          return (
            <CustomEnroll5Chrissie uploadFiles={this.uploadFiles}
              studentFormData={this.state.studentFormData}
             />
          );
        case 5:
          return (
            <CustomEnroll6 studentFormData={this.state.studentFormData}
              FinallyRegisteringChange={this.FinallyRegisteringChange}
              reasonForRegisterSelectedData={this.state.reasonForRegisterSelectedData}
             

            />
          );
        case 6:
          return (
            <CustomEnroll7 studentFormData={this.state.studentFormData}
              handleCheckedGeneralTerms={this.handleCheckedGeneralTerms}
              handleCheckedRefundTerms={this.handleCheckedRefundTerms}
              handleCheckedPrivacyTerms={this.handleCheckedPrivacyTerms}
              handleCheckedPicturePolicy={this.handleCheckedPicturePolicy}
              handleClose={this.handleClose}
              open={this.state.open}
              handleYes={this.handleYes}
              handleNo={this.handleNo}
            />
          );
        case 7:
          return (
            <CustomEnroll9 />
          );

        default:
          return 'Unknown stepIndex';
      }
    }
   
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ padding: '0' }}>


          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>
              <Drawer openD={this.state?.openD}
                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                navigation={this.props.navigation}
              />
            </Grid>

            {/* ---------------------- drawer Content start ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`
                    } :
                    { maxWidth: '90%', paddingRight: '0' }
                }
                maxWidth={false}
              >
                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}



                <Container maxWidth={false} style={{ margin: '0 auto', paddingRight: '1.1em' }}>
                  <Box style={{ marginTop: '100px',display:'flex',  justifyContent:'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >
                      Student Enrollment</Typography>
                      <span style={{width:'50%',display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                      {this.state.loadingPendingStudent && localStorage.getItem('studentID')? <CircularProgress /> : null}
                        </span>
                  </Box>

                  <Grid container style={{ display: 'flex', margin: '0' }}>
                  <HorizontalLine/>
                    <Grid item xs={12} style={{ marginTop: '20px', padding: '0' }}>
                      <Stepper activeStep={this.state.activeStep} alternativeLabel>
                        {this.getSteps().map((label: any) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    </Box>
                  </Grid>
                  <Container maxWidth={false} style={webStyle.mainWrapper}>
                    {getStepContent(this.state.activeStep)}
                    <Grid container spacing={4} >

                      <Grid item xs={12} style={{ marginBottom: '3em', paddingRight: '5em', textAlign: "right" }}>
                        <Button variant="contained" style={{
                          width: "200px",
                          height: "52px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#fafafb",
                          fontWeight: 600,
                          textAlign: "center",
                          borderRadius: "10px",
                          backgroundColor: "#9167ac",
                          textTransform: "none",
                          ...this.state.activeStep === 0 ? { display: 'none' } : { display: 'inline' },
                          ...this.state.activeStep === 7 && { display: 'none' }
                        }}
                          onClick={this.handleBackMain}
                        >Back</Button>


                        <Button variant="contained" style={{
                          width: "200px",
                          height: "52px",
                          color: "#fafafb",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: 600,
                          textAlign: "center",
                          borderRadius: "10px",
                          marginLeft: '1em',
                          backgroundColor: "#b9b9b9",
                          textTransform: "none",
                          ...this.state.activeStep > 5 ? { display: 'none' } : { display: 'inline' }

                        }}
                          onClick={this.onClickNextOfPrevSchool}>
                          {this.state.isLoading ? <CircularProgress /> : "Save to Draft"}



                        </Button>
                        <Snackbar anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }} open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.handleCloseSnackbar} >
                          <Alert severity="success">
                            {this.state.snackbarMessage}
                          </Alert>

                        </Snackbar>

                        <Button data-test-id="btnNext" variant="contained" style={{
                          width: "200px",
                          height: "52px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#fafafb",
                          textAlign: "center",
                          backgroundColor: "#fab03d",
                          borderRadius: "10px", marginLeft: '1em',
                          textTransform: "none",
                          ...this.state.activeStep === 7 && { display: 'none' }
                        }} onClick={this.handleNextMain}>
                          {this.state.activeStep > 5 ?
                            this.state.isLoading ? <CircularProgress /> : 'Submit'
                            : 'Next'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Container>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {


    borderRadius: " 50px",
    border: " 1px dashed #f1f1f5",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 15px rgba(68,68,79,0.1)",
    marginTop: '20px',
    padding: '0',
    marginBottom: '6em',
    // width:" 1000px"

  },

  heading: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "1em",
    marginBottom: "1em"

  },
  imgLogoBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  welcomeTextStyle: {
    //marginTop: '50px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
   },
  imgLogoStyle: {
    width: '100%',
  },
  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  },
  inputSelectLabelStyle: {
    width: '220px',
    height: '22px',
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: "10px"
  },
  inputBoxStyle: {
    marginTop: '30px',
  },
  inputSelectLabelStyleselect: {
    width: '220px',
    height: '22px',
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: "-1em"
  },
  inputLabelStyle: {
    marginBottom: '10px',
    width: '220px',
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },

  mainInnerWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    padding: '2em',
    top: "2.69px",
    width: "420px",
    height: "60.25px"
  },

  leftWrapper: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerRightStyle: {
    padding: "10px 30px",
  }, imgStyle: {
    width: '100%',
  },
  gridTextStyle: {
    marginTop: '30px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    lineSpacing: '0.08333328px',
  },

  gridContentContainerStyle: {
    marginTop: '10px',
    border: '1px solid #f1f1f5',
    borderRadius: '50px',
    backgroundColor: '#ffffff',
    // boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  innerLeftStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    textAlign: "center",
  },
  gridContainerStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  gridItemsLeftStyle: {
    // backgroundColor: 'red',
    //marginTop: '30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginRight: '20px',
    marginBottom: '30px',
  },
  gridItemsRightStyle: {
    //backgroundColor: 'yellow',
    //marginTop: '30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginLeft: '20px',
    marginBottom: '30px',

  },



  forgotHeading: {
    justifyContent: "center",
    alignItems: "left",
    alignText: "left",
  },
  rightWrapper: {
    backgroundColor: "#fbfbfb",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },

  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
