

import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
   Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});



// Customizable Area End
    // Customizable Area Start
import OrderDetailViewController, {
  Props,
} from "./OrderDetailViewController";
    // Customizable Area End
export default class CheckoutOne extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
                modal={this.manage}
              />
            </Grid>
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >



                <Container maxWidth={false} style={{ margin: '0 auto', width: "95%", padding: '0em', marginBottom: '6em' }}>
                  <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>
                  </Box>
                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>
                  {/* stepper tabs start */}
                  <Grid container style={webStyle.stepperContainer}>
                     <Grid item xs={12} style={webStyle.stepperGridItem}>
                         <Box style={webStyle.stepperMenuBox}>
                             <Typography style={{color: '#99cb51',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText1}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText2}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText3}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText4}</Typography>
                             <Typography style={{color: '#d4d9dd',...webStyle.stepperMenuTextStyle}}>{configJSON.stepperText5}</Typography>
                          </Box>
                          <Box style={webStyle.stepperLineBox}></Box>
                      </Grid>
                  </Grid>
                  {/* stepper tabs ends */}
                  <Grid container spacing={2} >
                    <Grid item xs={12} lg={8}>
                      <Box style={webStyle.leftGridWrapper}>
                        <Box style={{ paddingLeft: "1.3em" }}>
                          <Typography style={webStyle.mainheading} >Checkout for </Typography>
                          <Grid container style={{ display: "flex", paddingTop: '1em' }}>
                            <Avatar alt="student avatar" src={this.state.checkoutDataObj.studentAvatar} style={webStyle.cardAvatarStyle} />
                            {/* <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} /> */}
                            <Grid style={webStyle.studentDetailsHeading} >{this.state.checkoutDataObj.studentName}</Grid>
                            {/* <Grid style={webStyle.studentDetailsHeading} >Owais Feban</Grid> */}
                          </Grid>
                          <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc' }}><p></p></Box>
                        </Box>
                        <Typography style={webStyle.mainheadingSub1} >Program Items</Typography>
                        <Box style={{ padding: '1em' }}>
                          <Box style={webStyle.leftInnnerGridWrapperSubjects}>
                            <Grid container style={{ padding: '1em' }} >
                              <Grid item xs={12} style={webStyle.subjectGridStyle}>
                                <Box style={webStyle.subjectStyle}> {this.state.checkoutDataObj.programName}- Full Payment</Box>
                                {/* <Box style={webStyle.subjectStyle}>Touch Program - Full Payment</Box> */}
                                <Box style={webStyle.subjectStyle1} >₱ 35,000.00</Box>
                              </Grid>
                              <Grid item xs={12} style={webStyle.subjectGridStyle}>
                                <Box style={webStyle.subjectStyle}>WCFS US Accreditation Fee - Pre K to Grade 8</Box>
                                <Box style={webStyle.subjectStyle1} >₱ 12,500.00</Box>
                              </Grid>
                              <Grid item xs={12} style={webStyle.subjectGridStyle}>
                                <Box style={webStyle.subjectStyle}>Testing and Learning Enhancement Tool</Box>
                                <Box style={webStyle.subjectStyle1} >₱ 2,500.00</Box>
                              </Grid>
                              <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc', marginTop: '1.5em' }}><p></p></Box>
                              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: '1em', marginBottom: '1em' }}>
                                <Box style={webStyle.subjectStyle}>Total</Box>
                                <Box style={webStyle.subjectStyle1} >₱ 50,000.00</Box>
                              </Grid>
                            </Grid >
                          </Box>
                        </Box>
                        <Grid container spacing={4} >
                          <Grid item xs={12} style={{ marginTop: '1em', textAlign: "right" }}>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnSwitch }}
                            onClick={this.handleSwitchProgram}
                            > {configJSON.btnSwitchProgram} </Button>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnNext }} onClick={this.handleCheckoutFormTwo}>
                            {configJSON.btnNext}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Box style={webStyle.GridWrapper1}>
                        <Typography style={webStyle.mainheading} >Chekout Info</Typography>
                        <Grid container style={{ display: "flex", paddingTop: '1.2em' }}>
                          <Avatar alt="student avatar" src={this.state.checkoutDataObj.studentAvatar} style={webStyle.cardAvatarStyle} />
                          <Grid style={webStyle.studentDetailsHeading} >{this.state.checkoutDataObj.studentName}</Grid>
                          {/* <Grid style={webStyle.studentDetailsHeading} >Owais Feban</Grid> */}
                          {/* <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                          <Grid style={webStyle.studentDetailsHeading} >Owais Feban</Grid> */}
                        </Grid>
                        <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                          <Avatar alt="program avatar" src={this.state.checkoutDataObj.studentAvatar} style={webStyle.programAvatarStyle} />
                          {/* <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} /> */}
                          <Box style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Typography style={webStyle.ProgramInnerTextHeader}>{this.state.checkoutDataObj.programName}</Typography>
                              {/* <Typography style={webStyle.ProgramInnerTextHeader}>Program: Touch - Full</Typography> */}
                              <Typography style={webStyle.programInnerTextSubText}>Cost: ₱ 50,000.00</Typography>
                            </Box>
                          </Box>
                        </Paper>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1.5em' }}>
                          <Typography style={webStyle.mainheadingSubProgramCheckout}>Subtotal</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>₱ 50,000.00</Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em' }}>
                          <Typography style={webStyle.mainheadingSubProgramCheckout}>Shipping</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Free</Typography>
                        </Box>
                        <Box style={{ width: '100%', height: '1px', backgroundColor: '#dcdcdc' }}><p></p></Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1em' }}>
                          <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Total</Typography>
                          <Typography style={webStyle.mainheadingSubProgramPriceColor}>₱ 50,000.00</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  btnNext: {
    width: "200px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#fab03d",
    borderRadius: "10px", marginLeft: '1em',
  },
  btnSwitch: {
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#9267ac",
  },

  gridProgramContainerStyle: {
    position: 'relative' as 'relative',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '20px',
    display: 'flex',
    marginLeft: '15px',
    marginTop: '20px',
    marginBottom: '5px',
    },

  programAvatarStyle: {
  position: 'absolute' as 'absolute',
    width: '53px',
    height: '53px',
     top: '14px',
    left: '-17px',
  },

  ProgramInnerTextHeader: {
    color: ' #171725',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  programInnerTextSubText: {
     color: ' #7b7b7b',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },

  cardAvatarStyle: {
    width: '42px',
    height: '42px',
    marginRight: '10px'
  },
  studentDetailsHeading: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontWeight: 600,
    marginTop: '8px'
  },
  mainheadingSubProgramCheckout: {
    color: "#757575",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },

  mainheadingSubProgramPriceTotal: {
    color: "#192126",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
  },
  mainheadingSubProgramPriceColor: {
    color: "#fab03d",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
  },
  welcomeTextStyle: {
    //marginTop: '50px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },

  mainheading: {

    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.075px",
    marginTop: "1em"
  },
  mainheadingSub1: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.075px",
    paddingTop: '1.3em',
    paddingLeft: "1.3em",
    paddingRight: "1.3em",
  },

  leftInnnerGridWrapperSubjects: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
  },
  leftGridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    padding: "1.3em",
    marginTop: '2em',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
  },

  subjectStyle: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    flexGrow: 1,
    color: "#171725",
    paddingRight:'0.5em'
  },
  subjectGridStyle: { 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between", 
    marginTop: '0.8em'
   },
  subjectStyle1: {
    fontFamily: "Roboto",
    fontSize: "12px",
    ffontWeight: 500,
    marginRight: '1em',
    color: "#5e5e5e"
  },
  GridWrapper1: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #fab03d",
    padding: "1em",
    marginTop: '2em'
  },
  stepperContainer:{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center' 
  },
  stepperGridItem:{ 
    display: 'flex',  
    alignItems: 'flex-start', 
    justifyContent: 'flex-start',
    flexDirection:'column' as 'column',
  },
  stepperMenuBox:{
    width:'100%',
    padding: '1.3vw', 
    marginTop: '1.3vw', 
    marginBottom: '0.6vw', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between' 
  },
  stepperMenuTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    fontWeight: 600,
  },
  stepperLineBox:{
    width:'9vw', 
    marginLeft:"0.9vw",
    borderBottom:'0.2vw solid #99cb51'
  },

};
// Customizable Area End
