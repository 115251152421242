import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { GlobalContext } from "../../customform/src/useContext";



declare global {
  interface Window {
    HFCHAT_CONFIG: any;
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  popoverAnchor: any;
  studentsList: any;
  studentsListData: any;
  //expanded: any;
  expanded: boolean;

  expandedCheck: any;
  valueRadio: string;
  valueTextBookRadio: string;

  valueShow: any;
  studentsInd: any;
  studID: any;
  isLoadingStudentsList: any;
  currentPage: any;
  postPerPage: any;
  currentStudentsList: any;

  pages: any;

  openD: any;
  openS: any;
  drawerWidth: any;
  chatNow: boolean;
  askNow: boolean;
  isP: boolean;
  childGradeLevel: any;
  supportLevel: any;
  parentalInvolvement: any;
  HomeschoolCurriculum: any;
  primaryMedium: any;
  parentAdvisor: any;
  learningProgress: any;
  assessment: any;
  howManyChildrenAreYouPlanningToEnroll: any;
  tlcFilter: any;
  extendedSupportForMyChild: any;
  doYouHaveAnotherChild: any;
  liveOnlineClasses: any;
  yearsHomeSchooling: any;
  homeSchoolingStyleGeneral: any;
  homeSchoolingStyleParentalInvolvement: any;
  homeSchoolingStyleLearningStyleOfTheChild: any;
  homeSchoolingStyleCurriculumAndMaterials: any;
  homeSchoolingStyleEducationalPhilosophyg: any;
  homeSchoolingStyleSchedule: any;
  homeSchoolProgramSubmit: any;
  homeSchoolStyleSubmit: any;
  errors: any;
  downloadPdf: any;
  programResultData: any;
  pendingPay: any;
  checkPendingPaymentData: any,
  checkState: any;
  ///CheckoutDataOneStart///
  checkoutDataObj: {
    touchProgramFullPayment: any;
    wCFSUSAccreditationFeePreKToGrade8: any;
    testingAndLearningEnhancementTool: any;
    totalCheckoutDataOne: any;
    subtotalCheckoutDataOne: any;
    shippingCheckoutDataOne: any;
    studentAvatar: any;
    studentName: any;
    programName: any;
    ///CheckoutDataOneEnd///
    ///CheckoutDataTwoStart///
    fullOnlineCurriculum: any;
    blendedCurriculum: any;
    priceForFullOnlineCurriculum: any;
    priceblendedCurriculum: any;
    customCheckoutDataTwo: any;
    textBookTypeCheckoutDataTwo: any;
    ///CheckoutDataTwoEnd///
    ///CheckoutDataThreeStart///
    customSubEnglish:boolean;
    RecommSubEnglishGrammar:boolean;
    ///CheckoutDataThreeEnd///
    ///CheckoutDataFourStart///
    familyBkSuccess:boolean;
    familyBkPower:boolean;
    familyBkHistory1A:boolean;
    familyBkManners:boolean;
    familyBkHistory1B:boolean;
    familyBkTeenage:boolean;

    ///CheckoutDataFourEnd///
    ///CheckoutDataFiveStart///
    customProgramCheckoutDataFive: any;
    curriculum: any;
    familyBooks: any;
    miscellaneousAddOns: any;
    subTotal: any;
    subjectFilipino:boolean;
    subjectEnglish:boolean;
    subjectScience:boolean;
    subjectMath:boolean;
    subjectAralingPanlipunan:boolean;
    subjectScienceAdditional:boolean;
    subjectSocialStudiesAdditional:boolean;
    learnerEnrollment:string;
    ///CheckoutDataFiveEnd///
    ///CheckoutDataSixEnd///
    ///CheckoutDataSixEnd///
    ///CheckoutPaymentInstallmentsStarts///
    paymentOption:string;
    paymentMethod:string;
    reservationFee:string;
    ///CheckoutPaymentInstallmentsEnds///

  }
  snackbarMessage: any;
  snackbarOpenError: boolean;
  saveAsDraftHomeStyleData: any;
  saveAsDraftHomeProgramData: any;
  saveAsDraftUpdate: any;
  snackbarOpen: boolean;
  snackbarOpenProgram: boolean;

  //////////////file download start/////
  fileDownloads:{
  fileLetterRecommendation: any,
  filePwd: any,
  fileBirthCertificate: any,
  fileReportCard: any,
  fileSchoolRecords: any,
  fileLearnerReference: any,
  filePicture: any,
  fileSoloParent: any,
  fileParentValid: any,
  fileAchivementsProof: any,
  fileChurchLetter: any,
  fileIncomeProof: any,
  fileFatherDeathCertificate: any,
  fileStudentBirthCertificate: any,
  fileFamilyDetails: any,
  fileElectricBill: any,
  fileBirthCertificateLuovaStaff: any,
  fileParentBirthCertificateLuovaStaff: any,  
  },
  tabValue:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderDetailViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = GlobalContext
  apiStudentsListCallId: any;
  apiStudentsIndCallId: any;
  apiPaymentsCallId: any;
  apiHomeSchoolingCallId: any;
  apiHomeSchoolingStyleCallId: any;
  apiHomeSchoolingStyleCallIdSaveAsDraft: any;
  apiHomeSchoolingProgramCallIdSaveAsDraft: any;
  apiHomeSchoolingStyleCallIdSaveAsDraftButton: any;
  apiHomeSchoolingProgramCallIdSaveAsDraftButton: any;
  apiStudentsProgramQuestionCallId: any;
  apiStudentsStyleQuestionCallId: any;
  enrollmentApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      popoverAnchor: null,
      studentsList: [],
      studentsListData: [],
      //expanded: {},
      expanded: false,

      expandedCheck: false,
      valueRadio: 'lifepac',
      valueTextBookRadio: 'custom',

      valueShow: 5,
      studentsInd: [],
      studID: "",
      isLoadingStudentsList: false,
      currentPage: 1,
      postPerPage: 5,
      currentStudentsList: [],

      pages: 1,

      openD: true,
      openS: true,
      drawerWidth: 300,
      chatNow: false,
      askNow: true,
      isP: false,
      childGradeLevel: "",
      supportLevel: "",
      parentalInvolvement: "",
      HomeschoolCurriculum: "",
      primaryMedium: "",
      parentAdvisor: "",
      learningProgress: "",
      assessment: "",
      howManyChildrenAreYouPlanningToEnroll: "",
      tlcFilter: "",
      extendedSupportForMyChild: "",
      doYouHaveAnotherChild: "",
      liveOnlineClasses: "",
      yearsHomeSchooling: "",
      homeSchoolingStyleGeneral: "",
      homeSchoolingStyleParentalInvolvement: "",
      homeSchoolingStyleLearningStyleOfTheChild: "",
      homeSchoolingStyleCurriculumAndMaterials: "",
      homeSchoolingStyleEducationalPhilosophyg: "",
      homeSchoolingStyleSchedule: "",
      homeSchoolProgramSubmit: false,
      homeSchoolStyleSubmit: false,
      errors: {},
      downloadPdf: [],
      programResultData: {},
      pendingPay: 'Rejected',
      //pendingPay: 'Pending for payment',
      checkPendingPaymentData: [],
      checkState: false,
      ///CheckoutDataOneStart///
      checkoutDataObj: {
        touchProgramFullPayment: "",
        wCFSUSAccreditationFeePreKToGrade8: "",
        testingAndLearningEnhancementTool: "",
        totalCheckoutDataOne: "",
        subtotalCheckoutDataOne: "",
        shippingCheckoutDataOne: "",
        studentAvatar: '',
        studentName: '',
        programName: '',
        ///CheckoutDataOneEnd///
        ///CheckoutDataTwoStart///
        fullOnlineCurriculum: "",
        blendedCurriculum: "",
        priceForFullOnlineCurriculum: "",
        priceblendedCurriculum: "",
        customCheckoutDataTwo: "",
        textBookTypeCheckoutDataTwo: "",
        ///CheckoutDataTwoEnd///
        ///CheckoutDataThreeStart///
        customSubEnglish:false,
        RecommSubEnglishGrammar:false,
        ///CheckoutDataThreeEnd///
        ///CheckoutDataFourStart///
        familyBkSuccess:false,
        familyBkPower:false,
        familyBkHistory1A:false,
        familyBkManners:false,
        familyBkHistory1B:false,
        familyBkTeenage:false,
        ///CheckoutDataFourEnd///
        ///CheckoutDataFiveStart///
        customProgramCheckoutDataFive: "",
        curriculum: "",
        familyBooks: "",
        miscellaneousAddOns: "",
        subTotal: "",
        subjectFilipino:false,
        subjectEnglish:false,
        subjectScience:false,
        subjectMath:false,
        subjectAralingPanlipunan:false,
        subjectScienceAdditional:false,
        subjectSocialStudiesAdditional:false,
        learnerEnrollment:"",

        ///CheckoutDataFiveEnd///
        ///CheckoutDataSixEnd///
        ///CheckoutDataSixEnd///
        ///CheckoutPaymentInstallmentsStarts///
        paymentOption:"stripe",
        paymentMethod:"mastercard",
        reservationFee:"fullPaymentWithoutReservation",
        ///CheckoutPaymentInstallmentsEnds///
      },
      snackbarMessage: '',
      snackbarOpenError: false,
      saveAsDraftHomeStyleData: [],
      saveAsDraftHomeProgramData: [],
      saveAsDraftUpdate: "",
      snackbarOpen: false,
      snackbarOpenProgram: false,

      fileDownloads:{
        fileLetterRecommendation: { dummy: null, filenm: new File([""], "") },
        filePwd: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileReportCard: { dummy: null, filenm: new File([""], "") },
        fileSchoolRecords: { dummy: null, filenm: new File([""], "") },
        fileLearnerReference: { dummy: null, filenm: new File([""], "") },
        filePicture: { dummy: null, filenm: new File([""], "") },
        fileSoloParent: { dummy: null, filenm: new File([""], "") },
        fileParentValid: { dummy: null, filenm: new File([""], "") },
        fileAchivementsProof: { dummy: null, filenm: new File([""], "") },
        fileChurchLetter: { dummy: null, filenm: new File([""], "") },
        fileIncomeProof: { dummy: null, filenm: new File([""], "") },
        fileFatherDeathCertificate: { dummy: null, filenm: new File([""], "") },
        fileStudentBirthCertificate: { dummy: null, filenm: new File([""], "") },
        fileFamilyDetails: { dummy: null, filenm: new File([""], "") },
        fileElectricBill: { dummy: null, filenm: new File([""], "") },
        fileBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },
        fileParentBirthCertificateLuovaStaff: { dummy: null, filenm: new File([""], "") },  
        },
        tabValue:0

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    //------------------------ response for student list start --------------------

    if (this.apiStudentsListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
       
        this.setState({ isLoadingStudentsList: false })
        this.setState({ studentsList: apiResponse?.data })
        this.state.studentsList?.map((item: any) => {
          this.state.studentsListData.push({
            id: item?.id,
            full_name: item?.attributes.first_name + ' ' + item?.attributes.last_name,
            avatar: item?.attributes.id_picture?.url,
            program: item?.attributes.program_detail?.data?.attributes?.program,
          })
        })

      }

      else {
        this.setState({ isLoadingStudentsList: false })
      }
    }
   
    //------------------------ response for student list end --------------------
    //------------------------ response for student Individual start --------------------
    if (this.apiStudentsIndCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (apiResponse?.data) {
        const listOfFiles =[]
        // ---------getting all the files into one array of object so we can loop through it ----------
        console.log(apiResponse.data , "###data");
        apiResponse?.data?.attributes?.psa_birth_certificate &&  listOfFiles.push({...apiResponse?.data?.attributes?.psa_birth_certificate , "label": "Birth Certificate" })
        apiResponse?.data?.attributes?.learner_reference_number && listOfFiles.push({...apiResponse?.data?.attributes?.learner_reference_number , "label": "Learner Reference Number" })
        apiResponse?.data?.attributes?.pwd_id_proof &&  listOfFiles.push({...apiResponse?.data?.attributes?.pwd_id_proof , "label": "Pwd ID Proof" })
        apiResponse?.data?.attributes?.recent_report_card&&  listOfFiles.push({...apiResponse?.data?.attributes?.recent_report_card,"label": "Report Card" })
        apiResponse?.data?.attributes?.school_records&&  listOfFiles.push({...apiResponse?.data?.attributes?.school_records,"label": "School Records" })
        apiResponse?.data?.attributes?.church_affiliation_letter&&  listOfFiles.push({...apiResponse?.data?.attributes?.church_affiliation_letter,"label": "Church Affiliation Letter" })
        apiResponse?.data?.attributes?.electric_bill&&  listOfFiles.push({...apiResponse?.data?.attributes?.electric_bill,"label": "Electric Bill" })
        apiResponse?.data?.attributes?.family_details_letter &&  listOfFiles.push({...apiResponse?.data?.attributes?.family_details_letter,"label": "Family Details Letter" })
        apiResponse?.data?.attributes?.father_death_certificate &&  listOfFiles.push({...apiResponse?.data?.attributes?.father_death_certificate,"label": "Father Death Certificate" })
        apiResponse?.data?.attributes?.letter_of_recommendation &&  listOfFiles.push({...apiResponse?.data?.attributes?.letter_of_recommendation,"label": "Letter Of Recommendation" })
        apiResponse?.data?.attributes?.proof_of_achievements&&  listOfFiles.push({...apiResponse?.data?.attributes?.proof_of_achievements,"label": "Proof Of Achievements" })
        apiResponse?.data?.attributes?.luova_staff_birth_certificate &&  listOfFiles.push({...apiResponse?.data?.attributes?.luova_staff_birth_certificate,"label": "Luova Staff Birth Certificate" })
        apiResponse?.data?.attributes?.parent_birth_certificate &&  listOfFiles.push({...apiResponse?.data?.attributes?.parent_birth_certificate,"label": "Parent Birth Certificate" })
        apiResponse?.data?.attributes?.proof_of_income_or_billing &&  listOfFiles.push({...apiResponse?.data?.attributes?.proof_of_income_or_billing,"label": "Proof Of Income_Or Billing" })
        apiResponse?.data?.attributes?.student_birth_certificate &&  listOfFiles.push({...apiResponse?.data?.attributes?.student_birth_certificate,"label": "Student Birth Certificate" })
        // jpeg
        apiResponse?.data?.attributes?.id_picture &&  listOfFiles.push({...apiResponse?.data?.attributes?.id_picture,"label": "Id Picture" })
        apiResponse?.data?.attributes?.parent_valid_id &&  listOfFiles.push({...apiResponse?.data?.attributes?.parent_valid_id,"label": "Parent Valid Id" })
        apiResponse?.data?.attributes?.solo_parent_id_proof &&  listOfFiles.push({...apiResponse?.data?.attributes?.solo_parent_id_proof,"label": "Solo Parent Id Proof" })
        console.log(listOfFiles , "######listOfFiles#####")
        this.setState({ studentsInd: apiResponse?.data })
        this.setState({ downloadPdf: listOfFiles})
        this.setState({
          checkoutDataObj: {
            ...this.state.checkoutDataObj,
            studentAvatar: apiResponse?.data.attributes?.id_picture?.url,
            studentName: apiResponse?.data.attributes?.first_name + " " + apiResponse?.data.attributes?.last_name,
            programName: apiResponse?.data.attributes?.program_detail?.data?.attributes?.program,
          }
        })
        console.log(" stud dettt",this.state.studentsInd)

   

      }

      else {
        console.log("jjjjjjjjddd", this.state.downloadPdf)
      }


    }

    //------------------------ response for student Individual end --------------------

    ///HomeSchoolProgram result data start///
    if (this.apiStudentsProgramQuestionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.context.homeSchoolProgramDispatch({ type: 'UPDATE_PROGRAM_DATA', payload: apiResponse?.homeschool_program_recommendation })
        setTimeout(() => this.props.navigation.navigate('HomeSchoolProgramRecommendationResult'),2000)

      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    ///HomeSchoolProgram result data end///
    if(this.apiHomeSchoolingCallId == message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log("data submitted", JSON.stringify(apiResponse))
      if (apiResponse) {
        this.setState({homeSchoolProgramSubmit: true})
         this.homeSchoolProgramResultApiCall()
      }
    }

    ///HomeSchoolStyleSaveAsDraftButton result data start///
    if (this.apiHomeSchoolingStyleCallIdSaveAsDraftButton === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ snackbarOpen: true })
      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    ///HomeSchoolStyleSaveAsDraftButton result data end///
    ///HomeSchoolProgramSaveAsDraftButton result data start///
    if (this.apiHomeSchoolingProgramCallIdSaveAsDraftButton === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ snackbarOpenProgram: true })
      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    ///HomeSchoolProgramSaveAsDraftButton result data end///
    ///HomeSchoolStyle result data start///
    if (this.apiStudentsStyleQuestionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.context.homeSchoolStyleDispatch({ type: 'UPDATE_PROGRAM_STYLE_DATA', payload: apiResponse?.homeschool_style_recommendation })
        setTimeout(() => this.props.navigation.navigate('HomeSchoolStyleRecommendationResult'), 2000)
      }
      else {
        console.log('error', apiResponse?.error)
      }
    }
    ///HomeSchoolStyle result data end///

    ///final Checkout For Payment Start///

    if (this.apiPaymentsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
         getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiResponse?.session_url) {
        this.setState({ isP: true })
        location.href = apiResponse?.session_url
      }
      else {
        this.setState({ isP: false })
      }
    }
    ///final Checkout For Payment End///

    ///homeschool style Start///

    if (this.apiHomeSchoolingStyleCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ homeSchoolStyleSubmit: true })
       this.homeSchoolStyleResultApiCall()

      }
    }
    ///homeschool style End///
    ///homeschool saveasDraftstyle Start///

    if (this.apiHomeSchoolingStyleCallIdSaveAsDraft === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        let Statesdata: any = [{ state: "homeSchoolingStyleGeneral", question: this.programDetailsQuestions?.stylequestions?.subFirstQuestion },
        { state: "homeSchoolingStyleParentalInvolvement", question: this.programDetailsQuestions?.stylequestions?.subSecondQuestion },
        { state: "homeSchoolingStyleLearningStyleOfTheChild", question: this.programDetailsQuestions?.stylequestions?.subThirdQuestion },
        { state: "homeSchoolingStyleCurriculumAndMaterials", question: this.programDetailsQuestions?.stylequestions?.subFourthQuestion },
        { state: "homeSchoolingStyleEducationalPhilosophyg", question: this.programDetailsQuestions?.stylequestions?.subFifthQuestion },
        { state: "homeSchoolingStyleSchedule", question: this.programDetailsQuestions?.stylequestions?.subSixthQuestion }]
        this.setState({ saveAsDraftHomeStyleData: apiResponse?.data }, () => {
          if (this.state.saveAsDraftHomeStyleData.length != 0) {
            this.state.saveAsDraftHomeStyleData.map((item: any) => {
              let main: any = Statesdata.filter((i: any) => i.question == item.attributes.question)
              let final: any = main[0].question
              let answer: any = main[0].state
              if (item.attributes.question == final) {
                this.setState((prevState: any) => ({
                  ...prevState,
                  [answer]: item.attributes.answer
                }))
              }
            })
          }
        });
      }

      else {
        alert("Something went wrong. Please try again")

      }
    }
    ///homeschool SaveAsDraftstyle End///
    ///homeschoolprogram saveasDraftstyle Start///

    if (this.apiHomeSchoolingProgramCallIdSaveAsDraft === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        let Statesdata: any = [{ state: "childGradeLevel", question: this.programDetailsQuestions?.questions?.firstQuestion, },
        { state: "supportLevel", question: this.programDetailsQuestions?.questions?.secondQuestion },
        { state: "parentalInvolvement", question: this.programDetailsQuestions?.questions?.thirdQuestion },
        { state: "HomeschoolCurriculum", question: this.programDetailsQuestions?.questions?.fourthQuestion },
        { state: "primaryMedium", question: this.programDetailsQuestions?.questions?.fifthQuestion },
        { state: "parentAdvisor", question: this.programDetailsQuestions?.questions?.sixthQuestion },
        { state: "learningProgress", question: this.programDetailsQuestions?.questions?.seventhQuestion },
        { state: "assessment", question: this.programDetailsQuestions?.questions?.eighthQuestion },
        { state: "howManyChildrenAreYouPlanningToEnroll", question: this.programDetailsQuestions?.questions?.ninthQuestion },
        { state: "tlcFilter", question: this.programDetailsQuestions?.questions?.tenthSubQuestion },
        { state: "extendedSupportForMyChild", question: this.programDetailsQuestions?.questions?.eleventhQuestion },
        { state: "doYouHaveAnotherChild", question: this.programDetailsQuestions?.questions?.twelvethQuestion },
        { state: "liveOnlineClasses", question: this.programDetailsQuestions?.questions?.fourteenthQuestion },
        { state: "yearsHomeSchooling", question: this.programDetailsQuestions?.questions?.thirteenthQuestion },
        ]
        this.setState({ saveAsDraftHomeProgramData: apiResponse?.data }, () => {
          if (this.state.saveAsDraftHomeProgramData.length != 0) {
            this.state.saveAsDraftHomeProgramData.map((item: any) => {
              let main: any = Statesdata.filter((i: any) => i.question == item.attributes.question)
              let final: any = main[0]?.question
              let answer: any = main[0]?.state
              if (item.attributes.question == final) {
                this.setState((prevState: any) => ({
                  ...prevState,
                  [answer]: item.attributes.answer
                }))
                
              }
            })
          }
        });
        
      }

      else {
        alert("Something went wrong. Please try again")

      }
    }
    ///homeschoolprogram SaveAsDraftstyle End///

    // enrollement api starts ///
    if (this.enrollmentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        this.props.navigation.navigate('StudentEnrollmentFormStepper')
      } else {
        console.log("idStudddd error", apiResponse?.data)

      }
    }
    //enrollement api ends ///


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // ----------------componentDidMount-----------------------


  async componentDidMount() {
    super.componentDidMount();
    this.getStudentsList()
    this.getStudentsIndividualDetails()
    if (window.location.pathname == "/HomeschoolStyle") {
      this.homeSchoolStylingRequestSaveAsDraft()
    }
    if (window.location.pathname == "/HomeschoolProgram") {
      this.homeSchoolProgramRequestSaveAsDraft()
    }
  }
  //---------------------- student list api call request ----------------------
  getStudentsList = () => {
    this.setState({ isLoadingStudentsList: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiStudentsList,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStudentsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiStudentsListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiStudentsListMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
 
  //getstudentIndividualDetailsStart///
  getStudentsIndividualDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiStudentsList,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStudentsIndCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_student/students/${localStorage.getItem('StudentListId')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiStudentsListMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //getstudentIndividualDetailsEnd///

  ///final Checkout For Payment Start///

  finalCheckoutForPayment = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      item_name: "English Books",
      description: "testing",
      amount: 100,
      currency: "USD",
      images: [
        "https://i.picsum.photos/id/704/280/320.jpg?hmac=IF5Hth8fp139AmEc93Nh6m16PCyoprpbhUy2Ex08yqc"
      ],
      success_url: window.location.origin + '/Checkoutsucess6',
      cancel_url: "https://www.google.com/"

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPaymentsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiPayment
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPaymentMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///final Checkout For Payment End///
  ///homeschool_program questions start///

  programDetailsQuestions = {
    questions: {
      firstQuestion: "My child's grade level is...",
      secondQuestion: "Support level",
      thirdQuestion: "Parental Involvement",
      fourthQuestion: "In choosing a homeschool curriculum....",
      fifthQuestion: "Primary Medium of Learning",
      sixthQuestion: "Parent Advisor Interaction",
      seventhQuestion: "Learning Progress",
      eighthQuestion: "Assessment",
      ninthQuestion: "How many children are you planning to enroll?",
      tenthQuestion: "TLC Filter",
      tenthSubQuestion: "Does your child have any limitations (behavioral, developmental, physical, emotional) from attending a conventional school?",
      eleventhQuestion: "I'd like an extended support for my child who has exceptional or challenges in learning",
      twelvethQuestion: "Do you have another child that you would like to homeschool?",
      thirteenthQuestion: "How many years have you been homeschooling?",
      fourteenthQuestion: "I want my child to have live online classes with subject teachers that follows a fixed schedule all throughout the school year"

    },
    stylequestions: {
      firstQuestion: "General",
      secondQuestion: "Parental Involvement",
      thirdQuestion: "Learning Style of the Child",
      fourthQuestion: "Curriculum & Materials",
      fifthQuestion: "Educational Philosophy",
      sixthQuestion: "Schedule",
      subFirstQuestion: "In a greater scheme, as a parent what I value the most is...",
      subSecondQuestion: "As a parent, I would like to...",
      subThirdQuestion: "In the learning experience of my child, I prioritize...",
      subFourthQuestion: "In choosing the curriculum materials, I prefer to...",
      subFifthQuestion: "My educational philosophy as a parent is geared more towards...",
      subSixthQuestion: "I see our daily schedule as...",
    },
    answerOptions: {
      firstQuestionOptions: [
        "Pre-Kinder (4 years old)",
        "Kinder (5 years old)",
        "Grades 1-2",
        "Grades 3-6",
        "Grades 7-10",
        "Grades 11-12",
      ],
      secondQuestionOptions: {
        option1: "Parent-led learning with comprehensive advisor support",
        option2: "Parent-led learning with platform-driven essential advisor support",
        option3: "Asynchronized teacher support (excluding MAPEH)",
        option4: "Synchronized and asynchronized teacher support"
      },
      thirdQuestionOptions: {
        option1: "To be the primary instructor of my child's learning (Very high to high)",
        option2: "To facilitate and supervise the progress of my child (Medium)",
        option3: "Minimal parental supervision with teacher supervision, grading, and optional academic classes (Low to very low)",
      },
      fourthQuestionOptions: [
        "I would like a curriculum that we can customize based on my child's learning style",
        "I prefer a preset and structured curriculum that we will follow",
      ],
      fifthQuestionOptions: {
        option1: "Only printed books",
        option2: "Fully online with instructional videos, online library, virtual labs",
        option3: "Online (instructional videos, online library, virtual labs) with printed books",
        option4: "Any materials that are aligned with the Minimum Essential Comptencies required for every grade level"
      },
      sixthQuestionOptions: {
        option11: "Through weekly community gathering",
        option1: "On-demand",
        option2: "Weekly",
        option3: "Quarterly",
        option4: "Semi-annual"
      },
      seventhQuestionOptions: {
        option1: "Weekly",
        option2: "Monthly",
        option3: "Quarterly",
        option4: "On-demand",
        option5: "Year end portfolio submission"
      },
      eighthQuestionOptions: {
        option3Sub: "Quarterly Exams",
        option1: "Portfolio Review",
        option2: "Presentations",
        option3: "Quarterly Online Exams",
        option4: "Online Unit Tests",
      },
      ninthQuestionOptions: [
        "1",
        "2",
        "3 or more",
      ],
      tenthQuestionOptions: [
        "Yes",
        "No",
      ],
      eleventhQuestionOptions: [
        "Yes",
        "No",
      ],
      twelvethQuestionOptions: [
        "Yes",
        "No",
      ],
      thirteenthQuestionOptions: [
        "1 year",
        "2 consecutive years",
        "3 consecutive years and above",
        "This is my first time",
      ],
      fourteenthQuestionOptions: [
        "Yes",
        "No",
      ]
    },
    styleAnswerOptions: {
      firstQuestionOptions: [
        "a systematic learning from knowing, understanding, and applying the lesson in real-life situations.",
        "putting logic and critical thinking as most important skills to develop when studying.",
        "having my child's interests, strenghts and weakness development direct the flow of learning",
        "having my child's developmental needs as priority and work around  those needs"
      ],
      secondQuestionOptions: [
        "Set the framework of my child's homeschooling, then allow him/her to learn with minimal supervision",
        "to have the flexibility to tailor-fit the academic tasks of my child to support his/her strength and help him/her improve his/her weaknesses",
        "proactively engage in my child's education, from the selection of learning materials to shared experiences with other families",
      ],
      thirdQuestionOptions: [
        "experiences that fits my child's unique learning styles, exceptionality, and / or challenges",
        "having him gain knowlege, followed by understanding and then applying the concept he learns",
        "highlighting my child's personality and talents as he covers his lessons",
        "strengthening my child's ability in making learning choices through good habit training"
      ],
      fourthQuestionOptions: [
        "have my child learn classical western literature, arts, philosopies and language where Christian history is incorporated",
        "give my child time to go out and learn from nature rather than from a textbook.",
        "Expose my child to many interesting ideas and concepts through a generous curriculum of carefully chosen literary texts and objects of study.",
        "Follow the curriculum set that regular schools are using",
        "Use curriculum that fits to the theme that my child will be focusing on in all his subjects"
      ],
      fifthQuestionOptions: [
        "helping my child develop love for learning",
        "allowing my child to initiate learning by himself and study things even outside the recommended curriculum.",
        "being able to discover where my child excels by employing different techniques",
        "sticking to the flow of educational system with readily available pre-made worksheets, tests, and a listOfFileslate for grades",
        "strengthening my child's ability in making learning choices through good habit training"
      ],
      sixthQuestionOptions: [
        "customizable as we see fit",
        "well-planned number of hours per subject",
        "short but focused study times in varied order of subjects",
        "based on the pace and desire of my child to learn"
      ],

    }

  }

  ///homeschool_program questions end///

  ///homeschool_program Start///

  homeSchoolProgramRequest = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      data: {
        "status": "submitted",
        recommender_answers: [
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.firstQuestion,
            answer: this.state.childGradeLevel
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.secondQuestion,
            answer: this.state.supportLevel
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.thirdQuestion,
            answer: this.state.parentalInvolvement
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.fourthQuestion,
            answer: this.state.HomeschoolCurriculum
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.fifthQuestion,
            answer: this.state.primaryMedium
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.sixthQuestion,
            answer: this.state.parentAdvisor
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.seventhQuestion,
            answer: this.state.learningProgress
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.eighthQuestion,
            answer: this.state.assessment
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.ninthQuestion,
            answer: this.state.howManyChildrenAreYouPlanningToEnroll
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.tenthSubQuestion,
            answer: this.state.tlcFilter
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.eleventhQuestion,
            answer: this.state.extendedSupportForMyChild
          },
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.twelvethQuestion,
            answer: this.state.doYouHaveAnotherChild
          },
          this.state.childGradeLevel != "" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" ?
            {
              recommender_type: "homeschool_program",
              question: this.programDetailsQuestions?.questions?.thirteenthQuestion,
              answer: this.state.yearsHomeSchooling
            } : null,

          this.state.childGradeLevel != "" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" && this.state.childGradeLevel != "Grades 11-12" ?
            {
              recommender_type: "homeschool_program",
              question: this.programDetailsQuestions?.questions?.fourteenthQuestion,
              answer: this.state.liveOnlineClasses
            } : null
        ],

      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeschoolRecommendations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeschoolRecommendationsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///homeschool_program End///
  ///homeschool_program save as Draft Start///

  homeSchoolProgramRequestSaveAsDraftButton = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      data: {
        "status": "draft",
        recommender_answers: [
          this.state.childGradeLevel ?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.firstQuestion,
            answer: this.state.childGradeLevel
          }: null,
          this.state.supportLevel != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.secondQuestion,
            answer: this.state.supportLevel
          }: null,
          this.state.parentalInvolvement != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.thirdQuestion,
            answer: this.state.parentalInvolvement
          }: null,
          this.state.HomeschoolCurriculum != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.fourthQuestion,
            answer: this.state.HomeschoolCurriculum
          }: null,
          this.state.primaryMedium != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.fifthQuestion,
            answer: this.state.primaryMedium
          }: null,
          this.state.parentAdvisor != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.sixthQuestion,
            answer: this.state.parentAdvisor
          }: null,
          this.state.learningProgress != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.seventhQuestion,
            answer: this.state.learningProgress
          }: null,
          this.state.assessment != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.eighthQuestion,
            answer: this.state.assessment
          }: null,
          this.state.howManyChildrenAreYouPlanningToEnroll != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.ninthQuestion,
            answer: this.state.howManyChildrenAreYouPlanningToEnroll
          }: null,
          this.state.tlcFilter != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.tenthSubQuestion,
            answer: this.state.tlcFilter
          }: null,
          this.state.extendedSupportForMyChild != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.eleventhQuestion,
            answer: this.state.extendedSupportForMyChild
          }: null,
          this.state.doYouHaveAnotherChild != ""?
          {
            recommender_type: "homeschool_program",
            question: this.programDetailsQuestions?.questions?.twelvethQuestion,
            answer: this.state.doYouHaveAnotherChild
          }: null,

        
          (
            this.state.yearsHomeSchooling != ""?
            {
              recommender_type: "homeschool_program",
              question: this.programDetailsQuestions?.questions?.thirteenthQuestion,
              answer: this.state.yearsHomeSchooling
            }: null
          )
           
            ,

          (this.state.liveOnlineClasses !=""?
            {
              recommender_type: "homeschool_program",
              question: this.programDetailsQuestions?.questions?.fourteenthQuestion,
              answer: this.state.liveOnlineClasses
            }: null
          )
           
        ],

      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingProgramCallIdSaveAsDraftButton = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeschoolRecommendations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeschoolRecommendationsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///homeschool_program save as Draft End///
  ////homeSchool Result start///
  homeSchoolProgramResultApiCall = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiStudentsList,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStudentsProgramQuestionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeSchoolProgramRestPageCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeSchoolProgramAnswerType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ////homeSchool Result End///
  ///homeProgram save as Draft api start///

  homeSchoolProgramRequestSaveAsDraft = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingProgramCallIdSaveAsDraft = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeSchoolProgramSaveAsDraftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeSchoolProgramSaveAsDraftMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///homeProgram saveAsDraftapi end ///

  ///homeStyling api start///

  homeSchoolStylingRequest = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      data: {
        status: "submitted",
        recommender_answers: [
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subFirstQuestion,
            answer: this.state.homeSchoolingStyleGeneral
          },
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subSecondQuestion,
            answer: this.state.homeSchoolingStyleParentalInvolvement
          },
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subThirdQuestion,
            answer: this.state.homeSchoolingStyleLearningStyleOfTheChild
          },
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subFourthQuestion,
            answer: this.state.homeSchoolingStyleCurriculumAndMaterials
          },
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subFifthQuestion,
            answer: this.state.homeSchoolingStyleEducationalPhilosophyg
          },
          {
            recommender_type: "homeschool_styling",
            question: this.programDetailsQuestions?.stylequestions?.subSixthQuestion,
            answer: this.state.homeSchoolingStyleSchedule
          },
        ],

      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingStyleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeschoolRecommendations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeschoolRecommendationsMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///homeStyling api end ///
  ///homeStyling SaveAsDraftButton api start///

  homeSchoolStylingRequestSaveAsDraftButtonApi = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      data: {
        status: "draft",
        recommender_answers: [
          this.state.homeSchoolingStyleGeneral != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subFirstQuestion,
              answer: this.state.homeSchoolingStyleGeneral
            } : null,
          this.state.homeSchoolingStyleParentalInvolvement != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subSecondQuestion,
              answer: this.state.homeSchoolingStyleParentalInvolvement
            } : null,
          this.state.homeSchoolingStyleLearningStyleOfTheChild != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subThirdQuestion,
              answer: this.state.homeSchoolingStyleLearningStyleOfTheChild
            } : null,
          this.state.homeSchoolingStyleCurriculumAndMaterials != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subFourthQuestion,
              answer: this.state.homeSchoolingStyleCurriculumAndMaterials
            } : null,
          this.state.homeSchoolingStyleEducationalPhilosophyg != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subFifthQuestion,
              answer: this.state.homeSchoolingStyleEducationalPhilosophyg
            } : null,
          this.state.homeSchoolingStyleSchedule != "" ?
            {
              recommender_type: "homeschool_styling",
              question: this.programDetailsQuestions?.stylequestions?.subSixthQuestion,
              answer: this.state.homeSchoolingStyleSchedule
            } : null,
        ],

      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingStyleCallIdSaveAsDraftButton = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeschoolRecommendations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeschoolRecommendationsMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///homeStyling SaveAsDraftButton api end ///
  ///homeStyling save as Draft api start///

  homeSchoolStylingRequestSaveAsDraft = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHomeSchoolingStyleCallIdSaveAsDraft = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeSchoolStyleSaveAsDraftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeSchoolStyleSaveAsDraftMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///homeStyling saveAsDraftapi end ///
  ////homeSchool style Result start///
  homeSchoolStyleResultApiCall = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiStudentsList,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStudentsStyleQuestionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiHomeSchoolStyleRestPageCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiHomeSchoolStyleAnswerType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ////homeSchoolStyle Result End///
  ///Student enrollment Post api start///
  setEditStatus = async () => {
    const header = {
      "token": localStorage.getItem("authToken")
    };
    let gettingToken = localStorage.getItem("studentID");
    let formdata = new FormData();
    ///FirstFormDataStart///
    {
      gettingToken && formdata.append("student[id]", localStorage.getItem("studentID") as any)
    }

    /// status//

    formdata.append("student[status]", 'pending')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.enrollmentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.enrolmentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.enrolmentMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///Student enrollment Post api end///
  ///homeSchoolProgram Validations start///

  homeSchoolValidation = () => {
    let isFormValid = true;
    if (this.state.childGradeLevel === '') {
      this.state.errors['childGradeLevel'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.supportLevel === '') {
      this.state.errors['supportLevel'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.parentalInvolvement === '') {
      this.state.errors['parentalInvolvement'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.HomeschoolCurriculum
      === '') {
      this.state.errors['HomeschoolCurriculum'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.primaryMedium === '') {
      this.state.errors['primaryMedium'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.parentAdvisor === '') {
      this.state.errors['parentAdvisor'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.learningProgress
      === '') {
      this.state.errors['learningProgress'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.assessment === '') {
      this.state.errors['assessment'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.howManyChildrenAreYouPlanningToEnroll === '') {
      this.state.errors['howManyChildrenAreYouPlanningToEnroll'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.tlcFilter
      === '') {
      this.state.errors['tlcFilter'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.extendedSupportForMyChild === '') {
      this.state.errors['extendedSupportForMyChild'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)") {
      if (this.state.yearsHomeSchooling == "") {
        this.state.errors['yearsHomeSchooling'] = "Answer Required"
        isFormValid = false
      }
    }
    if (this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" && this.state.childGradeLevel != "Grades 11-12") {
      if (this.state.liveOnlineClasses == "") {
        this.state.errors['liveOnlineClasses'] = "Answer Required"
        isFormValid = false
      }
    }

    if (this.state.doYouHaveAnotherChild === '') {
      this.state.errors['doYouHaveAnotherChild'] = "Answer Required"
      isFormValid = false
    }

    return isFormValid

  }

  ///homeSchoolProgram Validations end///

  ///homeSchoolStyle Validations start///

  homeStyleValidation = () => {
    let isFormValid = true;
    if (this.state.homeSchoolingStyleGeneral
      === '') {
      this.state.errors['homeSchoolingStyleGeneral'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleParentalInvolvement === '') {
      this.state.errors['homeSchoolingStyleParentalInvolvement'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleLearningStyleOfTheChild === '') {
      this.state.errors['homeSchoolingStyleLearningStyleOfTheChild'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleCurriculumAndMaterials
      === '') {
      this.state.errors['homeSchoolingStyleCurriculumAndMaterials'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleEducationalPhilosophyg === '') {
      this.state.errors['homeSchoolingStyleEducationalPhilosophyg'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleSchedule === '') {
      this.state.errors['homeSchoolingStyleSchedule'] = "Answer Required"
      isFormValid = false
    }
    return isFormValid

  }

  ///homeSchoolStyle Validations end///

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ childGradeLevel: event.target.value })
  };
  openPopover = (e: any, id: any) => {
    this.setState({ popoverAnchor: e.currentTarget, studID: id })

  }

  handleClosePopover = () => {
    this.setState({ popoverAnchor: null })
  }

  handlerView = (id: any) => {
    console.log("inside view ");
    localStorage.setItem('StudentListId', id)
    this.props.navigation.navigate('StudentDetails')
  }
  handlerEdit = (id: any) => {
    localStorage.setItem('studentID', id)
    this.setEditStatus()

  }
  ///popover functions end /////
  ///expanded functions start /////
 
  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  };

  handleExpandClickCheck = () => {
    this.setState({ expandedCheck: !this.state.expandedCheck })
  };

  handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ valueRadio: (event.target as HTMLInputElement).value });
  };


  handleChangeTextBookRadio = (e: any) => {
    this.setState({ valueTextBookRadio: e.target.value })
  };
  ///expanded functions end /////
  manage = (e: any) => {
    this.setState({ askNow: false })
    window.HFCHAT_CONFIG = {
      EMBED_TOKEN: '1d558b10-f5f9-11ec-b383-27ade077cffa',
      ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
    };

    (function () {

      let scriptTag = document.createElement('script')
      scriptTag.type = 'text/javascript'
      scriptTag.async = true

      scriptTag.src = "https://widget.happyfoxchat.com/v2/visitor" + '/js/widget-loader.js'

      let s: any = document.getElementsByTagName('script')[0]


      s.parentNode.insertBefore(scriptTag, s)
      window.HFCHAT_CONFIG.onload = function () {
        let HappyFoxChat = this;
        HappyFoxChat.hideBadge();
        HappyFoxChat.expandChatbox();

        HappyFoxChat.addCustomStyles
          ('.titlebar { height: 4em !important;background-image: none !important;padding-top: 10px !important; padding-bottom: 4em !important;padding-left: 16px !important;color: #171725 !important;}.convertsation-toolbar{height:2em !important}.chatbox-container {padding-top:10em !important} .title { text-align: left !important;font-family: Poppins !important; font-size: 24px !important;font-weight: 600 !important; margin-bottom: 0 !important }', function (err: any) {
          });

        //loading false

      };

    })()


  }

  handleChatNow = () => {
    this.setState({ chatNow: true })
  }
  hideError = (event: any) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }

  handleProgramSubmitAnswers =async () => {
    if (this.homeSchoolValidation()) {
     await this.homeSchoolProgramRequest()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
    }
  }
  handleStyleSubmitAnswers =async () => {
    if (this.homeStyleValidation
      ()) {
    await  this.homeSchoolStylingRequest()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
    }
  }
  HomeStyleSaveAsDraft = () => {
    if (this.homeStyleValidation
      ()) {
      this.homeSchoolStylingRequestSaveAsDraft()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
    }
  }

  proceedCToCheckout = () => {
    //  this.props.navigation.navigate('CheckoutOne')
    console.log('idStudddd mmmmm', this.state.checkPendingPaymentData)

  }
  handleCheckoutFormTwo = () => {
    this.props.navigation.navigate('CheckoutTwo')

  }
  handleCheckoutFormThree = () => {
 
    if(this.state.valueTextBookRadio=='full_online_cur' || this.state.valueTextBookRadio=='blended_cur'){
      this.props.navigation.navigate('CheckoutFour')
    }
    else{
    this.props.navigation.navigate('CheckoutThree')
    }

  }
  handleCheckoutFormFour = () => {
    this.props.navigation.navigate('CheckoutFour')

  }
  handleCheckoutFormFive = () => {
    this.props.navigation.navigate('Checkout5')

  }
  CheckoutPayInstallments = () => {
    if(this.state.checkoutDataObj.learnerEnrollment=="finish_enrolling"){
      this.props.navigation.navigate('CheckoutOne')
    }
    else if (this.state.checkoutDataObj.learnerEnrollment=="proceed_to_checkout"){
      this.props.navigation.navigate('CheckoutPayInstallments')
    }
    else{
      alert ("Please select the appropriate option")
    }
    

  }
  handleCheckoutFormseven = () => {
    this.props.navigation.navigate('CheckoutPaymentPlan')

  }
  handleCheckoutFormEight = () => {
    this.props.navigation.navigate('CheckoutPaymentPlan')

  }


  firsthandleChnage = (e: any) => {
    this.setState({ childGradeLevel: e.target.value })
    this.setState({
      supportLevel: '', parentalInvolvement: "",
      HomeschoolCurriculum: "",
      primaryMedium: "",
      parentAdvisor: "",
      learningProgress: "",
      assessment: "",
      howManyChildrenAreYouPlanningToEnroll: "",
      tlcFilter: "",
      extendedSupportForMyChild: "",
      doYouHaveAnotherChild: "",
      liveOnlineClasses: "",
      yearsHomeSchooling: ""
    })
  }

  ///checkoutHandleChangeFunctionStart///
  handleChangeCheckoutData = (e: any) => {
    this.setState({
      checkoutDataObj: { ...this.state.checkoutDataObj, [e.target.name]: e.target.value },
    });
  }
  CheckOutContextmain = async () => {

    let mainData = {
      ///CheckoutDataOneStart///
      touchProgramFullPayment: this.state.checkoutDataObj.touchProgramFullPayment,
      wCFSUSAccreditationFeePreKToGrade8: this.state.checkoutDataObj.wCFSUSAccreditationFeePreKToGrade8,
      testingAndLearningEnhancementTool: this.state.checkoutDataObj.testingAndLearningEnhancementTool,
      totalCheckoutDataOne: this.state.checkoutDataObj.totalCheckoutDataOne,
      subtotalCheckoutDataOne: this.state.checkoutDataObj.subtotalCheckoutDataOne,
      shippingCheckoutDataOne: this.state.checkoutDataObj.shippingCheckoutDataOne,
      studentAvatar: this.state.checkoutDataObj.studentAvatar,
      studentName: this.state.checkoutDataObj.studentName,
      programName: this.state.checkoutDataObj.programName,
      ///CheckoutDataOneEnd///
      ///CheckoutDataTwoStart///
      fullOnlineCurriculum: this.state.checkoutDataObj.fullOnlineCurriculum,
      blendedCurriculum: this.state.checkoutDataObj.blendedCurriculum,
      priceForFullOnlineCurriculum: this.state.checkoutDataObj.priceForFullOnlineCurriculum,
      priceblendedCurriculum: this.state.checkoutDataObj.priceblendedCurriculum,
      customCheckoutDataTwo: this.state.checkoutDataObj.customCheckoutDataTwo,
      textBookTypeCheckoutDataTwo: this.state.checkoutDataObj.textBookTypeCheckoutDataTwo,
      ///CheckoutDataTwoEnd///
      ///CheckoutDataThreeStart///
      ///CheckoutDataThreeEnd///
      ///CheckoutDataFourStart///
      ///CheckoutDataFourEnd///
      ///CheckoutDataFiveStart///
      customProgramCheckoutDataFive: this.state.checkoutDataObj.customProgramCheckoutDataFive,
      curriculum: this.state.checkoutDataObj.curriculum,
      familyBooks: this.state.checkoutDataObj.familyBooks,
      miscellaneousAddOns: this.state.checkoutDataObj.miscellaneousAddOns,
      subTotal: this.state.checkoutDataObj.subTotal
      ///CheckoutDataFiveEnd///
      ///CheckoutDataSixEnd///
      ///CheckoutDataSixEnd///

    }

    await this.context.checkoutStateDispatch({ type: 'UPDATE_CHECKOUT_DATA', payload: mainData })


  }

  handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenError: false })
  };

  btnCheckedPendingPayment = (val:any,chk: any, id: any, avatar: any, fullname: any, program: any) => {
    console.log('idStudddd aaa', val)
    if (chk) {
      localStorage.setItem('StudentListId', id)
      localStorage.setItem('studentID', id)
      this.setState({
        checkoutDataObj: {
          ...this.state.checkoutDataObj, studentAvatar: avatar, studentName: fullname, programName: program,
        }
      })
      this.props.navigation.navigate('CheckoutOne')
    }

    else {
      this.setState({ snackbarOpenError: true, snackbarMessage: "Please select the related student to proceed for checkout." });
    }
  }

  multipleStudID = (checkPendingPayment: any, studentID: any) => {
    if (checkPendingPayment) {
      let arr: any = this.state.checkPendingPaymentData.filter((item: any) => item == studentID)
      arr.length == 0 && this.state.checkPendingPaymentData.push(studentID)
    }
    else {
      let arr1: any = this.state.checkPendingPaymentData.filter((item: any) => item != studentID)
      this.setState({ checkPendingPaymentData: arr1 })
    }

  }
  btnRejected = (studentID: any) => {
    localStorage.setItem('studentID', studentID);
    this.props.navigation.navigate('StudentRejectedReason')
  }

  ///checkoutHandleChangeFunctionStart///
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleCloseProgram = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenProgram: false })
  };
  handleSwitchProgram=async()=>{
    let swtichButtonStatus = {
      programStatus: true,
      activeFormState: 2,
     }

    await this.context.CheckoutSwitchProgramStateDispatch({ type: 'SWITCH_PROGRAM', payload: swtichButtonStatus })
   // this.setEditStatus()
    this.props.navigation.navigate('StudentEnrollmentFormStepper')

  }

  handleDrawerOpen=()=>{
    this.setState({ openD: !this.state?.openD })
  }

handlerFileDownload=(doc:any)=>{
  console.log("doc",doc)
  fetch(doc.url, {
    method: 'GET',
    headers: {
      'Content-Type': doc.content_type,
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      doc.name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    //link.parentNode.removeChild(link);
    document.body.removeChild(link);
  });
}

handleChangePaymentOption=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    checkoutDataObj: {
      ...this.state.checkoutDataObj, 
      paymentOption: e.target.value, 
      reservationFee:"",
    }
  })
}

handleChangePaymentMethod=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    checkoutDataObj: {
      ...this.state.checkoutDataObj, paymentMethod: e.target.value, 
    }
  })
}

handleChangeReservationFee=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    checkoutDataObj: {
      ...this.state.checkoutDataObj, reservationFee: e.target.value, 
    }
  })
}
handleChangeLearnerEnrollment=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    checkoutDataObj: {
      ...this.state.checkoutDataObj, learnerEnrollment: e.target.value, 
    }
  })
}
handleTabChange =(event: React.ChangeEvent<{}>, newValue: number) =>{
  console.log("lucky handleTabChange", newValue);
  this.setState({tabValue:newValue})  
}

handlChangeCheckBox =(e: React.ChangeEvent<HTMLInputElement>) =>{
  this.setState({
    checkoutDataObj: { ...this.state.checkoutDataObj, [e.target.name]: e.target.checked },
 },()=>{console.log("CHECKOUT STATES", this.state.checkoutDataObj)});
 
}


   // Customizable Area End
}
