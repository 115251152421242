Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.apiEndPointAddDetail='bx_block_student/students'
//firstFrom//
exports.apiEndPointReligionDetail='bx_block_student/religions'
exports.apiEndPointNationalitiesDetail='bx_block_student/nationalities'
exports.apiEndPointlanguagesDetail='bx_block_student/languages'
exports.apiEndPointScholarshipDetail='bx_block_student/scholarship_types'
exports.studentEnrollAPiGetMethod = "GET";
//second form
exports.PrevSchoolAPiPostMethod = "POST";
exports.PrevSchoolApiContentType = "application/json";
exports.PrevSchoolAPIEndPoint = "bx_block_student/previous_schools";
exports.PrevSchoolprogramPoint = "bx_block_student/program_details";
exports.PrevSchoolParentDetail = "bx_block_student/parents";
exports.apiCountryCallUrl="account_block/countries"
exports.apiLanguageCallUrl="bx_block_student/languages"
///thirdForm///
exports.programDetailsHubsEndPoint="bx_block_student/hubs"
exports.programDetailsgradesEndPoint="bx_block_student/grades"
exports.programDetailsAccreditationsEndPoint="bx_block_student/accreditations"
exports.programDetailsprogramsEndPoint="bx_block_student/programs"
exports.programDetailscommunitiesEndPoint="bx_block_student/communities"
///fourthForm///
exports.parentsInfoFathercountriesEndPoint="account_block/countries"
exports.parentsInfoMothercountriesEndPoint="account_block/countries"
exports.parentsInfoGuardiancountriesEndPoint="account_block/countries"
///FormApi///
exports.enrolmentEndPoint="student_enrolment"
///Grade api call
exports.GradeAPiGetMethod = "GET";
exports.GradeAPiContentType = "application/json";
exports.GradeAPiCallUrl="bx_block_student/grades";
///Pending Student Details api call
exports.pendingStudDetAPiGetMethod = "GET";
exports.pendingStudDetAPiContentType = "application/json";
exports.pendingStudDetAPiCallUrl="bx_block_student/pending_enrollment_details";

///Curriculum api call
exports.curriculumApiGetMethod = "GET";
exports.curriculumApiContentType = "application/json";
exports.curriculumApiCallUrl="bx_block_student/school_curriculums";
exports.barangays="get_barangays?city"
///Reason for registration api call
exports.reasonForRegisterAPiGetMethod = "GET";
exports.reasonForRegisterAPiContentType = "application/json";
exports.reasonForRegisterAPiCallUrl="bx_block_student/reason_of_registerings";

exports.programLevelsAPiGetMethod = "GET";
exports.programLevelsAPiContentType = "application/json";

exports.barangayBirthAPiGetMethod = "GET";
exports.barangayBirthAPiContentType = "application/json";

exports.contentTypeApiProvinces = "application/json";
exports.apiProvincesCallMethodType = "GET";
exports.apiProvincesCallUrl="provinces";
// Customizable Area End
