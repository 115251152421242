import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { imgConnect } from './assets'
import MenuItem from '@material-ui/core/MenuItem';
export const configJSON = require("./config");
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LazyLoadImage } from "react-lazy-load-image-component"
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
      MuiMenu: {
      paper: {
        maxHeight: 'calc(50% - 96px)',
       },
    },
    MuiIconButton: {
      root: {
         padding: 0
      },
    },
  },
});


const StyledTextField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',
      height: '19px',
      color: '#44444f'

    }
  }
})((props: any) => <TextField {...props} />);


import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

    // Customizable Area Start
  // Customizable Area End

  render() {

    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ margin: '20px auto', width: '95%' }}>

          <Grid container style={webStyle.mainWrapper}>
            <Grid item xs={12} sm={6} lg={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.leftWrapper}>
                <Box sx={webStyle.innerLeftStyle}>
                  <Box style={{ width: '50%' }}>
                    <LazyLoadImage
                      alt="connect"
                      src={imgConnect}
                      style={webStyle.imgStyle}
                      effect="blur"
                    />
                  </Box>
                  <Typography variant="h5" component={'h5'} style={webStyle.textStyleLeft} >
                    Step into the exiting world of <span style={{ color: '#171725' }}>learning better </span>
                    and <span style={{ color: '#171725' }}>pursuing what matters,</span> the HG Way
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.rightWrapper}>
                <Box sx={webStyle.innerRightStyle}>
                  <Typography variant="h6" component={'h6'} style={webStyle.textStyleRight} >
                    Signup below to get started
                  </Typography>
                  {/* ---------------Form Starts--------------------- */}
                  <Box style={{ marginTop: '30px' }}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.labelFirstName} </InputLabel>
                    <StyledTextField
                      placeholder="Enter First Name"
                      fullWidth={true}
                      name='firstName'
                      value={this.state.firstName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                       
                        this.setState({ firstName:  e.target.value.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')   })}
                      
                      onFocus={this.hideError}
                    />
                  </Box>
                  <Box>
                    {
                      (this.state.errors.firstName)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.firstName}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.lastName} </InputLabel>
                    <StyledTextField
                      placeholder="Enter Last Name"
                      fullWidth={true}
                      name='lastName'
                      value={this.state.lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                        this.setState({ lastName: e.target.value.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')  })}
                      onFocus={this.hideError}
                    />
                  </Box>
                  <Box>
                    {
                      (this.state.errors.lastName)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.lastName}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> Contact Number </InputLabel>
                    <Box style={{ display: 'flex', alignItems: 'end' }}>
                      <StyledTextField
                        select
                        style={{ width: '150px', marginTop: '-15px'}}
                        label={this.state.countryCodeSelected === "" ? "+91" : ""}
                        InputLabelProps={{ shrink: false }}
                        name='countryCodeSelected'
                        value={this.state.countryCodeSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ countryCodeSelected: e.target.value })}
                      >
                        {
                          this.state.uniqueCountryCodeDataNew?.map((item: any, index: number) => (
                            <MenuItem
                              key={index}
                              value={item?.phonecode}
                            >
                              {
                                '+' + item?.phonecode
                              }
                              

                            </MenuItem>
                          )
                          )
                        }
                      </StyledTextField>

                      <StyledTextField
                        inputProps={{ maxlength: 11 }}
                        style={{ marginLeft: '20px' }}
                        placeholder="1234567890"
                        fullWidth={true}
                        name='phone'
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        
                        onFocus={this.hideError}
                      />
                    </Box>
                  </Box>
                  <Box>
                    {
                      (!this.state.countryCodeSelected)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.countryCodeSelected}</small>
                        : <small style={{ visibility: 'hidden' }}>"Code Require"</small>
                    }
                    {
                      (this.state.errors.phone)
                        ? <small style={{ marginLeft: '3vw', ...webStyle.errorStyle }}>{this.state.errors.phone}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>

                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.labelPassword} </InputLabel>
                    <StyledTextField
                      
                      type={this.state.passwordInputState}
                      placeholder="Enter Password"
                      fullWidth={true}
                      name='password'
                      value={this.state.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value })}
                      onFocus={this.hideError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"  >
                            <IconButton onClick={this.changePasswordState}> 
                            {
                              this.state.passwordState ?
                              <Visibility style={webStyle.EmailOutlinedIcon} /> 
                              :<VisibilityOff style={webStyle.EmailOutlinedIcon} /> 
                            }
                              
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box>
                    {
                      (this.state.errors.password)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.password}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}>{configJSON.labelEmail} </InputLabel>
                    <StyledTextField
                      placeholder="email@mail.com"
                      fullWidth={true}
                      name='email'
                      value={this.state.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
                      onFocus={this.hideError}
                    />
                  </Box>
                  <Box>
                    {
                      (this.state.errors.email)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.email}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> Country of Residence </InputLabel>
                   
                    <Autocomplete
                     value={this.state.countryAutoCompleteValue}
                     onChange={(e:any, newValue:any)=>{this.setState({countryAutoCompleteValue:newValue})}}
                     options={ this.state.getAllCountries}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='country'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={this.state.country}
                      onInputChange={(e:any, newInputValue:any) => {
                         this.setState({ country: newInputValue })
                         this.changeHandlerGetCities(e,newInputValue)
                      }}
                        />
                    </Box>

                  
                  <Box>
                    {
                      (this.state.errors.country)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.country}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> City of Residence / Province </InputLabel>
                   
                    <Autocomplete
                     value={this.state.cityAutoCompleteValue}
                     onChange={(e:any, newValue:any)=>{this.setState({cityAutoCompleteValue:newValue})}}
                     options={this.state.getAllCities}
                     getOptionLabel={(option:any) => option.name || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => 
                      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                       name='city'  InputLabelProps={{ shrink: false }} 
                      />}
                      inputValue={this.state.city}
                      onInputChange={(e:any, newInputValue:any) => {
                         this.setState({ city: newInputValue })
                         this.cityChangeHandler(e,newInputValue)
                      }}
                        />
                  </Box>
                  
                  <Box>
                    {
                      (this.state.errors.city)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.city}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>

                  {this.state.byrangayData?.length > 0 && this.state.city != ""?
                  <>
                  <Box style={webStyle.inputBoxStyle}>
                  <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                <StyledTextField
                  select
                  color="darkgrey"
                  fullWidth
                  name="barangay"
                  InputLabelProps={{ shrink: false }}
                  value={this.state.barangay}
                  onChange={(e: any) => this.barangayChange(e)}
                >
                  <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                  {

                    this.state.byrangayData?.map((item: any) => {
                      return (

                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.attributes?.barangay_name}
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>
                  </Box>
                  <Box>
                    {
                      (this.state.errors.barangay)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.barangay}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                   </>
                  : null}

                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> Reason for Considering Homeschooling </InputLabel>
                    <StyledTextField
                      select
                      
                      style={{ width: '42vw' }}
                      InputLabelProps={{ shrink: false }}
                      fullWidth={true}
                      name='reasonForHomeSchooling'
                      value={this.state.reasonForHomeSchooling}
                      onChange={this.reasonChangeHandler}
                    >
                      <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                      {
                        this.state.reasonForHomeSchoolingData?.map((item: any, index: number) => (
                          <MenuItem
                            style={{ width: '550px', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            
                            key={item?.id + index}
                            value={item?.id}
                          >
                            {item?.attributes?.reason}
                          </MenuItem>
                        ))
                      }
                    </StyledTextField>
                  </Box>
                  <Box>
                    {
                      (this.state.errors.reasonForHomeSchooling)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.reasonForHomeSchooling}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}>How did you learn about us? </InputLabel>
                    <StyledTextField
                      select
                      InputLabelProps={{ shrink: false }}
                      fullWidth={true}
                      style={{ maxWidth: '100%', width: '100%', overflow: 'hidden' }}
                      name='learnAboutUs'
                      value={this.state.learnAboutUs}
                      onChange={this.learnChangeHandler}
                    >
                      <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                      <MenuItem value="Homeschool Global Website">Homeschool Global Website</MenuItem>
                      <MenuItem value="Google/Internet">Google/Internet</MenuItem>
                      <MenuItem value="Family/Friends">Family/Friends</MenuItem>
                      <MenuItem value="Facebook">Facebook</MenuItem>
                      <MenuItem value="Church">Church</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </StyledTextField>
                  </Box>
                  <Box>
                    {
                      (this.state.errors.learnAboutUs)
                        ? <small style={webStyle.errorStyle}>{this.state.errors.learnAboutUs}</small>
                        : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                  </Box>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} onClose={this.handleClose2} >
                    <Alert severity="error">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Button
                    type='submit'
                    fullWidth
                    disableElevation
                    disableRipple
                    style={{
                      textTransform: 'none', ...webStyle.signupFormSubmit
                    }}
                    onClick={this.signupFormSubmit}
                  >{this.state.loadingSignup && <CircularProgress /> }
                    {!this.state.loadingSignup && "Submit"}
                  </Button>
                  <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                    <Typography style={webStyle.textStyleInquiry}>Inquired before or an existing HG Parent?<span><Link to='/'><span style={webStyle.linkStyle}> Login</span></Link></span></Typography>
                  </Box>
                  {/* ---------------Form Ends--------------------- */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  signupFormSubmit: {
    width: "100%",
    height: "52px",
    marginTop: "40px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: '#fafafb',
    fontFamily: 'Poppins',
    fontSize: ' 16px',
    fontWeight: 600,
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    height: '100%',
    justifyContent: 'center',
    boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
  },

  mainInnerWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  leftWrapper: {
    flex: 1,
    border: '1px solid   #f1f1f5',
    // border: '1px solid  #979797',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  innerLeftStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '90px',
    textAlign: 'center',
  },

  rightWrapper: {
    flex: 1,
    // border: '3px solid #9167ac',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    backgroundColor: '#fbfbfb'
  },

  innerRightStyle: {
    paddingLeft: '50px',
    paddingRight: '50px',
  },

  imgStyle: {
    width: '100%',
  },

  textStyleRight: {
    marginTop: '30px',
    marginBottom: '19px',
    height: '42px',
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '42px',
    letterSpacing: '0.12px',
  },

  inputLabelStyle: {
    marginBottom: '10px',
    width: '220px',
    height: '22px',
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  inputSelectLabelStyle: {
    marginBottom: '10px',
    width: '220px',
    height: '22px',
    color: '#555555',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },

  inputBoxStyle: {
    marginTop: '14px',
  },
  textStyleInquiry: {
    marginTop: '25px',
    color: '#696974',
    fontFamily: 'Roboto',
    fontSize: ' 16px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0.1px',
  },
  textStyleLeft: {
    marginTop: '22px',
    color: '#757575',
    fontFamily: 'Poppins',
    fontSize: ' 25px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0.1px',
    alignText: 'center',
  },

  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  },
  linkStyle:{
    color:'black',
    textDecoration:'underline', 
    textDecorationColor: 'black',
  },
  EmailOutlinedIcon:{
    color: "#b5b5be"
  },
};



// Customizable Area End
