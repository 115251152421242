import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Modal,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
export const configJSON = require("./config");
import TicketFileUpload from "../../../components/src/TicketFileUpload.web";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',
    },
    "& .Mui-disabled": {
      color: "currentColor",
    }
  }
})((props: any) => <TextField {...props} />);


const GetErrorStyle = (props: any) => {
  const { stateType } = props;
  return (
    <Box>
      {
        stateType
          ? <small style={webStyle.errorStyle}>{stateType}</small>
          : <small style={{ visibility: 'hidden' }}>""</small>
      }
    </Box>
  )
}

// Customizable Area End
// Customizable Area Start
import TicketSystemController, {
  Props,
} from "./TicketSystemController";
// Customizable Area End


export default class Tickets extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { editorState, ticketCategory } = this.state;


    console.log("ganta customCategorisData", this.state.customCategorisData.category_id);
    console.log("ganta ticketMainConcernGroup", this.state.ticketMainConcernGroup)

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
              />
            </Grid>


            {/* ---------------------- drawer Content ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >

                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                  <Box style={{ marginTop: '100px' }}>
                    <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Create New Ticket </Typography>
                  </Box>
                  <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', backgroundColor: '#dcdcdc' }}></hr>

                  <Container maxWidth={false} style={webStyle.mainWrapper}>
                    <Container maxWidth={false} style={webStyle.GridWrapper}>
                      <Typography style={webStyle.mainheader}>Ticket Details</Typography>


                      {/* ------------------------ inner contents --------------------------     */}
                      <Grid container style={{ padding: '2em', display: 'flex', flexDirection: 'column' }}>

                        <Grid item xs={12} md={5}>
                          <Box style={webStyle.inputBoxStyle}>
                            <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketCategory} </InputLabel>
                            <StyledTextField
                              select
                              required
                              InputLabelProps={{ shrink: false }}
                              fullWidth={true}
                              name='ticketCategory'
                              value={this.state.ticketCategory.name}
                              onChange={this.handleChangeTicketCategory}
                            >
                              <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>
                              {
                                this.state.ticketCategoryDataSelect.length > 0 && this.state.ticketCategoryDataSelect?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      key={item?.id}
                                      value={item?.name}
                                      selected={item.value == this.state.ticketCategory.name}
                                    >
                                      {item?.name}
                                    </MenuItem>
                                  )
                                })

                              }
                            </StyledTextField>
                          </Box>
                          <GetErrorStyle stateType={this.state.ticketErrors.ticketCategory} />
                         
                        </Grid>

                        {/* ------------------------------------------Type of concerns------------------------------------------ */}

                        {(ticketCategory.name != "choose" && ticketCategory.id != 25) &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}> Type of Concern* </InputLabel>
                              <Autocomplete
                                value={this.state.academicConcernAutoCompleteValue}
                                onChange={(e: any, newValue: any) => { this.setState({ academicConcernAutoCompleteValue: newValue }) }}
                                options={this.state.ticketConcerns}
                                getOptionLabel={this.handleGetOptionLabel}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicConcern' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.academicConcern.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangeacademicConcern(e, newInputValue) }}
                              />
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.academicConcern} />                           
                          </Grid>
                        }
                        {/* ------------------------------------------------------------------------------------ */}

                        {/* ------------------------------------------Programs------------------------------------------ */}

                        {(ticketCategory.name != "choose" && ticketCategory.id == 36) &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>Program*</InputLabel>
                              <Autocomplete
                                value={this.state.academicProgramAutoCompleteValue}
                                onChange={(e: any, newValue: any) => { this.setState({ academicProgramAutoCompleteValue: newValue }) }}
                                options={this.state.ticketProgram}
                                getOptionLabel={(option: any) => option.text || ""}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.academicProgram.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangeacademicProgram(e, newInputValue) }}
                              />
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.academicProgram} />  
                           
                          </Grid>
                        }

                        {/* ------------------------------------------------------------------------------------ */}

                        {/* ------------------------------------------Name of Student*------------------------------------------ */}
                        {(ticketCategory.id == 48 || ticketCategory.id == 25) &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketNameOfStudent} </InputLabel>
                              <StyledTextField
                                placeholder="Enter Name "
                                required
                                fullWidth={true}
                                name='ticketNameOfStudent'
                                value={this.state.ticketNameOfStudent}
                                onChange={this.handlerChangeticketNameOfStudent}
                                onFocus={this.hideError}
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketNameOfStudent} />
                           
                          </Grid>
                        }
                        {/* ------------------------------------------------------------------------------------ */}


                        {/* ------------------------------------------Records and documents other fields start------------------------------------------ */}
                        {ticketCategory.id == 25 &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>Hub*</InputLabel>
                              <Autocomplete
                                value={this.state.hubAutoCompleteValue}
                                onChange={(e: any, newValue: any) => { this.setState({ hubAutoCompleteValue: newValue }) }}
                                options={this.state.recordsHGPHHubs}
                                getOptionLabel={(option: any) => option.text || ""}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.hubRecords.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangeHubRecords(e, newInputValue) }}
                              />
                              <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The HG Learning Hub where your child is enrolled in.</Typography>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.hubRecords} /> 
                           
                          </Grid>
                        }

                        {ticketCategory.id == 25 &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>Type of Document*</InputLabel>
                              <Autocomplete
                                value={this.state.typeOfDocAutoCompleteValue}
                                onChange={(e: any, newValue: any) => { this.setState({ typeOfDocAutoCompleteValue: newValue }) }}
                                options={this.state.recordsoncerns}
                                getOptionLabel={(option: any) => option.text || ""}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.typeOfDocRecords.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangeTypeOfDocRecords(e, newInputValue) }}
                              />
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.typeOfDocRecords} />
                            
                          </Grid>
                        }
                        {ticketCategory.id == 25 &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>School Year*</InputLabel>
                              <Autocomplete
                                value={this.state.schoolYearAutoCompleteValue}
                                onChange={(e: any, newValue: any) => { this.setState({ schoolYearAutoCompleteValue: newValue }) }}
                                options={this.state.recordsSchoolYear}
                                getOptionLabel={(option: any) => option.text || ""}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.schoolYearRecords.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangeSchoolYearRecords(e, newInputValue) }}
                              />
                              <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The academic year for your requested document.</Typography>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.schoolYearRecords} />
                           
                          </Grid>
                        }
                        {ticketCategory.id == 25 &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>Partner School*</InputLabel>
                              <Autocomplete
                                value={this.state.partnerSchoolAutoCompleteValue}
                                // onChange={(e: any, newValue: any) => { this.setState({ partnerSchoolAutoCompleteValue: newValue }) }}
                                onChange={this.handleChangePartnerSchoolAutoCompleteValue}
                                options={this.state.recordsPartnerSchool}
                                getOptionLabel={(option: any) => option.text || ""}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                inputValue={this.state.partnerSchoolRecords.text}
                                onInputChange={(e: any, newInputValue: any) => { this.handlerChangePartnerSchoolRecords(e, newInputValue) }}
                              />
                              <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The school that provides the accreditation for your homeschooling.</Typography>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.partnerSchoolRecords} />
                           
                          </Grid>
                        }

                        {/* -----------------------------------------Records and documents other fields End------------------------------------------- */}



                        <Grid item xs={12} md={5}>
                          <Box style={webStyle.inputBoxStyle}>
                            <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketSubject} </InputLabel>
                            <StyledTextField
                              placeholder="Type here"
                              required
                              fullWidth={true}
                              name='ticketSubject'
                              value={this.state.ticketSubject}
                              onChange={this.handlerChangeticketSubject}
                              onFocus={this.hideError}
                            >

                            </StyledTextField>
                          </Box>
                          <GetErrorStyle stateType={this.state.ticketErrors.ticketSubject} />
             
                        </Grid>
                        <Grid item xs={12} >
                          <Box style={webStyle.inputBoxStyle}>
                            <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketMessage} </InputLabel>
                            <Box style={
                              {
                                borderRadius: '15px',
                                border: '1px solid #e2e2ea',
                              }
                            }>
                              <Editor
                                editorState={editorState}
                                editorStyle={webStyle.editorClassStyle}
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                  options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                  inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                  },
                                  list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'],
                                  },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },

                                }}
                              />
                            </Box>
                          </Box>
                          <GetErrorStyle stateType={this.state.ticketErrors.editorState} />
                        
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                          <Button
                            style={{ textTransform: 'none', ...webStyle.btnAddStyle, opacity: this.state.openCC ? 0.5 : 1 }}
                            onClick={this.handleOnClickCC}
                            disabled={this.state.openCC}
                          >Add CC
                          </Button>
                          <Button
                            style={{ textTransform: 'none', ...webStyle.btnAddStyle, opacity: this.state.openBCC ? 0.5 : 1 }}
                            onClick={this.handleOnClickBCC}
                          >Add BCC
                          </Button>
                        </Grid>

                        {this.state.openCC &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketAddCC} </InputLabel>
                              <StyledTextField
                                placeholder="Enter Email"
                                required
                                fullWidth={true}
                                name='ticketAddCC'
                                value={this.state.ticketAddCC}
                                onChange={this.handlerChangeticketAddCC}
                                onFocus={this.hideError}
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketAddCC} />                          
                          </Grid>
                        }

                        {this.state.openBCC &&
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketAddBCC} </InputLabel>
                              <StyledTextField
                                placeholder="Enter Email"
                                required
                                fullWidth={true}
                                name='ticketAddBCC'
                                value={this.state.ticketAddBCC}
                                onChange={this.handlerChangeticketAddBCC}
                                onFocus={this.hideError}
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketAddBCC} />                           
                          </Grid>
                        }

                        <Grid item xs={12} >
                          <TicketFileUpload uploadFiles={this.uploadFiles}
                            ticketFile={this.state.ticketFile}
                          />

                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.headingContactStyle}>{configJSON.contactDetails} </InputLabel>
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketFullName} </InputLabel>
                              <StyledTextField
                                required
                                fullWidth={true}
                                name='ticketFullName'
                                value={localStorage.getItem("username")}
                                disabled
                              // readonly
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketFullName} />                           
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketEmail} </InputLabel>
                              <StyledTextField
                                placeholder="Type here"
                                required
                                fullWidth={true}
                                name='ticketEmail'
                                value={localStorage.getItem('email')}
                                disabled
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketEmail} />                         
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Box style={webStyle.inputBoxStyle}>
                              <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketPhoneNumber} </InputLabel>
                              <StyledTextField
                                placeholder="Type here"
                                required
                                fullWidth={true}
                                name='ticketPhoneNumber'
                                value={localStorage.getItem('phoneNumber')}
                                disabled
                              // value={this.state.ticketPhoneNumber}
                              // onChange={this.handlerChangeticketPhoneNumber}
                              // onFocus={this.hideError}
                              >
                              </StyledTextField>
                            </Box>
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketPhoneNumber} />                          
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel style={webStyle.formlabelStyle}
                              control={
                                <Checkbox
                                  style={{ color: '#757575' }}
                                  size="small"
                                  checked={this.state.ticketConsent}
                                  onChange={this.handlerChangeticketConsent}
                                />}
                              label={configJSON.ticketConsent}
                            />
                            <GetErrorStyle stateType={this.state.ticketErrors.ticketConsent} />                           
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: '3em', textAlign: "right" }}>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCancel }}
                              onClick={this.handleCancelTicket}
                            >Cancel
                            </Button>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }}
                              onClick={this.handleCreateTicket}
                            > Create New Ticket
                            </Button>
                          </Grid>

                          <Modal
                            open={this.state.updateOpen}
                          >
                            <Box style={webStyle.modalStyle}>
                              <Box style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Box style={{ paddingBottom: '1em' }}>
                                  {/* <CheckCircleIcon style={webStyle.CheckBoxStyle} /> */}
                                </Box>
                                <Typography align='center' style={{ ...webStyle.Successfully, textAlign: "justify" }}>
                                  Thank you for submitting this ticket. Anticipate a copy of this request to be sent to your email with a Reference Number.</Typography>
                              </Box>                             
                            </Box>
                          </Modal>
                        </Grid>
                      </Grid>
                    </Container>
                  </Container>


                </Container>

                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

              </Container>
            </Grid>
          </Grid>



        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {


  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  Successfully: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 600,
    width: '368px',

  },
  boxCancelIcon: {
    paddingTop: '2px',
    borderRadius: '50px',
    color: '#fff',
    backgroundColor: '#B98ED6',
    width: '32px',
    height: '32px'
  },
  welcomeTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
  },

  btnAddStyle: {
    width: '132px',
    height: '52px',
    borderRadius: '10px',
    backgroundColor: '#e4e4e4',
    border: "none",
    color: '#757575',
    fontFamily: 'Poppins',
    fontSize: ' 16px',
    fontWeight: 500,
    backgroundBlendMode: 'normal',
    margin: '1.5em 1.5em 1.5em 0',
  },
  disabledButton: {
    background: "red",
  },
  btnTicketCancel: {
    width: "200px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#b9b9b9",
    borderRadius: "10px",
    marginLeft: '1em',
  },
  btnTicketCreate: {
    width: "300px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#fab03d",
    borderRadius: "10px",
    marginLeft: '1em',
  },

  mainWrapper: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
    padding: '0em',
  },
  GridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    padding: "2em",
    marginTop: "2em",
  },
  mainheader: {
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
  },
  inputBoxStyle: {
    marginTop: '30px',
  },

  inputSelectLabelStyle: {
    color: '#757575',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '5px',
  },
  headingContactStyle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '1em',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  },
  formlabelStyle: {
    display: 'block',
    marginTop: '2em',
    color: '#757575',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 300
  },
  editorClassStyle: {
    height: '250px',
    overflowy: 'auto',
    padding: '15px',
  },

};
// Customizable Area End
