

// Customizable Area Start
import React from "react";
// Customizable Area End

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

import TicketSystemController, { Props, } from "./TicketSystemController";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import {  rightArrowActive, rightArrowInActive, leftArrowActive, leftArrowInActive } from './assets';
import { makeStyles } from '@material-ui/core/styles';

const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {

        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiInput: {
            underline: {

                " &:before": {
                    borderBottom: 'none',
                    transition: 'none',
                    content: 'none',
                },

            },
        },

    },
});

// Customizable Area End




export default class TicketStatusInfo extends TicketSystemController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const statusColor: any = { "completed": "#99CB51", "pending": "#EAC543", "Pending Action required": "#F4A760" }
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>

                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={this.handleDrawerOpen}
                                navigation={this.props.navigation}
                            />
                        </Grid>

                        {/* ---------------------- drawer Content ------------------------- */}
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >

                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                                    <Box style={{ marginTop: '100px', display: "flex", justifyContent: "space-between" }}>
                                        <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Tickets </Typography>
                                        <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }} onClick={this.handleTicketNavigation}>
                                            New Ticket
                                        </Button>
                                    </Box>   

                                    <Box className="ticketBox" style={webStyle.ticketBox}>
                                        <Box className="boxHeader" style={webStyle.boxHeader}>
                                            <Box className="dropDown">
                                                <select value={this.state.filterOption} onChange={this.handleChange} style={webStyle.dropDownStyles}>
                                                    <option value="All Tickets">All Tickets</option>
                                                    <option value="Pending">Pending Tickets</option>
                                                    <option value="Completed">Completed Tickets</option>
                                                    <option value="Pending action required">Pending Action Required</option>
                                                </select>

                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Button onClick={this.handlePrevious} disabled={this.handlePreviousDisable()}>
                                                    {this.handlePreviousDisable() ? <img src={leftArrowInActive} alt="backwardArrow" /> : <img src={leftArrowActive} alt="backwardArrow" />}
                                                </Button>
                                                <Typography>{this.state.ticketsPageInfo.start_index} - {this.state.ticketsPageInfo.end_index}</Typography>
                                                <Button onClick={this.handleNext} disabled={this.handleNextDisable()}>
                                                    {this.handleNextDisable()? <img src={rightArrowInActive} alt="rightArrow" /> : <img src={rightArrowActive} alt="rightArrow" />}
                                                </Button>
                                            </Box>
                                        </Box>
                                        <hr />
                                        <Box className="headerStyle" style={webStyle.headerStyle}>
                                            <Typography className="width30" style={webStyle.width30}>Status</Typography>
                                            <Typography className="width30" style={webStyle.width30}>Subject</Typography>
                                            <Typography className="width20" style={webStyle.width20}>Submitted on</Typography>
                                            <Typography className="width20" style={webStyle.width20}>Ticket Id</Typography>
                                        </Box>
                             
                                        {this.state.allTicketsData.map((rowValue: any, index: any) =>

                                        (
                                            <Box className="bodyStyle" style={webStyle.bodyStyle} key={index}>
                                                <Typography className="width30 statusBar" style={{ ...webStyle.width30, ...webStyle.statusBar, height: "auto", textAlign: "center", backgroundColor: statusColor[rowValue.status.behavior] }}>{rowValue.status.behavior}</Typography>
                                                {/* <Typography className="width30 statusBar" style={{ ...webStyle.width30, ...webStyle.statusBar, height: "auto", textAlign: "center", backgroundColor: rowValue.status.color }}>{rowValue.status.behavior}</Typography> */}
                                                <Typography className="width30 subjectName" style={{ ...webStyle.width30, ...webStyle.subjectName, whiteSpace: "nowrap" }}>{rowValue.subject}</Typography>
                                                <Typography className="width20" style={webStyle.width20}>{rowValue.created_at.split(' ')[0]}</Typography>
                                                <Typography className="width20" style={webStyle.width20}>{rowValue.id}</Typography>
                                            </Box>
                                        )
                                        )}
                                    </Box>


                                </Container>

                                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    ticketBox: {
        borderRadius: "15px",
        border: "1px dashed #f1f1f5",
        background: "#ffffff",
        backgroundBlendMode: "normal",
        boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
        padding: "29px 47px",
        marginTop: "17px"
    },
    boxHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    hLine: {
        borderColor: "#ececf1",
    },
    width25: {
        width: "25%",
        marginLeft: "68px !important",
    },
    width30: {
        width: "30%",
        marginLeft: "68px ",
    },
    width20: {
        width: "20%",
        marginLeft: "68px ",
    },
    headerStyle: {
        display: "flex",
        height: "50px",
        borderRadius: "10px",
        background: "#9167ac",
        backgroundBlendMode: "normal",
        alignItems: "center",
    },

    bodyStyle: {
        display: "flex",
        marginTop: "12px",
        height: "58px",
        borderRadius: "10px",
        border: "1px solid #e2e2ea",
        alignItems: "center",
    },
    statusBar: {
        height: "24px",
        borderRadius: "11px",
        background: "#99cb51",
        backgroundBlendMode: "normal",
        // textAlign: "center",
        /* width: 160px; */
    },
    subjectName: {
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    btnTicketCreate: {
        width: "235px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px",
        marginLeft: '1em',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        // color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
    },

    btnStatus: {
        width: '200px',
        height: '24px',
        borderRadius: '10px',
        border: "none",
        color: '#fafafb',
        fontFamily: 'Poppins',
        fontSize: ' 11px',
        fontWeight: 500,
        backgroundBlendMode: 'normal',
        padding: '1.2em 0.5em'
    },

    gridContentContainerStyle: {
        marginTop: '14px',
        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        padding: '20px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between'


    },
    textTicketStyle: {
        height: '36px',
        color: '#171725',
        fontFamily: '  Poppins',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '36px',
    },
    headingTextStyle: {
        height: '18px',
        color: '#92929d',
        fontFamily: '  Poppins',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },

    headingPinTextStyle: {
        height: '21px',
        color: '#171725',
        fontFamily: '  Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
    },
    headingStatusTextStyle: {

        color: '#383838',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 400,

    },
    avatarTitleTextStyle: {
        height: '14px',
        color: ' #92929d',
        fontFamily: '   Roboto',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14px',
        marginBottom: '0.6em'
    },
    cardAvatarStyle: {
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    dropDownStyles: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        width: "80%",
        border: "white"
    }

};

function useState(second: any) {
    throw new Error("Function not implemented.");
}
// Customizable Area End
