

import React from "react";

import {
   // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
     Grid,
    Paper,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
export const configJSON = require("./config");
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { avatar } from './assets';
import Avatar from '@material-ui/core/Avatar';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Drawer from "../../../components/src/Drawer.web";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const GreenRadio = withStyles({
    root: {
        color: '#99cb51',
        '&$checked': {
            color: '#99cb51',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);


  const PurpleCheckbox = withStyles({
    root: {
        color: '#9267ac',
      '&$checked': {
        color: '#9267ac',
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End
 // Customizable Area Start
import OrderDetailViewController, {
    Props,

} from "./OrderDetailViewController";
 // Customizable Area End
export default class Checkout5 extends OrderDetailViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>
                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={this.handleDrawerOpen}
                                navigation={this.props.navigation}
                                modal={this.manage}
                            />
                        </Grid>
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >
                                <Container maxWidth={false} style={{ margin: '0 auto', width: "95%", padding: '0em' }}>
                                    <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>

                                    </Box>
                                    <Grid container style={{ display: 'flex' }}>
                                        <HorizontalLine />
                                    </Grid>
                                        {/* stepper tabs start */}
                                        <Grid container style={webStyle.stepperContainer}>

                                            <Grid item xs={12} style={webStyle.stepperGridItem}>
                                                <Box style={webStyle.stepperMenuBox}>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText1}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText2}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText3}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText4}</Typography>
                                                    <Typography style={webStyle.stepperMenuTextStyle}>{configJSON.stepperText5}</Typography>
                                                </Box>
                                                <Box style={webStyle.stepperLineBox}></Box>
                                            </Grid>
                                         </Grid>
                                        {/* stepper tabs ends */}
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} lg={8}>
                                            <Box style={webStyle.leftGridWrapper}>
                                                <Typography style={webStyle.mainheading} >Miscellaneous Add Ons </Typography>
                                                <Box style={webStyle.leftInnnerGridWrapper}>
                                                    <Typography style={webStyle.mainheadingSub1} >Optional Items</Typography>
                                                    <Box style={{ padding: '1em' }}>
                                                        <Box style={webStyle.leftInnnerGridWrapperSubjects}>
                                                            <Typography style={webStyle.mainheadingSub2} >Online Group Tutorials - 5 Subjects</Typography>
                                                            <Grid container >

                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > 
                                                                    <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectFilipino}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectFilipino"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Filipino Subject</span>}
                                                                    />
  
                                                                    </Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box >
                                                                        <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectEnglish}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectEnglish"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>English Subject</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectScience}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectScience"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Science Subject</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectMath}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectMath"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Math Subject</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectAralingPanlipunan}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectAralingPanlipunan"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Araling Panlipunan Subject</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                            </Grid >
                                                        </Box>

                                                    </Box>
                                                    <Typography style={webStyle.mainheadingSub3} >Additional Testing</Typography>
                                                    <Box style={{ padding: '1em' }}>
                                                        <Box style={webStyle.leftInnnerGridWrapperSubjects}>
                                                            <Typography style={webStyle.mainheadingSub2} >Additional MobyMax Subject</Typography>
                                                            <Grid container >

                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectScienceAdditional}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectScienceAdditional"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Science</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>

                                                                </Grid>
                                                                <Grid item xs={12} style={webStyle.CheckoutStyle}>
                                                                    <Box > <FormControlLabel
                                                                        control={
                                                                            <PurpleCheckbox
                                                                            style={{ marginLeft: '0.5em'}}
                                                                             checked={this.state.checkoutDataObj.subjectSocialStudiesAdditional}
                                                                            onChange={this.handlChangeCheckBox }
                                                                            name="subjectSocialStudiesAdditional"
                                                                            />
                                                                        }
                                                                       label={<span style={webStyle.subjectStyle}>Social Studies</span>}
                                                                    /></Box>
                                                                    <Box style={webStyle.subjectStyle1} >₱ 8,000.00</Box>
                                                                </Grid>
                                                            </Grid >
                                                        </Box>
                                                    </Box>

                                                </Box>
                                                <Box style={webStyle.mainPrograms}>
                                                    <Typography style={webStyle.mainheadingSubProgram}>Program - Touch</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPrice}>₱ 50,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram}>Curriculum</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPrice}>₱ 12,347.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram}>Family Books</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPrice}>₱ 10,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram}>Miscellaneous Add Ons</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPrice}>₱ 42,000.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram}>Sub Total</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPrice}>₱ 1,06,347.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '0.5em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <RadioGroup 
                                                aria-label="learnerEnrollment" 
                                                name="learnerEnrollment"
                                                value={this.state.checkoutDataObj.learnerEnrollment}
                                                onChange={this.handleChangeLearnerEnrollment}
                                                >
                                                    <FormControlLabel value="finish_enrolling" control={<GreenRadio />} label={<span style={webStyle.radio}>Finish enrolling all learners</span>} />
                                                    <FormControlLabel value="proceed_to_checkout" control={<GreenRadio />} label={<span style={webStyle.radio}>Proceed to checkout to complete this learner's enrollment</span>} />
                                                </RadioGroup>
                                                <Grid container spacing={4} style={{ marginBottom: '6em' }}>
                                                    <Grid item xs={12} style={{ marginTop: '2em', textAlign: "right" }}>
                                                       <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnSwitch }}
                                                        onClick={this.handleSwitchProgram}> 
                                                        {configJSON.btnSwitchProgram} </Button>
                                                        <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.handleCheckout}}
                                                        onClick={this.CheckoutPayInstallments}>
                                                        {configJSON.btnCheckout}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}lg={4}>
                                                <Box style={webStyle.GridWrapper1}>
                                                <Typography style={webStyle.mainheading} >Chekout Info</Typography>
                                                <Grid container style={{ display: "flex", paddingTop: '1.5em' }}>
                                                    <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} />
                                                    <Grid style={webStyle.studentDetailsHeading} >Owais Feban</Grid>
                                                </Grid>
                                                <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                                    <Avatar alt="program avatar" src={avatar} style={webStyle.programAvatarStyle} />
                                                    <Box style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
                                                        <Box>
                                                            <Typography style={webStyle.ProgramInnerTextHeader}>Program: Touch - Full</Typography>
                                                            <Typography style={webStyle.programInnerTextSubText}>Cost: ₱ 50,000.00</Typography>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                                <Box style={webStyle.mainPrograms}>
                                                    <Typography style={webStyle.mainheadingSubProgramCheckout}>Subtotal</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotal}>₱ 50,000.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramCheckout}>Shipping</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Free</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramCheckoutTextBook}>Text Book Type</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramCheckoutTextBook}>Custom</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Subjects</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>English</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 4,739.00</Typography>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>Maths</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 7,608.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Family Books</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 10,000.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>
                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotalSubject}>Miscellaneous Add Ons </Typography>
                                                    <Typography style={webStyle.mainheadingSubProgram1}>₱ 42,000.00</Typography>
                                                </Box>
                                                <Box style={{ paddingTop: '1em' }}>
                                                    <Box style={webStyle.Breakline}><p></p></Box>                                                </Box>
                                                <Box style={webStyle.Programs}>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceTotal}>Total</Typography>
                                                    <Typography style={webStyle.mainheadingSubProgramPriceColor}>₱ 1,06,347.00</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    mainPrograms: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '1.5em'
    },
    Programs: {
        display: 'flex', justifyContent: 'space-between', paddingTop: '0.5em'
    },
    CheckoutStyle: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '0.5em'
    },
    handleCheckout: {
        width: "200px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px", marginLeft: '1em',
    },
    btnSwitch: {
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#9167ac",
    },
    gridProgramContainerStyle: {
        position: 'relative' as 'relative',
        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        padding: '20px',
        //     width: '80%',
        display: 'flex',
        marginLeft: '15px',
        marginTop: '20px',
        marginBottom: '5px',

    },
    programAvatarStyle: {
        position: 'absolute' as 'absolute',
        width: '53px',
        height: '53px',
        top: '14px',
        left: '-17px',
    },
    cardInnerTextHeader: {
        height: '24px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    ProgramInnerTextHeader: {
        color: ' #171725',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '18px',
    },
    programInnerTextSubText: {
        color: ' #7b7b7b',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },

    cardAvatarStyle: {
        width: '42px',
        height: '42px',
        marginRight: '10px'
    },
    studentDetailsHeading: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontWeight: 600,
        marginTop: '8px'
    },

    radio: {
        color: "#171725",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
    },
    mainheadingSubProgram: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
    },
    mainheadingSubProgram1: {
        color: " #192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,

    },
    mainheadingSubProgramCheckout: {
        color: "#757575",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },
    mainheadingSubProgramCheckoutTextBook: {
        color: "#192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },
    mainheadingSubProgramPrice: {
        color: " #171725",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,

    },
    mainheadingSubProgramPriceTotal: {
        color: "#192126",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,

    },
    mainheadingSubProgramPriceTotalSubject: {
        color: "#192126",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,

    },
    mainheadingSubProgramPriceColor: {
        color: "#fab03d",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
    },

    mainheading: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.075px",
        marginTop: "1em"
    },
    mainheadingSub1: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.075px",
        paddingTop: '1.3em',
        paddingLeft: "1.3em",
        paddingRight: "1.3em",
    },
    mainheadingSub2: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.075px",
        paddingTop: '1.5em',
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        paddingBottom: "1em"
    },
    mainheadingSub3: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.075px",
        paddingLeft: "1.3em",
        paddingRight: "1.3em",
    },
    leftInnnerGridWrapperSubjects: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
    },

    leftGridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        padding: "1.3em",
        marginTop: '2em'
    },
    leftInnnerGridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: '2em',

    },
    subjectStyle: {

        fontFamily: "Poppins",
        fontSize: "2.2vh",
        fontWeight: 500,
        flexGrow: 1,
        color: "#171725"

    },
    subjectStyle1: {
        fontFamily: "Roboto",
        fontSize: "2vh",
        ffontWeight: 500,
        marginRight: '1em',
        color: "#5e5e5e"
    },
    GridWrapper1: {

        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #fab03d",
        padding: "1em",
        marginTop: '2em'
    },
    stepperContainer:{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      },
      stepperGridItem:{ 
        display: 'flex',  
        alignItems: 'center', 
        justifyContent: 'center',
        flexDirection:'column' as 'column',
      },
      stepperMenuBox:{
        width:'100%',
        padding: '1.3vw', 
        marginTop: '1.3vw', 
        marginBottom: '0.6vw', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between' 
      },
      stepperMenuTextStyle: {
        fontFamily: 'Poppins',
        fontSize: '0.9vw',
        fontWeight: 600,
        color: '#99cb51',
      },
      stepperLineBox:{
        width:'98%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
         borderBottom:'0.2vw solid #99cb51'
      },
};
// Customizable Area End
