// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import Customform from "../../blocks/customform/src/Customform";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import Videos from "../../blocks/videos/src/Videos";
import MultipleStepRegistrationForm2 from "../../blocks/MultipleStepRegistrationForm2/src/MultipleStepRegistrationForm2";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import AlgorithmForFeeCalculator2 from "../../blocks/AlgorithmForFeeCalculator2/src/AlgorithmForFeeCalculator2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest.web";
import StudentEnrollmentFormStepper from '../../blocks/customform/src/StudentEnrollmentFormStepper.web';
import TicketStatusInfo from '../../blocks/TicketSystem/src/TicketStatusInfo.web';
import Tickets from '../../blocks/TicketSystem/src/Tickets.web';
import CreateTickets from '../../blocks/TicketSystem/src/CreateTickets.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import StudentDetails from '../../blocks/OrderDetailView/src/StudentDetails.web';
import CheckoutOne from '../../blocks/OrderDetailView/src/CheckoutOne.web';
import CheckoutTwo from '../../blocks/OrderDetailView/src/CheckoutTwo.web';
import CheckoutThree from '../../blocks/OrderDetailView/src/CheckoutThree.web';
import CheckoutFour from '../../blocks/OrderDetailView/src/CheckoutFour.web';
import Checkout5 from '../../blocks/OrderDetailView/src/Checkout5.web';
import Checkout6 from '../../blocks/OrderDetailView/src/Checkout6.web';
import CheckoutPaymentOptions from '../../blocks/OrderDetailView/src/CheckoutPaymentOptions.web';
import CheckoutPayInstallments from '../../blocks/OrderDetailView/src/CheckoutPayInstallments.web';
import CheckoutPaymentPlan from '../../blocks/OrderDetailView/src/CheckoutPaymentPlan.web';
import Checkoutsucess6 from '../../blocks/OrderDetailView/src/Checkoutsucess6.web';
import AccountSettings from '../../blocks/email-account-registration/src/AccountSettings.web';
import HomeschoolProgram from '../../blocks/OrderDetailView/src/HomeschoolProgram.web';
import HomeSchoolProgramRecommendationResult from '../../blocks/OrderDetailView/src/HomeSchoolProgramRecommendationResult.web';
import HomeSchoolStyleRecommendationResult from '../../blocks/OrderDetailView/src/HomeSchoolStyleRecommendationResult.web';
import HomeschoolStyle from '../../blocks/OrderDetailView/src/HomeschoolStyle.web';
import HomeschoolButtons from '../../blocks/OrderDetailView/src/HomeschoolButtons.web';
import HomeschoolResults from '../../blocks/OrderDetailView/src/HomeschoolResults.web';
import StudentsList from '../../blocks/OrderDetailView/src/StudentsList.web';
import StudentRejectedReason from '../../blocks/OrderDetailView/src/StudentRejectedReason.web';
import Chat9 from '../../blocks/Chat9/src/Chat9.web';
import Faq from '../../blocks/Chat9/src/Faq.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import DashboardPage from '../../blocks/dashboard/src/DashboardPage.web';
import DashBoardPageNonExpandable from '../../blocks/dashboard/src/DashBoardPageNonExpandable.web';
import DashBoardNonExpandablePage from '../../blocks/dashboard/src/DashBoardNonExpandablePage.web';
import DashBoardDayInLifeVideo from '../../blocks/dashboard/src/DashBoardDayInLifeVideo.web';
import DashboardIntroVideo from '../../blocks/dashboard/src/DashboardIntroVideo.web';
import OrientationVideo from '../../blocks/dashboard/src/OrientationVideo.web';
import HgFormula from '../../blocks/dashboard/src/HgFormula.web';

import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import GlobalProvider from '../../blocks/customform/src/useContext';

const routeMap = {
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  StudentEnrollmentFormStepper: {
    component: StudentEnrollmentFormStepper,
    path: '/StudentEnrollmentFormStepper'
  },
  TicketStatusInfo: {
    component: TicketStatusInfo,
    path: '/TicketStatusInfo'
  },
  TicketSystem: {
    component: TicketSystem,
    path: '/TicketSystem'
  },
  Tickets: {
    component: Tickets,
    path: '/Tickets'
  },
  CreateTickets: {
    component: CreateTickets,
    path: '/CreateTickets'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },
  StudentDetails: {
    component: StudentDetails,
    path: '/StudentDetails'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  CheckoutOne: {
    component: CheckoutOne,
    path: '/CheckoutOne'
  },
  CheckoutTwo: {
    component: CheckoutTwo,
    path: '/CheckoutTwo'
  },
  CheckoutThree: {
    component: CheckoutThree,
    path: '/CheckoutThree'
  },
  CheckoutFour: {
    component: CheckoutFour,
    path: '/CheckoutFour'
  },
  Checkout5: {
    component: Checkout5,
    path: '/Checkout5'
  },
  Checkout6: {
    component: Checkout6,
    path: '/Checkout6'
  },
  Checkoutsucess6: {
    component: Checkoutsucess6,
    path: '/Checkoutsucess6'
  },
  CheckoutPaymentOptions: {
    component: CheckoutPaymentOptions,
    path: '/CheckoutPaymentOptions'
  },
  CheckoutPayInstallments: {
    component: CheckoutPayInstallments,
    path: '/CheckoutPayInstallments'
  },
  CheckoutPaymentPlan: {
    component: CheckoutPaymentPlan,
    path: '/CheckoutPaymentPlan'
  },
  AccountSettings: {
    component: AccountSettings,
    path: '/AccountSettings'
  },
  HomeschoolProgram: {
    component: HomeschoolProgram,
    path: '/HomeschoolProgram'
  },
  HomeSchoolProgramRecommendationResult: {
    component: HomeSchoolProgramRecommendationResult,
    path: '/HomeSchoolProgramRecommendationResult'
  },
  HomeSchoolStyleRecommendationResult: {
    component: HomeSchoolStyleRecommendationResult,
    path: '/HomeSchoolStyleRecommendationResult'
  },
  HomeschoolStyle: {
    component: HomeschoolStyle,
    path: '/HomeschoolStyle'
  },
  HomeschoolButtons: {
    component: HomeschoolButtons,
    path: '/HomeschoolButtons'
  },
  HomeschoolResults: {
    component: HomeschoolResults,
    path: '/HomeschoolResults'
  },
  StudentsList: {
    component: StudentsList,
    path: '/StudentsList'
  },
  StudentRejectedReason: {
    component: StudentRejectedReason,
    path: '/StudentRejectedReason'
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9'
  },
  Faq: {
    component: Faq,
    path: '/Faq'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  DashboardPage: {
    component: DashboardPage,
    path: '/DashboardPage'
  },
  DashBoardNonExpandablePage: {
    component: DashBoardNonExpandablePage,
    path: '/DashBoardNonExpandablePage'
  },
  DashBoardDayInLifeVideo: {
    component: DashBoardDayInLifeVideo,
    path: '/DashBoardDayInLifeVideo'
  },
  DashBoardPageNonExpandable: {
    component: DashBoardPageNonExpandable,
    path: '/DashBoardPageNonExpandable'
  },
  DashboardIntroVideo: {
    component: DashboardIntroVideo,
    path: '/DashboardIntroVideo'
  },
  OrientationVideo: {
    component: OrientationVideo,
    path: '/OrientationVideo'
  },
  HgFormula: {
    component: HgFormula,
    path: '/HgFormula'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  ImageThumbnail: {
    component: ImageThumbnail,
    path: '/ImageThumbnail'
  },
  login: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
Videos:{
 component:Videos,
path:"/Videos"},
MultipleStepRegistrationForm2:{
 component:MultipleStepRegistrationForm2,
path:"/MultipleStepRegistrationForm2"},
DynamicContent:{
 component:DynamicContent,
path:"/DynamicContent"},
AlgorithmForFeeCalculator2:{
 component:AlgorithmForFeeCalculator2,
path:"/AlgorithmForFeeCalculator2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},

Dashboard:{
 component:Dashboard,
path:"/Dashboard"},


  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  Home: {
    component: HomeScreen,
    path: '/Home'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <GlobalProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </GlobalProvider>
    );
  }
}

export default App;
