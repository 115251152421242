import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { EditorState, convertToRaw } from "draft-js";
import { number } from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openD: any;
  drawerWidth: any;
  editorState: EditorState;
  ticketCategoryData: any;
  ticketCategoryDataSelect: any;
  ticketNameOfStudent: string;

  ticketCategory: any;
  ticketConcerns: any;
  ticketMainConcernGroup: any;
  ticketProgram: any;


  ticketSubject: string;
  ticketAddCC: string;
  ticketAddBCC: string;
  openCC: any;
  openBCC: any;
  ticketFile: any;
  ticketFullName: string;
  ticketEmail: string;
  ticketPhoneNumber: string;
  ticketConsent: boolean;
  ticketErrors: any;
  updateOpen: any;

  customCategorisData: any;
  academicConcern: any;

  academicProgram: any;
  hubRecords: any;
  typeOfDocRecords: any;
  schoolYearRecords: any;
  partnerSchoolRecords: any;
  academicConcernAutoCompleteValue: any;

  academicProgramAutoCompleteValue: any;
  hubAutoCompleteValue: any;
  typeOfDocAutoCompleteValue: any;
  schoolYearAutoCompleteValue: any;
  partnerSchoolAutoCompleteValue: any;
  enrollmentStudentName: any;
  enrollmentPaymentsConcern: any;
  otherConcernsHGPH: any;
  recordsPartnerSchool: any;
  recordsStudentName: any;
  recordsoncerns: any;
  recordsHGPHHubs: any;
  recordsSchoolYear: any;
  shineConcerns: any;
  trainingConcerns: any;
  academicConcernId: string;
  academicProgramId: string;
  academicTestingData: any;
  academicTestingDataSelected: any;

  // academicConcernData: any;
  academicProgramData: any;
  ticketsPageInfo: any;
  allTicketsData: any;
  rowsPerPage: any;
  prevDisable: any;
  nextDisable: any;
  filterOption: any;
  filteredData: any
  pageCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketSystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiTicketCategoriesCallId: string = "";
  apiCustomCategoriesCallId: string = "";
  apiCreateTicketCallId: string = "";
  apiAllTicketsCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    // Customizable Area Start
    // Customizable Area End


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: true,
      // Customizable Area Start
      openD: true,
      drawerWidth: 300,
      editorState: EditorState.createEmpty(),
      ticketCategoryData: [],
      ticketCategoryDataSelect: [],

      ticketCategory: { id: "", name: "choose" },
      ticketConcerns: [],
      ticketMainConcernGroup: { id: "", name: "" },
      ticketProgram: [],

      ticketNameOfStudent: "",
      ticketSubject: "",
      ticketAddCC: "",
      ticketAddBCC: "",
      openCC: false,
      openBCC: false,
      ticketFile: { filenm: new File([""], "") },
      ticketFullName: "",
      ticketEmail: "",
      ticketPhoneNumber: "",
      ticketConsent: false,
      ticketErrors: {},
      updateOpen: false,
      customCategorisData: [],
      academicConcern: { id: "", text: "choose" },

      academicProgram: { id: "", text: "choose" },
      hubRecords: { id: "", text: "choose" },
      typeOfDocRecords: { id: "", text: "choose" },
      schoolYearRecords: { id: "", text: "choose" },
      partnerSchoolRecords: { id: "", text: "choose" },
      academicConcernAutoCompleteValue: '',
      academicProgramAutoCompleteValue: '',
      hubAutoCompleteValue: '',
      typeOfDocAutoCompleteValue: '',
      schoolYearAutoCompleteValue: '',
      partnerSchoolAutoCompleteValue: '',
      enrollmentStudentName: [],
      enrollmentPaymentsConcern: [],
      otherConcernsHGPH: [],
      recordsPartnerSchool: [],
      recordsStudentName: [],
      recordsoncerns: [],
      recordsHGPHHubs: [],
      recordsSchoolYear: [],
      shineConcerns: [],
      trainingConcerns: [],

      academicConcernId: '',
      academicProgramId: '',
      academicTestingData: [],
      academicTestingDataSelected: [],

      // academicConcernData: [],
      academicProgramData: [],
      ticketsPageInfo: {},
      allTicketsData: [],
      rowsPerPage: 10,
      prevDisable: true,
      nextDisable: false,
      filterOption: '',
      filteredData: [],
      pageCount: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // ---------------------  get ticket categories response start -----------------------
    if (this.apiTicketCategoriesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ ticketCategoryData: apiResponse })
        let data: any = [];
        this.state.ticketCategoryData?.map((item: any) => {
          data.push({
            id: item?.id,
            name: item?.name,
          })
        })
        this.setState({ ticketCategoryDataSelect: data })
      }
      else {
        console.log('ticket categories fetching error', apiResponse?.errors)
      }
    }
    // ---------------------  get ticket categories response end -----------------------

    // ---------------------  custom categories response start -----------------------

    if (this.apiCustomCategoriesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ customCategorisData: apiResponse })

      if (!this.state.customCategorisData.errors) {

        // console.log("gant customCategorisData", this.state.customCategorisData);
        //CategoryId --- Type of concerns  -- dropdown option
        //36 --- 32 --- Academics & Testing
        //48 --- 37 --- Enrollment & Payment
        //39 --- 39 --- HGPH Other Concerns
        //51 --- 40 --- Shine or Events
        //38 --- 38 --- Training & Engagement
        const ListOfConcernIds = [32, 37, 38, 39, 40];

        this.state.customCategorisData.custom_fields.map((item: any) => {
          if (ListOfConcernIds.includes(item.id)) {
            this.setState({ ticketConcerns: item.choices, ticketMainConcernGroup: { id: item.id, name: item.name } })
          }
        })

        if (this.state.customCategorisData.category_id === 36) {
          this.state.customCategorisData.custom_fields.map((item: any) => {
            if (item.name === "HGPH Program") {
              this.setState({ ticketProgram: item.choices })
            }
          })
        }

        // --------------------------- Academics & Testing ---------------------------




        // --------------------------- Records or Documents ---------------------------
        if (this.state.customCategorisData.category_id == this.state.ticketCategory.id &&
          this.state.ticketCategory.name == "Records or Documents") {

          this.state.customCategorisData.custom_fields.map((item: any) => {

            item.name == "Partner School" && item.choices.map((choice: any) => {
              this.state.recordsPartnerSchool.push({
                text: choice.text,
                id: choice.id,
              })
            })

            item.name == "Partner School" &&
              this.state.recordsPartnerSchool.push({
                customCatergoryName: item.name,
                customId: item.id,
              })

            item.name == "Name of Student" &&
              this.state.recordsStudentName.push({
                customCatergoryName: item.name,
                customId: item.id,
              })

            item.name == "Records Concerns" && item.choices.map((choice: any) => {
              this.state.recordsoncerns.push({
                text: choice.text,
                id: choice.id,
              })
            })

            item.name == "Records Concerns" &&
              this.state.recordsoncerns.push({
                customCatergoryName: item.name,
                customId: item.id,
              })

            item.name == "HGPH Hubs" && item.choices.map((choice: any) => {
              this.state.recordsHGPHHubs.push({
                text: choice.text,
                id: choice.id,
              })
            })

            item.name == "HGPH Hubs" &&
              this.state.recordsHGPHHubs.push({
                customCatergoryName: item.name,
                customId: item.id,
              })

            item.name == "School Year" && item.choices.map((choice: any) => {
              this.state.recordsSchoolYear.push({
                text: choice.text,
                id: choice.id,
              })
            })

            item.name == "School Year" &&
              this.state.recordsSchoolYear.push({
                customCatergoryName: item.name,
                customId: item.id,
              })

          })
        }
      }

      else {
        console.log('custom categories error', this.state.customCategorisData.errors[0])
      }
    }
    // ---------------------  custom categories response end -----------------------

    // ---------------------  create ticket response start -----------------------
    if (this.apiCreateTicketCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ updateOpen: true }, () => {
          setTimeout(() => {
            this.props.navigation.navigate('TicketStatusInfo');
          }, 5000)
        })

        console.log('create ticket', apiResponse)
      }
      else {
        console.log('create ticket error', apiResponse?.errors)
      }
    }
    // ---------------------  create ticket response end -----------------------



    // -----------------------All Tickets start-----------------------------------------------------------
    if (this.apiAllTicketsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({
          ticketsPageInfo: apiResponse?.page_info,
          allTicketsData: apiResponse?.data
        })
      }
      else {
        console.log(' All Tickets error', apiResponse?.error)
      }
    }
    // Customizable Area End
  }

  // -----------------------All Tickets end-----------------------------------------------------------
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getTicketCategories();
    this.getAllTicketsData(1, "");
    this.getCustomCategories();


  }
  // -----------------------Ticket categories start-----------------------------------------------------------
  getTicketCategories = () => {
    const header = {
      "Content-Type": configJSON.apicontentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTicketCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiTicketCategoriesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // -----------------------Ticket categories end-----------------------------------------------------------
  // -----------------------Custom categories start-----------------------------------------------------------
  getCustomCategories = () => {

    const header = {
      "Content-Type": configJSON.apicontentType,
      "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      data: {
        category_id: this.state.ticketCategory.id,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCustomCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCustomCategoriesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // -----------------------Custom categories end-----------------------------------------------------------
  // -----------------------Custom categories start-----------------------------------------------------------
  createTicket = () => {

    const header = {
      "token": localStorage.getItem("authToken")
    };
    let formdata = new FormData();


    formdata.append("ticket[name]", localStorage.getItem("username") || "");
    formdata.append("ticket[email]", localStorage.getItem("email") || "");
    formdata.append("ticket[phone]", localStorage.getItem("phoneNumber") || "");

    formdata.append("ticket[subject]", this.state.ticketSubject,);
    formdata.append("ticket[text]", convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text);
    formdata.append("ticket[cc]", this.state.ticketAddCC);
    formdata.append("ticket[bcc]", this.state.ticketAddBCC);

    // ticket category  "36"
    formdata.append("ticket[category]", this.state.customCategorisData.category_id);
    // ticket t-cf-33 "405"
    formdata.append("ticket[t-cf-33]", this.state.academicProgramAutoCompleteValue.id);
    // ticket t-cf-32 "387"
    formdata.append(`ticket[t-cf-${this.state.ticketMainConcernGroup.id}]`, this.state.academicConcern.id);

    //name of the student
    formdata.append("ticket[t-cf-13]", this.state.ticketNameOfStudent);
    //HGPH Hubs
    formdata.append("ticket[t-cf-18]", this.state.hubAutoCompleteValue);
    //"Records Concerns" or "Type of Document*"
    formdata.append("ticket[t-cf-17]", this.state.typeOfDocAutoCompleteValue);
    //"School Year"
    formdata.append("ticket[t-cf-36]", this.state.schoolYearAutoCompleteValue);
    // "Partner School"
    formdata.append("ticket[t-cf-8]", this.state.partnerSchoolAutoCompleteValue);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTicketCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCreateTicketCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // -----------------------Custom categories end-----------------------------------------------------------

  // -----------------------All Tickets start-----------------------------------------------------------

  getAllTicketsData = (pageNum: number, statusValue: string) => {

    const header = {
      "Content-Type": configJSON.contentTypeApiHgFormula,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllTicketsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.allTicketsUrl 
      configJSON.allTicketsUrl + `?page=${pageNum}` + `&status=${statusValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allTicketsMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // -----------------------All Tickets end-----------------------------------------------------------


  handleDrawerOpen = () => {
    this.setState({ openD: !this.state?.openD })
  }

  handleTicketNavigation = () => {
    this.props.navigation.navigate('CreateTickets')
  }

  handleGetOptionLabel = (option: any) => {
    return (option.text || ""
    )
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState
    });
    this.state.ticketErrors['editorState'] = ""
    console.log("TEST DEBUG", convertToRaw(editorState.getCurrentContent()).blocks[0].text)
  };

  handleChangeTicketCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors['ticketCategory'] = "";
    this.setState({
      ticketCategory: {
        ...this.state.ticketCategory,
        name: e.target.value,
        id: this.state.ticketCategoryDataSelect.find((o: any) => o.name == e.target.value)?.id
      }
    }, () => {
      this.getCustomCategories();
    })
  }


  handlerChangeacademicConcern = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['academicConcern'] = "";
    this.setState({
      academicConcern: {
        ...this.state.academicConcern,
        text: newInputValue,
        id: this.state.ticketConcerns.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }

  handlerChangeacademicProgram = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['academicProgram'] = "";
    this.setState({
      academicProgram: {
        ...this.state.academicProgram,
        text: newInputValue,
        id: this.state.academicProgramData.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangeHubRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['hubRecords'] = "";
    this.setState({
      hubRecords: {
        ...this.state.hubRecords,
        text: newInputValue,
        id: this.state.recordsHGPHHubs.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangeTypeOfDocRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['typeOfDocRecords'] = "";
    this.setState({
      typeOfDocRecords: {
        ...this.state.typeOfDocRecords,
        text: newInputValue,
        id: this.state.recordsoncerns.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangeSchoolYearRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['schoolYearRecords'] = "";
    this.setState({
      schoolYearRecords: {
        ...this.state.schoolYearRecords,
        text: newInputValue,
        id: this.state.recordsSchoolYear.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangePartnerSchoolRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['partnerSchoolRecords'] = "";
    this.setState({
      partnerSchoolRecords: {
        ...this.state.partnerSchoolRecords,
        text: newInputValue,
        id: this.state.recordsPartnerSchool.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }

  handlerChangeticketConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ticketConsent: e.target.checked })
    this.state.ticketErrors['ticketConsent'] = "";
  }
  handlerChangeticketNameOfStudent = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketNameOfStudent: e.target.value })
  handlerChangeticketSubject = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketSubject: e.target.value })
  handlerChangeticketAddCC = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketAddCC: e.target.value })
  handlerChangeticketAddBCC = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketAddBCC: e.target.value })
  handlerChangeticketFullName = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketFullName: e.target.value })
  handlerChangeticketEmail = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketEmail: e.target.value })
  handlerChangeticketPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketPhoneNumber: e.target.value })
  handleChangeacademicConcernAutoCompleteValue = (newValue: any) => this.setState({ academicConcernAutoCompleteValue: newValue })



  handleChangeacademicProgramAutoCompleteValue = (newValue: any) => this.setState({ academicProgramAutoCompleteValue: newValue })
  handleChangeHubAutoCompleteValue = (newValue: any) => {
    this.setState({ hubAutoCompleteValue: newValue })
  }
  handleChangeTypeOfDocAutoCompleteValue = (newValue: any) => this.setState({ typeOfDocAutoCompleteValue: newValue })
  handleChangeSchoolYearAutoCompleteValue = (newValue: any) => this.setState({ schoolYearAutoCompleteValue: newValue })
  handleChangePartnerSchoolAutoCompleteValue = (e: any, newValue: any) => this.setState({ partnerSchoolAutoCompleteValue: newValue })

  handleOnClickCC = () => {
    this.setState({ openCC: true })
  }
  handleOnClickBCC = () => {
    this.setState({ openBCC: true })
  }


  hideError = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors[event.target.name] = '';
    this.setState({ ticketErrors: this.state.ticketErrors })
  }

  handleCancelTicket = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
      ticketCategory: "choose",
      ticketSubject: "",
      // ticketAddCC:"",
      ticketFile: { filenm: new File([""], "") },
      ticketFullName: "",
      ticketEmail: "",
      ticketPhoneNumber: "",
      ticketConsent: false,
      ticketErrors: {},
    })
  }


  handleCreateTicket = () => {
    if (this.ticketValidate()) {
      this.createTicket()
    }
    else {

      this.setState({
        ticketErrors: this.state.ticketErrors
      })
      console.log(this.state.ticketErrors)
    }
  }

  handlePreviousDisable = () => {
    return this.state.ticketsPageInfo.start_index === 1 || this.state.ticketsPageInfo.start_index === 0;
  }

  handleNextDisable = () => {
    return this.state.ticketsPageInfo.end_index === this.state.ticketsPageInfo.last_index
  }

  handlePrevious = () => {
    const prevRowValue = this.state.rowsPerPage - this.state.ticketsPageInfo.count
    //  console.log("luck prev page ", prevRowValue / this.state.ticketsPageInfo?.count)
    if (this.state.rowsPerPage >= 0) {
      this.setState({
        rowsPerPage: prevRowValue,
        prevDisable: false,
        nextDisable: false
      })
      this.setState((prevState: any) => ({
        pageCount: prevState.pageCount - 1
      }), () => {
        this.getAllTicketsData(this.state.pageCount, this.state.filterOption);
      })
    } else {
      this.setState({ prevDisable: true })
    }
  }

  handleNext = () => {

    const nextRowValue = this.state.rowsPerPage + this.state.ticketsPageInfo.count
    if (this.state.rowsPerPage < this.state.ticketsPageInfo?.last_index) {
      this.setState({
        rowsPerPage: nextRowValue,
        prevDisable: false,
        nextDisable: false,
      });
      this.setState((prevState: any) => ({
        pageCount: prevState.pageCount + 1
      }), () => {
        this.getAllTicketsData(this.state.pageCount, this.state.filterOption);
      })
    } else {
      this.setState({ nextDisable: true })
    }
  }


  handleChange = (e: any) => {

    this.setState({ filterOption: e.target.value }, () => {
      console.log("luck filterOption", this.state.filterOption);
    })

    if (e.target.value === "All Tickets") {
      this.getAllTicketsData(this.state.pageCount, "");
    }
    else if (e.target.value === "Pending") {
      this.getAllTicketsData(this.state.pageCount, "Pending");
    }
    else if (e.target.value === "Completed") {
      this.getAllTicketsData(this.state.pageCount, "Completed");
    }
    else if (e.target.value === "Pending action required") {
      this.getAllTicketsData(this.state.pageCount, "Pending action required");
    }
  };




  //  =================== ticket validation starts ===========================
  ticketValidate = () => {
    let isFormValid = true;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (this.state.ticketCategory.name === 'choose') {
      this.state.ticketErrors['ticketCategory'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketSubject === '') {
      this.state.ticketErrors['ticketSubject'] = "Required"
      isFormValid = false
    }
    if (convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text == "") {
      this.state.ticketErrors['editorState'] = "Required"
      isFormValid = false
    }

    // Type of Concern*

    if (this.state.customCategorisData.category_id != 25) {
      if (this.state.academicConcern.text === 'choose' || this.state.academicConcern.text === '') {
        this.state.ticketErrors['academicConcern'] = "Required *"
        isFormValid = false
      }
    }

    // Program
    if ((this.state.customCategorisData.category_id == 36)) {
      if (this.state.academicProgram.text === 'choose' || this.state.academicProgram.text === '') {
        this.state.ticketErrors['academicProgram'] = "Required"
        isFormValid = false
      }
    }

    if ((this.state.customCategorisData.category_id == 48 || this.state.customCategorisData.category_id == 25)) {
      if (this.state.ticketNameOfStudent === '') {
        this.state.ticketErrors['ticketNameOfStudent'] = "Required"
        isFormValid = false
      }
      else if (!regexName.test(this.state.ticketNameOfStudent)) {
        this.state.ticketErrors['ticketNameOfStudent'] = "Incorrect format - Only alphabets are allowed."
        isFormValid = false;
      }
    }

    if ((this.state.customCategorisData.category_id == 25)) {
      if (this.state.hubRecords.text === 'choose' || this.state.hubRecords.text === '') {
        this.state.ticketErrors['hubRecords'] = "Required"
        isFormValid = false
      }
    }
    if ((this.state.customCategorisData.category_id == 25)) {
      if (this.state.typeOfDocRecords.text === 'choose' || this.state.typeOfDocRecords.text === '') {
        this.state.ticketErrors['typeOfDocRecords'] = "Required"
        isFormValid = false
      }
    }
    if ((this.state.customCategorisData.category_id == 25)) {
      if (this.state.schoolYearRecords.text === 'choose' || this.state.schoolYearRecords.text === '') {
        this.state.ticketErrors['schoolYearRecords'] = "Required"
        isFormValid = false
      }
    }
    if ((this.state.customCategorisData.category_id == 25)) {
      if (this.state.partnerSchoolRecords.text === 'choose' || this.state.partnerSchoolRecords.text === '') {
        this.state.ticketErrors['partnerSchoolRecords'] = "Required"
        isFormValid = false
      }
    }



    if (this.state.ticketAddCC !== '') {
      if (!emailRegex.test(this.state.ticketAddCC)) {
        isFormValid = false;
        this.state.ticketErrors['ticketAddCC'] = "Incorrect Email"
      }
    }

    if (this.state.ticketAddBCC !== '') {
      if (!emailRegex.test(this.state.ticketAddBCC)) {
        isFormValid = false;
        this.state.ticketErrors['ticketAddBCC'] = "Incorrect Email"
      }
    }


    if (this.state.ticketConsent === false) {
      this.state.ticketErrors['ticketConsent'] = "Required"
      isFormValid = false
    }

    return isFormValid
  }
  //  =================== ticket validation ends ===========================

  ///file upload function start
  uploadFiles = (file: any, purpose: any) => {
    if (purpose === 'ticketFile') {
      this.setState({ ticketFile: { filenm: file } })
    }
  }
  ///file upload function end


  // Customizable Area End
}
