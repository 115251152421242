export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const avatar = require("../assets/avatar.png");
export const mother_daughter = require("../assets/mother_daughter.png");
export const history = require("../assets/history.png");
export const history_b = require("../assets/history_b.png");
export const manners = require("../assets/manners.png");
export const success = require("../assets/success.png");
export const teenage = require("../assets/teenage.png");
export const true_success = require("../assets/true_success.png");
export const checkbox_img = require("../assets/checkbox_img.png");
export const stripe = require("../assets/Bitmap.png");
export const dragonPay = require("../assets/dragonPay.png");
export const robot = require("../assets/robot.png");
export const call_bubble = require("../assets/call_bubble.png");
export const chat_bubble = require("../assets/chat_bubble.png");
export const chat_avatar = require("../assets/chat_avatar.png");
export const download_icon = require("../assets/download_icon.png");
export const paypal = require("../assets/paypal.png");
export const ipay = require("../assets/ipay.png");
export const mastercard = require("../assets/mastercard.png");
export const visa = require("../assets/visa.png");
export const profilePic = require("../assets/profilePic.svg");
