import React from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentPage from './PaymentPage.web'

const stripePromise = loadStripe('pk_test_51LDp9ISFt3OmzZmd3fxDGwAV2Jr3aRng5ci3p2w9huETi6FH9Dhj2h6Y9pOnUqbcr5un5fUkntJB27uu4i5o3V2N00fjaqQBWs')

const StripePayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentPage />
    </Elements>
  )
}

export default StripePayment