import React, { Component } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Grid,
  MenuItem,
  Switch,
  // Customizable Area End
} from "@material-ui/core";
import DashboardController, { Props } from "./DashboardController";


export default class DashboardPage extends DashboardController {
  render() {
    return (
      <div>
        <h1> Welcome to Dashboard</h1>
        <Button onClick={this.studentEnrollment} variant="contained"style={{textTransform:"none",backgroundColor:"orange",color:"white"}}>studentEnrollment</Button>
      </div>
      
      
    )
  }
}

