import React, { Component } from "react";

import {
    // Customizable Area Start
  Box,
  InputLabel,
  Typography,
  TextField,
  Grid,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);

export default class CustomEnroll4 extends Component<any> {
  render() {
    const { studentFormData, handleChangePersonal,
      parentsInfoFathercountriesuniqueCountryCodeSelectedData,
      fathercountriesDataChange, mothercountriesDataChange, GuardianscountriesDataChange,
      hideError, MaritalStatusChange, uniqueCountryCodeData,uniqueCountryCodeDataOne,
      programFatherContactHandler,programMotherContactHandler,programGardianContactHandler

    } = this.props;
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Typography style={webStyle.heading} >Parents Info</Typography>

        <Grid container spacing={4} xs={12} style={{ padding: '2em' }} >
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Father's First Name *</InputLabel>
            <StyledTextField
              placeholder="First Name"
              fullWidth
              name='parentsInfoFathersFirstName'
              value={studentFormData.parentsInfoFathersFirstName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoFathersFirstName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersFirstName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Father's Middle Name *</InputLabel>
            <StyledTextField
              placeholder="Middle Name"
              fullWidth
              name='parentsInfoFathersMiddleName'
              value={studentFormData.parentsInfoFathersMiddleName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoFathersMiddleName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersMiddleName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Father's Last Name *</InputLabel>
            <StyledTextField
              placeholder="Last Name"
              fullWidth
              name='parentsInfoFathersLastName'
              value={studentFormData.parentsInfoFathersLastName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoFathersLastName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersLastName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Father's Occupation*</InputLabel>
            <StyledTextField
              placeholder="occupation"
              fullWidth
              name='parentsInfoFathersOccupation'
              value={studentFormData.parentsInfoFathersOccupation}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoFathersOccupation)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersOccupation}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box >
              <InputLabel style={webStyle.inputLabelStyle}>Father's Mobile Number *</InputLabel>
              <Box style={{ display: 'flex', alignItems: 'end' }}>
                <StyledTextField
                  select
                  style={{ width: '80px', marginTop: '-15px' }}
                  InputLabelProps={{ shrink: false }}
                  name='parentsInfoFathersCountryCode'
                  value={studentFormData.parentsInfoFathersCountryCode}
                  onChange={fathercountriesDataChange}

                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>+91</span></MenuItem>


                  {

                    uniqueCountryCodeDataOne?.map((item: any, index: number) => {
                      return (

                        <MenuItem
                          key={index}
                          value={item?.phonecode}
                        >
                           {/* {
                                item?.phonecode.includes('+') ? item?.phonecode 
                                :item?.phonecode== ''?  item?.phonecode
                                : '+' + item?.phonecode
                              } */}
                              {
                                '+' + item?.phonecode
                              }

                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>

                <StyledTextField
                  style={{ marginLeft: '20px' }}
                  inputProps={{maxLength: 11}}
                  placeholder="0123456789"
                  fullWidth

                  name='parentsInfoFathersPhoneNumber'
                  type="number"
                  value={studentFormData.parentsInfoFathersPhoneNumber}
                  onChange={programFatherContactHandler}
                  // onChange={handleChangePersonal}
                  onFocus={hideError}
                />
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={{ marginTop: '3px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoFathersCountryCode)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersCountryCode}</small>
                      : <small style={{ visibility: 'hidden' }}>"Code Require"</small>
                  }
                </Box>

                <Box style={{ marginTop: '3px', marginLeft: '13px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoFathersPhoneNumber)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersPhoneNumber}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Father's Email Address (if not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="email address"
              fullWidth
              type="email"
              name='parentsInfoFathersEmailAddress'
              value={studentFormData.parentsInfoFathersEmailAddress}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoFathersEmailAddress)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoFathersEmailAddress}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Mother's First Name *</InputLabel>
            <StyledTextField
              placeholder="First Name"
              fullWidth={true}
              name='parentsInfoMothersFirstName'
              value={studentFormData.parentsInfoMothersFirstName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMothersFirstName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersFirstName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Mother's Middle Name (Maiden) *</InputLabel>
            <StyledTextField
              placeholder="Middle Name"
              fullWidth
              name='parentsInfoMothersMiddleName'
              value={studentFormData.parentsInfoMothersMiddleName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMothersMiddleName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersMiddleName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Mother's Last Name  *</InputLabel>
            <StyledTextField
              placeholder="Last Name"
              fullWidth
              name='parentsInfoMothersLastName'
              value={studentFormData.parentsInfoMothersLastName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMothersLastName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersLastName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Mother's Occupation *</InputLabel>
            <StyledTextField
              placeholder="occupation"
              fullWidth
              name='parentsInfoMothersOccupation'
              value={studentFormData.parentsInfoMothersOccupation}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMothersOccupation)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersOccupation}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box >
              <InputLabel style={webStyle.inputLabelStyle}>Mother's Mobile Number *</InputLabel>
              <Box style={{ display: 'flex', alignItems: 'end' }}>
                <StyledTextField
                  select
                  style={{ width: '80px', marginTop: '-15px' }}
                  InputLabelProps={{ shrink: false }}
                  name='parentsInfoMothersCountryCode' value={studentFormData.parentsInfoMothersCountryCode}
                  onChange={mothercountriesDataChange}

                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>+91</span></MenuItem>
                  {

                  uniqueCountryCodeDataOne?.map((item: any, index: number) => {
                      return (

                        <MenuItem
                          key={index}
                          value={item?.phonecode}
                        >
                           {/* {
                                item?.phonecode.includes('+') ? item?.phonecode 
                                :item?.phonecode== ''?  item?.phonecode
                                : '+' + item?.phonecode
                              } */}
                              {
                                '+' + item?.phonecode
                              }
                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>

                <StyledTextField
                  style={{ marginLeft: '20px' }}
                  inputProps={{maxLength: 11}}
                  placeholder="0123456789"
                  fullWidth
                  type="number"
                  name='parentsInfoMothersPhoneNumber'
                  value={studentFormData.parentsInfoMothersPhoneNumber}
                  onChange={programMotherContactHandler}
                  // onChange={handleChangePersonal}
                  onFocus={hideError}
                />
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={{ marginTop: '3px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoMothersCountryCode)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersCountryCode}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>

                <Box style={{ marginTop: '3px', marginLeft: '13px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoMothersPhoneNumber)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersPhoneNumber}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Box>

            </Box>
          </Grid>

          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Mother's Email Address (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="Address"
              type="email"
              fullWidth
              name='parentsInfoMothersEmailAddress'
              value={studentFormData.parentsInfoMothersEmailAddress}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMothersEmailAddress)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMothersEmailAddress}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Marital Status *</InputLabel>
            <StyledTextField
              select
              color="darkgrey"
              fullWidth
              name="parentsInfoMaritalStatus"
              value={studentFormData.parentsInfoMaritalStatus}
              onChange={MaritalStatusChange}
            >
              <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>Please Select</span></MenuItem>
              <MenuItem value="Married">Married</MenuItem>
              <MenuItem value="Not Married">Not Married</MenuItem>
              <MenuItem value="Solo Parent">Solo Parent</MenuItem>
              <MenuItem value="Legally Separated">Legally Separated</MenuItem>
            </StyledTextField>
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoMaritalStatus)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoMaritalStatus}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Guardian's Name (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="N/A"
              fullWidth
              name='parentsInfoGuardiansName'
              value={studentFormData.parentsInfoGuardiansName}
              onChange={handleChangePersonal}
              onFocus={hideError}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoGuardiansName)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansName}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Guardian's Address (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="N/A"
              fullWidth
              name='parentsInfoGuardiansAddress'
              value={studentFormData.parentsInfoGuardiansAddress}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoGuardiansAddress)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansAddress}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Guardian's Occupation (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="N/A"
              fullWidth
              name='parentsInfoGuardiansOccupation'
              value={studentFormData.parentsInfoGuardiansOccupation}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />

            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoGuardiansOccupation)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansOccupation}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box >
              <InputLabel style={webStyle.inputLabelStyle}>Guardian's Mobile Number (If not applicable,input "0") *</InputLabel>
              <Box style={{ display: 'flex', alignItems: 'end' }}>
                <StyledTextField
                  select
                  style={{ width: '80px', marginTop: '-15px' }}
                  InputLabelProps={{ shrink: false }}
                  name='parentsInfoGuardiansCountryCode'
                  value={studentFormData.parentsInfoGuardiansCountryCode}
                  onChange={GuardianscountriesDataChange}
                >
                  <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>+91</span></MenuItem>

                  {

                  uniqueCountryCodeDataOne?.map((item: any, index: number) => {
                      return (

                        <MenuItem
                          key={index}
                          value={item?.phonecode}
                        >
                           {/* {
                                item?.phonecode.includes('+') ? item?.phonecode 
                                :item?.phonecode== ''?  item?.phonecode
                                : '+' + item?.phonecode
                              } */}
                              {
                                '+' + item?.phonecode
                              }

                        </MenuItem>
                      )

                    })

                  }
                </StyledTextField>

                <StyledTextField
                  style={{ marginLeft: '20px' }}
                  inputProps={{maxLength: 11}}
                  placeholder="0123456789"
                  fullWidth
                  name='parentsInfoGuardiansPhoneNumber'
                  type="number"
                  value={studentFormData.parentsInfoGuardiansPhoneNumber}
                 // onChange={programGardianContactHandler}
                  onChange={handleChangePersonal}
                  onFocus={hideError}
                />
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={{ marginTop: '3px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoGuardiansCountryCode)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansCountryCode}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>

                <Box style={{ marginTop: '3px', marginLeft: '13px' }}>
                  {
                    (studentFormData.parentsValidationErrors.parentsInfoGuardiansPhoneNumber)
                      ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansPhoneNumber}</small>
                      : <small style={{ visibility: 'hidden' }}>""</small>
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Guardian's Email Address (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="N/A"
              fullWidth={true}
              name='parentsInfoGuardiansEmailAddress'
              value={studentFormData.parentsInfoGuardiansEmailAddress}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoGuardiansEmailAddress)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoGuardiansEmailAddress}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Relationship to the student (If not applicable,input "N/A") *</InputLabel>
            <StyledTextField
              placeholder="N/A"
              fullWidth
              name='parentsInfoRelationshipToTheStudent'
              value={studentFormData.parentsInfoRelationshipToTheStudent}
              onChange={handleChangePersonal}
              onFocus={hideError}
            />
            <Box style={{ marginTop: '3px' }}>
              {
                (studentFormData.parentsValidationErrors.parentsInfoRelationshipToTheStudent)
                  ? <small style={webStyle.errorStyle}>{studentFormData.parentsValidationErrors.parentsInfoRelationshipToTheStudent}</small>
                  : <small style={{ visibility: 'hidden' }}>""</small>
              }
            </Box>
          </Grid>

        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  StudentMenuItems:{
    display: "none", color: 'darkgray' 
  },
  heading: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "1em",
    paddingLeft: '1.4em'

  },
  inputLabelStyle: {
    marginBottom: '10px',
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  errorStyle: {
    padding: '5px',
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: ' 12px',
  }

};
// Customizable Area End
