Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.contentTypeApiVideo = "application/json";
exports.apiVideoCallMethodType = "GET";
exports.apiVideoCallUrl="bx_block_upload_media/videos?video_type=onboarding"

exports.contentTypeApiAllVideo = "application/json";
exports.apiAllVideoCallMethodType = "GET";
exports.apiAllVideoCallUrl="bx_block_upload_media/videos"

exports.calcGradePage="bx_block_student/grades"
exports.calcProgramPage="bx_block_student/programs"
exports.calcAccreditionPage="bx_block_student/accreditations"

exports.contentTypeApiPostIntroVideo = "application/json";
exports.apiPostIntroVideoCallMethodType = "POST";
exports.apiPostIntroVideoCallUrl="bx_block_student/track_time/update_video_detail"

exports.contentTypeApiUser = "application/json";
exports.apiUserCallMethodType = "GET";
exports.apiUserCallUrl="bx_block_customisableuserprofiles/profiles/6"

exports.contentTypeApiVideoTime = "application/json"; 
exports.apiVideoTimeCallMethodType = "POST";
exports.apiVideoTimeCallUrl="bx_block_student/track_time/onboarding_video_detail"

exports.contentTypeApiResendLink = "application/json"; 
exports.apiResendLinkCallMethodType = "POST";
exports.apiResendLinkCallUrl="profiles/resend_activation_link"

exports.contentTypeApiHgFormula = "application/json";
exports.hgFormulaMethodType = "GET";
exports.hgFormulaUrl="bx_block_dashboard/hg_formulas"

exports.contentTypeEmailActivation = "application/json";
exports.emailActivationMethodType = "GET";
exports.emailActivationUrl="account/accounts/email_confirmation"
// Customizable Area End